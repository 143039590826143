import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";

import { pxToRem } from "../../../../../../assets/theme/functions";
import SmileysModal from "../../../../../../components/SmileysModal";
import { submitDeveloperRating } from "../../../../../../redux/actions/ProjectActions";
import Progress from "../../../../../../components/Progress";

const ClientSurvey = () => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();

  const [developers, setDevelopers] = useState([]);
  const [incompleteRatings, setIncompleteRatings] = useState();
  const [projectRating, setProjectRating] = useState({});
  const [isReady, setIsReady] = useState(false);

  const { progress_event, project, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const onChange = (value, idx) => {
    if (project.category === "project") {
      setProjectRating({
        ...projectRating,
        rating: value,
      });
    } else {
      const devs = developers;
      devs[idx] = { ...devs[idx], rating: value };
      setDevelopers([...devs]);
    }
  };

  const onSave = (e) => {
    e.preventDefault();

    let completeRating = false;

    if (project.category === "dedicated") {
      developers.forEach((rating) => {
        if (rating.rating === "") {
          completeRating = true;
        }
      });
    } else if (projectRating.rating === "") {
      completeRating = true;
    }

    if (completeRating) {
      setIncompleteRatings(true);
      return false;
    }
    setIncompleteRatings(false);

    if (project.category === "project") {
      const payload = {
        event: {
          id: params.report,
        },
      };

      const ratingId =
        progress_event.progress_reports.length > 0 ? progress_event.progress_reports[0].id : null;

      payload.rate_communication = projectRating.rating;

      dispatch(
        submitDeveloperRating({
          ...payload,
          id: ratingId,
        }),
      );
    } else {
      developers.forEach((developer) => {
        const payload = {
          event: {
            id: params.report,
          },
        };

        let ratingId = null;
        progress_event.developer_ratings.forEach((rating) => {
          if (rating.user.id === developer.userId) {
            ratingId = rating.id;
          }
        });

        payload.rating = developer.rating;
        payload.user = developer.userId;

        dispatch(
          submitDeveloperRating({
            ...payload,
            id: ratingId,
          }),
        );
      });
    }
    return true;
  };

  useEffect(() => {
    if (project !== null && progress_event.project && Object.keys(project || {}).length !== 0) {
      if (project.category === "project") {
        if (progress_event.progress_reports.length > 0) {
          setProjectRating({
            id: progress_event.progress_reports[0].id,
            rating: progress_event.progress_reports[0].rate_communication,
          });
        } else {
          setProjectRating({
            rating: "",
          });
        }
      } else {
        const devs = project.participation.map((rating) => {
          return {
            id: rating.id,
            name: rating.user.display_name,
            rating: "",
            userId: rating.user.id,
          };
        });

        const developerRatings = devs;
        devs.forEach((rating, i) => {
          let ratingValue = "";
          if (progress_event.developer_ratings) {
            progress_event.developer_ratings.forEach((developer_rating) => {
              if (rating.userId === developer_rating.user.id) {
                ratingValue = `${developer_rating.rating}`;
              }
            });
          }
          developerRatings[i].rating = ratingValue;
        });

        setDevelopers([...developerRatings]);
      }
      setIsReady(true);
    }
  }, [project]);

  return (
    <Wrapper data-testid="clientSurvey-form" onSubmit={onSave} className="container">
      <div className="content">
        {!isReady || project === null ? (
          <Progress />
        ) : developers.length > 0 || project?.category === "project" ? (
          <div className="card">
            {incompleteRatings && (
              <div className="card-body">
                <div className="text-danger">Please complete all ratings</div>
              </div>
            )}

            {project?.category === "dedicated" ? (
              <div className="card-body">
                {developers.map((dev, idx) => (
                  <div className="card__section" key={`dev-${idx}`}>
                    <h4>
                      How would you rate your collaboration with <b>{dev.name}</b> on this project?
                    </h4>
                    <SmileysModal
                      index={idx}
                      name={`rating-${dev.id}`}
                      selected={dev.rating}
                      onChange={onChange}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="card-body">
                <div className="card__section">
                  <h4>How would you rate your collaboration with Tunga on this project?</h4>
                  <SmileysModal
                    index={1}
                    name="rating-10"
                    selected={`${projectRating.rating}`}
                    onChange={onChange}
                  />
                </div>
              </div>
            )}

            <div className="card-bottom">
              <div className="card-body">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isMakingRequest.developerRating}
                >
                  Submit Report
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              The project currently has no developers attached to it for you to rate. Please ask a
              CM to enable this.
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content {
    padding: 0px;
  }

  .card {
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .card-bottom {
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;
    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .card__section {
    padding: 2.4rem 1rem;
    border-bottom: 1px solid #edf1f7;
    &:last-child {
      border: 0px solid #edf1f7;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 150%;
      margin-bottom: 0.9rem;
      color: #151a30;
    }
  }
`;

export default ClientSurvey;
