/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { isDesigner } from "../../../../utils/auth";
import { StyledForm } from "../../../../utils/styles";
import { cleanSkills } from "../../../../utils/api";
import PrioritySkillSelector from "../../../../components/PrioritySkillSelector";
import theme from "../../../../assets/theme";

const isADesigner = [
  ["language", "Design skills"],
  ["platform", "Design tools"],
];
const isNotADesigner = [
  ["language", "Programming languages"],
  ["framework", "Frameworks"],
  ["platform", "Platforms"],
  ["library", "Libraries"],
  ["storage", "Storage engines"],
];

const SkillsetForm = ({ id, skills: skillsFromProps, proceed }) => {
  const [filters] = useState([
    ...(!isDesigner() ? isNotADesigner : isADesigner),
    ["other", "Miscellaneous"],
  ]);
  const [filter, setFilter] = useState([]);

  const [skillsByCategory, setSkillsByCategory] = useState(() => {
    const cleanedSkills = cleanSkills(skillsFromProps) || [];

    return cleanedSkills.reduce((accSkills, skill) => {
      const accumulator = { ...accSkills };
      const skillType = accumulator[skill.type] || [];

      skillType.push(skill);

      accumulator[skill.type] = skillType;

      return accumulator;
    }, {});
  });

  /* istanbul ignore next */
  const changeFilter = (newFilterValue) => {
    setFilter(newFilterValue);
  };

  /* istanbul ignore next */
  const onSkillChange = (category, newSkillsValue) => {
    const newSkills = cleanSkills(newSkillsValue).map((skill) => {
      return { ...skill, type: skill?.type || category };
    });

    const skillsByCategoryClone = { ...skillsByCategory };

    skillsByCategoryClone[category] = newSkills;

    setSkillsByCategory({ ...skillsByCategoryClone });
  };

  const onSave = (e) => {
    e.preventDefault();
    const skills = Object.values(skillsByCategory).flat();

    proceed({
      skills,
    });
  };

  useEffect(() => {
    setFilter(filters[0]);
  }, []);

  return (
    <StyledForm id={id} data-testid="skillset-form" onSubmit={onSave} role="form">
      <StyledNavLinks className="filter-navbar">
        <div className="filter-links">
          {filters.map((link, i) => {
            return (
              <button
                type="button"
                key={`skill-filters--${i}`}
                className={`btn ${link[0] === filter[0] ? "active" : null}`}
                onClick={() => changeFilter(link)}
              >
                {link[1]}
              </button>
            );
          })}
        </div>
      </StyledNavLinks>

      <FormGroup>
        <ThemeProvider theme={theme}>
          <PrioritySkillSelector
            filter={{
              filter: filter.length > 0 ? filter[0] : "null...",
            }}
            onChange={(skill) => onSkillChange(filter ? filter[0] : {}, skill)}
            selected={skillsByCategory[filter[0]] || []}
            placeholder="Type to search and select..."
          />
        </ThemeProvider>
      </FormGroup>
    </StyledForm>
  );
};

const StyledNavLinks = styled.div`
  margin-bottom: 40px;
  overflow-x: scroll;

  .filter-links {
    width: max-content;
    display: inline-block;
    padding: 2px 0;

    button.btn {
      padding: 0;
      border-radius: 0;
      position: relative;
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #8f9bb3;
      margin-right: 40px;
      text-transform: uppercase;

      &.active {
        border-radius: 0;
        border-bottom: 3px solid #da3451;
        padding-bottom: 12px;
        font-weight: 600;
        color: #da3451;
        box-shadow: none;
      }
    }
  }
`;

SkillsetForm.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      primary: PropTypes.bool,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  proceed: PropTypes.func,
  id: PropTypes.string,
};

export default SkillsetForm;
