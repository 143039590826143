/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import TitleBarSwitch from "./TitleBarSwitch";

/* -------------------- Internel Dependencies (Utilites) -------------------- */
import {
  isAdmin,
  isAdminOrPMOrSpaceLead,
  isClient,
  isCSO,
  isDev,
  isPM,
  isSpaceLead,
} from "../utils/auth";

/* ------------------------- Component dependencies ------------------------- */
import TitleBarContent from "./TitleBarContent";

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  user: PropTypes.shape({}),
  Project: PropTypes.shape({}),
};

const NavLinks = () => {
  const { project } = useSelector(({ Projects }) => Projects);

  const getUrl = (section) => {
    return (match) => {
      return `/projects/${match.params.projectId}/${section}`;
    };
  };

  const shortlistLength = () => {
    return <LinkBadge>{project?.total_shortlisted}</LinkBadge>;
  };

  const projectsSections = [
    ["/projects/dedicated", "Dedicated Developers"],
    ["/projects/managed", "Managed Projects"],
    ...(isClient() || isDev() ? [] : [["/projects/opportunity", "Opportunities"]]),
    ["/projects/archived", "Archive"],
    ...(isPM() || isCSO() || isSpaceLead()
      ? [[null, <TitleBarSwitch key="title-bar-switch" />]]
      : []),
  ];

  const projectDetailSections = project?.id
    ? project?.stage === "opportunity"
      ? [
          [getUrl("details"), "Details"],
          ...(!isDev()
            ? [
                [getUrl("network/shortlist"), "Shortlist", shortlistLength()],
                [getUrl("network/talent-pool"), "Talent Pool"],
                ...(!isClient() ? [[getUrl("settings"), "Settings"]] : []),
              ]
            : []),
        ]
      : [
          [getUrl("pay"), "Payments"],
          [getUrl("plan"), "Planning"],
          [getUrl("reports"), "Reports"],
          [getUrl("docs"), "Documents"],
          ...(!isClient() && !isDev() ? [[getUrl("settings"), "Settings"]] : []),
        ]
    : [];

  const paymentSections = [
    ...(isAdminOrPMOrSpaceLead() || isCSO()
      ? [
          ["/payments/in", "Payments"],
          ["/payments/out", "Payouts"],
        ]
      : []),
  ];
  const settingsSections = [
    ...(isDev() || isPM() || isCSO() || isSpaceLead()
      ? [
          ["/settings/profile", "Profile"], // All
          ["/settings/personal-details", "Personal details"], // All
          ["/settings/payment", "Payment"],
          ["/settings/account", "Account"], // All
          ["/settings/privacy", "Privacy"],
          ...(isPM() || isSpaceLead() ? [["/settings/users", "Users"]] : []),
        ]
      : [
          ["/settings/personal-details", "Personal details"], // All
          ...(!isAdmin()
            ? [
                ["/settings/company-profile", "Company Profile"],
                ["/settings/company-details", "Company Details"],
              ]
            : []),
          ["/settings/account", "Account"], // All
          ["/settings/privacy", "Privacy"], // All
          ...(isAdmin() || isPM() || isSpaceLead() ? [["/settings/users", "Users"]] : []),
        ]),
  ];

  return (
    <Wrapper>
      <Switch>
        {[
          ["/projects/archived/:section", [...projectsSections]],
          ["/projects/:projectId/:section", [...projectDetailSections]],
          ["/projects", projectsSections],
          ["/payments", paymentSections],
          ["/settings", settingsSections],
        ].map((path, i) => {
          return (
            <Route
              key={`title-path--${i}`}
              path={path[0]}
              render={(props) => (
                <TitleBarContent {...props} sectionLinks={path[1]} {...(path[2] || {})} />
              )}
            />
          );
        })}
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`;

const LinkBadge = styled.span`
  background: grey;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  color: white;
  font-size: 12px;
  padding-top: 4px;
  font-weight: bold;
`;

NavLinks.propTypes = proptypes;

export default NavLinks;
