export function displayExpectedReturn(value) {
  let displayValue = "permanent";
  switch (value) {
    case "2w":
      displayValue = "2 weeks";
      break;
    case "6m":
      displayValue = "6 months";
      break;
    default:
      break;
  }
  return displayValue;
}

/* Flatten arrays and objects to string */
export function flattenToString(data) {
  if (!data) return data;

  if (typeof data === "string") return data;

  if (Array.isArray(data)) return data.toString();

  let result = "";
  Object.keys(data).forEach((key, index) => {
    result += index === 0 ? data[key] : `, ${data[key].toString()}`;
  });
  return result;
}

/** Skills object to array */
export function skillsToArray(data) {
  if (!data) return [];

  let result = [];
  Object.keys(data).forEach((key) => {
    result = [...result, ...data[key]];
  });

  return result;
}
