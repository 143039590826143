import React, { useMemo } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import DashboardLayout from "../../../layouts/DashboardLayout";
import { isCSO, isDev, isPM, isSpaceLead } from "../../../utils/auth";
import Account from "./account/Account";
import CompanyDetails from "./CompanyDetails";
import CompanyProfile from "./CompanyProfile";
import ActiveUser from "./Invite/ActiveUser";
import CreateUser from "./Invite/CreateUser";
import DeactivatedUser from "./Invite/DeactivatedUser";
import Invite from "./Invite/InviteUser";
import PendingInvite from "./Invite/pendingInvites/PendingInvites";
import Payment from "./Payment";
import Privacy from "./Privacy";
import PersonalDetails from "./PersonalDetails";
import Profile from "./Profile";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const Settings = () => {
  useTrackPage(pages.SETTINGS);

  const location = useLocation();

  const classNames = useMemo(() => {
    const classes = [];

    switch (true) {
      case location.pathname === "/settings/profile":
        classes.push("border-0");
        break;

      case location.pathname === "/settings/payment":
        classes.push("active_users");
        break;

      case location.pathname === "/settings/users/active":
        classes.push("full_width");
        break;

      case location.pathname === "/settings/users/deactivated":
        classes.push("full_width");
        break;

      case location.pathname.includes("/pending"):
        classes.push("pending_invites");
        break;

      default:
        break;
    }

    return classes.join(" ");
  }, [location.pathname]);

  return (
    <DashboardLayout>
      <Switch>
        <Redirect
          exact
          from="/settings"
          to={`/settings/${
            isDev() || isPM() || isSpaceLead() || isCSO() ? "profile" : "personal-details"
          }`}
        />
        <Redirect exact from="/settings/users" to="/settings/users/active" />
        <Route exact path="/settings/profile" component={Profile} />
      </Switch>
      <div className={`content-card settings-card settings-container clearfix ${classNames}`}>
        <Switch>
          <Route exact path="/settings/personal-details" component={PersonalDetails} />
          <Route exact path="/settings/account" component={Account} />
          <Route exact path="/settings/payment" component={Payment} />
          <Route exact path="/settings/privacy" component={Privacy} />
          <Route exact path="/settings/company-profile" component={CompanyProfile} />
          <Route exact path="/settings/company-details" component={CompanyDetails} />
          <Route exact path="/settings/users/active" component={ActiveUser} />
          <Route exact path="/settings/users/deactivated" component={DeactivatedUser} />
          <Route exact path="/settings/users/invite" component={Invite} />
          <Route exact path="/settings/users/create" component={CreateUser} />
          <Route exact path="/settings/users/pending" component={PendingInvite} />
        </Switch>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
