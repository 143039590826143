import { useDispatch, useSelector } from "react-redux";
import { PROJECT_STAGE_ACTIVE } from "../../utils/api";
import { openConfirm } from "../../utils/modals";
import { updateProject, sendReminder } from "../../redux/actions/ProjectActions";
import { getUser } from "../../utils/auth";
import { createInterest } from "../../redux/actions/InterestPollActions";
import { eventTrack } from "../../utils/analytics";
import { CATEGORIES, EVENTS } from "../../analytics/events";

function useOpportunity(project) {
  const dispatch = useDispatch();
  const { interest } = useSelector(({ InterestPoll }) => InterestPoll);
  const { reminder } = useSelector(({ Projects }) => Projects);

  const activateProject = () => {
    openConfirm({
      message: "Are you sure you want to make this opportunity an active project?",
      title: "Activate project",
    }).then(() => {
      dispatch(
        updateProject(project.id, {
          stage: PROJECT_STAGE_ACTIVE,
        }),
      );
      eventTrack(
        CATEGORIES.projects,
        `${EVENTS.activate_project}: ${project.title}`,
        "opportunity",
      );
    });
  };

  const sendEmail = (user) => {
    if (user) {
      dispatch(sendReminder(project.id, user));
    } else {
      openConfirm({
        message: "Are you sure you want to send reminders to all users?",
        title: "Send reminder",
      }).then(() => {
        dispatch(sendReminder(project.id));
      });
    }
  };

  const onInterestUpdate = (status) => {
    dispatch(
      createInterest({
        project: { id: project.id },
        user: { id: getUser().id },
        status,
      }),
    );
    eventTrack(CATEGORIES.projects, `${EVENTS.click}_${status}: ${project.title}`, "opportunity");
  };

  return { activateProject, sendEmail, onInterestUpdate, reminder, interest };
}

export default useOpportunity;
