import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import Progress from "../../../components/Progress";
import SectionNav from "../../../components/SectionNav";
import { fetchProjects, fetchMoreProjects } from "../../../redux/actions/ProjectActions";
import { isClient, isDev, isPM, isSpaceLead } from "../../../utils/auth";
import { addPropsToChildren } from "../../../utils/children";

const ProjectListContainer = (props) => {
  const {
    filter,
    children,
    match: {
      params: { type },
    },
  } = props;

  const dispatch = useDispatch();
  const { isMakingRequest, projectPMFilter, projects } = useSelector(({ Projects }) => Projects);
  const { user } = useSelector(({ Auth }) => Auth);

  const getProjectFilters = (filterText) => {
    switch (filterText) {
      case "dedicated":
        return {
          category: "dedicated",
          stage: "active",
          archived: "False",
        };
      case "opportunity":
        return { stage: "opportunity", archived: "False" };
      case "managed":
        return {
          category: "project",
          stage: "active",
          archived: "False",
        };
      default: {
        let param;

        switch (type) {
          case "projects":
            param = {
              category: "project",
              stage: "active",
            };
            break;
          case "opportunity":
            param = {
              category: "",
              stage: "opportunity",
            };
            break;

          default:
            param = {
              category: type,
              stage: "active",
            };
            break;
        }
        return {
          archived: "True",
          ...param,
        };
      }
    }
  };

  const getList = () => {
    dispatch(
      fetchProjects({
        ...getProjectFilters(filter),
        show_all: isPM() || isSpaceLead() ? projectPMFilter : true,
      }),
    );
  };

  useEffect(() => {
    if (user.id) {
      getList();
    }
  }, [projectPMFilter, filter, type, user]);

  const renderChildren = () => {
    return addPropsToChildren(children, {
      onLoadMore: () => {
        dispatch(fetchMoreProjects(projects.next));
      },
      ...getProjectFilters(filter),
    });
  };

  return (
    <>
      {getProjectFilters(filter).archived === "True" && (
        <SectionNav
          urlExact={false}
          links={[
            {
              route: `projects/archived/dedicated`,
              name: "Dedicated Developers",
            },
            {
              route: `projects/archived/projects`,
              name: "Managed Projects",
            },
            ...(isClient() || isDev()
              ? [{}]
              : [
                  {
                    route: `projects/archived/opportunity`,
                    name: "Opportunities",
                  },
                ]),
          ]}
        />
      )}

      {isMakingRequest.list ? (
        <Progress
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      ) : (
        renderChildren()
      )}
    </>
  );
};

ProjectListContainer.propTypes = {
  project: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  filter: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

ProjectListContainer.defaultProps = {
  filter: null,
};

export default ProjectListContainer;
