/* eslint-disable import/no-named-as-default */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";

/* -------------------------- Internal dependencies ------------------------- */
import { Wrapper } from "../style";
import useTempProject from "../useTempProject";
import Avatar from "../../../../../components/Avatar";
import Icon from "../../../../../components/Icon";
import IconButton from "../../../../../components/IconButton";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import ProjectMemberForm from "../../../../../components/ProjectMemberForm";
import { openConfirm, openModal } from "../../../../../utils/modals";

const TeamForm = () => {
  const { project, setProject, deleteTeamMember, addPaymentStructure } = useTempProject();
  const onAddUsers = (type, title, max) => {
    /* istanbul ignore next */
    const typeMap = {
      cm: "client_manager",
      owner: "project_owner",
      cso: "client_support_officer",
      dev: "developer",
    };
    /* istanbul ignore next */
    openModal({
      body: (
        <ProjectMemberForm
          id="users-form"
          type={typeMap[type]}
          max={max}
          selected_users={project.participation || []}
        />
      ),
      title,
      canClose: true,
      options: {
        size: "sm",
        ok: "Add User",
        form: {
          type: "submit",
          form: "users-form",
        },
      },
      header: null,
      hideActions: false,
    }).then(
      (users) => {
        /* istanbul ignore next */
        if (users && users.length) {
          const reqData = {};
          if (type === "dev") {
            // Ensure no developer is added multiple times
            const participants = project.participation || [];
            const currUserIds = participants.map((u) => u.id);
            users.map((user) => {
              if (!currUserIds.includes(user.id)) {
                participants.push(user);
              }
            });
            reqData.participation = participants;
          } else {
            reqData[type] = users[0];
          }
          setProject({ ...project, ...reqData });
        }
      },
      () => {},
    );
  };
  const onDeleteUser = (user, type) => {
    /* istanbul ignore next */
    openConfirm({
      message: (
        <div>
          Are you sure you want to delete {user.display_name}
          <br /> from the project?
        </div>
      ),
      title: "Remove User",
      canClose: true,
      options: {
        ok: "Delete user",
        cancel: "Cancel",
        dismissAsCancel: true,
        size: "sm",
      },
    }).then(
      () => {
        /* istanbul ignore next */
        if (["cm", "cso", "owner"].includes(type)) {
          const reqData = {};
          reqData[type] = null;
          setProject({ ...project, ...reqData });
        } else if (type === "dev") {
          deleteTeamMember(user.id);
        }
      },
      () => {},
    );
  };
  return (
    <StypledWrapper>
      <div className="header">
        <h4>Team</h4>
        <p>Add project owner and other team members</p>
      </div>

      <div className="section">
        <div className="section-title">
          <span>Project Owner</span>
          {!project.owner && (
            <a href="#" onClick={() => onAddUsers("owner", "Add project owner", 1)}>
              <Icon name="round-add" size="sm" /> Add
            </a>
          )}
        </div>
        <div className="section-items">
          {project.owner ? (
            <div className="item">
              <Avatar
                image={project.owner.avatar_url}
                initials={generateUserInitials(project.owner)}
                size="dash"
              />
              <span>{project.owner.display_name}</span>

              <div className="item-actions">
                <IconButton
                  name="delete-outline"
                  onClick={() => onDeleteUser(project.owner, "owner")}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          <span>Client Support Officer</span>
          {!project.cso && (
            <a href="#" onClick={() => onAddUsers("cso", "Add Client support officer", 1)}>
              <Icon name="round-add" size="sm" /> Add
            </a>
          )}
        </div>
        <div className="section-items">
          {project.cso ? (
            <div className="item">
              <Avatar
                image={project.cso.avatar_url}
                initials={generateUserInitials(project.cso)}
                size="dash"
              />
              <span>{project.cso.display_name}</span>

              <div className="item-actions">
                <IconButton
                  name="delete-outline"
                  onClick={() => onDeleteUser(project.cso, "cso")}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          <span>Client Manager</span>
          {!project.cm && (
            <a href="#" onClick={() => onAddUsers("cm", "Add client manager", 1)}>
              <Icon name="round-add" size="sm" /> Add
            </a>
          )}
        </div>
        <div className="section-items">
          {project.cm ? (
            <div className="item">
              <Avatar
                image={project.cm.avatar_url}
                initials={generateUserInitials(project.cm)}
                size="dash"
              />
              <span>{project.cm.display_name}</span>

              <div className="item-actions">
                <IconButton name="delete-outline" onClick={() => onDeleteUser(project.cm, "cm")} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          <span>Team Members</span>
          <a href="#" onClick={() => onAddUsers("dev", "Add team members", 100000)}>
            <Icon name="round-add" size="sm" /> Add
          </a>
        </div>
        <div className="section-items">
          {(project.participation || []).map((user) => (
            <div className="item-pack my-2" key={user.id}>
              <div className="item">
                <Avatar image={user.avatar_url} initials={generateUserInitials(user)} size="dash" />
                <span>{user.display_name}</span>&nbsp;
                <span style={{ color: "#8F9BB3" }}>- {user.display_type}</span>
                <div className="item-actions">
                  <IconButton name="delete-outline" onClick={() => onDeleteUser(user, "dev")} />
                </div>
              </div>
              {project.category === "dedicated" && (
                <div>
                  <div
                    className="inp-div"
                    style={{
                      marginLeft: ".05rem",
                      marginRight: "0rem",
                      marginBottom: "-11px",
                    }}
                  >
                    <input
                      style={{ borderLeft: "none", borderRight: "none" }}
                      name="hours_per_week"
                      required
                      type="number"
                      placeholder="Enter expected hours"
                      value={user.hours_per_week || ""}
                      onChange={(e) =>
                        e.target.value >= 0 &&
                        addPaymentStructure(user.id, "hours_per_week", e.target.value)
                      }
                    />
                    <span className="span-append">hours/week</span>
                  </div>
                  <div className="row" style={{ marginBottom: "-22px" }}>
                    <div className="inp-div col">
                      <input
                        style={{ borderLeft: "none", borderRadius: "0px" }}
                        name="payout_rate"
                        required
                        value={user.payout_rate || ""}
                        onChange={(e) =>
                          e.target.value >= 0 &&
                          addPaymentStructure(user.id, "payout_rate", e.target.value)
                        }
                        type="number"
                        placeholder="€ Developer rate per hour"
                      />
                      <span className="span-append">/hour</span>
                    </div>
                    <div className="inp-div col">
                      <input
                        style={{ borderRight: "none", borderRadius: "0px" }}
                        required
                        disabled={true}
                        type="number"
                        value={((user.payout_rate / 5) * user.hours_per_week * 22).toFixed(2) || ""}
                      />
                      <span className="span-append">expected total payout/month</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="inp-div col">
                      <input
                        style={{ borderLeft: "none", borderRadius: "0px" }}
                        name="client_rate"
                        required
                        value={user.client_rate || ""}
                        onChange={(e) =>
                          e.target.value >= 0 &&
                          addPaymentStructure(user.id, "client_rate", e.target.value)
                        }
                        type="number"
                        placeholder="€ Client rate per hour"
                      />
                      <span className="span-append">/hour</span>
                    </div>
                    <div className="inp-div col">
                      <input
                        style={{ borderRight: "none", borderRadius: "0px" }}
                        required
                        disabled={true}
                        type="number"
                        value={((user.client_rate / 5) * user.hours_per_week * 22).toFixed(2) || ""}
                      />
                      <span className="span-append">expected total payment/month</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </StypledWrapper>
  );
};

const StypledWrapper = styled(Wrapper)`
  input:focus {
    outline: none;
  }

  padding: 40px;

  .span-append {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
    padding: 0px 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
  .row {
    padding: 11px 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .section {
    padding: 43px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      border-bottom: none;
    }

    .section-title {
      font-size: 16px;
      margin-bottom: 0;

      span {
        font-weight: 600;
        line-height: 19px;
        color: #151a30;
      }
      a {
        float: right;
        font-weight: 500;
        line-height: 24px;
        color: #062e64;
        text-decoration: none;
        i {
          margin-right: 12px;
          font-size: 16px;
          vertical-align: baseline;
        }
      }
    }

    .section-items {
      &:not(:empty) {
        margin-top: 26px;
      }
      .item-pack {
        background: #ffffff;
        border: 1px solid #edf1f7;
        box-sizing: border-box;
        border-radius: 4px;
      }
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        padding: 11px 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .avatar.avatar-dash {
          width: 35px;
          height: 35px;

          &.avatar-initials {
            font-size: 14px;
          }
        }

        > span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #3e4857;
        }

        .item-actions {
          margin: 0 0 0 auto;
          display: flex;

          button i {
            margin-left: 30px;
            color: #8f9bb3;
            font-size: 20px;
          }

          input {
            width: 150px;
          }
        }
      }
    }
  }
`;

export default TeamForm;
