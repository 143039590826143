import React, { useState } from "react";
import styled from "styled-components";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PropTypes from "prop-types";

import { FilterLogo } from "../FilterLogo";
import IconButton from "../IconButton";

const PayoutFilter = ({
  paymentFilters,
  handleClearFilters,
  handleApplyFilters,
  handlePaymentFiltersCheck,
}) => {
  const [dropdownOpen, setDropdown] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);

  const toggle = () => {
    setDropdown(!dropdownOpen);
    setOpenDrop(false);
  };

  const arrowUpHandler = () => {
    setOpenDrop(false);
  };
  const arrowDownHandler = () => {
    setOpenDrop(true);
  };

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle tag="text">
          <span className="cursor-pointer" data-testid="toggle">
            <FilterLogo />
            <span className="mx-1 font-14">Filter</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu" right>
          <div className="d-flex justify-content-between inner-wrapper font-12">
            <button
              data-testid="clear"
              type="button"
              className="px-2 pb-4 btn-clear font-14"
              onClick={handleClearFilters}
            >
              Clear
            </button>
            <span className="font-14 filter-text">Filters</span>
            <button
              data-testid="done"
              type="button"
              className="px-2 pb-4 btn-done font-14"
              onClick={handleApplyFilters}
            >
              Done
            </button>
          </div>
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <span className="font-12 font-weight-bolder payment-platform-text">
              PAYMENT PLATFORMS
            </span>
            {openDrop ? (
              <IconButton
                size={14}
                styles="-10px"
                boxShadow="none"
                data-testid="arrowup"
                name="arrow-up"
                onClick={arrowUpHandler}
              />
            ) : (
              <IconButton
                size={14}
                styles="-10px"
                boxShadow="none"
                name="arrow-down"
                data-testid="arrowdown"
                onClick={arrowDownHandler}
              />
            )}
          </div>
          {openDrop && (
            <div className="mx-2">
              {["Payoneer", "IBAN"].map((x, index) => {
                return (
                  <div key={index}>
                    <span className="font-14">
                      <input
                        data-testid={`check-${x}`}
                        name="clear-it"
                        type="checkbox"
                        checked={paymentFilters.includes(x)}
                        onChange={() => handlePaymentFiltersCheck(x)}
                      />{" "}
                      {x}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .dropdown-menu {
    width: 230px;
  }

  .filter-text {
    height: 21px;
    margin-top: 10px;
  }

  .payment-platform-text {
    color: #8f9bb3;
  }

  .inner-wrapper {
    padding-right: 10px;
    padding: 0 2px;
    background: #f5f7fa;
    height: 47px;
  }

  .btn {
    &-clear,
    &-done {
      margin-top: 10px;
      height: 21px;
      border: 1px solid #062e64;
    }

    &-clear {
      background: #fff;
      border-radius: 4px;
      margin-left: 10px;
    }

    &-done {
      background: #062e64;
      color: #fff;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
`;

PayoutFilter.propTypes = {
  paymentFilters: PropTypes.arrayOf(PropTypes.shape({})),
  handleClearFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  handlePaymentFiltersCheck: PropTypes.func.isRequired,
};

export default PayoutFilter;
