/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import * as Sentry from "@sentry/react";
import {
  RETRIEVE_APPLICATION_SUCCESS,
  RETRIEVE_INVITE_FAILED,
  RETRIEVE_INVITE_START,
  RETRIEVE_INVITE_SUCCESS,
} from "../../configs/constants/ActionTypes";
import * as AuthActions from "../actions/AuthActions";

function user(state = {}, action) {
  switch (action.type) {
    case AuthActions.LOGIN_SUCCESS:
    case AuthActions.VERIFY_SUCCESS:
      Sentry.setUser({ email: action.user.email });
      return { ...state, ...action.user };
    case AuthActions.LOGOUT_SUCCESS: {
      localStorage.clear();
      Sentry.setUser(null);
      return {};
    }
    default:
      return state;
  }
}

export function isMakingRequest(_, action) {
  switch (action.type) {
    case AuthActions.LOGIN_START:
      return { login: true };
    case AuthActions.LOGOUT_START:
      return { logout: true };
    case AuthActions.REGISTER_START:
      return { register: true };
    case AuthActions.REGISTER_SUCCESS:
      return { isRegistered: true };
    case AuthActions.VERIFY_START:
      return { verify: true };
    case AuthActions.VERIFY_FAILED: {
      localStorage.clear();
      return { verify: false };
    }
    case RETRIEVE_INVITE_START:
      return { retrieveInvitation: true };
    default:
      return {};
  }
}

function errors(state = {}, action) {
  switch (action.type) {
    case AuthActions.LOGIN_FAILED:
      return { login: action.error };
    case AuthActions.LOGOUT_FAILED:
      return { logout: action.error };
    case AuthActions.REGISTER_FAILED:
      return { register: action.error };
    default:
      return state;
  }
}

function forgotPassword(state = {}, action) {
  switch (action.type) {
    case AuthActions.RESET_PASSWORD_START:
      return { loading: true };

    case AuthActions.RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };

    case AuthActions.RESET_PASSWORD_FAILED:
      return { loading: false, error: action.error };

    default:
      return state;
  }
}

function confirmReset(state = {}, action) {
  switch (action.type) {
    case AuthActions.RESET_PASSWORD_CONFIRM_START:
      return { loading: true };

    case AuthActions.RESET_PASSWORD_CONFIRM_SUCCESS:
      return { loading: false, success: true };

    case AuthActions.RESET_PASSWORD_CONFIRM_FAILED:
      return { loading: false, errors: action.error };

    default:
      return state;
  }
}

function invitation(state = {}, action) {
  switch (action.type) {
    case RETRIEVE_INVITE_SUCCESS:
      return action.invite;
    case RETRIEVE_INVITE_START:
    case RETRIEVE_INVITE_FAILED:
      return {};
    default:
      return state;
  }
}

function application(state = {}, action) {
  switch (action.type) {
    case RETRIEVE_APPLICATION_SUCCESS:
      return action.application;
    default:
      return state;
  }
}

const Auth = combineReducers({
  user,
  isMakingRequest,
  errors,
  confirmReset,
  forgotPassword,
  invitation,
  application,
});

export default Auth;
