import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertDialogue from "../components/AlertDialogue";
import WiseForm from "../components/Payment/WiseForm";
import { addWise, fetchPaymentProviders, fetchWise } from "../redux/actions/PaymentActions";
import { openModal, openAlert } from "../utils/modals";

const useConnectWise = () => {
  const [payload, setPayload] = useState({ email: "", name: "", iban: "" });
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { wiseProvider, errors } = useSelector(({ Payments }) => Payments);

  const dispatch = useDispatch();

  const connectToWise = () => {
    setSubmitError(null);
    openModal({
      body: <WiseForm id="wise-connect-form" payload={payload} submitError={submitError} />,
      title: "Connect Wise",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Connect",
        form: {
          type: "submit",
          form: "wise-connect-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      header: null,
      hideActions: false,
    }).then(async (data) => {
      const { email, name, iban } = data;
      setPayload(data);

      await dispatch(
        addWise({
          email,
          iban,
          account_name: name,
          account_type: "Iban",
        }),
      );
    });
  };

  useEffect(() => {
    if (
      wiseProvider?.message?.toLowerCase() === "wise account connected successfully" &&
      wiseProvider?.data?.iban
    ) {
      openAlert({
        body: <AlertDialogue msg="Successfully connected Wise" />,
        canClose: false,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispatch(fetchWise({ silent: true }));
      dispatch(fetchPaymentProviders());
      setShouldTriggerPrompt(true);
    }
  }, [wiseProvider]);

  useEffect(() => {
    if (submitError) {
      connectToWise();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.connectWise) {
      setSubmitError(errors.connectWise);
    }
  }, [errors.connectWise]);

  return { connectToWise, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectWise;
