import React from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import SectionNav from "../../../../../components/SectionNav";
import Details from "./Details";
import { isAdminOrPMOrSpaceLead, isCSO } from "../../../../../utils/auth";

const Settings = () => {
  const match = useRouteMatch();

  return (
    <>
      <SectionNav
        links={[
          ...(isAdminOrPMOrSpaceLead() || isCSO()
            ? [
                {
                  route: `${match.url.replace("/", "")}/details`,
                  name: "Details",
                },
              ]
            : []),
        ]}
      />

      <Switch>
        {[["details", <Details />]].map((path) => (
          <Route
            key={`report-${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => {
              /* istanbul ignore next */
              return <>{path[1]}</>;
            }}
          />
        ))}
        <Redirect
          exact
          from={`${match.url}`}
          to={`${match.url}/${isAdminOrPMOrSpaceLead() || isCSO() ? "details" : "/"}`}
        />
      </Switch>
    </>
  );
};

export default Settings;
