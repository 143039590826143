/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import styled from "styled-components";
import FieldError from "../FieldError";
import Input from "../Input";
import { Label, Title } from "./styles";

const PayoneerForm = ({ id, proceed, payload: data, submitError }) => {
  const [email, setEmail] = useState(data.payoneer_email || "");
  const [firstName, setFirstName] = useState(data.first_name || "");
  const [lastName, setLastName] = useState(data.last_name) || "";
  const [emailError, setEmailError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);

  const validateEmail = (emailString) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailString).toLowerCase());
  };

  const handleEmail = (event) => {
    const { value } = event.target;
    if (validateEmail(value)) {
      setEmailError(null);
      setEmail(value);
    } else {
      setEmailError("Enter a valid email address");
      setEmail(value);
    }
  };

  const handleFirstName = (event) => {
    const { value } = event.target;
    if (typeof value === "string" && value.trim()) {
      setFirstNameError(null);
      setFirstName(value);
    } else {
      setFirstNameError("Enter a valid name");
      setFirstName(value.trim());
    }
  };

  const handleLastName = (event) => {
    const { value } = event.target;
    if (typeof value === "string" && value.trim()) {
      setLastNameError(null);
      setLastName(value);
    } else {
      setLastNameError("Enter a valid name");
      setLastName(value.trim());
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (firstNameError || lastNameError || emailError) {
      return;
    }

    proceed({
      payoneer_email: email,
      first_name: firstName,
      last_name: lastName,
      next_url: `${window.origin}/settings/payment`,
    });
  };

  return (
    <form className="mt-3" data-testid="payoneer-form" id={id} onSubmit={onSubmit}>
      <Title>Enter the name and email address for your Payoneer account</Title>

      {submitError && (
        <div style={{ marginBottom: "1em" }}>
          <FieldError message={submitError} />
        </div>
      )}

      <FormGroup className="mb-4">
        <Row>
          <Col>
            <Label htmlFor="first_name" className="mb-2">
              First name
            </Label>
            <StyledInput
              type="text"
              className="py-0"
              placeholder="Enter first name"
              dataTestId="first_name"
              id="first_name"
              name="first_name"
              value={firstName}
              onChange={handleFirstName}
              required
            />
            {firstNameError && <FieldError message={firstNameError} />}
          </Col>
          <Col>
            <Label htmlFor="last_name" className="mb-2">
              Last name
            </Label>
            <StyledInput
              type="text"
              className="py-0"
              placeholder="Enter last name"
              dataTestId="last_name"
              id="last_name"
              name="last_name"
              value={lastName}
              onChange={handleLastName}
              required
            />
            {lastNameError && <FieldError message={lastNameError} />}
          </Col>
        </Row>
      </FormGroup>

      <FormGroup className="mb-4">
        <Label htmlFor="email" className="mb-2">
          Email address
        </Label>
        <StyledInput
          id="email"
          dataTestId="email"
          name="email"
          type="email"
          className="py-0"
          placeholder="Type your Payoneer email address"
          value={email}
          onChange={handleEmail}
          required
        />
        {emailError && <FieldError message={emailError} />}
      </FormGroup>
    </form>
  );
};

const StyledInput = styled(Input)`
  div {
    border: 1px solid red;
  }

  [data-testid="input"] {
    margin: 0;
  }

  input.form-control {
    color: rgb(127, 140, 159);
    padding: 0;
  }
`;

export default PayoneerForm;
