/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  TungaDevProfileOverview,
  TungaDevSkillsToolsSection,
  TungaEducationSection,
  TungaProjectSection,
  TungaTabSwitch,
  TungaWorkExperience,
} from "@tunga/tunga-components-react";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import BioForm from "./modals/BioForm";
import SkillsetForm from "./modals/SkillsetForm";
import ProjectForm from "./modals/ProjectForm";
import WorkForm from "./modals/WorkForm";
import EducationForm from "./modals/EducationForm";
import { openAlert, openModal } from "../../../utils/modals";
import { verify } from "../../../redux/actions/AuthActions";
import {
  updateProfile,
  updateProject,
  updateWork,
  updateEducation,
  shareProfile,
} from "../../../redux/actions/ProfileActions";
import useDidUpdate from "../../../hooks/useDidUpdate";
import usePrevious from "../../../hooks/usePrevious";
import AlertDialogue from "../../../components/AlertDialogue";
import { skillsToArray } from "../../../utils/helpers";
import { skillsTitleDictionary } from "../../../configs/constants/global";
import ShareProfileModal from "./modals/ShareProfileModal";

const Profile = () => {
  const dispatch = useDispatch();

  const {
    profile,
    avatar_url: avatarUrl,
    display_name,
    work: workExperience,
    project: userProject,
    education: educationExperience,
    share_settings,
  } = useSelector(({ Auth }) => Auth.user);
  const { isSaved, errors } = useSelector(({ Profile: UserProfile }) => UserProfile);

  const prevIsSaved = usePrevious(isSaved);

  const [skills, setSkills] = useState(skillsToArray(profile.skills_details) || []);
  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState({
    profile: false,
    work: false,
    project: false,
    education: false,
  });

  const reVerify = () => dispatch(verify({ silent: true }));

  const submissionStatus = (key, value) =>
    setIsSubmitting((prev) => ({
      ...prev,
      [key]: value,
    }));

  const skillsData = useMemo(() => {
    const skillsDetails = Object.entries(profile?.skills_details || {});

    const data = skillsDetails.map((entries) => {
      const [key, _skills] = entries;

      const skillArray = _skills.map(({ name, years_of_experience }) => ({
        name,
        yearsOfExperience: years_of_experience,
      }));

      return {
        title: skillsTitleDictionary[key],
        skills: skillArray,
      };
    });

    return data;
  }, [profile]);

  const projectData = useMemo(
    () =>
      userProject.map((project) => {
        const {
          start_month_display,
          start_year,
          category,
          title,
          avatar_url,
          details,
          skills: userSkills,
          project_link,
          repository_link,
          end_month_display,
          end_year,
          is_tunga_project,
        } = project;

        return {
          startMonth: start_month_display,
          startYear: start_year,
          project_link,
          repository_link,
          avatarUrl:
            avatar_url || "https://thumbs.dreamstime.com/z/website-url-background-5988824.jpg",
          category,
          skills: userSkills,
          details: details || "N/A",
          name: title || "N/A",
          endMonth: end_month_display,
          endYear: end_year,
          is_tunga_project,
          rawProject: {
            ...project,
          },
        };
      }),
    [userProject],
  );

  const experienceData = useMemo(
    () =>
      workExperience.map((work) => {
        const {
          start_month_display,
          start_year,
          details,
          company,
          position,
          end_month_display,
          end_year,
          location,
          skills: userSkills,
        } = work;

        return {
          startMonth: start_month_display,
          startYear: start_year,
          details,
          company,
          position,
          endMonth: end_month_display,
          endYear: end_year,
          location,
          skills: userSkills,
          rawWork: {
            ...work,
          },
        };
      }),
    [workExperience],
  );

  const educationData = useMemo(
    () =>
      educationExperience.map((education) => {
        const {
          start_month_display,
          start_year,
          institution,
          award,
          end_month_display,
          end_year,
          details,
        } = education;
        return {
          startMonth: start_month_display,
          startYear: start_year,
          institution,
          award,
          endMonth: end_month_display,
          endYear: end_year,
          details,
          rawEducation: {
            ...education,
          },
        };
      }),
    [educationExperience],
  );

  const onUpdateBio = (e) => {
    e.preventDefault();

    openModal({
      body: <BioForm bio={profile.bio} id="bio-form" />,
      title: `Bio`,
      canClose: true,
      options: {
        ok: `Save changes`,
        form: {
          type: "submit",
          form: `bio-form`,
        },
      },
      header: null,
      hideActions: false,
    }).then(({ bio }) => {
      submissionStatus("profile", true);
      dispatch(
        updateProfile(
          profile.id,
          {
            bio,
          },
          null,
          false,
          true,
        ),
      );
    });
  };

  const onUpdateSkillSets = (e) => {
    e.preventDefault();

    openModal({
      body: <SkillsetForm skills={skills} id="skillset-form" />,
      title: `Your Skillset`,
      canClose: true,
      options: {
        ok: `Save changes`,
        form: {
          type: "submit",
          form: `skillset-form`,
        },
      },
      header: null,
      hideActions: false,
    }).then((data) => {
      submissionStatus("profile", true);
      dispatch(
        updateProfile(
          profile.id,
          {
            skills: data.skills,
          },
          null,
          false,
          true,
        ),
      );
    });
  };

  const onAddProject = (e, selectedProject = {}) => {
    e.preventDefault();

    const { rawProject: project = {} } = selectedProject || { rawProject: {} };

    openModal({
      body: <ProjectForm id="project-form" project={project} />,
      title: `${project.id ? "Edit" : "Add New"} Project`,
      canClose: true,
      options: {
        ok: `${project.id ? "Edit" : "Add"} Project`,
        form: {
          type: "submit",
          form: `project-form`,
        },
      },
      header: null,
      hideActions: false,
    }).then((data) => {
      submissionStatus("project", true);
      updateProject(project?.id || "", data)(dispatch, reVerify);
    });
  };

  const onAddWork = (e, selectedWork = {}) => {
    e.preventDefault();

    const { rawWork: work = {} } = selectedWork || { rawWork: {} };

    openModal({
      body: <WorkForm id="work-form" work={work} />,
      title: `${work.id ? "Edit" : "Add New"} Work Experience`,
      canClose: true,
      options: {
        ok: `${work.id ? "Edit" : "Add"} Work Experience`,
        form: {
          type: "submit",
          form: `work-form`,
        },
      },
      header: null,
      hideActions: false,
    }).then((data) => {
      submissionStatus("work", true);

      updateWork(work?.id || null, data)(dispatch, reVerify);
    });
  };

  const onAddEducation = (e, selectedEducation = {}) => {
    e.preventDefault();
    const { rawEducation: education = {} } = selectedEducation || { rawEducation: {} };

    openModal({
      body: <EducationForm id="educational-form" education={education} />,
      title: `${education.id ? "Edit" : "Add New"} Education`,
      canClose: true,
      options: {
        ok: `${education.id ? "Edit" : "Add"} Education`,
        form: {
          type: "submit",
          form: `educational-form`,
        },
      },
      header: null,
      hideActions: false,
    }).then((data) => {
      submissionStatus("education", true);

      updateEducation(education?.id || null, data)(dispatch, reVerify);
    });
  };

  /* istanbul ignore next */
  const onDeleteExperience = (id, key) => {
    switch (key) {
      case "work":
        updateWork(id, {}, true)(dispatch, reVerify);
        break;

      case "project":
        updateProject(id, {}, true)(dispatch, reVerify);
        break;

      case "education":
        updateEducation(id, {}, true)(dispatch, reVerify);
        break;

      default:
        break;
    }
  };

  /* istanbul ignore next */
  const openAlertModal = (message, isError = false) => {
    const alertConfig = isError
      ? { className: "error-dailogue", iconClass: "bs-x-danger" }
      : { className: "alert-dailogue", iconClass: "" };

    openAlert({
      body: <AlertDialogue msg={message} iconClass={alertConfig.className} />,
      canClose: true,
      options: {
        className: alertConfig.className,
        hideActions: true,
        hideBackdrop: true,
      },
    });
  };

  const openShareProfileModal = () => {
    if (share_settings === null) {
      dispatch(shareProfile("", false, { method: "post", silent: false }));
    }

    openModal({
      body: <ShareProfileModal id="share-profile-form" />,
      title: "Share Profile",
      canClose: true,
      options: {
        className: "share-profile-modal",
      },
      header: null,
      hideActions: true,
    });
  };

  /* istanbul ignore next */
  const handleSuccessModals = useCallback(() => {
    switch (true) {
      case isSaved.profile:
        openAlertModal("Profile saved successfully");
        break;

      case isSaved.work:
        openAlertModal("Work Experience saved successfully");
        break;

      case isSaved.education:
        openAlertModal("Education saved successfully");
        break;

      case isSaved.project:
        openAlertModal("Project Experience saved successfully");
        break;

      default:
        break;
    }
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    switch (true) {
      case !prevIsSaved?.profile && isSaved?.profile:
        document.querySelector("#main-content").scrollTo(0, 0);
        submissionStatus("profile", false);
        break;

      case !prevIsSaved?.work && isSaved?.work:
        submissionStatus("work", false);
        break;

      case !prevIsSaved?.project && isSaved?.project:
        submissionStatus("project", false);
        break;

      case !prevIsSaved?.education && isSaved?.education:
        submissionStatus("education", false);
        break;

      default:
        break;
    }

    if (!isEqual(skills, skillsToArray(profile.skills_details))) {
      setSkills(skillsToArray(profile.skills_details));
    }
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    handleSuccessModals();
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    switch (true) {
      case !!errors.profile:
        openAlertModal(errors.profile, true);
        submissionStatus("profile", false);
        break;

      case !!errors.work:
        openAlertModal(errors.work, true);
        submissionStatus("work", false);
        break;

      case !!errors.education:
        openAlertModal(errors.education, true);
        submissionStatus("education", false);
        break;

      case !!errors.project:
        openAlertModal(errors.project, true);
        submissionStatus("project", false);
        break;

      default:
        break;
    }
  }, [errors?.profile, errors?.work, errors?.project, errors?.education]);

  return (
    <ContentSection className="position-relative">
      <TungaDevProfileOverview
        isEditable="true"
        showCloseButton="false"
        bio={profile.bio}
        city={profile.city}
        countryName={profile.country_name}
        avatarUrl={avatarUrl}
        displayName={display_name}
        onEditClick={onUpdateBio}
        editInProgress={isSubmitting?.profile}
        isVetted={profile?.is_vetted || false}
        showHireButton="true"
        actionBtnText="SHARE PROFILE"
        onHireBtnClick={openShareProfileModal}
      />
      <div className="bg-wrap">
        <TungaTabSwitch top="108px" isEditable="true" />

        <div className="folder" id="Skills">
          <TungaDevSkillsToolsSection
            isEditable="true"
            skillsData={skillsData}
            onEditClick={onUpdateSkillSets}
            editInProgress={isSubmitting?.profile}
          />
        </div>

        <div className="folder" id="Projects">
          <TungaProjectSection
            isEditable="true"
            addInProgress={isSubmitting?.project}
            projectData={projectData}
            onEditClick={onAddProject}
            onEditItemClick={(e) => onAddProject(e, e.detail)}
            onDeleteItemClick={({ detail }) => onDeleteExperience(detail.rawProject.id, "project")}
          />
        </div>

        <div className="folder" id="Experience">
          <TungaWorkExperience
            isEditable="true"
            addInProgress={isSubmitting?.work}
            experienceData={experienceData}
            onEditClick={onAddWork}
            onEditItemClick={(e) => onAddWork(e, e.detail)}
            onDeleteItemClick={({ detail }) => onDeleteExperience(detail.rawWork.id, "work")}
          />
        </div>

        <div className="folder" id="Education">
          <TungaEducationSection
            isEditable="true"
            addInProgress={isSubmitting?.education}
            educationData={educationData}
            onEditClick={onAddEducation}
            onEditItemClick={(e) => onAddEducation(e, e.detail)}
            onDeleteItemClick={({ detail }) =>
              onDeleteExperience(detail.rawEducation.id, "education")
            }
          />
        </div>
      </div>
    </ContentSection>
  );
};

export const ContentSection = styled.div`
  padding-bottom: 100px !important;
  width: 100%;

  tunga-dev-profile-overview:host(.overview) {
    background-color: red;
  }

  @media only screen and (min-width: 992px) {
    width: 90%;
  }

  .bg-wrap {
    background: #ffffff;
    border: 1px solid #e0e1e2;
    border-top: none;
    padding-bottom: 40px;
  }

  [id] {
    scroll-margin-top: 9.8rem;
  }
`;

export default Profile;
