import React from "react";
import { openModal } from "./modals";
import store from "../redux/store";
import ProjectDateForm from "../pages/Dashboard/projects/projectDetail/components/ProjectDateForm";
import MilestoneForm from "../pages/Dashboard/projects/projectDetail/components/MilestoneForm";
import PlanningForm from "../pages/Dashboard/projects/projectDetail/components/PlanningForm";
import {
  createDocument,
  createProgressEvent,
  updateDocument,
  updateProgressEvent,
  updateProject,
} from "../redux/actions/ProjectActions";
import { getUser } from "./auth";

/**
 * Create record of which fields have changes showing previous and current values
 * @param {*} fields Object keys of object you are trying to edit e.g project
 * @param {*} reason
 * @param {*} update new object from form
 * @param {*} original old object that you are trying to edit
 * @returns array of fields that have changed and the reason
 */
const parseChangeLog = (fields, update, original) => {
  const changes = [];
  fields.forEach((field) => {
    if (update[field] && update[field] !== original[field]) {
      changes.push({
        field,
        reason: update.reason,
        previous_value: original[field] || null,
        new_value: update[field],
      });
    }
  });
  return changes;
};

export const onManageSchedule = (project, timeline = {}) => {
  const { id, reason, deadline, start_date } = timeline;

  openModal({
    body: (
      <ProjectDateForm
        id="timeline-form"
        project={{
          id,
          reason,
          deadline,
          start_date,
        }}
      />
    ),
    options: {
      className: "modal-payments",
      ok: `Save ${Object.keys(timeline).length !== 0 ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `timeline-form`,
      },
    },
    title: `${Object.keys(timeline).length !== 0 ? "Update" : "Add"} timeline`,
  }).then((data) => {
    const newData = { ...data };

    if (newData.reason) {
      const changes = parseChangeLog(["reason", "deadline", "start_date"], newData, project);
      delete newData.reason;

      if (changes.length) {
        newData.change_log = changes;
      }
    }
    store.dispatch(updateProject(project.id, newData));
  });
};

export const onManageMilestone = (project, milestone = {}) => {
  const { id, reason, title, due_at } = milestone;

  openModal({
    body: <MilestoneForm id="milestone-form" milestone={{ id, reason, title, due_at }} />,
    options: {
      className: "modal-payments",
      ok: `Save ${Object.keys(milestone).length !== 0 ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `milestone-form`,
      },
    },
    title: `${Object.keys(milestone).length !== 0 ? "Update" : "Add"} Milestone`,
  }).then((data) => {
    const newData = { ...data };

    if (data.reason) {
      const changes = parseChangeLog(["title", "due_at", "reason"], data, milestone);
      delete newData.reason;
      if (changes.length) {
        newData.change_log = changes;
      }
    }
    newData.type = "milestone";
    newData.project = { id: project.id };

    if (Object.keys(milestone).length > 0) {
      store.dispatch(updateProgressEvent(milestone.id, newData));
    } else {
      store.dispatch(
        createProgressEvent({
          ...newData,
          user: { id: getUser().id },
        }),
      );
    }
  });
};

export const onManagePlan = (project, plan = {}) => {
  const { id, reason, title, url, download_url } = plan;

  openModal({
    body: (
      <PlanningForm
        id="planning-form"
        plan={{
          id,
          reason,
          title,
          url: url || download_url,
        }}
      />
    ),
    options: {
      className: "modal-payments",
      ok: `Save ${Object.keys(plan).length !== 0 ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `planning-form`,
      },
    },
    title: `${Object.keys(plan).length !== 0 ? "Update" : "Add"} detailed planning`,
  }).then((data) => {
    const newData = { ...data };
    if (newData.reason) {
      const changes = parseChangeLog(["reason", "title", "url"], data, plan);
      delete newData.reason;
      if (changes.length) {
        newData.change_log = changes;
      }
    }

    newData.type = "planning";
    newData.project = { id: project.id };

    if (Object.keys(plan).length > 0) {
      store.dispatch(updateDocument(plan.id, newData));
    } else {
      store.dispatch(createDocument(newData));
    }
  });
};

export const getReportsTableColumns = () => [
  {
    Header: "Due Date",
    accessor: "due_date",
  },
  {
    Header: "Report Name",
    accessor: "report_name",
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const reportsTableData = (data) => [
  ...data.map((report) => {
    const row = {
      due_date: report,
      report_name: report,
      status: report,
      user: report,
      action: report,
    };
    return row;
  }),
];
