/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Avatar from "../../../../../components/Avatar";
import IconButton from "../../../../../components/IconButton";
import { isClient, isDev } from "../../../../../utils/auth";
import { openModal } from "../../../../../utils/modals";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import ModalHeader from "../components/ModalHeader";
import TeamModalContent from "../components/TeamModalContent";
import { Table } from "./ClientReports";
import ReactTable from "../../../../../components/ReactTable";
import { getReportsTableColumns, reportsTableData } from "../../../../../utils/projectUtils";

const TeamReports = ({ activities, count, onLoadMore, currentPage, pageSize }) => {
  const isMissed = (date) => {
    return isClient() ? false : moment.utc(date).add(isDev() ? 1 : 3, "day") < moment.utc();
  };

  const openClientReport = (activity) => {
    openModal({
      body: <TeamModalContent activity={activity} />,
      title: "Report Title",
      canClose: true,
      options: { className: "modal-report" },
      header: (
        <ModalHeader title={activity.title} created_at={activity.progress_reports[0].created_at} />
      ),
    });
  };

  const getTableDisplayValue = (cell) => {
    const activity = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(activity.due_at).format("DD MMM YYYY")}
          </td>
        );
      }

      case "report_name": {
        return (
          <td key={`report_name${key}`} className="nowrap">
            {activity.title}
          </td>
        );
      }

      case "user": {
        return (
          <td className="nowrap" key={`user${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={activity?.user?.avatar_url}
                initials={generateUserInitials(activity?.user)}
                size="dash"
              />
              {activity?.user?.display_name}
            </div>
          </td>
        );
      }

      case "status": {
        return (
          <td className="nowrap" key={`status${key}`}>
            <span
              className={`${
                activity.progress_reports.length > 0
                  ? "completed"
                  : isMissed(activity.due_at)
                  ? "overdue"
                  : "pending"
              }`}
            >
              {activity.progress_reports.length > 0
                ? "Completed"
                : isMissed(activity.due_at)
                ? "Overdue"
                : "Pending"}
            </span>
          </td>
        );
      }

      case "action": {
        return (
          <td className="nowrap" key={`action${key}`}>
            <span style={{ height: 35, paddingLeft: 0, display: "block" }}>
              {activity.progress_reports.length > 0 && (
                <IconButton
                  onClick={() => openClientReport(activity)}
                  className="btn-cta"
                  data-testid="arrow-right-btn"
                  name="arrow-right"
                  size="sm"
                />
              )}
            </span>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="project-reports table-responsive">
      <Table>
        <ReactTable
          tableData={reportsTableData(activities)}
          tableColumns={getReportsTableColumns()}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
        />
      </Table>
    </div>
  );
};

TeamReports.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      due_at: PropTypes.string,
      title: PropTypes.string,
      progress_reports: PropTypes.arrayOf(
        PropTypes.shape({
          created_at: PropTypes.string,
        }),
      ),
      user: PropTypes.shape({
        avatar_url: PropTypes.string,
      }),
    }),
  ),
  count: PropTypes.number,
  onLoadMore: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};
export default TeamReports;
