/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------- Internal dependencies ------------------------- */
import Icon from "./Icon";

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
};

const PaginationWrapper = ({
  pages,
  page,
  onPageChange,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}) => {
  const [visiblePages, setVisiblePages] = useState([]);

  const filterPages = (visiblePagesParams, totalPages) => {
    return visiblePagesParams.filter((visiblePage) => visiblePage <= totalPages);
  };

  const getVisiblePages = (pageParams, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (pageParams % 5 >= 0 && pageParams > 4 && pageParams + 2 < total) {
      return [1, pageParams - 1, pageParams, pageParams + 1, total];
    }
    if (pageParams % 5 >= 0 && pageParams > 4 && pageParams + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  useEffect(() => {
    setVisiblePages(getVisiblePages(null, pages));
  }, [pages]);

  useEffect(() => {
    const pagesVisible = getVisiblePages(page, pages);
    setVisiblePages(filterPages(pagesVisible, pages));
  }, [page]);

  const changePage = (selectedPage) => {
    const activePage = page + 1;
    if (selectedPage === activePage) {
      return;
    }
    onPageChange(selectedPage - 1);
  };

  const activePage = page + 1;

  return (
    <Pagination aria-label="Page navigation example">
      <StyledPaginationItem
        onClick={() => {
          previousPage();
        }}
        disabled={canPreviousPage}
      >
        <PaginationLink href="#">
          <Icon name="arrow-left" size="sm" />
        </PaginationLink>
      </StyledPaginationItem>

      <div className="Table__visiblePagesWrapper" style={{ display: "flex" }}>
        {visiblePages.map((visiblePage, index, array) => {
          return array[index - 1] + 2 < visiblePage ? (
            <StyledPaginationItem
              key={visiblePage}
              active={activePage === visiblePage}
              onClick={() => changePage(visiblePage)}
            >
              <PaginationLink href="#">...{visiblePage}</PaginationLink>
            </StyledPaginationItem>
          ) : (
            <StyledPaginationItem
              key={visiblePage}
              active={activePage === visiblePage}
              onClick={() => changePage(visiblePage)}
            >
              <PaginationLink href="#">{visiblePage}</PaginationLink>
            </StyledPaginationItem>
          );
        })}
      </div>
      <StyledPaginationItem
        className="next"
        onClick={() => {
          nextPage();
        }}
        disabled={canNextPage}
      >
        <PaginationLink href="#">
          <Icon name="arrow-right" size="sm" />
        </PaginationLink>
      </StyledPaginationItem>
    </Pagination>
  );
};

const StyledPaginationItem = styled(PaginationItem)`
  &.active a.page-link {
    background-color: #062e64;
    border-color: #062e64;
  }
`;

PaginationWrapper.propTypes = proptypes;

export default React.memo(PaginationWrapper);
