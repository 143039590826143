/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState, Fragment } from "react";
import { debounce, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import { LOCATION_CHANGE } from "react-router-redux";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import IconButton from "../IconButton";
import InputGroup from "../InputGroup";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import randomString from "../../utils/generateRandomString";
import { getSkills as getSkillsAction } from "../../redux/actions/SkillAction";
import { updateProfile } from "../../redux/actions/ProfileActions";
import Star from "../../assets/images/icons/bi_star.svg";
import StarFilled from "../../assets/images/icons/bi_star_filled.svg";

const defaultError = {
  oneSkill: "",
  duplicateSkill: "",
};

const PrioritySkillSelector = (props) => {
  const {
    className,
    size,
    prepend,
    placeholder,
    selected,
    display,
    max,
    onChange,
    onRemove,
    enableDelete,
    selectionKey: key,
    filter: filterFromProp,
  } = props;

  const dispatch = useDispatch();

  const { profile } = useSelector(({ Auth }) => Auth.user);
  const { skills } = useSelector(({ Skill }) => Skill);

  const [prevKey] = useState(null);
  const [selectionKey] = useState(key || randomString.generate());
  const [selectedSkill, setSelectedSkill] = useState(selected);
  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(defaultError);

  const prevSearch = usePrevious(search);
  const prevSelectedSkill = usePrevious(selectedSkill);

  const searchKey = () => {
    return `${selectionKey}-${search}`;
  };

  const onSelectSkill = (e, skill) => {
    e.preventDefault();

    const isContainedSkill = selectedSkill.some((itm) => itm.name === skill);
    if (!isContainedSkill) {
      setSearch("");
      setShowSuggestions(false);
      setSelectedSkill([
        ...selectedSkill,
        { name: skill, primary: false, years_of_experience: "" },
      ]);
      setError(defaultError);
    } else {
      setError({
        ...error,
        duplicateSkill: `${skill} is already a skill`,
      });
    }
  };

  const getSkills = debounce((filter) => {
    dispatch(getSkillsAction(filter, searchKey(), prevKey));
  }, 100);

  const checkMaxPrimary = () => {
    let maxPrimary = 0;

    selectedSkill.forEach((skill) => {
      if (skill.primary) {
        maxPrimary += 1;
      }
    });
    return maxPrimary;
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const skill = e.target.value;
      onSelectSkill(e, skill);
    }
  };

  const handleChange = (e) => {
    const skill = e.target.value;
    setSearch(skill);
    setShowSuggestions(!!skill);
  };

  const onRemoveSkill = (e, index) => {
    e.preventDefault();

    const splicedItem = selectedSkill.filter((_, idx) => idx + 1 !== index);
    if (splicedItem) {
      setSelectedSkill(splicedItem);
    }
  };

  const removeSkillAndUpdateUser = (e, skill) => {
    if (!enableDelete) {
      setError({
        ...error,
        oneSkill: "You must have at least one skill",
      });
    } else {
      onRemoveSkill(e, skill.id);

      const filteredSkills = profile.skills.filter((item) => item.id !== skill.id);
      onRemove();
      dispatch({ type: LOCATION_CHANGE });
      dispatch(
        updateProfile(
          profile.id,
          {
            skills: filteredSkills,
          },
          null,
          false,
          true,
        ),
      );
    }
  };

  const markAsPrimary = (e, skill) => {
    e.preventDefault();
    if (checkMaxPrimary() >= 5 && !skill.primary) return;

    const filteredSkills = selectedSkill.map((item) => {
      if (item.name === skill.name) {
        return {
          id: item.id || "",
          name: item.name,
          slug: item.slug || "",
          primary: !item.primary,
          type: item.type,
          years_of_experience: item.years_of_experience || "",
        };
      }
      return item;
    });

    setSelectedSkill(filteredSkills);
  };

  const onAddYearsOfExperience = (e, skill) => {
    const filteredSkills = selectedSkill.map((item) => {
      if (item.name === skill.name) {
        return {
          ...item,
          years_of_experience: e.target.value,
        };
      }
      return item;
    });

    setSelectedSkill(filteredSkills);
  };

  useEffect(() => {
    checkMaxPrimary();
  }, []);

  useEffect(() => {
    setSelectedSkill(selected);
  }, [selected]);

  useDidUpdate(() => {
    if (!isEqual(selectedSkill, prevSelectedSkill) && onChange) {
      onChange(selectedSkill);
    }

    if (!isEqual(search, prevSearch)) {
      getSkills({
        search,
        type: filterFromProp && filterFromProp.filter,
      });
    }

    if (!isEqual(selectedSkill, selectedSkill)) {
      checkMaxPrimary();
    }
  }, [selectedSkill, search]);

  return (
    <div className="tag-input position-relative">
      {(!max || max > selectedSkill.length) && !display && (
        <div>
          {error.duplicateSkill && <span style={{ color: "#da3450" }}>{error.duplicateSkill}</span>}
          <InputGroup
            className={className}
            prepend={prepend ? <i className="tg-ic-tag" /> : null}
            size={size}
            placeholder={placeholder}
            {...filterInputProps(props)}
            {...filterEventProps(props)}
            selected={selectedSkill}
            value={search}
            onFocus={() => setShowSuggestions(!!search)}
            onChange={handleChange}
            onKeyPress={onKeyPress}
          />

          {showSuggestions && (
            <Suggestions className="list-group suggestions">
              {(skills[searchKey()] || []).map((skill) => {
                let exists = false;
                selectedSkill.forEach((item) => {
                  if (item.name === skill.name) {
                    exists = true;
                  }
                });

                if (exists) return null;

                return (
                  <button
                    type="button"
                    className="list-group-item text-left"
                    key={skill?.id || skill?.name}
                    onClick={(e) => onSelectSkill(e, skill.name)}
                  >
                    {skill.name}
                  </button>
                );
              })}
            </Suggestions>
          )}
        </div>
      )}

      {error.oneSkill && <span style={{ color: "#da3450" }}>{error.oneSkill}</span>}

      {selectedSkill && selectedSkill.length > 0 && (
        <SelectedContainer $display={display}>
          {selectedSkill.map((skill, index) => {
            return (
              <Fragment key={`skill-${skill.name}`}>
                {display ? (
                  <SelectedItem2 key={`skill-${skill.name}`}>
                    {skill.primary && (
                      <>
                        <img src={StarFilled} alt="" />
                        &nbsp;&nbsp;
                      </>
                    )}
                    {skill.name}
                    <IconButton
                      name="x-circle"
                      size="sm"
                      className="p-0"
                      onClick={(e) => removeSkillAndUpdateUser(e, skill)}
                    />
                  </SelectedItem2>
                ) : (
                  <SelectedItem key={`skill-${skill.name}`}>
                    <span>{skill.name}</span>

                    <div className="actions">
                      <span style={{ marginRight: 10 }}>
                        {skill.primary ? "Remove primary status" : "Mark as primary skill"}
                      </span>
                      <span style={{ borderBottom: "2px solid #062E64" }}>
                        <input
                          value={skill.years_of_experience}
                          onChange={(e) => onAddYearsOfExperience(e, skill)}
                          className="border-zero"
                          data-testid={`years-${index}`}
                          type="number"
                        />
                        Years
                      </span>
                      <button
                        type="button"
                        data-testid={`testId-${skill.name}`}
                        className={`btn p-0 ${
                          checkMaxPrimary() >= 5 && !skill.primary ? "disable" : ""
                        }`}
                        onClick={(e) => markAsPrimary(e, skill)}
                      >
                        &nbsp;&nbsp;
                        {skill.primary ? (
                          <img src={StarFilled} alt="" />
                        ) : (
                          <img src={Star} alt="" />
                        )}
                      </button>

                      {selectedSkill.length > 1 && (
                        <IconButton
                          data-testid={`testId-del-${skill.name}`}
                          name="delete-outline"
                          size="sm"
                          className="p-0"
                          onClick={(e) => onRemoveSkill(e, index + 1)}
                        />
                      )}
                    </div>
                  </SelectedItem>
                )}
              </Fragment>
            );
          })}
        </SelectedContainer>
      )}
    </div>
  );
};

const SelectedContainer = withTheme(styled.div`
  ${(props) => props.theme.mixins.clearfix}
  ${(props) => (!props.display ? "margin-top: 5px" : "margin-bottom: 24px")}
`);

const SelectedItem = withTheme(styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(17)};
  color: ${(props) => props.theme.colors["dark-blue"]};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edf1f7;
  padding: 16px 0;

  > span {
    display: inline-block;
    place-self: center;
    padding: 8px 12px;
    border-radius: 50px;
    background: rgba(6, 46, 100, 0.04);
    border: 1px solid rgba(6, 46, 100, 0.04);
    margin-right: 16px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;

    button.btn {
      &.disable {
        opacity: 0.3;
        cursor: default;
      }
      cursor: pointer;

      span {
        display: none;
        color: ${(props) => props.theme.colors["dark-blue"]};
      }
    }
  }

  &:hover {
    .actions {
      button.btn {
        &.disable span {
          display: none;
        }
        span {
          display: inline-block;
        }
      }
    }
  }

  button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(20)};
      margin-left: 14px;
      color: ${(props) => props.theme.colors["dark-blue"]};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const SelectedItem2 = withTheme(styled.span`
  display: inline-block;
  padding: 0px 12px;
  border-radius: 50px;
  background: rgba(6, 46, 100, 0.04);
  border: 1px solid rgba(6, 46, 100, 0.04);
  margin-right: 16px;

  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(17)};
  color: ${(props) => props.theme.colors["dark-blue"]};

  img {
    height: 15px;
  }

  button {
    height: 28px;
    vertical-align: baseline;
    line-height: unset;
    margin-left: 14px;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(15)};
      color: ${(props) => props.theme.colors["dark-blue"]};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const Suggestions = withTheme(styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;

  button.list-group-item {
    color: #da3451;
    text-align: left;

    :hover {
      color: #fff;
      background-color: #da3451;
    }
  }
`);

PrioritySkillSelector.defaultProps = {
  placeholder: "Add skills or products",
  selectionKey: null,
  max: null,
  display: false,
  enableDelete: false,
};

PrioritySkillSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        years_of_experience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        slug: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
  ]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  max: PropTypes.number,
  prepend: PropTypes.bool,
  filter: PropTypes.shape({
    filter: PropTypes.string,
  }),
  display: PropTypes.bool,
  onRemove: PropTypes.func,
  enableDelete: PropTypes.bool,
};
export default PrioritySkillSelector;
