/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconButton from "../../../../../components/IconButton";

/* -------------------------- Internal Dependencies ------------------------- */

const Filters = ({
  clearDuration,
  removeStatus,
  removeUser,
  activities,
  filters: { checkedStatus, checkedUsers, duration },
}) => {
  const isObjEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  return (
    <>
      {(duration.start !== null ||
        duration.end !== null ||
        !isObjEmpty(checkedStatus) ||
        !isObjEmpty(checkedUsers)) && (
        <FilterLabels>
          <div className="labels">
            {checkedUsers &&
              Object.keys(checkedUsers).map((item, i) => (
                <span key={`key-label-${i}`}>
                  {checkedUsers[item].name}
                  <IconButton
                    data-testid="removeUserBtn"
                    name="x-circle"
                    size="sm"
                    className="p-0"
                    onClick={() => removeUser(item)}
                  />
                </span>
              ))}
            {checkedStatus &&
              Object.keys(checkedStatus).map((item, i) => (
                <span key={`key-label-${i}`}>
                  {checkedStatus[item].name}
                  <IconButton
                    data-testid="removeStatusBtn"
                    name="x-circle"
                    size="sm"
                    className="p-0"
                    onClick={() => removeStatus(item)}
                  />
                </span>
              ))}
            {(duration.start !== null || duration.end !== null) && (
              <span>
                {duration.start ? moment.utc(duration.start).local().format("DD MMM YYYY") : null}
                {" - "}
                {duration.end ? moment.utc(duration.end).local().format("DD MMM YYYY") : null}
                <IconButton
                  data-testid="clearDurationBtn"
                  name="x-circle"
                  size="sm"
                  className="p-0"
                  onClick={() => clearDuration()}
                />
              </span>
            )}
          </div>
          <span>
            {activities.length} result
            {(activities.length > 1 || activities.length === 0) && "s"}
          </span>
        </FilterLabels>
      )}
    </>
  );
};

const FilterLabels = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;

  .labels span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #062e64;
    padding: 0px 12px;
    border-radius: 50px;
    background: rgba(6, 46, 100, 0.04);
    border: 1px solid rgba(6, 46, 100, 0.04);
    display: inline-flex;
    margin-right: 16px;
    margin-bottom: 8px;

    button {
      margin-left: 15px;
      height: 28px;
      vertical-align: baseline;
      line-height: unset;

      i {
        vertical-align: baseline;
        cursor: pointer;
      }
    }
  }

  span {
    min-width: 70px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #3e4857;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
  }
`;

/* --------------------------- Component proptypes -------------------------- */
const propTypes = {
  filters: PropTypes.shape({
    checkedStatus: PropTypes.shape({
      overdue: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.bool,
      }),
      completed: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.bool,
      }),
      pending: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.bool,
      }),
    }),
  }),
  clearDuration: PropTypes.func,
  removeStatus: PropTypes.func,
  removeUser: PropTypes.func,
  activities: PropTypes.arrayOf(PropTypes.shape({})),
};
Filters.propTypes = propTypes;

export default Filters;
