// Dashboard action types
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const DELETE_NOTIFICATIONS_START = "DELETE_NOTIFICATIONS_START";
export const DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATIONS_FAILED = "DELETE_NOTIFICATIONS_FAILED";
export const CREATE_NOTIFICATION_LOG_START = "CREATE_NOTIFICATION_LOG_START";
export const CREATE_NOTIFICATION_LOG_SUCCESS = "CREATE_NOTIFICATION_LOG_SUCCESS";
export const CREATE_NOTIFICATION_LOG_FAILED = "CREATE_NOTIFICATION_LOG_FAILED";

// Dashboard stats action types
export const GET_DASHBOARD_STATS_START = "GET_DASHBOARD_STATS_START";
export const GET_DASHBOARD_STATS_SUCCESS = "GET_DASHBOARD_STATS_SUCCESS";
export const GET_DASHBOARD_STATS_FAILED = "GET_DASHBOARD_STATS_FAILED";

// Invoices action types
export const CREATE_INVOICE_START = "CREATE_INVOICE_START";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED";
export const CREATE_INVOICE_BATCH_START = "CREATE_INVOICE_BATCH_START";
export const CREATE_INVOICE_BATCH_SUCCESS = "CREATE_INVOICE_BATCH_SUCCESS";
export const CREATE_INVOICE_BATCH_FAILED = "CREATE_INVOICE_BATCH_FAILED";
export const LIST_INVOICES_START = "LIST_INVOICES_START";
export const LIST_INVOICES_SUCCESS = "LIST_INVOICES_SUCCESS";
export const LIST_INVOICES_FAILED = "LIST_INVOICES_FAILED";
export const SEARCH_INVOICES_START = "SEARCH_INVOICES_START";
export const SEARCH_INVOICES_SUCCESS = "SEARCH_INVOICES_SUCCESS";
export const SEARCH_INVOICES_FAILED = "SEARCH_INVOICES_FAILED";
export const RETRIEVE_INVOICE_START = "RETRIEVE_INVOICE_START";
export const RETRIEVE_INVOICE_SUCCESS = "RETRIEVE_INVOICE_SUCCESS";
export const RETRIEVE_INVOICE_FAILED = "RETRIEVE_INVOICE_FAILED";
export const UPDATE_INVOICE_START = "UPDATE_INVOICE_START";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILED = "UPDATE_INVOICE_FAILED";
export const LIST_MORE_INVOICES_START = "LIST_MORE_INVOICES_START";
export const LIST_MORE_INVOICES_SUCCESS = "LIST_MORE_INVOICES_SUCCESS";
export const LIST_MORE_INVOICES_FAILED = "LIST_MORE_INVOICES_FAILED";
export const SEARCH_MORE_INVOICES_START = "SEARCH_MORE_INVOICES_START";
export const SEARCH_MORE_INVOICES_SUCCESS = "SEARCH_MORE_INVOICES_SUCCESS";
export const SEARCH_MORE_INVOICES_FAILED = "SEARCH_MORE_INVOICES_FAILED";
export const DELETE_INVOICE_START = "DELETE_INVOICE_START";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILED = "DELETE_INVOICE_FAILED";
export const PAY_INVOICE_START = "PAY_INVOICE_START";
export const PAY_INVOICE_SUCCESS = "PAY_INVOICE_SUCCESS";
export const PAY_INVOICE_FAILED = "PAY_INVOICE_FAILED";
export const ARCHIVE_INVOICE_SUCCESS = "ARCHIVE_INVOICE_SUCCESS";
export const ARCHIVE_INVOICE_START = "ARCHIVE_INVOICE_START";
export const ARCHIVE_INVOICE_FAILED = "ARCHIVE_INVOICE_FAILED";
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS";
export const GENERATE_INVOICE_START = "GENERATE_INVOICE_START";
export const GENERATE_INVOICE_FAILED = "GENERATE_INVOICE_FAILED";
export const BULK_ACTION_START = "BULK_ACTION_START";
export const BULK_ACTION_FAILED = "BULK_ACTION_FAILED";
export const BULK_ACTION_SUCCESS = "BULK_ACTION_SUCCESS";
export const DOWNLOAD_INVOICE_CSV_STARTED = "DOWNLOAD_INVOICE_CSV_STARTED";
export const DOWNLOAD_INVOICE_CSV_FAILED = "DOWNLOAD_INVOICE_CSV_FAILED";
export const DOWNLOAD_INVOICE_CSV_SUCCESS = "DOWNLOAD_INVOICE_CSV_SUCCESS";
export const INVOICE_SUMMARY_START = "INVOICE_SUMMARY_START";
export const INVOICE_SUMMARY_SUCCESS = "INVOICE_SUMMARY_SUCCESS";
export const INVOICE_SUMMARY_FAILED = "INVOICE_SUMMARY_FAILED";
export const INVOICE_ACTIVITY_START = "INVOICE_ACTIVITY_START";
export const INVOICE_ACTIVITY_SUCCESS = "INVOICE_ACTIVITY_SUCCESS";
export const INVOICE_ACTIVITY_FAILED = "INVOICE_ACTIVITY_FAILED";
export const GET_PLATFORM_SUMMARY_START = "GET_PLATFORM_SUMMARY_START";
export const GET_PLATFORM_SUMMARY_SUCCESS = "GET_PLATFORM_SUMMARY_SUCCESS";
export const GET_PLATFORM_SUMMARY_FAILED = "GET_PLATFORM_SUMMARY_FAILED";
export const GET_INVOICE_COMMENTS_START = "GET_INVOICE_COMMENTS_START";
export const GET_INVOICE_COMMENTS_SUCCESS = "GET_INVOICE_COMMENTS_SUCCESS";
export const GET_INVOICE_COMMENTS_FAILED = "GET_INVOICE_COMMENTS_FAILED";
export const CREATE_INVOICE_COMMENT_START = "CREATE_INVOICE_COMMENT_START";
export const CREATE_INVOICE_COMMENT_SUCCESS = "CREATE_INVOICE_COMMENT_SUCCESS";
export const CREATE_INVOICE_COMMENT_FAILED = "CREATE_INVOICE_COMMENT_FAILED";

export const SEARCH_PROJECTS_START = "SEARCH_PROJECTS_START";
export const SEARCH_PROJECTS_SUCCESS = "SEARCH_PROJECTS_SUCCESS";
export const SEARCH_PROJECTS_FAILED = "SEARCH_PROJECTS_FAILED";
export const SEARCH_MORE_PROJECTS_START = "SEARCH_MORE_PROJECTS_START";
export const SEARCH_MORE_PROJECTS_SUCCESS = "SEARCH_MORE_PROJECTS_SUCCESS";
export const SEARCH_MORE_PROJECTS_FAILED = "SEARCH_MORE_PROJECTS_FAILED";

// Projects Actions types
export const TOGGLE_PROJECT_FILTER = "TOGGLE_PROJECT_FILTER";
export const FETCH_PROJECT_START = "FETCH_PROJECT_START";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILED = "FETCH_PROJECT_FAILED";
export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILED = "FETCH_PROJECTS_FAILED";
export const FETCH_MORE_PROJECTS_START = "FETCH_MORE_PROJECTS_START";
export const FETCH_MORE_PROJECTS_SUCCESS = "FETCH_MORE_PROJECTS_SUCCESS";
export const FETCH_MORE_PROJECTS_FAILED = "FETCH_MORE_PROJECTS_FAILED";
export const UPDATE_PROJECT_START = "UPDATE_PROJECT_START";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED";
export const CREATE_PROGRESS_EVENT_START = "CREATE_PROGRESS_EVENT_START";
export const CREATE_PROJECT_START = "CREATE_PROJECT_START";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";
export const CREATE_PROGRESS_EVENT_SUCCESS = "CREATE_PROGRESS_EVENT_SUCCESS";
export const CREATE_PROGRESS_EVENT_FAILED = "CREATE_PROGRESS_EVENT_FAILED";
export const UPDATE_PROGRESS_EVENT_START = "UPDATE_PROGRESS_EVENT_START";
export const UPDATE_PROGRESS_EVENT_SUCCESS = "UPDATE_PROGRESS_EVENT_SUCCESS";
export const UPDATE_PROGRESS_EVENT_FAILED = "UPDATE_PROGRESS_EVENT_FAILED";
export const RETRIEVE_PROGRESS_EVENT_START = "RETRIEVE_PROGRESS_EVENT_START";
export const RETRIEVE_PROGRESS_EVENT_SUCCESS = "RETRIEVE_PROGRESS_EVENT_SUCCESS";
export const RETRIEVE_PROGRESS_EVENT_FAILED = "RETRIEVE_PROGRESS_EVENT_FAILED";
export const CREATE_DOCUMENT_START = "CREATE_DOCUMENT_START";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_FAILED = "CREATE_DOCUMENT_FAILED";
export const UPDATE_DOCUMENT_START = "UPDATE_DOCUMENT_START";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILED = "UPDATE_DOCUMENT_FAILED";
export const DELETE_DOCUMENT_START = "DELETE_DOCUMENT_START";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";
export const LIST_TIMESHEETS_START = "LIST_TIMESHEETS_START";
export const LIST_TIMESHEETS_SUCCESS = "LIST_TIMESHEETS_SUCCESS";
export const LIST_TIMESHEETS_FAILED = "LIST_TIMESHEETS_FAILED";
export const CREATE_TIMESHEET_START = "CREATE_TIMESHEET_START";
export const CREATE_TIMESHEET_SUCCESS = "CREATE_TIMESHEET_SUCCESS";
export const CREATE_TIMESHEET_FAILED = "CREATE_TIMESHEET_FAILED";
export const LIST_ACTIVITIES_START = "LIST_ACTIVITIES_START";
export const LIST_ACTIVITIES_SUCCESS = "LIST_ACTIVITIES_SUCCESS";
export const LIST_ACTIVITIES_FAILED = "LIST_ACTIVITIES_FAILED";
export const LIST_MORE_ACTIVITIES_START = "LIST_MORE_ACTIVITIES_START";
export const LIST_MORE_ACTIVITIES_SUCCESS = "LIST_MORE_ACTIVITIES_SUCCESS";
export const LIST_MORE_ACTIVITIES_FAILED = "LIST_MORE_ACTIVITIES_FAILED";
export const LIST_PROGRESS_EVENTS_START = "LIST_PROGRESS_EVENTS_START";
export const LIST_PROGRESS_EVENTS_SUCCESS = "LIST_PROGRESS_EVENTS_SUCCESS";
export const LIST_PROGRESS_EVENTS_FAILED = "LIST_PROGRESS_EVENTS_FAILED";
export const UPDATE_PROGRESS_REPORT_START = "UPDATE_PROGRESS_REPORT_START";
export const UPDATE_PROGRESS_REPORT_SUCCESS = "UPDATE_PROGRESS_REPORT_SUCCESS";
export const UPDATE_PROGRESS_REPORT_FAILED = "UPDATE_PROGRESS_REPORT_FAILED";
export const FILTER_PROJECT_REPORTS = "FILTER_PROJECT_REPORTS";
export const SHOW_USERS_FILTER = "SHOW_USERS_FILTER";
export const CLEAR_DURATION_FILTER = "CLEAR_DURATION_FILTER";
export const REMOVE_STATUS_FILTER = "REMOVE_STATUS_FILTER";
export const REMOVE_USER_FILTER = "REMOVE_USER_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";
export const SUBMIT_DEVELOPER_RATING_START = "SUBMIT_DEVELOPER_RATING_START";
export const SUBMIT_DEVELOPER_RATING_SUCCESS = "SUBMIT_DEVELOPER_RATING_SUCCESS";
export const SUBMIT_DEVELOPER_RATING_FAILED = "SUBMIT_DEVELOPER_RATING_FAILED";
export const SEND_REMINDER_START = "SEND_REMINDER_START";
export const SEND_REMINDER_SUCCESS = "SEND_REMINDER_SUCCESS";
export const SEND_REMINDER_FAILED = "SEND_REMINDER_FAILED";
export const ASK_TUNGA_START = "ASK_TUNGA_START";
export const ASK_TUNGA_SUCCESS = "ASK_TUNGA_SUCCESS";
export const ASK_TUNGA_FAILED = "ASK_TUNGA_FAILED";
export const UPDATE_PARTICIPATION_START = "UPDATE_PARTICIPATION_START";
export const UPDATE_PARTICIPATION_SUCCESS = "UPDATE_PARTICIPATION_SUCCESS";
export const UPDATE_PARTICIPATION_FAILED = "UPDATE_PARTICIPATION_FAILED";

// Test action types
export const CREATE_RESULT_START = "CREATE_RESULT_START";
export const CREATE_RESULT_SUCCESS = "CREATE_RESULT_SUCCESS";
export const CREATE_RESULT_FAILED = "CREATE_RESULT_FAILED";
export const UPDATE_RESULT_START = "UPDATE_RESULT_START";
export const UPDATE_RESULT_SUCCESS = "UPDATE_RESULT_SUCCESS";
export const UPDATE_RESULT_FAILED = "UPDATE_RESULT_FAILED";
export const FETCH_RESULT_START = "FETCH_RESULT_START";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_FAILED = "FETCH_RESULT_FAILED";
export const DELETE_RESULT_START = "DELETE_RESULT_START";
export const DELETE_RESULT_SUCCESS = "DELETE_RESULT_SUCCESS";
export const DELETE_RESULT_FAILED = "DELETE_RESULT_FAILED";
export const LIST_MORE_RESULTS_START = "LIST_MORE_RESULTS_START";
export const LIST_MORE_RESULTS_SUCCESS = "LIST_MORE_RESULTS_SUCCESS";
export const LIST_MORE_RESULTS_FAILED = "LIST_MORE_RESULTS_FAILED";
export const SET_FILTERS = "SET_FILTERS";

// Skills action types
export const GET_SKILLS_START = "GET_SKILLS_START";
export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_SKILLS_FAILED = "GET_SKILLS_FAILED";
export const INVALIDATE_SKILLS = "INVALIDATE_SKILLS";

// Utility action types
export const TOGGLE_RIGHT_NAV = "TOGGLE_RIGHT_NAV";
export const SET_PENDING_INVITES = "SET_PENDING_INVITES";
export const SET_MORE_PENDING_INVITES = "SET_MORE_PENDING_INVITES";
export const DELETE_PENDING_INVITE = "DELETE_PENDING_INVITE";

// Profile Actions
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const RETRIEVE_SETTINGS_START = "RETRIEVE_SETTINGS_START";
export const RETRIEVE_SETTINGS_SUCCESS = "RETRIEVE_SETTINGS_SUCCESS";
export const RETRIEVE_SETTINGS_FAILED = "RETRIEVE_SETTINGS_FAILED";
export const UPDATE_SETTINGS_START = "UPDATE_SETTINGS_START";
export const UPDATE_SETTINGS_FAILED = "UPDATE_SETTINGS_FAILED";
export const UPDATE_ONBOARDING_STAGE = "UPDATE_ONBOARDING_STAGE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const ONBOARDING_STAGE_ONE = "ONBOARDING_STAGE_ONE";
export const ONBOARDING_STAGE_TWO = "ONBOARDING_STAGE_TWO";
export const ONBOARDING_STAGE_THREE = "ONBOARDING_STAGE_THREE";
export const ONBOARDING_FINISH = "ONBOARDING_FINISH";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";
export const UPDATE_WORK_SUCCESS = "UPDATE_WORK_SUCCESS";
export const UPDATE_WORK_FAILED = "UPDATE_WORK_FAILED";
export const UPDATE_PROJECTS_SUCCESS = "UPDATE_PROJECTS_SUCCESS";
export const UPDATE_PROJECTS_FAILED = "UPDATE_PROJECTS_FAILED";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_FAILED = "UPDATE_EDUCATION_FAILED";
export const DELETE_PARTICIPATION_START = "DELETE_PARTICIPATION_START";
export const DELETE_PARTICIPATION_SUCCESS = "DELETE_PARTICIPATION_SUCCESS";
export const DELETE_PARTICIPATION_FAILED = "DELETE_PARTICIPATION_FAILED";

export const UPDATE_COMPANY_START = "UPDATE_COMPANY_START";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";

// Buttons
export const SET_BUTTON = "SET_BUTTON";

// Payments
export const FETCH_PAYMENT_PROVIDERS_START = "FETCH_PAYMENT_PROVIDERS_START";
export const FETCH_PAYMENT_PROVIDERS_SUCCESS = "FETCH_PAYMENT_PROVIDERS_SUCCESS";
export const FETCH_PAYMENT_PROVIDERS_FAILED = "FETCH_PAYMENT_PROVIDERS_FAILED";

export const GET_PAYONEER_START = "GET_PAYONEER_START";
export const GET_PAYONEER_SUCCESS = "GET_PAYONEER_SUCCESS";
export const GET_PAYONEER_FAILED = "GET_PAYONEER_FAILED";

export const ADD_PAYONEER_START = "ADD_PAYONEER_START";
export const ADD_PAYONEER_SUCCESS = "ADD_PAYONEER_SUCCESS";
export const ADD_PAYONEER_FAILED = "ADD_PAYONEER_FAILED";

export const FETCH_WISE_START = "FETCH_WISE_START";
export const FETCH_WISE_SUCCESS = "FETCH_WISE_SUCCESS";
export const FETCH_WISE_FAILED = "FETCH_WISE_FAILED";

export const CONNECT_WISE_START = "CONNECT_WISE_START";
export const CONNECT_WISE_SUCCESS = "CONNECT_WISE_SUCCESS";
export const CONNECT_WISE_FAILED = "CONNECT_WISE_FAILED";

export const PATCH_CHANGE_DEFAULT_ACCOUNT_START = "PATCH_CHANGE_DEFAULT_ACCOUNT_START";
export const PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS = "PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS";
export const PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED = "PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED";

export const GET_IBAN_START = "GET_IBAN_START";
export const GET_IBAN_SUCCESS = "GET_IBAN_SUCCESS";
export const GET_IBAN_FAILED = "GET_IBAN_FAILED";

export const ADD_IBAN_START = "ADD_IBAN_START";
export const ADD_IBAN_SUCCESS = "ADD_IBAN_SUCCESS";
export const ADD_IBAN_FAILED = "ADD_IBAN_FAILED";

// CSV UPLOAD
export const UPLOAD_CSV_START = "UPLOAD_CSV_START";
export const UPLOAD_CSV_SUCCESS = "UPLOAD_CSV_SUCCESS";
export const UPLOAD_CSV_FAILED = "UPLOAD_CSV_FAILED";
// User Actions
export const LIST_USERS_START = "LIST_USERS_START";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_FAILED = "LIST_USERS_FAILED";
export const LIST_MORE_USERS_SUCCESS = "LIST_MORE_USERS_SUCCESS";
export const LIST_TALENTS_START = "LIST_TALENTS_START";
export const LIST_TALENTS_SUCCESS = "LIST_TALENTS_SUCCESS";
export const LIST_TALENTS_FAILED = "LIST_TALENTS_FAILED";

export const DEACTIVATION_ACTIVATION_USER_START = "DEACTIVATION_ACTIVATION_USER_START";
export const DEACTIVATION_ACTIVATION_USER_SUCCESS = "DEACTIVATION_ACTIVATION_USER_SUCCESS";
export const DEACTIVATION_ACTIVATION_USER_FAILED = "DEACTIVATION_ACTIVATION_USER_FAILED";

export const RETRIEVE_INVITE_START = "RETRIEVE_INVITE_START";
export const RETRIEVE_INVITE_SUCCESS = "RETRIEVE_INVITE_SUCCESS";
export const RETRIEVE_INVITE_FAILED = "RETRIEVE_INVITE_FAILED";

export const RETRIEVE_APPLICATION_START = "RETRIEVE_APPLICATION_START";
export const RETRIEVE_APPLICATION_SUCCESS = "RETRIEVE_APPLICATION_SUCCESS";
export const RETRIEVE_APPLICATION_FAILED = "RETRIEVE_APPLICATION_FAILED";

// Payment Reminder
export const PAYMENT_REMINDER_START = "PAYMENT_REMINDER_START";
export const PAYMENT_REMINDER_SUCCESS = "PAYMENT_REMINDER_SUCCESS";
export const PAYMENT_REMINDER_FAILED = "PAYMENT_REMINDER_FAILED";
// Dev profile
export const DEV_PROFILE_START = "DEV_PROFILE_START";
export const DEV_PROFILE_SUCCESS = "DEV_PROFILE_SUCCESS";
export const DEV_PROFILE_FAILED = "DEV_PROFILE_FAILED";
export const SHARE_PROFILE_START = "SHARE_PROFILE_START";
export const SHARE_PROFILE_SUCCESS = "SHARE_PROFILE_SUCCESS";
export const SHARE_PROFILE_FAILED = "SHARE_PROFILE_FAILED";

// Fetch Shortlist
export const FETCH_SHORTLIST_START = "FETCH_SHORTLIST_START";
export const FETCH_SHORTLIST_SUCCESS = "FETCH_SHORTLIST_SUCCESS";
export const FETCH_SHORTLIST_FAILED = "FETCH_SHORTLIST_FAILED";

// TimeSheet Entries
export const LIST_TIMESHEET_MONTHLY_ENTRIES_START = "LIST_TIMESHEET_MONTHLY_ENTRIES_START";
export const LIST_TIMESHEET_MONTHLY_ENTRIES_SUCCESS = "LIST_TIMESHEET_MONTHLY_ENTRIES_SUCCESS";
export const LIST_TIMESHEET_MONTHLY_ENTRIES_FAILED = "LIST_TIMESHEET_MONTHLY_ENTRIES_FAILED";
export const LIST_TIMESHEET_YEARLY_ENTRIES_START = "LIST_TIMESHEET_YEARLY_ENTRIES_START";
export const LIST_TIMESHEET_YEARLY_ENTRIES_SUCCESS = "LIST_TIMESHEET_YEARLY_ENTRIES_SUCCESS";
export const LIST_TIMESHEET_YEARLY_ENTRIES_FAILED = "LIST_TIMESHEET_YEARLY_ENTRIES_FAILED";
export const TIMESHEET_APPROVE_REJECT_START = "TIMESHEET_APPROVE_REJECT_START";
export const TIMESHEET_APPROVE_REJECT_SUCCESS = "TIMESHEET_APPROVE_REJECT_SUCCESS";
export const TIMESHEET_APPROVE_REJECT_FAILED = "TIMESHEET_APPROVE_REJECT_FAILED";
export const UPDATE_TIMESHEET_ENTRY_START = "UPDATE_TIMESHEET_ENTRY_START";
export const UPDATE_TIMESHEET_ENTRY_SUCCESS = "UPDATE_TIMESHEET_ENTRY_SUCCESS";
export const UPDATE_TIMESHEET_ENTRY_FAILED = "UPDATE_TIMESHEET_ENTRY_FAILED";

// Interest polls
export const SHARE_SHORTLIST_START = "SHARE_SHORTLIST_START";
export const SHARE_SHORTLIST_SUCCESS = "SHARE_SHORTLIST_SUCCESS";
export const SHARE_SHORTLIST_FAILED = "SHARE_SHORTLIST_FAILED";

export const GET_USER_TIMESHEET_ENTRIES_START = "GET_USER_TIMESHEET_ENTRIES_START";
export const GET_USER_TIMESHEET_ENTRIES_SUCCESS = "GET_USER_TIMESHEET_ENTRIES_SUCCESS";
export const GET_USER_TIMESHEET_ENTRIES_FAILED = "GET_USER_TIMESHEET_ENTRIES_FAILED";
export const RESET_USER_TIMESHEET_ENTRIES = "RESET_USER_TIMESHEET_ENTRIES";
