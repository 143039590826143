import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import {
  isCMOrCSOAndHasProjectAcess,
  isAdmin,
  isDev,
  isInternalUser,
} from "../../../../utils/auth";
import { generateUserInitials } from "../../../../utils/stringUtils";
import Button from "../../../../components/Button";
import { displayExpectedReturn } from "../../../../utils/helpers";
import Avatar from "../../../../components/Avatar";
import Icon from "../../../../components/Icon";
import useOpportunity from "../../../../hooks/opportunity/useOpportunity";
import { STATUS_INTERESTED, STATUS_UNINTERESTED } from "../../../../utils/api";
import Success from "../../../../components/Success";
import Error from "../../../../components/Error";

const OpportunityDetails = (props) => {
  const { project } = props;
  const { activateProject, onInterestUpdate, interest } = useOpportunity(project);

  return (
    <OpportunityWrapper>
      <div className="bs-content-card">
        {interest.success ? <Success message="Successfully submitted your availability" /> : null}

        {interest.error ? <Error message="Something went wrong! Please try again." /> : null}

        <div className="section">
          <div className="header">Opportunity Details</div>
        </div>

        <div className="section">
          <div className="title">Title</div>
          <p>{project.title}</p>
        </div>

        <div className="section">
          <div className="title">Description</div>
          <p>{project.description}</p>
        </div>

        <div className="section row">
          <div className="col">
            <div className="title">Service Category</div>
            <p
              style={{
                textTransform: "capitalize",
              }}
            >
              {project?.category === "project" ? "Managed" : project?.category || "No project type"}
            </p>
          </div>
          <div className="col">
            <div className="title">Expected Duration</div>
            <p>{displayExpectedReturn(project.expected_duration)}</p>
          </div>
        </div>

        <div className="section">
          <div className="title">Technologies</div>
          <div className="list">
            {project.skills.map((skill, i) => {
              return <span key={`skills-${i}`}>{skill.name}</span>;
            })}
          </div>
        </div>

        {(project?.contact_name || project?.contact_email) && isInternalUser() && (
          <div className="section row">
            <div className="col">
              <div className="title">Client Name</div>
              <p>{project?.contact_name}</p>
            </div>
            <div className="col">
              <div className="title">Client Email</div>
              <p>{project?.contact_email}</p>
            </div>
          </div>
        )}

        <div className="section row">
          <div className="col">
            <div className="title">Created By</div>
            <div>
              <Avatar
                image={project.user.avatar_url}
                initials={generateUserInitials(project.user)}
                size="dash"
                className="bsd-mb6"
              />
              {project.user.display_name}
            </div>
          </div>
          <div className="col">
            <div className="title">Created On</div>
            <p>{moment.utc(project.created_at).local().format("DD MMM YYYY; hh:mm A")}</p>
          </div>
        </div>

        {(isAdmin() || isCMOrCSOAndHasProjectAcess(project)) && (
          <div className="section">
            <Button onClick={() => activateProject()} data-testid="activateBtn">
              <Icon name="baseline-folder-open" size="sm" />
              Convert to Project
            </Button>
          </div>
        )}

        {isDev() ? (
          project?.user_interest_status ? (
            <div className="section">
              {project.user_interest_status !== STATUS_INTERESTED ? (
                <>
                  <Button
                    onClick={() => onInterestUpdate(STATUS_INTERESTED)}
                    data-testid="available"
                  >
                    I&apos;m available{" "}
                    {project.user_interest_status === STATUS_UNINTERESTED
                      ? "again"
                      : "and interested"}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : null}

              {project?.user_interest_status !== STATUS_UNINTERESTED ? (
                <Button
                  variant="secondary"
                  onClick={() => onInterestUpdate(STATUS_UNINTERESTED)}
                  data-testid="not_available"
                >
                  I&apos;m not available{" "}
                  {project?.user_interest_status === STATUS_INTERESTED
                    ? "anymore"
                    : "for this project"}
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="section">
              <Button
                className="primary"
                onClick={() => onInterestUpdate(STATUS_INTERESTED)}
                data-testid="available_and_interested"
              >
                I&apos;m available and interested
              </Button>{" "}
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="secondary"
                onClick={() => onInterestUpdate(STATUS_UNINTERESTED)}
                data-testid="not_available"
              >
                I&apos;m not available
              </Button>
            </div>
          )
        ) : null}
      </div>
    </OpportunityWrapper>
  );
};

OpportunityDetails.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    expected_duration: PropTypes.string,
    user_interest_status: PropTypes.string,
    contact_name: PropTypes.string,
    contact_email: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({ avatar_url: PropTypes.string, display_name: PropTypes.string }),
    created_at: PropTypes.string,
    category: PropTypes.string,
  }),
  isSaved: PropTypes.shape({ interest: PropTypes.bool }), // TODO: make this into a bool
  ProjectActions: PropTypes.shape({}),
  match: PropTypes.shape({ url: PropTypes.string }),
};

const OpportunityWrapper = styled.div`
  max-width: 640px;

  .section {
    padding: 24px 0;
    border-bottom: 1px solid #edf1f7;
    font-size: 16px;
    line-height: 24px;

    &:first-of-type {
      padding-top: 0px;
      padding-bottom: 34px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #151a30;
    }

    .title {
      font-weight: 600;
      color: #151a30;
      margin-bottom: 16px;
    }

    p {
      color: #3e4857;
      margin-bottom: 0;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      span {
        padding: 7px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #062e64;
        background: rgba(6, 46, 100, 0.04);
        border: 1px solid rgba(6, 46, 100, 0.04);
        box-sizing: border-box;
        border-radius: 50px;
        margin-right: 16px;
      }
    }

    .avatar.avatar-dash {
      width: 35px;
      height: 35px;

      &.avatar-initials {
        font-size: 14px;
      }
    }

    button {
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      color: #062e64;
      box-shadow: none;
      border: none;

      &.primary {
        background: #da3451;
        color: white;
      }

      i {
        margin-right: 18px;
      }
    }
  }

  .opportunity-files {
    list-style-type: none;
  }

  .interest-user {
    padding: px-to-rem(10);
    margin-bottom: 10px;
    color: get-color("white");
    background-color: get-color("dark-grey");
    border: 1px solid rgba(0, 0, 0, 0.125);

    .avatar {
      margin-right: 5px;
    }
  }

  .interest-actions {
    .btn {
      color: get-color("dark-grey");
      margin-right: 5px;
    }

    .btn-accepted {
      color: get-color("green");
    }

    .btn-rejected {
      color: get-color("red");
    }
  }

  .opportunity-actions {
    .btn {
      margin-right: 10px;
    }
  }
`;

export default OpportunityDetails;
