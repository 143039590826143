import React from "react";
import { Link, useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import Icon from "../../../../../components/Icon";
import SectionNav from "../../../../../components/SectionNav";
import useRightNav from "../../../../../layouts/RightSideNav/useRightNav";
import Navigation from "../../../../../layouts/RightSideNav/Navigation";
import { NavActions } from "../../../payments/styles";
import ReportsListContainer from "./ReportsListContainer";
import YourReports from "./YourReports";
import TeamReports from "./TeamReports";
import ClientReports from "./ClientReports";
import {
  getUser,
  isAdmin,
  isAdminOrCMOrClientOrCSO,
  isClient,
  isCM,
  isCSO,
} from "../../../../../utils/auth";
import {
  PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING,
  PROGRESS_EVENT_TYPE_CM,
  PROGRESS_EVENT_TYPE_DEVELOPER,
} from "../../../../../utils/api";
import FilterSidebar from "./FilterSidebar";

const navSections = [
  {
    component: <FilterSidebar />,
    title: "Filters",
    headerText: true,
  },
];

const Reports = () => {
  const match = useRouteMatch();
  const { open } = useRightNav();

  const urlBase = match.url.split("/").splice(0, 3).join("/");
  const accessCheck = isAdminOrCMOrClientOrCSO();

  return (
    <>
      <SectionNav
        links={
          accessCheck
            ? [
                ...(isClient()
                  ? [
                      {
                        route: `${match.url.replace("/", "")}/team`,
                        name: "From Team Members",
                      },
                    ]
                  : []),
                ...(!isAdmin()
                  ? [{ route: `${match.url.replace("/", "")}/yours`, name: "Yours" }]
                  : []),
                ...(!isClient()
                  ? [
                      {
                        route: `${match.url.replace("/", "")}/team`,
                        name: "Team Members",
                      },
                      {
                        route: `${match.url.replace("/", "")}/client`,
                        name: "Client",
                      },
                    ]
                  : []),
              ]
            : []
        }
        rightActions={
          <StyledNavActions className={!accessCheck ? "single-action" : ""}>
            <button
              className="btn p-0"
              type="button"
              onClick={() => open(<Navigation borderBottom={false} navSections={navSections} />)}
            >
              <Icon name="filter-variant" size="sm" /> Filter
            </button>
            {accessCheck && (
              <Link to={`${urlBase}/settings/reports`}>
                <Icon name="outline-settings" size="sm" /> Configure
              </Link>
            )}
          </StyledNavActions>
        }
      />

      <Switch>
        {[
          ["yours", <YourReports />],
          ["team", <TeamReports />],
          ["client", <ClientReports />],
        ].map((path) => (
          <Route
            key={`report-${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => {
              const page = path[0];

              const type =
                page === "team"
                  ? `${PROGRESS_EVENT_TYPE_DEVELOPER}${
                      !(isCM() || isCSO()) ? `,${PROGRESS_EVENT_TYPE_CM}` : ""
                    }`
                  : page === "client"
                  ? PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING
                  : isCM() || isCSO()
                  ? PROGRESS_EVENT_TYPE_CM
                  : isClient()
                  ? PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING
                  : PROGRESS_EVENT_TYPE_DEVELOPER;

              const params = page === "yours" ? { users: getUser().id } : null;

              return (
                <ReportsListContainer page={page} params={params} type={type}>
                  {path[1]}
                </ReportsListContainer>
              );
            }}
          />
        ))}
        <Redirect exact from={`${match.url}`} to={`${match.url}/${isAdmin() ? "team" : "yours"}`} />
      </Switch>
    </>
  );
};

const StyledNavActions = withTheme(styled(NavActions)`
  &.single-action {
    display: block;
    float: none;
  }

  a {
    color: ${(props) => props.theme.colors.gray || "initial"};
    font-weight: 500;
    font-size: ${(props) =>
      props.theme.functions.pxToRem(
        14,
      )}; /* TODO 14px can be added to global styles cause it seems all text is 14px  */
    text-decoration: none;
    margin-left: 25px;

    /* Pull in mixins from props */
    ${(props) => props.theme.mixins.link(props.theme.colors.gray)}

    i {
      vertical-align: baseline;
      margin-right: 5px;
    }
  }

  a:nth-child(1) {
    i {
      font-size: 11px; /* TODO: this icon dimensions have to be adjusted in resources directly */
    }
  }

  button.btn {
    height: unset;
    line-height: unset;

    :hover,
    :active {
      color: ${(props) => props.theme.colors.gray || "initial"};
    }
  }
`);
export default Reports;
