import React from "react";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const Referral = () => {
  useTrackPage(pages.REFER_AND_EARN);

  const { user } = useSelector(({ Auth }) => Auth);

  const iframe = () => {
    let iframeSrc = process.env.REACT_APP_REFERRAL_JOIN_LINK;
    if (user.profile?.referral_details !== null) {
      iframeSrc = `https://tunga.referral-factory.com/${user.profile.referral_details?.referral_code}/referrals`;
    }

    return {
      // Sanitize external HTML to prevent XSS attacks
      __html: DOMPurify.sanitize(
        `<iframe class="clickup-embed" src="${iframeSrc}" onwheel="" width="100%" height="700px" style="background: transparent;"></iframe>`,
        {
          ALLOWED_TAGS: ["iframe"],
          ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
        },
      ),
    };
  };

  return (
    <DashboardLayout>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={iframe()} />
    </DashboardLayout>
  );
};

export default Referral;
