/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { isValidElement } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

/* -------------------------- Internal Dependencies ------------------------- */
import Icon from "./Icon";
import { logout } from "../redux/actions/AuthActions";
import logo from "../assets/images/logo_round.png";
import { isClient, isDev } from "../utils/auth";
import useRightNav from "../layouts/RightSideNav/useRightNav";
import {
  ConnectedPeople,
  DashboardIcon,
  LogoutIcon,
  PaymentsIcon,
  ProjectsIcon,
} from "../pages/Dashboard/dashboard/Icons";
import { resetAnalytics } from "../analytics/segment";

const SideBar = () => {
  const dispatch = useDispatch();
  const { close } = useRightNav();
  const { location: loc } = useHistory();

  const onSignOut = (e) => {
    resetAnalytics();
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <Wrapper id="sidebar" className="sidebar">
      <img alt="company-logo" src={logo} />
      <ul className="nav">
        {[
          ["dashboard", "Dashboard", <DashboardIcon active={loc?.pathname === "/dashboard"} />],
          [
            "projects",
            "Projects",
            <ProjectsIcon active={loc?.pathname.indexOf("/projects") === 0} />,
          ],
          // ...(isAdminOrPMOrSpaceLead() ? [["tests", "Tests", "award"]] : []),
          [
            `payments/${isDev() ? "out" : "in"}`,
            "Payments",
            <PaymentsIcon active={loc?.pathname.indexOf("/payments") === 0} />,
          ],
          ["settings", "Settings", "outline-settings"],
          ...(isDev()
            ? [
                [
                  "referral",
                  "Refer and Earn",
                  <ConnectedPeople active={loc?.pathname === "/referral"} />,
                ],
              ]
            : []),
          ...(!isClient() ? [["community", "Community Guide", "baseline-folder-open"]] : []),
        ].map((item, idx) => {
          return (
            <li key={`dashboard-${idx}`}>
              {item[0] === "referral" ? (
                <a href={process.env.REACT_APP_REFERRAL_JOIN_LINK} target="_blank" rel="noreferrer">
                  <span className="menu-icon">
                    {isValidElement(item[2]) ? item[2] : <Icon name={item[2]} size="sidebar" />}
                  </span>
                  <span>{item[1]}</span>
                </a>
              ) : (
                <NavLink
                  to={`/${item[0]}`}
                  activeClassName="active"
                  onClick={close}
                  className="pe-0"
                  isActive={(match, location) => {
                    return item[0] === "payments/in/all"
                      ? location?.pathname.includes("payments/in/") ||
                          location?.pathname.includes("payments/out/")
                      : location?.pathname.split("/")[1] === item[0].split("/")[0];
                  }}
                >
                  <span className="menu-icon">
                    {isValidElement(item[2]) ? item[2] : <Icon name={item[2]} size="sidebar" />}
                  </span>
                  <span>{item[1]}</span>
                </NavLink>
              )}
            </li>
          );
        })}
        <li>
          <NavLink data-testid="signout" to="logout" onClick={(e) => onSignOut(e)}>
            <span className="menu-icon">
              <LogoutIcon />
            </span>
            <span>Log Out</span>
          </NavLink>
        </li>
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    width: 75px;
    margin: 2rem auto;
    display: block;
  }

  position: fixed;
  width: 220px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  background-color: #062e64;
  box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.5);
  padding: 0 1.25rem 1.875rem;

  h2 {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin: 24px 18px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      display: block;
      margin: 0 auto 22px;
      width: 100%;

      a {
        display: block;
        color: #8f9bb3;
        padding: 12px 18px;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &.active {
          color: #ffffff;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 6px;
        }

        span {
          display: inline-block;
          font-size: 13.68px;
        }

        .menu-icon {
          margin-right: 12px;
          vertical-align: baseline;
          width: 20px;
          text-align: right;
        }
      }
    }
  }
`;

export default SideBar;
