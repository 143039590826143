/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { useFormik } from "formik";
import moment from "moment";
import { FormGroup } from "reactstrap";
import styled, { ThemeProvider } from "styled-components";
import { object, date as yupDate, string } from "yup";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import FieldError from "../../../../components/FieldError";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import {
  StyledDateTimePicker,
  StyledForm,
  CustomCheckboxWrapper,
  WordCount,
} from "../../../../utils/styles";
import { isDate } from "../../../../utils/dateUtil";
import theme from "../../../../assets/theme";
import SkillSelector from "../../../../components/SkillSelector";
import { cleanSkills } from "../../../../utils/api";

const wordCount = 450;

const WorkForm = ({ id, work, proceed }) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate:
        work.start_year && work.start_month
          ? new Date(work.start_year, work.start_month - 1)
          : null,
      endDate:
        work?.end_year && work?.end_month ? new Date(work.end_year, work.end_month - 1) : "present",
      experience: work?.details || "",
      company: work?.company || "",
      position: work?.position || "",
      project_link: work?.project_link || "",
      repository_link: work?.repository_link || "",
      skills: work?.skills || [],
      location: work?.location || "",
    },
    validationSchema: object().shape({
      company: string().required("Company is required"),
      position: string().required("Job title is required"),
      experience: string().required("Experience is required"),
      startDate: yupDate()
        .typeError("Start date is required")
        .test("", "Start date is greater than end date", (startDate, context) => {
          return (
            context.parent.endDate === "present" ||
            new Date(startDate) < new Date(context.parent.endDate)
          );
        })
        .required("Start date is required"),
    }),
    onSubmit: ({ experience, ...formData }) => {
      const start_day = new Date(formData.startDate);
      const end_day = new Date(formData.endDate);

      proceed({
        ...formData,
        details: experience,
        start_month: start_day.getMonth() + 1,
        end_month: end_day.getMonth() + 1,
        start_year: start_day.getUTCFullYear(),
        end_year: end_day.getUTCFullYear(),
      });
    },
  });

  /* istanbul ignore next */
  const onChangeValue = (key, value) => {
    form.setFieldValue(key, value);
  };

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    const newSkills = cleanSkills(newSkillsValue).map((skill) => {
      return { ...skill };
    });
    onChangeValue("skills", newSkills);
  };

  return (
    <StyledForm
      id={id}
      data-testid="work-form"
      onSubmit={(e) => {
        form.handleSubmit(e);
      }}
    >
      <FormGroup>
        <label htmlFor="company">
          Company Name
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        <Input
          id="company"
          name="company"
          className="py-0"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Enter company name"
          value={form.values.company}
          required
        />
        {form.touched.company && form.errors.company && (
          <FieldError message={form.errors.company} />
        )}
      </FormGroup>

      <FormGroup>
        <label htmlFor="position">
          Job Title
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>
        <Input
          id="position"
          name="position"
          className="py-0"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Enter job title"
          value={form.values.position}
          required
        />
        {form.touched.position && form.errors.position && (
          <FieldError message={form.errors.position} />
        )}
      </FormGroup>

      <FormGroup>
        <label htmlFor="location">Location</label>
        <Input
          id="location"
          name="location"
          className="py-0"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Enter location"
          value={form.values.location}
        />
        {form.touched.location && form.errors.location && (
          <FieldError message={form.errors.location} />
        )}
      </FormGroup>

      <div className="row">
        <div className="col-sm-12">
          <label htmlFor="timespan">
            Timespan
            <LabelStyle>
              <span className="label-style">*</span>
            </LabelStyle>
          </label>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              data-testid="startDate-input"
              id="startDate"
              name="startDate"
              className="tg-date-field"
              placeholder="Select start date"
              format="MMM YYYY"
              $time={false}
              max={new Date()}
              value={
                form.values.startDate && isDate(form.values.startDate)
                  ? new Date(form.values.startDate)
                  : null
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;
                  onChangeValue("startDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
              required
            />
            {form.touched.startDate && form.errors.startDate && (
              <FieldError message={form.errors.startDate} />
            )}
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              id="endDate"
              name="endDate"
              className="tg-date-field"
              placeholder="Select end date"
              format="MMM YYYY"
              $time={false}
              value={
                form.values.endDate === "present" ||
                !form.values.endDate ||
                !isDate(form.values.startDate)
                  ? null
                  : new Date(form.values.endDate)
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;

                  onChangeValue("endDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
            />
            <CustomCheckboxWrapper className="custom-control custom-checkbox mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck"
                onChange={
                  /* istanbul ignore next */
                  () => {
                    onChangeValue(
                      "endDate",
                      form.values.endDate === "present"
                        ? ""
                        : form.values.endDate === null
                        ? "present"
                        : "present",
                    );
                  }
                }
                checked={!!(form.values.startDate && form.values.endDate === "present")}
                disabled={!form.values.startDate}
              />
              <label className="custom-control-label" htmlFor="customCheck">
                Present
              </label>
            </CustomCheckboxWrapper>
          </FormGroup>
        </div>
      </div>
      <FormGroup>
        <label htmlFor="experience" className="control-label">
          Experience Details
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        <div className="position-relative">
          <TextArea
            id="experience"
            name="experience"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Enter details here"
            value={form.values.experience}
            maxLength={wordCount}
            required
          />
          <WordCount className="position-absolute">
            {wordCount - form.values.experience.length}
          </WordCount>
        </div>
        {form.touched.experience && form.errors.experience && (
          <FieldError message={form.errors.experience} />
        )}
      </FormGroup>

      <FormGroup>
        <label className="control-label" htmlFor="details">
          Associated skills
        </label>
        <ThemeProvider theme={theme}>
          <SkillSelector
            onChange={(skill) => onSkillChange(skill)}
            selected={form.values.skills || []}
            placeholder="Type to search and select the skills used in this role"
          />
        </ThemeProvider>
      </FormGroup>
    </StyledForm>
  );
};

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

WorkForm.propTypes = {
  work: PropTypes.shape({
    start_month: PropTypes.number,
    start_year: PropTypes.number,
    end_month: PropTypes.number,
    end_year: PropTypes.number,
    details: PropTypes.string,
    company: PropTypes.string,
    position: PropTypes.string,
    project_link: PropTypes.string,
    repository_link: PropTypes.string,
    location: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({})),
  }),

  proceed: PropTypes.func,
  id: PropTypes.string,
};

export default WorkForm;
