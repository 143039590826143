/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import CalendarDates from "calendar-dates";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  getUserTimeSheetEntries,
  updateTimeSheetEntry,
} from "../../../../redux/actions/TimeSheetActions";
import Progress from "../../../../components/Progress";
import { openAlert, openConfirm } from "../../../../utils/modals";
import AlertDialogue from "../../../../components/AlertDialogue";
import { isClient, getUser } from "../../../../utils/auth";

const calendarDates = new CalendarDates();

const calenderHeaderTitles = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Weekly Totals",
];

const removeAllNullData = (arr) => {
  const rowIndex = arr.findIndex((innerArr) => innerArr.every((item) => item === null));

  if (rowIndex !== -1) {
    const newArr = [...arr];
    newArr.splice(rowIndex, 1);

    return newArr;
  }

  return arr;
};

const mapDatesToData = () => {};

const currentDay = new Date().toLocaleDateString().split("/").reverse().join("-");

const daysToRemove = ["Saturday", "Sunday"];

const TimeSheetCalendar = ({ currentMonth, userId, projectId, isApproved, expectedHours }) => {
  const dispatch = useDispatch();

  const { users, isSaved, isMakingRequest } = useSelector(
    ({ TimeSheetEntries }) => TimeSheetEntries,
  );

  const [calendar, setCalendar] = useState([]);
  const [timeSheetEntries, setTimeSheetEntries] = useState([]);

  const handleHourSubmission = () => {
    const timesheet_entries = timeSheetEntries
      .flat()
      .filter((obj) => Object.keys(obj).length && obj?.name !== "total")
      .map((obj) => {
        const entries = Object.entries(obj)[0];
        const [day, hours_worked] = entries;
        return {
          day,
          hours_worked: +hours_worked,
          project: projectId,
          user: userId,
        };
      });

    const payload = {
      timesheet_entries,
    };
    const totalHours = timesheet_entries.reduce((a, b) => a + b.hours_worked, 0);
    if (totalHours > expectedHours) {
      const currentUserId = getUser().id;
      const actor = currentUserId === userId ? "your" : "the developer's";
      openConfirm({
        message: (
          <div>
            The hours you input into the timesheet are more than {actor} expected hours.{" "}
            <span style={{ fontWeight: "bolder" }}>Do you still want to submit?</span>
          </div>
        ),
        title: "Hours more than expected",
        canClose: true,
        options: {
          ok: "Yes, submit",
          cancel: "No, go back",
          dismissAsCancel: true,
          size: "sm",
        },
      }).then(() => {
        dispatch(updateTimeSheetEntry(payload, userId));
      });
      return;
    }
    dispatch(updateTimeSheetEntry(payload, userId));
  };

  const handleChange = (e, rowIndex, colIndex, colIso) => {
    const copyArray = [...timeSheetEntries];

    copyArray[rowIndex][colIndex][colIso] = e.target.value;
    const sum = copyArray[rowIndex].reduce((total, currentValue) => {
      const currentValueNum = +Object.values(currentValue)[0];
      const num = Number.isNaN(currentValueNum) ? 0 : currentValueNum;

      return total + num;
    }, 0);
    copyArray[rowIndex][5].sum = sum;
    setTimeSheetEntries([...copyArray]);
  };

  const cleanDateMatrix = (month) => {
    /** If Sunday of the first week is 1st or 2nd, means that week whould be filtered out for the current month */
    /* eslint-disable no-unused-vars */
    return month.filter((week, index) => {
      if (index === 0) {
        // Sunday is the first day of week 1 of this month
        const thatWeek = month[1];
        return thatWeek[0].date !== 1 && thatWeek[0].date !== 2;
      }
      return true;
    });
  };

  const getCalendarDate = async (dateEntries) => {
    const dateMatrix = await calendarDates.getMatrix(new Date(currentMonth));
    /** If Sunday of the first week is 1st or 2nd, means that week whould be filtered out for the current month */
    const filteredMatrix = cleanDateMatrix(dateMatrix).filter(
      (week) => !week.every(({ type }) => type === "next"),
    );

    const entries = filteredMatrix.map((week) => {
      const arrayWithData = week
        .filter((weekDay) => {
          if (weekDay?.name === "total") return weekDay;

          const day = moment(weekDay.iso).format("dddd");

          return !daysToRemove.includes(day); // Remove Saturdays and Sundays
        })
        .map((weekDay) => {
          // Return null for dates not in current month
          if (weekDay.type === "previous" || weekDay.type === "next") {
            return null;
          }

          return weekDay;
        })
        .map((weekDay) => {
          if (weekDay?.iso) {
            return {
              [weekDay?.iso]:
                dateEntries.find(({ day }) => day === weekDay?.iso)?.hours_worked || "",
            };
          }

          return {};
        });

      const sum = arrayWithData.reduce((total, currentValue) => {
        return total + +(Object.values(currentValue)[0] || 0);
      }, 0);

      const mappedEntries = [...arrayWithData, { name: "total", sum }];

      return mappedEntries;
    });

    const dates = filteredMatrix.map((week) => {
      const arrayWithData = week.map((weekDay) => {
        return {
          ...weekDay,
          data: dateEntries.find(({ day }) => day === weekDay?.iso) ?? {},
        };
      }); // Add data to day Object

      const newDates = arrayWithData
        .filter((weekDay) => {
          const day = moment(weekDay.iso).format("dddd");

          return !daysToRemove.includes(day); // Remove Saturdays and Sundays
        })
        .map((weekDay) => {
          // Return null for dates not in current month
          if (weekDay.type === "previous" || weekDay.type === "next") {
            return null;
          }

          return weekDay;
        });

      return newDates;
    });

    const dateWithWeeklyTotal = removeAllNullData(dates).map((date) => {
      const sum = date.reduce((total, currentValue) => {
        if (currentValue === null) return total + 0;
        return total + +(currentValue?.data.hours_worked ?? 0);
      }, 0);

      return [...date, { name: "total", sum }];
    });

    setTimeSheetEntries(entries);
    setCalendar(dateWithWeeklyTotal);
  };

  useEffect(() => {
    if (!users[userId]?.fetched) {
      dispatch(getUserTimeSheetEntries(userId, projectId, currentMonth));
    }
  }, [users]);

  useEffect(() => {
    if (users[userId]?.fetched) {
      const { entries } = users[userId];
      getCalendarDate(entries);
    }
  }, [users]);

  useEffect(() => {
    if (isSaved?.updateTimeSheetEntry) {
      openAlert({
        body: <AlertDialogue msg={isSaved.message} />,
        canClose: true,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
    }
  }, [isSaved?.updateTimeSheetEntry]);

  return (
    <>
      {users[userId]?.fetched ? (
        <>
          <Wrapper className="mt-3 row-header">
            {calenderHeaderTitles.map((title) => (
              <div key={title} className="grid-item calendar-header">
                {title}
              </div>
            ))}
          </Wrapper>
          {calendar.map((row, rowIndex, arr) => {
            return (
              <Wrapper
                key={`wrapper-${rowIndex}`}
                className={rowIndex !== arr.length - 1 ? "bordered" : ""}
              >
                {row.map((col, colIndex) => {
                  const entry = timeSheetEntries[rowIndex][colIndex];

                  const value = entry[col?.iso];
                  const { sum } = entry;

                  const dateIsGreaterThanToday = new Date(col?.iso) > new Date();
                  const inputDisabled = isClient() || isApproved || dateIsGreaterThanToday;

                  if (col === null) return <div />;

                  if (col?.name === "total") {
                    const availDaysInAWeek = Math.round(
                      (row.filter((_col) => _col !== null)?.length ?? 1) - 1,
                    );

                    return (
                      <div
                        key={`${colIndex}${rowIndex}`}
                        className="grid-item d-flex flex-column justify-content-end calendar-total"
                      >
                        <div className="d-flex justify-content-between calendar-week-total position-relative">
                          <p className="m-0">
                            <strong>{sum.toFixed(2)}</strong>/
                            {(expectedHours / 20) * availDaysInAWeek} expected
                          </p>
                          <span className="total-hrs ms-1">hrs</span>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="grid-item d-flex flex-column justify-content-end calendar-date">
                      <p
                        className={`calendar-day d-flex align-items-center justify-content-center mb-2 ${
                          currentDay === col?.iso ? "current-day" : ""
                        }`}
                      >
                        {col?.date || ""}
                      </p>
                      <div className="position-relative calendar-hours">
                        <input
                          data-testid={col?.iso}
                          type="number"
                          inputMode="numeric"
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          placeholder="Enter hours"
                          disabled={inputDisabled}
                          readOnly={inputDisabled}
                          min={0}
                          max={24}
                          onChange={(e) => handleChange(e, rowIndex, colIndex, col.iso)}
                        />
                        <span className="position-absolute">hrs</span>
                      </div>
                    </div>
                  );
                })}
              </Wrapper>
            );
          })}
          {!isApproved && !isClient() && (
            <Footer className="d-flex justify-content-end">
              <button
                data-testid="submit-hours-btn"
                className="btn btn-approve btn-submit-hours"
                type="button"
                disabled={isMakingRequest?.updateTimeSheetEntry}
                onClick={handleHourSubmission}
              >
                {isMakingRequest?.updateTimeSheetEntry ? "Submitting..." : "Submit hours"}
              </button>
            </Footer>
          )}
        </>
      ) : (
        <div>
          <Progress />
        </div>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 15px;
  /* margin: 0 -24px; */
  padding: 0 16px;

  &.bordered {
    border-bottom: 1px solid #edf1f7;
  }

  &.row-header {
    background-color: #f5f7fa;
  }

  .calendar {
    &-header {
      color: #8f9bb3;
      padding: 11px 0;
    }

    &-date,
    &-total {
      padding: 24px 0;
    }

    &-hours input,
    &-week-total {
      border: 1px solid #edf1f7;
      padding: 9px 13px 10px;
      border: 1px solid #e3e9f2 !important;
    }

    &-week-total,
    &-hours {
      span:not(.total-hrs) {
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #8f9bb3;
      }
    }

    &-week-total {
      background: #f5f7fa;
      p {
        color: #3e4857;
        font-size: 0.8125rem;

        strong {
          font-weight: 600;
          color: #151a30;
        }
      }
    }

    &-header,
    &-day {
      background-color: #f5f7fa;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    &-day {
      width: 24px;
      height: 24px;
      color: #3e4857;
      border-radius: 100px;

      &.current-day {
        background-color: #062e64;
        color: #ffffff;
      }
    }

    &-hours {
      color: #8f9bb3;
      font-size: 14px;
      line-height: 21px;

      input {
        color: #151a30;
        font-weight: 500;
        border-radius: 4px;

        ::placeholder {
          color: #8f9bb3;
        }

        :focus {
          border: 2px solid #062e64 !important;
        }
      }
    }
  }
`;

const Footer = styled.div`
  border-top: 1px solid #edf1f7;
  padding: 22px 24px 24px;
`;

TimeSheetCalendar.propTypes = {
  currentMonth: PropTypes.string,
  userId: PropTypes.number,
  projectId: PropTypes.number,
  isApproved: PropTypes.bool,
  expectedHours: PropTypes.number,
};
export default TimeSheetCalendar;
