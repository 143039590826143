/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Icon from "../Icon";
import AlertDialogue from "../AlertDialogue";
import { OutlineBtn, ConnectedSpan } from "./styles";
import { openModal, openAlert } from "../../utils/modals";
import CheckIcon from "../svg/CheckIcon";
import {
  changeDefaultAccount,
  fetchPaymentProviders,
  fetchPayoneer,
  fetchWise,
} from "../../redux/actions/PaymentActions";

const ActionsHandler = ({
  connected,
  isDefault,
  providerName,
  connectHandler,
  paymentProviderID,
  triggerDefaultPrompt,
  setShouldTriggerPrompt,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);

  const { isMakingRequest, paymentProvider: provider } = useSelector(({ Payments }) => Payments);

  const update = () => {
    dispatch(fetchPaymentProviders());
    dispatch(fetchPayoneer({ silent: true }));
    dispatch(fetchWise({ silent: true }));
  };

  const showDefaultSuccess = (providerNameParams) => {
    const providerNameValue =
      providerNameParams === "iban"
        ? "IBAN"
        : `${providerNameParams?.charAt(0).toUpperCase()}${providerNameParams?.slice(1)} `;

    openAlert({
      body: <AlertDialogue msg={`${providerNameValue} account marked as default`} />,
      canClose: false,
      options: {
        className: "alert-dailogue",
        hideActions: true,
        hideBackdrop: true,
      },
    });
  };

  const handleDefaultAccountChange = (provider_id) => {
    openModal({
      body: (
        <div>
          Are you sure you want to change your default payout method? This will not affect any past
          or ongoing payouts, only subsequent ones.
        </div>
      ),
      title: "Change default payout method",
      canClose: true,
      options: { ok: "Yes, change", cancel: "No, cancel", dismissAsCancel: true },
      header: null,
      hideActions: false,
    }).then(
      () => {
        dispatch(
          changeDefaultAccount(provider_id, () => {
            showDefaultSuccess(providerName);
            update();
          }),
        );
      },
      () => {
        // Nothing
      },
    );
  };

  const changeConnectedAccount = () => {
    openModal({
      body: (
        <div>
          Changing the account you previously connected will affect the way you are paid by Tunga.
          Are you sure you want to continue?
        </div>
      ),
      title: "Connect new account",
      canClose: true,
      options: { ok: "Yes, continue", cancel: "No, cancel", dismissAsCancel: true },
      header: null,

      hideActions: false,
    }).then(
      () => {
        connectHandler();
      },
      () => {
        // Nothing
      },
    );
  };

  const defaultAccountPrompt = (provider_id) => {
    openModal({
      body: <div>Would you like to make this your default payment service provider?</div>,
      title: "Default payment service provider",
      canClose: true,
      options: {
        ok: "Yes, make default",
        cancel: "No",
        dismissAsCancel: true,
      },
      header: null,
      hideActions: false,
    }).then(
      () => {
        dispatch(changeDefaultAccount(provider_id), () => {
          showDefaultSuccess(providerName);
          update();
        });
      },
      () => {
        setShouldTriggerPrompt(false);
      },
    );
  };

  useEffect(() => {
    if (triggerDefaultPrompt && paymentProviderID) defaultAccountPrompt(paymentProviderID);
  }, [triggerDefaultPrompt, paymentProviderID]);

  if (connected) {
    return (
      <>
        <ConnectedSpan className="">
          <CheckIcon />
          <span
            style={{ display: "inline-block", paddingLeft: "0.3em" }}
            data-testid="connectedBtn"
          >
            Connected
          </span>
        </ConnectedSpan>

        <StyledDropdown
          isOpen={open}
          data-testid={`dropdown-${providerName}`}
          toggle={() => setOpen(!open)}
        >
          <DropdownToggle className="toggle px-2">
            <Icon name="dots-horizontal-small" />
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu mt-1">
            {!isDefault && (
              <DropdownItem
                data-testid={`make-${providerName}-default`}
                className="dropdown-item"
                onClick={() => handleDefaultAccountChange(paymentProviderID)}
              >
                Make default method
              </DropdownItem>
            )}

            <DropdownItem
              data-testid={`change-${providerName}-account`}
              className="dropdown-item"
              onClick={changeConnectedAccount}
            >
              Change account
            </DropdownItem>
          </DropdownMenu>
        </StyledDropdown>
      </>
    );
  }

  return (
    <OutlineBtn data-testid="outlineBtn" type="button" onClick={connectHandler}>
      Connect
    </OutlineBtn>
  );
};

const StyledDropdown = styled(Dropdown)`
  position: absolute;
  right: 1em;
  bottom: 0;
  margin-bottom: 0.2rem;

  .toggle {
    box-shadow: none;
    border: none;
    margin-bottom: 1em;
    background-color: rgba(143, 155, 179, 0.15);
    color: rgb(143, 155, 179);
    padding: 5px;
    height: 2.2em;
    line-height: 0;
    border-radius: 5px;
  }
`;

export default ActionsHandler;
