import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import styled, { createGlobalStyle, withTheme } from "styled-components";

import AskTungaForm from "../../../components/AskTungaForm";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { ModalHeader } from "../../../components/ModalHeader";
import AcceptInvite from "./components/AcceptInvite";
import { askTunga, updateParticipation } from "../../../redux/actions/ProjectActions";
import { openModal } from "../../../utils/modals";
import { eventTrack } from "../../../utils/analytics";
import { CATEGORIES, EVENTS } from "../../../analytics/events";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f8f8f8;
  }
`;

const ProjectInvite = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { isMakingRequest } = useSelector(({ Projects }) => Projects);

  const [saveDisabled, setSaveDisabled] = useState(true);

  const performAction = (action) => {
    setSaveDisabled(action.saveDisabled);
  };

  const openTCs = () => {
    openModal({
      body: <AskTungaForm id="ask-tunga-form" />,
      title: "",
      canClose: true,
      options: {
        ok: `Send`,
        form: {
          type: "submit",
          form: `ask-tunga-form`,
        },
      },
      header: <ModalHeader style={{ paddingBottom: "8px" }} options={{ title: "Ask Tunga" }} />,
      hideActions: false,
    }).then((data) => {
      dispatch(askTunga(params?.id, data));
    });
  };

  const updateCallback = () => {
    eventTrack(
      CATEGORIES.projects,
      `${EVENTS.accept_invite}: ${localStorage.getItem("tempProjectTitle")}`,
    );
    localStorage.removeItem("tempProjectTitle");
    localStorage.removeItem("tempProjectCategory");
    localStorage.removeItem("tempParticipationId");
    window.location.pathname = `/projects/${params.id}`;
  };

  const updateParticipationStatus = (status) => {
    dispatch(
      updateParticipation(
        window.localStorage.getItem("tempParticipationId"),
        { status },
        updateCallback,
      ),
    );
  };

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="layout-navbar">
        <Nav>
          <div className="nav-content">
            <span className="layout-close">
              <Link to="/projects">
                <Icon name="x-circle" />
                Close
              </Link>
            </span>

            <h5>{window.localStorage.getItem("tempProjectTitle")}</h5>
          </div>
        </Nav>
      </div>

      <Container className="container">
        <div style={{ padding: "40px 40px 16px 40px" }}>
          <AcceptInvite action={performAction} />
        </div>

        <div className="content-nav">
          <div className="ask-cta">
            <span>Got questions ?</span>
            {isMakingRequest.askTunga ? (
              <span className="saving-state">Sending question...</span>
            ) : (
              <button
                className="btn p-0 d-inline-flex align-items-center askBtn"
                type="button"
                onClick={() => openTCs()}
              >
                Ask Tunga <Icon name="arrow-right" size="sm" />
              </button>
            )}
          </div>
          <Button
            className="btn next"
            disabled={saveDisabled || isMakingRequest?.updateParticipationStatus}
            onClick={() => updateParticipationStatus("accepted")}
          >
            Accept Invite
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .askBtn {
    line-height: unset;
    height: unset;
    i {
      margin-top: 2px;
    }
  }

  .content-nav {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    background: rgba(237, 241, 247, 0.25);

    .ask-cta {
      height: fit-content;
      align-self: center;
      span.saving-state {
        color: #da3451;
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      color: #151a30;
      margin-right: 16px;
    }

    button {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      text-decoration: none;
      color: #da3451;

      :hover,
      :active {
        color: #a41e35;
      }

      i {
        font-weight: 600;
        font-size: 14px;
      }
    }

    button.previous {
      background: transparent;
      border: none;
      box-shadow: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #062e64;
      padding-left: 0;

      i {
        vertical-align: baseline;
        font-size: 14px;
        margin-right: 12px;
      }
    }

    button.next {
      background: #da3451;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
`;

const Wrapper = withTheme(styled.div`
  height: 100vh;
  display: block;
  width: 100%;
  position: fixed;
  margin-top: 73px;
  overflow-y: scroll;
  overflow-x: scroll;

  &:hover::-webkit-scrollbar {
    visibility: visible;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  ${(props) => props.theme.mixins.scrollbar}

  .layout-navbar {
    display: block;
    background: #fff;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 80%, 0.5);
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
  }
`);

const Nav = withTheme(styled.div`
  .nav-content {
    padding: 24px 40px;

    h5 {
      font-weight: bold;
      font-size: ${(props) => props.theme.functions.pxToRem(20)};
      line-height: ${(props) => props.theme.functions.pxToRem(24)};
      text-align: center;
      color: ${(props) => props.theme.colors["text-black"]};
      margin: 0;
    }

    .layout-close {
      float: left;
      a {
        font-weight: 500;
        font-size: ${(props) => props.theme.functions.pxToRem(16)};
        line-height: ${(props) => props.theme.functions.pxToRem(19)};
        color: ${(props) => props.theme.colors.gray};
      }
    }

    .layout-close a {
      text-decoration: none;
      i {
        font-size: ${(props) => props.theme.functions.pxToRem(20)};
        vertical-align: text-bottom;
        margin-right: 10px;
      }
    }
  }
`);

export default ProjectInvite;
