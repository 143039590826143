/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

/* -------------------------- Internel Dependencies ------------------------- */
import Progress from "../../../components/Progress";
import { addPropsToChildren } from "../../../utils/children";
import { listInvoices } from "../../../redux/actions/InvoiceActions";
import usePrevious from "../../../hooks/usePrevious";
import { getPaymentsFilters, getPaymentsQueries } from "../../../utils/invoiceUtils";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { trackEvent } from "../../../analytics/segment";
import { EVENTS } from "../../../analytics/events";

const InvoiceListContainer = ({
  children,
  match: {
    params: { filter },
  },
  type, // invoice type
  project, // selected project
}) => {
  const { isMakingRequest, list, isSaved } = useSelector(({ Invoice }) => Invoice);
  const dispatch = useDispatch();
  const { status, close } = useRightNav();

  const pageSizeRef = useRef(20);

  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0); // react table pagination page
  const [pageSize, setPageSize] = useState(pageSizeRef.current);

  const prevIsMakingRequest = usePrevious(isMakingRequest);
  const prevFilter = usePrevious(filter);

  const getList = (prevFilters = {}) => {
    const newFilters = {
      ...getPaymentsFilters(filter),
      ...getPaymentsQueries(type),
      ...(project !== null ? { project: project?.id } : {}),
      ...prevFilters,
    };

    setFilters(newFilters); // memoization of filters
    listInvoices(newFilters)(dispatch);
  };

  const refreshList = () => {
    setCurrentPage(0);
    getList({
      page_size: pageSize,
    });

    const event = {
      "all": EVENTS.click_all_filter,
      "paid": EVENTS.click_paid_filter,
      "credit-notes": EVENTS.click_credit_note_filter,
      "commitment-payments": EVENTS.click_commitment_filter,
      "archived": EVENTS.click_archived_filter,
      "overdue": EVENTS.click_overdue_filter,
      "pending": EVENTS.click_pending_filter,
    };

    trackEvent(event[filter], {
      filter_type: filter,
    });
  };

  const newfilter = {
    ...filters,
    page: currentPage + 1,
    page_size: pageSize,
  };

  useEffect(() => {
    getList(newfilter);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (isSaved?.invoice) {
      getList(newfilter);

      if (!status.collapsed) close();
    }
  }, [isSaved]);

  useEffect(() => {
    if (prevFilter && prevFilter !== filter) {
      refreshList();
    }
  }, [filter, prevIsMakingRequest]);

  const renderChildren = () => {
    return addPropsToChildren(children, {
      ...list,
      filter,
      getList,
      project,
      onLoadMore: (page, page_size) => {
        pageSizeRef.current = page_size;
        setPageSize(page_size);
        setCurrentPage(page);
      },
      currentPage,
      pageSize,
      refreshList,
    });
  };

  return isMakingRequest?.list ? <Progress style={{ textAlign: "center" }} /> : renderChildren();
};

InvoiceListContainer.propTypes = {
  type: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      filter: PropTypes.string,
    }),
  }),
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
};

export default InvoiceListContainer;
