/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ActionsHandler from "./ActionsHandler";
import PaymentContainer from "./PaymentContainer";
import useConnectPayoneer from "../../hooks/useConnectPayoneer";
import PayoneerImage from "../../assets/images/payoneer_logo.png";
import { Default } from "./styles";

const Payoneer = () => {
  const [connected, setConnected] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [payoneerAccount, setPayoneerAccount] = useState({});

  const { connectPayoneer, triggerDefaultPrompt, setShouldTriggerPrompt } = useConnectPayoneer();

  const {
    payoneer,
    paymentProvider: { payment_provider_accounts, default_account_provider },
  } = useSelector(({ Payments }) => Payments);

  const payo_details = payment_provider_accounts;
  const filteredResult = payo_details?.filter((w) => w.payment_provider === "payoneer");

  const payo_id = filteredResult && filteredResult[0]?.id;
  const provider_name = filteredResult && filteredResult[0]?.payment_provider;

  useEffect(() => {
    if (payoneer && payoneer.length !== 0) {
      setPayoneerAccount(payoneer[0]);
      setConnected(true);
    }
  }, [payoneer]);

  useEffect(() => {
    if (
      default_account_provider?.is_default &&
      default_account_provider?.payment_provider === "payoneer"
    ) {
      setIsDefault(default_account_provider?.is_default);
    } else {
      setIsDefault(false);
    }

    setProviderName(default_account_provider?.payment_provider);
  }, [default_account_provider, providerName, isDefault]);

  return (
    <PaymentContainer>
      <div className="d-flex w-100 h-100 bs-border-right">
        <div className="w-20">
          <div>
            <img height={32} width={32} src={PayoneerImage} className="mx-auto" alt="Payoneer" />
          </div>
        </div>

        <div className="w-80">
          <div className="media pdb-40">
            <div className="media-body">
              <h4>
                Payoneer
                {isDefault && providerName === "payoneer" && <Default>DEFAULT</Default>}
              </h4>

              {connected ? (
                <p className="m-0" data-testid="connected_text">
                  You&#39;ve successfully connected your Payoneer account -{" "}
                  <span style={{ fontWeight: 600 }}>{payoneerAccount.payoneer_email}</span>
                </p>
              ) : (
                <p className="m-0" data-testid="unconnected_text">
                  Connect your Payoneer account to receive your payouts there. You can create a new
                  account if you don&#39;t have one already.
                </p>
              )}

              <ActionsHandler
                connected={connected}
                connectHandler={connectPayoneer}
                paymentProviderID={payo_id}
                providerName={provider_name}
                isDefault={isDefault}
                triggerDefaultPrompt={triggerDefaultPrompt}
                setShouldTriggerPrompt={setShouldTriggerPrompt}
              />
            </div>
          </div>
        </div>
      </div>
    </PaymentContainer>
  );
};

export default Payoneer;
