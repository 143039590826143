/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Select from "react-select";
/* ------------------------- Component dependencies ------------------------- */
import SectionNav from "../../../../components/SectionNav";
import { isAdmin } from "../../../../utils/auth";

const options = [
  { value: "all", label: "Show: all" },
  { value: "developer", label: "Show: Developers" },
  { value: "project_owner", label: "Show: Clients" },
  { value: "client_manager", label: "Show: Client Managers" },
  { value: "admin", label: "Show: Admins" },
  { value: "client_support_officer", label: "Show: Client Support Officers" },
];
// istanbul ignore next
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: "99999",
  }),
  control: (base) => ({
    ...base,
    height: "100%",
    width: "180px",
    border: "1px solid 	#cccccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid 	#cccccc",
    },
  }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  option: (base, { isFocused }) => ({
    ...base,
    background: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: isFocused ? "#fff" : "#fff",
    },
  }),
  input: (base) => ({
    ...base,
    textAlign: "right",
  }),
};

const InviteContainer = ({ children, acct_type, setAcctType }) => {
  const location = useLocation();
  return (
    <>
      <SectionNav
        style={{
          marginBottom: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
        links={[
          ...(isAdmin
            ? [
                {
                  route: `settings/users/active`,
                  name: "ACTIVE USERS",
                },
                {
                  route: `settings/users/deactivated`,
                  name: "DEACTIVATED",
                },
                {
                  route: `settings/users/invite`,
                  name: "INVITE USERS",
                },
                {
                  route: `settings/users/create`,
                  name: "CREATE CLIENT",
                },
                {
                  route: `settings/users/pending`,
                  name: "View pending invites",
                },
              ]
            : [
                {
                  route: `settings/users/invite`,
                  name: "INVITE USERS",
                },
                {
                  route: `settings/users/pending`,
                  name: "View pending invites",
                },
              ]),
        ]}
        rightActions={
          location.pathname === "/settings/users/active" ||
          location.pathname === "/settings/users/deactivated" ? (
            <Select
              styles={customStyles}
              options={options}
              value={acct_type}
              onChange={setAcctType}
              isSearchable={false}
            />
          ) : null
        }
      />
      {children}
    </>
  );
};

InviteContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  acct_type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setAcctType: PropTypes.func,
};

export default InviteContainer;
