import React from "react";
import axios from "axios";
import {
  ADD_PAYONEER_FAILED,
  ADD_PAYONEER_START,
  ADD_PAYONEER_SUCCESS,
  CONNECT_WISE_FAILED,
  CONNECT_WISE_START,
  CONNECT_WISE_SUCCESS,
  FETCH_PAYMENT_PROVIDERS_FAILED,
  FETCH_PAYMENT_PROVIDERS_START,
  FETCH_PAYMENT_PROVIDERS_SUCCESS,
  FETCH_WISE_FAILED,
  FETCH_WISE_START,
  FETCH_WISE_SUCCESS,
  GET_PAYONEER_FAILED,
  GET_PAYONEER_START,
  GET_PAYONEER_SUCCESS,
  PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED,
  PATCH_CHANGE_DEFAULT_ACCOUNT_START,
  PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS,
  ADD_IBAN_START,
  ADD_IBAN_SUCCESS,
  ADD_IBAN_FAILED,
  GET_IBAN_START,
  GET_IBAN_SUCCESS,
  GET_IBAN_FAILED,
  UPLOAD_CSV_START,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILED,
} from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, success } from "../../utils/actions";
import {
  CHANGE_DEFAULT_ACCOUNT,
  PAYONEER_PAYMENT,
  WISE_PAYMENT,
  IBAN_PAYMENT,
  UPLOAD_CSV,
} from "../../utils/api";
import { openAlert } from "../../utils/modals";
import AlertDialogue from "../../components/AlertDialogue";

const addPayoneerStart = () => ({ type: ADD_PAYONEER_START });

/** Add payoneer */
const addPayoneer = (data) => {
  return async (dispatch) => {
    dispatch(addPayoneerStart());

    try {
      const response = await axios.post(`${PAYONEER_PAYMENT}`, data, { headers: {} });

      dispatch(success(ADD_PAYONEER_SUCCESS, response.data));
      return response;
    } catch (error) {
      dispatch(failed(ADD_PAYONEER_FAILED, formatErrorResponse(error)));
      return error;
    }
  };
};

/** Get user's payoneer payment methods */
const fetchPayoneerStart = () => ({ type: GET_PAYONEER_START });
const fetchPayoneer = ({ silent = false } = {}) => {
  return (dispatch) => {
    if (!silent) dispatch(fetchPayoneerStart());

    axios
      .get(PAYONEER_PAYMENT)
      .then((resp) => {
        dispatch(success(GET_PAYONEER_SUCCESS, resp.data));
      })
      .catch((error) => {
        dispatch(failed(GET_PAYONEER_FAILED, error));
      });
  };
};

/** Fetch wise payment method */
const fetchWiseStart = () => ({ type: FETCH_WISE_START });
const fetchWise = ({ silent = false } = {}) => {
  return async (dispatch) => {
    if (!silent) dispatch(fetchWiseStart());

    try {
      const response = await axios.get(`${WISE_PAYMENT}?is_active=True`);
      dispatch(success(FETCH_WISE_SUCCESS, response.data));
      return response;
    } catch (error) {
      dispatch(failed(FETCH_WISE_FAILED, formatErrorResponse(error)));
      return error;
    }
  };
};

/** Add wise */
const addWiseStart = () => ({ type: CONNECT_WISE_START });
const addWise = (data) => {
  return async (dispatch) => {
    dispatch(addWiseStart());

    try {
      const response = await axios.post(`${WISE_PAYMENT}`, data, { headers: {} });

      dispatch(success(CONNECT_WISE_SUCCESS, response.data));
      return response;
    } catch (error) {
      dispatch(failed(CONNECT_WISE_FAILED, formatErrorResponse(error)));
      return error;
    }
  };
};

/** Change default account */
const changeDefaultAccountStart = () => ({
  type: PATCH_CHANGE_DEFAULT_ACCOUNT_START,
});

const changeDefaultAccount = (payment_provider_id, callback) => {
  return async (dispatch) => {
    dispatch(changeDefaultAccountStart());

    try {
      const { data } = await axios.patch(`${CHANGE_DEFAULT_ACCOUNT}${payment_provider_id}/`);
      dispatch(success(PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS, data));
      if (callback) callback();
    } catch (error) {
      const errMsg = formatErrorResponse(error);

      openAlert({
        body: <AlertDialogue msg={errMsg.message} iconClass="bs-x-danger" />,
        canClose: false,
        options: {
          className: "error-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispatch(failed(PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED, errMsg));
    }
  };
};

/** Fetch providers */
const fetchPaymentProvidersStart = () => ({ type: FETCH_PAYMENT_PROVIDERS_START });
const fetchPaymentProviders = () => {
  return async (dispatch) => {
    dispatch(fetchPaymentProvidersStart());

    try {
      const response = await axios.get(`${CHANGE_DEFAULT_ACCOUNT}`);
      dispatch(success(FETCH_PAYMENT_PROVIDERS_SUCCESS, response.data));

      return response;
    } catch (error) {
      dispatch(failed(FETCH_PAYMENT_PROVIDERS_FAILED, error));
      return error;
    }
  };
};

/** CONNECT IBAN */
const addIbanStart = () => ({ type: ADD_IBAN_START });
export const addIban = (data) => {
  return async (dispatch) => {
    dispatch(addIbanStart());

    try {
      const response = await axios.post(`${IBAN_PAYMENT}`, data, { headers: {} });
      dispatch(success(ADD_IBAN_SUCCESS, response.data));
    } catch (error) {
      dispatch(failed(ADD_IBAN_FAILED, formatErrorResponse(error)));
    }
  };
};

/** Get IBAN payment method */
export const fetchIbanStart = () => ({ type: GET_IBAN_START });

export const fetchIban = ({ silent = false } = {}) => {
  return async (dispatch) => {
    if (!silent) dispatch(fetchIbanStart());

    try {
      const response = await axios.get(IBAN_PAYMENT);
      dispatch(success(GET_IBAN_SUCCESS, response.data));
    } catch (error) {
      dispatch(failed(GET_IBAN_FAILED, formatErrorResponse(error)));
    }
  };
};

export const uploadCsvStart = () => ({ type: UPLOAD_CSV_START });
const uploadCsv = (formData) => {
  return async (dispath) => {
    dispath(uploadCsvStart());
    try {
      const { data } = await axios.post(`${UPLOAD_CSV}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      openAlert({
        body: <AlertDialogue msg={data.message} />,
        canClose: false,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispath(success(UPLOAD_CSV_SUCCESS, data));
    } catch (e) {
      openAlert({
        body: (
          <AlertDialogue msg="Error processing file data, make sure you have send a valid file" />
        ),
        canClose: false,
        options: {
          className: "error-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispath(failed(UPLOAD_CSV_FAILED, formatErrorResponse(e)));
    }
  };
};

export {
  addPayoneer,
  addWise,
  fetchPayoneer,
  fetchWise,
  fetchPaymentProviders,
  changeDefaultAccount,
  uploadCsv,
};
