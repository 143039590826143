/* eslint-disable react/prop-types */
import React from "react";
import { useFormik } from "formik";
import moment from "moment";
import { FormGroup } from "reactstrap";
import styled, { ThemeProvider } from "styled-components";
import { object, date as yupDate, string, lazy } from "yup";

import FieldError from "../../../../components/FieldError";
import Input from "../../../../components/Input";
import ProjectSelector from "../../../../components/ProjectSelector";
import Select from "../../../../components/Select";
import TextArea from "../../../../components/TextArea";
import SkillSelector from "../../../../components/SkillSelector";
import { cleanSkills as cleanProjects, cleanSkills } from "../../../../utils/api";
import { isDesigner } from "../../../../utils/auth";
import { isDate } from "../../../../utils/dateUtil";
import { validURL } from "../../../../utils/stringUtils";
import {
  CustomCheckboxWrapper,
  StyledDateTimePicker,
  StyledForm,
  WordCount,
} from "../../../../utils/styles";
import theme from "../../../../assets/theme";

const wordCount = 450;

const ProjectForm = ({ id, project: projectFromProps, proceed }) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: projectFromProps?.title || "",
      details: projectFromProps?.details || "",
      startDate:
        projectFromProps?.start_year && projectFromProps?.start_month
          ? new Date(projectFromProps?.start_year, projectFromProps.start_month - 1)
          : null,
      endDate:
        projectFromProps?.end_year && projectFromProps?.end_month
          ? new Date(projectFromProps?.end_year, projectFromProps.end_month - 1)
          : "present",
      category: projectFromProps?.category || "other",
      project_link: projectFromProps?.project_link || "",
      repository_link: projectFromProps?.repository_link || "",
      skills: projectFromProps?.skills || [],
    },
    validationSchema: object().shape({
      title: string().required("Select a project"),
      startDate: yupDate()
        .typeError("Start date is required")
        .test("no-future-date", "Start date shouldn't be in the future", (startDate) => {
          return new Date(startDate) < new Date();
        })
        .test(
          "not-greater-than-enDate",
          "Start date is greater than end date",
          (startDate, context) => {
            return (
              context.parent.endDate === "present" ||
              new Date(startDate) < new Date(context.parent.endDate)
            );
          },
        )
        .required("Start date is required"),
      endDate: lazy((date) => {
        switch (typeof date) {
          case "string":
            return string().required("End date is required");
          default:
            return yupDate().typeError("Start date is required").required("End date is required");
        }
      }),
      project_link: string().test(
        "valid-url",
        "Invalid link",
        (url) => !url?.length >= 1 || validURL(url),
      ),
      repository_link: string().test(
        "valid-url",
        "Invalid link",
        (url) => !url?.length >= 1 || validURL(url),
      ),
    }),
    onSubmit: (formData) => {
      const start_day = new Date(formData.startDate);
      const end_day = new Date(formData.endDate);

      proceed({
        ...formData,
        start_month: start_day.getMonth() + 1,
        end_month: end_day.getMonth() + 1,
        start_year: start_day.getUTCFullYear(),
        end_year: end_day.getUTCFullYear(),
      });
    },
  });

  const filterProjects = (category) => {
    const filteredProjects = cleanProjects(projectFromProps).filter(
      (project) => project.type === category,
    );

    return filteredProjects;
  };

  /* istanbul ignore next */
  const onChangeValue = (key, value) => {
    form.setFieldValue(key, value);
  };

  /* istanbul ignore next */
  const onProjectChange = (category, projects) => {
    const newProjects = cleanProjects(projects).map((project) => {
      return { ...project, type: category };
    });

    onChangeValue("title", newProjects[0].name);
  };

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    const newSkills = cleanSkills(newSkillsValue).map((skill) => {
      return { ...skill };
    });
    onChangeValue("skills", newSkills);
  };

  return (
    <StyledForm id={id} data-testid="project-form" onSubmit={form.handleSubmit}>
      <FormGroup>
        <label htmlFor="category">Project Category</label>
        <Select
          id="category"
          name="category"
          onChange={form.handleChange}
          value={form.values.category}
          options={[
            { value: "", name: "--Select--" },
            { value: "other", name: "Other projects" },
            { value: "tunga_project", name: "Tunga projects" },
          ]}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="title">
          Project Title
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        {form.values.category === "other" ? (
          <Input
            id="title"
            name="title"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Enter project title"
            className="py-0"
            value={form.values.title}
            required
          />
        ) : (
          <ProjectSelector
            onChange={
              /* istanbul ignore next */
              (projects) => onProjectChange("project", projects)
            }
            value={form.values.title}
            selected={filterProjects("project") || []}
            placeholder="Type to search and select..."
          />
        )}

        {form.touched.title && form.errors.title && <FieldError message={form.errors.title} />}
      </FormGroup>

      <div className="row">
        <div className="col-sm-12">
          <label htmlFor="timespan">
            Timespan
            <LabelStyle>
              <span className="label-style">*</span>
            </LabelStyle>
          </label>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              id="startDate"
              name="startDate"
              className="tg-date-field"
              placeholder="Start date"
              format="MMM YYYY"
              $time={false}
              value={
                form.values.startDate && isDate(form.values.startDate)
                  ? new Date(form.values.startDate)
                  : null
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;
                  onChangeValue("startDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
              required
            />
            {form.touched.startDate && form.errors.startDate && (
              <FieldError message={form.errors.startDate} />
            )}
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              id="endDate"
              name="endDate"
              className="tg-date-field"
              placeholder="End date"
              format="MMM YYYY"
              valueDisplayFormat={{ dateStyle: "short" }}
              $time={false}
              value={
                form.values.endDate === "present" ||
                !form.values.endDate ||
                !isDate(form.values.startDate)
                  ? null
                  : new Date(form.values.endDate)
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;

                  onChangeValue("endDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
            />

            <CustomCheckboxWrapper className="custom-control custom-checkbox mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck"
                onChange={
                  /* istanbul ignore next */
                  () => {
                    onChangeValue(
                      "endDate",
                      form.values.endDate === "present"
                        ? ""
                        : form.values.endDate === null
                        ? "present"
                        : "present",
                    );
                  }
                }
                checked={!!(form.values.startDate && form.values.endDate === "present")}
                disabled={!form.values.startDate}
              />
              <label className="custom-control-label" htmlFor="customCheck">
                Present
              </label>
            </CustomCheckboxWrapper>

            {form.touched.endDate && form.errors.endDate && (
              <FieldError message={form.errors.endDate} />
            )}
          </FormGroup>
        </div>
      </div>
      <FormGroup>
        <label className="control-label" htmlFor="details">
          Details (Optional)
        </label>

        <div className="position-relative">
          <TextArea
            id="details"
            name="details"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Enter details here"
            value={form.values.details}
            maxLength={wordCount}
          />
          <WordCount className="position-absolute">
            {wordCount - form.values.details.length}
          </WordCount>
        </div>
      </FormGroup>

      <div className="row">
        <div className="col-sm-12">
          <label id="links" htmlFor="links">
            Links
          </label>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <Input
              id="project_link"
              name="project_link"
              placeholder="Live project"
              className="py-0"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.project_link}
              dataTestId="project_link"
            />
            {form.touched.project_link && form.errors.project_link && (
              <FieldError message={form.errors.project_link} />
            )}
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <Input
              id="repository_link"
              name="repository_link"
              placeholder={`${isDesigner() ? "Shots link (Dribble)" : "Repository"}`}
              className="py-0"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.repository_link}
              dataTestId="repository_link"
            />
            {form.touched.repository_link && form.errors.repository_link && (
              <FieldError message={form.errors.repository_link} />
            )}
          </FormGroup>
        </div>
      </div>

      <FormGroup>
        <label className="control-label" htmlFor="details">
          Associated skills
        </label>
        <ThemeProvider theme={theme}>
          <SkillSelector
            onChange={(skill) => onSkillChange(skill)}
            selected={form.values.skills || []}
            placeholder="Type to search and select the skills used in this project"
          />
        </ThemeProvider>
      </FormGroup>
    </StyledForm>
  );
};

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default ProjectForm;
