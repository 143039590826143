/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useRef, useContext } from "react";
import Switch from "react-switch";
import moment from "moment";
import styled, { ThemeProvider } from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin, isCMOrCSOAndHasProjectAcess } from "../../../../../utils/auth";
/* -------------------------- Internel Dependencies ------------------------- */

import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import { StyledDateTimePicker } from "../../createProjects/style";
import { TempProjectContext } from "../../createProjects/TempProjectContext";
import { updateProject } from "../../../../../redux/actions/ProjectActions";
import theme from "../../../../../assets/theme";
import { cleanSkills } from "../../../../../utils/api";
import SkillSelector from "../../../../../components/SkillSelector";
import FieldError from "../../../../../components/FieldError";
import { emailRegex } from "../../../../../configs/constants/global";
import Icon from "../../../../../components/Icon";
import { openConfirm } from "../../../../../utils/modals";

/* --------------------------- Component proptypes -------------------------- */

const TeamDetails = () => {
  const dispatch = useDispatch();
  const [project, setProject] = useContext(TempProjectContext);

  useEffect(() => {
    setProject({ ...project });
  }, []);

  const { project: projectFromState, isMakingRequest } = useSelector(({ Projects }) => Projects);
  const {
    id,
    title,
    description,
    expected_start_date,
    expected_end_date,
    payment_structure,
    invoicing_email,
    cc_primary_email,
    skills,
    contact_name,
    contact_email,
    archived,
  } = projectFromState;
  const [projectDetails, setprojectDetails] = useState({
    id,
    title,
    description,
    expected_start_date,
    expected_end_date,
    payment_structure,
    amount: payment_structure?.amount,
    hours_per_week: payment_structure?.hours_per_week,
    invoicing_email,
    cc_primary_email,
    skills,
    contact_name,
    contact_email,
  });

  const [canSave, setcanSave] = useState(false);
  const [error, setError] = useState(null);

  const prevProjectDetailsPropsRef = useRef();
  const durPrevProjectDetailsPropsRef = useRef();
  const payPrevProjectDetailsPropsRef = useRef();
  const clientPrevProjectDetailsPropsRef = useRef();

  const validateEmail = (email) => {
    const emailAddress = email || projectDetails.contact_email;

    if (emailAddress === null || emailAddress === "") {
      setError("");
      return false;
    }

    if (!emailRegex.test(emailAddress)) {
      setError("Invalid email address.");
      return false;
    }

    setError("");
    return true;
  };

  /* istanbul ignore next */
  useEffect(() => {
    prevProjectDetailsPropsRef.current = {
      title,
      description,
    };
    durPrevProjectDetailsPropsRef.current = {
      expected_start_date,
      expected_end_date,
    };
    payPrevProjectDetailsPropsRef.current = {
      hours_per_week: projectDetails.hours_per_week,
      amount: projectDetails.amount,
      payment_type: projectDetails.payment_type,
      skills,
      invoicing_email,
    };
    clientPrevProjectDetailsPropsRef.current = {
      contact_name,
      contact_email,
    };
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (!isMakingRequest.update) {
      const prevProps = prevProjectDetailsPropsRef.current;
      if (prevProps.title !== title || prevProps.description !== description) {
        prevProjectDetailsPropsRef.current = {
          title,
          description,
        };
        setcanSave(false);
      }

      const clientPrevProps = clientPrevProjectDetailsPropsRef.current;
      if (
        clientPrevProps.contact_name !== contact_name ||
        clientPrevProps.contact_email !== contact_email
      ) {
        clientPrevProjectDetailsPropsRef.current = {
          contact_name,
          contact_email,
        };
        setcanSave(false);
      }
    }

    if (!isMakingRequest.update) {
      const durPrevProps = durPrevProjectDetailsPropsRef.current;
      if (
        durPrevProps.expected_start_date !== expected_start_date ||
        durPrevProps.expected_end_date !== expected_end_date
      ) {
        durPrevProjectDetailsPropsRef.current = {
          expected_start_date,
          expected_end_date,
        };
        setcanSave(false);
      }
    }
  }, [isMakingRequest.update]);

  /* istanbul ignore next */
  useEffect(() => {
    const prevProps = prevProjectDetailsPropsRef.current;
    const durPrevProps = durPrevProjectDetailsPropsRef.current;
    const clientPrevProps = clientPrevProjectDetailsPropsRef.current;
    if (
      prevProps.title !== projectDetails.title ||
      prevProps.description !== projectDetails.description ||
      prevProps.skills !== projectDetails.skills ||
      clientPrevProps.contact_name !== projectDetails.contact_name ||
      clientPrevProps.contact_email !== projectDetails.contact_email ||
      moment(durPrevProps.expected_start_date).format("MM/DD/YYYY") !==
        moment(projectDetails.expected_start_date).format("MM/DD/YYYY") ||
      moment(durPrevProps.expected_end_date).format("MM/DD/YYYY") !==
        moment(projectDetails.expected_end_date).format("MM/DD/YYYY")
    ) {
      setcanSave(true);
    } else {
      setcanSave(false);
    }
  }, [projectDetails]);

  const onSaveProject = () => {
    updateProject(id, {
      title: projectDetails.title,
      description: projectDetails.description,
      expected_start_date: projectDetails.expected_start_date,
      expected_end_date: projectDetails.expected_end_date,
      skills: projectDetails.skills,
      contact_name: projectDetails.contact_name || "",
      contact_email: projectDetails.contact_email || "",
    })(dispatch);
  };

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    const newSkills = cleanSkills(newSkillsValue).map((skill) => {
      return skill;
    });

    setprojectDetails({
      ...projectDetails,
      skills: newSkills,
    });
  };

  const onToggleArchiveOpportunity = () => {
    openConfirm({
      message: (
        <div>Are you sure you want to {archived ? "un-archive" : "archive"} this opportunity?</div>
      ),
      title: null,
      canClose: false,
      options: {
        ok: "Yes",
        cancel: "No",
      },
    }).then(
      () => {
        updateProject(id, {
          archived: !archived,
        })(dispatch);
      },
      () => {
        // do nothing
      },
    );
  };

  return (
    <StyledWrapper>
      <div className="section">
        <h3 className="section-title">
          Project Title and Description
          <span style={{ color: "#da3451", paddingLeft: "2px" }}>*</span>
        </h3>

        <Input
          placeholder="Title"
          className="form-control"
          value={projectDetails.title}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin())}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              title: e.target.value,
            })
          }
        />
        <TextArea
          placeholder="Description"
          className="form-control"
          value={projectDetails.description}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin())}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              description: e.target.value,
            })
          }
        />
      </div>
      <div className="section">
        <div className="title">
          What is the expected duration of the project?
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup className="row">
          <div className="col">
            <label htmlFor="startDate">Start date</label>
            <StyledDateTimePicker
              $calendar
              id="startDate"
              className="tg-date-field"
              placeholder="Select start date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $time={false}
              value={
                projectDetails.expected_start_date
                  ? new Date(projectDetails.expected_start_date)
                  : null
              }
              max={
                projectDetails.expected_end_date
                  ? new Date(projectDetails.expected_end_date)
                  : new Date(2099, 11, 31)
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_start_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
            />
          </div>
          <div className="col">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="endDate">End date</label>
              <div>
                <span className="mx-1">No end date</span>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={13.5}
                  width={22.5}
                  onChange={() => {
                    setprojectDetails({
                      ...projectDetails,
                      expected_end_date: projectDetails.expected_end_date
                        ? null
                        : +new Date(2099, 11, 31),
                    });
                  }}
                  checked={!projectDetails.expected_end_date}
                  onColor="#062E64"
                />
              </div>
            </div>
            <StyledDateTimePicker
              id="endDate"
              className="tg-date-field"
              placeholder="Select end date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $calendar
              $time={false}
              value={
                projectDetails.expected_end_date &&
                typeof projectDetails.expected_end_date === "string"
                  ? new Date(projectDetails.expected_end_date)
                  : null
              }
              min={
                new Date(projectDetails.expected_start_date) >= new Date(Date.now())
                  ? new Date(projectDetails.expected_start_date)
                  : new Date(Date.now())
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_end_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
              required
            />
          </div>
        </FormGroup>

        <div className="section">
          <div className="title">What technology do you want to use (optional)</div>
          <FormGroup>
            <ThemeProvider theme={theme}>
              <SkillSelector
                onChange={(skill) => onSkillChange(skill)}
                selected={projectDetails.skills || []}
                placeholder="Type to select..."
              />
            </ThemeProvider>
          </FormGroup>
        </div>

        <div className="section">
          <div className="header pb-4 border-0">
            <h4>Client Details</h4>
            <p>
              The client contact shared below will be the point of contact when the client requests
              an interview on a developer’s profile
            </p>
          </div>

          <FormGroup className="row">
            <div className="col">
              <div className="title">Client name</div>
              <FormGroup>
                <Input
                  className="mb-3"
                  id="clientName"
                  value={projectDetails.contact_name}
                  placeholder="Enter client name"
                  dataTestId="contact_name"
                  onChange={(e) =>
                    setprojectDetails({
                      ...projectDetails,
                      contact_name: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </div>
            <div className="col">
              <div className="title">Client email address</div>
              <FormGroup>
                <Input
                  className="mb-3"
                  type="email"
                  id="clientEmailAddress"
                  dataTestId="contact_email"
                  value={projectDetails.contact_email}
                  placeholder="Enter client email address"
                  onChange={(e) => {
                    const { value } = e.target;

                    setprojectDetails({
                      ...projectDetails,
                      contact_email: value,
                    });
                    validateEmail(value);
                  }}
                  onBlur={() => validateEmail()}
                />
                {error && <FieldError message={error} />}
              </FormGroup>
            </div>
          </FormGroup>
        </div>

        {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
          <Button
            type="button"
            data-testid="save-project-btn"
            disabled={
              !!error ||
              !canSave ||
              isMakingRequest.update ||
              !(
                projectDetails.expected_start_date &&
                projectDetails.description &&
                projectDetails.title &&
                projectDetails.contact_name &&
                projectDetails.contact_email
              ) ||
              !(
                projectDetails.expected_end_date == null ||
                projectDetails.expected_end_date !== +new Date(2099, 11, 31)
              )
            }
            onClick={() => onSaveProject()}
            className="save-button"
          >
            Save changes
          </Button>
        )}
      </div>

      {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
        <div className="section">
          <h3 className="section-title">Archive Opportunity</h3>
          <p>
            Mark this opportunity as archive and read-only. This will also remove this opportunity
            from your opportunity list and send it to the archived list.
          </p>
          <Button
            className="save-button"
            type="button"
            data-testid="archive-opportunity-btn"
            onClick={() => onToggleArchiveOpportunity()}
          >
            <Icon name="archive-outline" size="sm" />
            &nbsp;
            {archived ? "Una" : "A"}rchive Opportunity
          </Button>
        </div>
      )}
    </StyledWrapper>
  );
};

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

const StyledWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 40px;

  .section {
    padding: 24px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }

    .section-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #151a30;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      color: #151a30;
      margin-bottom: 24px;
    }

    input,
    textarea {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    .save-button {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;
      background: rgba(6, 46, 100, 0.05);
      border: none;
      border-radius: 4px;
      box-shadow: none;
      margin-top: 10px;

      &:disabled {
        color: rgba(6, 46, 100, 0.25);
      }

      i {
        vertical-align: baseline;
      }
    }
  }
`;

export default TeamDetails;
