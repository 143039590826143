import React from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import TimeIcon from "../../../../assets/images/icons/clock.svg";
import { isClient } from "../../../../utils/auth";

const TimeSheetSummary = () => {
  const { url } = useRouteMatch();

  const { monthView } = useSelector(({ TimeSheetEntries }) => TimeSheetEntries);

  return (
    <Container className="d-flex align-items-center justify-content-between mb-3">
      <div className="d-flex align-items-center">
        <img src={TimeIcon} alt="" />

        <div className="ms-3">
          <h4 className="mb-0">Total/expected hrs</h4>
          <p className="m-0">
            <strong>{monthView?.summary?.total}</strong>/{monthView?.summary?.expected ?? 0} hrs
          </p>
        </div>
      </div>

      {!isClient() && <Link to={`${url.slice(0, 14)}settings/team`}>View hour settings</Link>}
    </Container>
  );
};

const Container = styled.div`
  --color-blue: #062e64;
  padding: 18px;
  background-color: rgba(6, 46, 100, 0.05);
  border-radius: 4px;
  border-left: 4px solid var(--color-blue);

  h4,
  p {
    font-weight: 400;
    color: #3e4857;
  }

  h4,
  a {
    font-size: 14px;
    line-height: 21px;
  }

  p {
    font-size: 24px;
    line-height: 36px;
  }

  p strong {
    color: #151a30;
    font-weight: 600;
  }

  a {
    color: var(--color-blue);
    font-weight: 500;
  }
`;

export default TimeSheetSummary;
