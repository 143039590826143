/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import OnboardingWizard from "./OnboardingWizard";
import CreateLayout from "../../../layouts/CreateLayout";

const OnboardingContainer = () => {
  return (
    <CreateLayout>
      <OnboardingWizard />
    </CreateLayout>
  );
};

export default OnboardingContainer;
