import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { downloadCsv } from "../../../../utils/invoiceUtils";

const CSVDownload = ({ filter, type, project = null }) => {
  return (
    <Button
      type="button"
      className="btn download-csv-btn ml-2"
      onClick={() => downloadCsv(filter, type === "in" ? "Payments" : "Payouts", project)}
      data-testid="downloadCsv"
    >
      Download .csv{" "}
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10544_23227w)">
          <path
            d="M11.0679 16.3184L14.2498 19.5003L17.4318 16.3184"
            stroke="#7F8C9F"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.25 12V19.5"
            stroke="#7F8C9F"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.99978 19.5H6.74978C6.00525 19.4993 5.26939 19.3402 4.59104 19.0333C3.91269 18.7265 3.30737 18.2788 2.81526 17.7201C2.32314 17.1614 1.95548 16.5045 1.73669 15.7928C1.5179 15.0812 1.45297 14.3311 1.54623 13.5924C1.63948 12.8538 1.88877 12.1434 2.27757 11.5084C2.66636 10.8735 3.18576 10.3285 3.80129 9.90963C4.41682 9.49077 5.11439 9.20762 5.84772 9.07897C6.58105 8.95032 7.33336 8.97912 8.0547 9.16345"
            stroke="#7F8C9F"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 12.0002C7.50012 10.8119 7.78261 9.6406 8.3242 8.58284C8.86578 7.52509 9.65097 6.61117 10.615 5.9164C11.5791 5.22162 12.6945 4.76587 13.8692 4.58671C15.044 4.40754 16.2445 4.5101 17.3719 4.88591C18.4992 5.26172 19.5211 5.90004 20.3534 6.74827C21.1857 7.59649 21.8045 8.63034 22.1588 9.76462C22.5132 10.8989 22.5929 12.1012 22.3915 13.2723C22.19 14.4434 21.7132 15.55 21.0002 16.5007"
            stroke="#7F8C9F"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_10544_23227w">
            <rect width={24} height={24} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Button>
  );
};

const Button = styled.button`
  background: #ffffff;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  color: #8f9bb3;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
`;

CSVDownload.propTypes = {
  filter: PropTypes.shape({}),
  type: PropTypes.string,
  project: PropTypes.number,
};

export default CSVDownload;
