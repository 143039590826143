/* eslint-disable eqeqeq */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { Provider } from "react-redux";
import { LOCATION_CHANGE } from "react-router-redux";
import { confirmable } from "react-confirm";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";

/* -------------------------- Internel Dependencies ------------------------- */
import Button from "./Button";
import store from "../redux/store";
import { ModalHeader } from "./ModalHeader";

const DOMEvents = ["mousewheel", "mousemove", "click", "keypress"];

const GenericModal = ({
  show,
  proceed,
  dismiss,
  cancel,
  options = {},
  modalHeader,
  modalContent,
}) => {
  const [response, setResponse] = useState(null);
  const wrapperRef = useRef(null);

  const handleModalClose = () => {
    if (options.dismissAsCancel) {
      cancel();
    } else {
      dismiss();
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current?.contains(event.target) && !options.mustRespond) {
      event.preventDefault();
      event.stopPropagation();
      handleModalClose();
    }
  };

  const dialogueEventHandler = (operation) => {
    if (
      options?.className?.includes("alert-dailogue") ||
      options?.className?.includes("error-dailogue")
    ) {
      if (operation === "add") {
        setTimeout(() => {
          DOMEvents.forEach((event) => {
            document.addEventListener(event, handleClickOutside);
          });
        }, 2000);
      }

      if (operation === "remove") {
        DOMEvents.forEach((event) => {
          document.removeEventListener(event, handleClickOutside);
        });
      }
    }
  };

  useEffect(() => {
    let timer = null;
    store.dispatch({ type: LOCATION_CHANGE });

    dialogueEventHandler("add");
    document.addEventListener("mousedown", handleClickOutside);

    if (
      options?.className?.includes("alert-dailogue") ||
      options?.className?.includes("error-dailogue")
    ) {
      timer = setTimeout(() => handleModalClose(), 5000);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      dialogueEventHandler("remove");

      clearTimeout(timer);
    };
  }, []);

  const onResponseChange = (e) => {
    setResponse(e.target.value);
  };

  const renderModalContent = () => {
    return typeof modalContent == "string"
      ? modalContent
      : React.cloneElement(modalContent, {
          proceed,
          dismiss,
          cancel,
        });
  };

  const renderModalHeader = () => {
    return modalHeader ? (
      React.cloneElement(modalHeader, { proceed, dismiss, cancel })
    ) : options.title ? (
      <ModalHeader proceed={proceed} dismiss={dismiss} cancel={cancel} options={options} />
    ) : null;
  };

  return (
    <Provider store={store}>
      <Modal
        centered
        style={options.style}
        isOpen={show}
        toggle={dismiss}
        className={`${options.size ? `modal-${options.size}` : ""} ${options.className || ""}`}
        backdrop={options.hideBackdrop ? false : options.mustRespond ? "static" : true}
        keyboard={!options.mustRespond}
      >
        <div ref={wrapperRef}>
          {(!options.mustRespond || options.title) && renderModalHeader()}
          <StyledModalBody>
            <div>{renderModalContent()}</div>
            {options.isPrompt && (
              <div className="form-group">
                <textarea onChange={(e) => onResponseChange(e)} />
              </div>
            )}
          </StyledModalBody>
          {!options.hideActions && (
            <StyledModalFooter>
              {!options.hideCancel && (
                <Button
                  className="cancel"
                  onClick={() => cancel()}
                  variant="secondary"
                  data-testid="modalCancelBtn"
                >
                  {options.cancel || "Cancel"}
                </Button>
              )}

              <Button
                className="okay"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(options.form || "")}
                onClick={() => {
                  if (options.isPrompt) {
                    if (response) {
                      proceed(response);
                    }
                  } else if (!options.form) {
                    proceed();
                  }
                }}
                data-testid="modalOkBtn"
                disabled={options.isPrompt && !response}
              >
                {options.ok || "OK"}
              </Button>
            </StyledModalFooter>
          )}
        </div>
      </Modal>
    </Provider>
  );
};

const StyledModalBody = styled(ModalBody)`
  padding: 0 40px 40px 40px;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding: 20px 40px;
  background: rgba(237, 241, 247, 0.25);

  button {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0;

    &.cancel {
      border: 1px solid #3e4857;
      color: #3e4857;
      background-color: #fff;
      margin-right: 16px;
    }

    &.okay {
      color: #fff;
      background-color: #da3451;
      border-color: #da3451;
    }
  }
`;

GenericModal.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.shape({}),
};

GenericModal.defaultProps = {
  modalHeader: null,
};

export default confirmable(GenericModal);
