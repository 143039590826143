/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------- Internel Dependencies ------------------------- */
import Button from "../../../../../components/Button";

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  id: PropTypes.number,
  update_days: PropTypes.string,
  className: PropTypes.string,
  save: PropTypes.func,
};

const SelectDaysChecks = ({ id, update_days, className, save }) => {
  const checkedDays = update_days?.split(",");
  // istanbul ignore next
  const [days, setdays] = useState([
    { value: 0, name: "Mon", isChecked: checkedDays?.includes("0") },
    { value: 1, name: "Tue", isChecked: checkedDays?.includes("1") },
    { value: 2, name: "Wed", isChecked: checkedDays?.includes("2") },
    { value: 3, name: "Thur", isChecked: checkedDays?.includes("3") },
    { value: 4, name: "Fri", isChecked: checkedDays?.includes("4") },
  ]);

  const onChange = (event) => {
    const tmpDays = days.map((day) => {
      if (day.value === +event.target.value) day.isChecked = !day.isChecked;
      return day;
    });
    setdays(tmpDays);
  };

  const checkAll = () => {
    const tmpDays = days.map((day) => {
      day.isChecked = true;
      return day;
    });
    setdays(tmpDays);
  };

  const submit = (e) => {
    e.preventDefault();
    save(e, id, days);
  };

  const unCheckAll = () => {
    const tmpDays = days.map((day) => {
      day.isChecked = false;
      return day;
    });
    setdays(tmpDays);
  };

  return (
    <StyledForm data-testid="saveBtn" className={className} onSubmit={submit}>
      <div className="days-select">
        {days.map((day) => (
          <div className="form-check" key={`day-${day.value}`}>
            <label className="form-check-label" htmlFor={`check-${day.name}`}>
              {day.name}
            </label>

            <input
              className="switch form-check-input"
              id={`check-${day.name}`}
              value={day.value}
              type="checkbox"
              checked={day.isChecked}
              onChange={(e) => onChange(e)}
            />
          </div>
        ))}
      </div>

      <div className="selection-submit-actions">
        <div>
          <button type="button" className="curs" onClick={() => checkAll()}>
            Turn all on
          </button>
          &nbsp;|&nbsp;
          <button type="button" className="curs" onClick={() => unCheckAll()}>
            Turn all off
          </button>
        </div>
        <div>
          <Button type="submit">Save</Button>
        </div>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  margin-top: 30px;
  .curs {
    cursor: pointer;
    background: none !important;
    font-size: 14px !important;
  }
  .selection-submit-actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;
      box-shadow: none;
      border: none;
      margin: 0 0 0 auto;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #062e64;
    }
  }

  .days-select {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .form-check {
      background: rgba(237, 241, 247, 0.15);
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;

      .form-check-input {
        position: initial;
        margin-top: 0;
        margin-left: 0;
        float: right;
      }

      @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        input[type="checkbox"],
        input[type="radio"] {
          --active: #219653;
          --active-inner: #fff;
          --focus: 2px rgba(39, 94, 254, 0.3);
          --border: #bbc1e1;
          --border-hover: #219653;
          --background: var(--ab, var(--border));
          --disabled: #f6f8ff;
          --disabled-inner: rgba(33, 150, 83, 0.5);
          -webkit-appearance: none;
          -moz-appearance: none;
          height: 15px;
          outline: none;
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin: 0;
          cursor: pointer;
          border: 1px solid var(--bc, var(--border));
          background: var(--b, var(--background));
          transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
          &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
          }
          &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
          }
          &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
              --b: var(--disabled-inner);
              --bc: var(--border);
            }
            & + label {
              cursor: not-allowed;
            }
          }
          &:hover {
            &:not(:checked) {
              &:not(:disabled) {
                --bc: var(--border-hover);
              }
            }
          }
          &:focus {
            box-shadow: 0 0 0 var(--focus);
          }
        }
        input[type="checkbox"] {
          &.switch {
            width: 30px;
            border-radius: 11px;
            &:after {
              left: 1px;
              top: 1px;
              border-radius: 50%;
              width: 11px;
              height: 11px;
              background: #fff;
              transform: translateX(var(--x, 0));
            }
            &:checked {
              --ab: var(--active-inner);
              --x: 15px;
            }
            &:disabled {
              &:not(:checked) {
                &:after {
                  opacity: 0.6;
                }
              }
            }
          }
        }
        input[type="radio"] {
          border-radius: 50%;
          &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
          }
          &:checked {
            --s: 0.5;
          }
        }
      }
    }
  }
`;

SelectDaysChecks.propTypes = proptypes;

export default SelectDaysChecks;
