/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

/* -------------------------- Internel Dependencies ------------------------- */
import UserSelector from "./UserSelector";

/* --------------------------- Style dependencies --------------------------- */
import { StyledForm } from "../utils/styles";

const proptypes = {
  type: PropTypes.string,
  max: PropTypes.number,
  proceed: PropTypes.func,
  id: PropTypes.string,
};
const ProjectMemberForm = ({ max, id, proceed, type, selected_users }) => {
  const [selected, setSelected] = useState([]);
  const onSelectUser = (select) => {
    if (max === 0) {
      setSelected(select);
    } else if (max > 0) {
      setSelected(select.slice(0, max));
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    if (proceed) {
      proceed(selected);
    }
  };
  return (
    <div className="project-team">
      <StyledForm id={id} onSubmit={onSave}>
        <FormGroup>
          <UserSelector
            account_type={type}
            onChange={onSelectUser}
            variant="bottom"
            max={max}
            selected_users={selected_users.map((item) => item.id)}
          />
        </FormGroup>
      </StyledForm>
    </div>
  );
};

ProjectMemberForm.propTypes = proptypes;
export default ProjectMemberForm;
