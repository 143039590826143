/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

/* -------------------------- Internel Dependencies ------------------------- */
import styled, { withTheme } from "styled-components";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { NavProvider } from "./RightSideNav/NavContext";
import RightSideNav from "./RightSideNav/RightSideNav";
import useAgreement from "../hooks/useAgreement";

const DashboardLayout = ({ children, style }) => {
  useAgreement();

  const divRef = createRef();
  const { pathname } = useLocation();

  const [navHeight, setNavHeight] = useState(0);

  const resizeObserver = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      setNavHeight(entries[0].contentRect.height);
    });
  });

  useEffect(() => {
    resizeObserver.observe(divRef.current);
  }, [divRef.current]);

  // Scroll Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <NavProvider>
      <SideBar />

      <NavBar ref={divRef} />
      <MainContent id="main-content" navHeight={navHeight} style={{ ...style }}>
        {children}
      </MainContent>

      <RightSideNav />
    </NavProvider>
  );
};

const MainContent = withTheme(styled.div`
  padding: 40px;
  margin-left: 220px;
  margin-top: ${(props) => props.navHeight}px;
  background-color: #f8f8f8;
  ${(props) => props?.theme?.mixins?.scrollbar}
`);

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  style: PropTypes.shape({}),
};

export default DashboardLayout;
