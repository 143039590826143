/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { fetchProject, fetchShortlist } from "../../../../redux/actions/ProjectActions";
import Progress from "../../../../components/Progress";
import Avatar from "../../../../components/Avatar";
import ReactTable from "../../../../components/ReactTable";
import { getShortlistTableColumns, shortlistTableData } from "../../../../utils/invoiceUtils";
import SummaryPlaceholder from "../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import { generateUserInitials } from "../../../../utils/stringUtils";
import useRightNav from "../../../../layouts/RightSideNav/useRightNav";
import UserProfile from "../components/UserProfile";
import { CaretDown } from "../../dashboard/Icons";
import { StyledButtonDropdown } from "../../payments/styles";
import Icon from "../../../../components/Icon";
import useDropdownPopper from "../../../../hooks/useDropdownPopper";
import {
  shareShortlistAction,
  shortlist as shortlistAction,
} from "../../../../redux/actions/InterestPollActions";
import { openModal } from "../../../../utils/modals";
import Success from "../../../../components/Success";
import Error from "../../../../components/Error";
import RemoveFromShortlistForm from "./RemoveFromShorlistForm";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import ShareShortlistForm from "./ShareShortlistForm";
import useClearInterestUpdateFeedback from "../../../../hooks/interestPoll/useClearInterestUpdateFeedback";

const LINK = "link";
const PASS = "pass";

const Shortlist = ({ project }) => {
  useClearInterestUpdateFeedback();

  const dispatch = useDispatch();
  const { shortlist } = useSelector(({ Projects }) => Projects);
  const { interestUpdate, isSaved } = useSelector(({ InterestPoll }) => InterestPoll);
  const projectId = project.id;

  const [open, setopen] = useState(null);
  const [checked] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [page_size, setPageSize] = useState(20);
  const { open: openSideBar } = useRightNav();
  const { styles, setPopperElement, setReferenceElement, dropDownPortal } = useDropdownPopper();
  const [clipboardTxt, setClipboardTxt] = useState("");

  const handleUserProfile = (id) => {
    openSideBar(
      <UserProfile userId={id} />,
      { width: "75%", scrollBehavior: "smooth" },
      true,
      true,
    );
  };
  const filter = {
    page: currentPage + 1,
    page_size,
  };
  const onLoadMore = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  useEffect(() => {
    if (projectId && filter) {
      fetchShortlist(projectId, filter)(dispatch);
    }
  }, [projectId, filter.page, filter.page_size]);

  const removeFromShortlist = (user) => {
    openModal({
      body: <RemoveFromShortlistForm id="remove-dev-form" />,
      title: "Remove from shortlist",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Remove from Shortlist",
        size: "md",
        form: {
          type: "submit",
          form: "remove-dev-form",
        },
      },
    })
      .then(({ reason }) => {
        dispatch(
          shortlistAction(
            project,
            { users: user ? [user] : checked, action: "remove", reason },
            () => dispatch(fetchProject(project.id)),
          ),
        );
      })
      .catch(() => {});
  };

  const shareShortlist = () => {
    openModal({
      body: (
        <ShareShortlistForm
          id="share-shortlist-form"
          message={`Good day, here's a link and password to access the developers shortlisted for this role. \n\nLink: ${shortlist?.access_link} \nPassword: ${shortlist?.access_password}`}
        />
      ),
      title: "Send to client",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Send email",
        size: "md",
        form: {
          type: "submit",
          form: "share-shortlist-form",
        },
      },
    })
      .then((data) => {
        dispatch(shareShortlistAction(data));
      })
      .catch(() => {});
  };

  const copyToClipboard = (key, value) => {
    setClipboardTxt(key);
    navigator.clipboard.writeText(value);
  };

  const getTableDisplayValue = (cell) => {
    const shortlistData = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "developer": {
        return (
          <td className="nowrap" key={`developer${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={shortlistData.user.avatar_url}
                initials={generateUserInitials(shortlistData.user)}
                size="dash"
              />
              <button
                style={{
                  marginLeft: "-8px",
                  background: "none",
                  border: "none",
                }}
                type="button"
                onClick={() => handleUserProfile(shortlistData?.user?.id)}
                data-testid={`user_display_${shortlistData?.user?.id}`}
              >
                <span style={{ color: "black", padding: 6, fontWeight: "bold" }}>
                  {shortlistData?.user?.display_name}
                </span>
                <span style={{ padding: 0 }}>
                  <CaretDown />
                </span>
              </button>
            </div>
          </td>
        );
      }
      case "actions": {
        return (
          <td key={`actions${key}`} style={{ display: "table", width: "100%" }}>
            <div className="actions-icons">
              <StyledButtonDropdown
                isOpen={open === cell.value.id}
                toggle={() => toggleAction(cell.value.id)}
              >
                <DropdownToggle ref={setReferenceElement}>
                  <Icon name="dots-horizontal-small" />
                </DropdownToggle>
                {dropDownPortal(
                  <DropdownMenu
                    className="dropdown-menu"
                    ref={setPopperElement}
                    style={styles.popper}
                  >
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        removeFromShortlist(cell.value.user.id);
                      }}
                    >
                      <Icon name="cancel" />
                      &nbsp;&nbsp;&nbsp; Remove
                    </DropdownItem>
                  </DropdownMenu>,
                )}
              </StyledButtonDropdown>
            </div>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return shortlist?.loading ? (
    <Progress />
  ) : shortlist?.results?.length === 0 ? (
    <SummaryPlaceholder className="page-filters-pay-summary" description="No Data Available Yet" />
  ) : shortlist?.results?.length ? (
    <>
      <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>Shortlisted Developers</p>
      <div className="row">
        <div className="col-8">
          <div className="table-responsive" style={{ maxWidth: "100%" }}>
            {interestUpdate.success ? (
              <Success
                data-testid="success"
                message={`Successfully ${
                  interestUpdate?.data?.message.includes("added") ? "added to" : "removed from"
                } shortlist`}
              />
            ) : null}

            {interestUpdate.error ? (
              <Error data-testid="error" message="Something went wrong! Please try again." />
            ) : null}

            <Table>
              <ReactTable
                tableData={shortlistTableData(shortlist.results)}
                tableColumns={getShortlistTableColumns(filter, project)}
                currentPage={currentPage}
                count={shortlist.count}
                getTableDisplayValue={getTableDisplayValue}
                loadPage={onLoadMore}
                pageSize={page_size}
              />
            </Table>
          </div>
        </div>
        <div className="col-4">
          <Share>
            <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>Share with client</p>
            {isSaved?.shareShortlist ? (
              <Success
                data-testid="success"
                message={isSaved.shareShortlist || "Email successfully sent"}
              />
            ) : null}
            <label
              className="control-label"
              htmlFor="reason"
              style={{ fontSize: "14px", width: "100%" }}
            >
              Private link and password
            </label>

            <div style={{ position: "relative" }}>
              <Input className="mb-3" id="reason" value={shortlist.access_link} disabled />
              <CopyContainer
                onClick={() => {
                  copyToClipboard(LINK, shortlist.access_link);
                }}
                data-testid="linkCopy"
              >
                {clipboardTxt === LINK ? "Copied" : "Copy"}
              </CopyContainer>
            </div>

            <div style={{ position: "relative" }}>
              <Input
                className="mb-3"
                id="reason"
                value={shortlist.access_password}
                type="password"
                disabled
                iconClass="bsd-icon-ext"
              />
              <CopyContainer
                onClick={() => {
                  copyToClipboard(PASS, shortlist.access_password);
                }}
                data-testid="passCopy"
              >
                {clipboardTxt === PASS ? "Copied" : "Copy"}
              </CopyContainer>
            </div>

            <div className="text-center">
              <Button className="create-btn" onClick={shareShortlist} data-testid="shareBtn">
                Send via email
              </Button>
            </div>
          </Share>
        </div>
      </div>
    </>
  ) : null;
};

Shortlist.propTypes = {
  project: PropTypes.shape({}),
};

export default Shortlist;

const Table = styled("div")`
  width: 100%;
  display: inline-block;
  .btn {
    background: #fff;
    border: none;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    height: 2.5rem;
  }
  thead tr {
    th:last-child {
      padding-right: 5rem;
      float: right;
    }
  }

  .edit {
    opacity: 0.6;
    margin-right: 0px;

    &.btn-accepted {
      opacity: 1;
    }
    i {
      color: #219653;
      font-size: 18px;
    }
  }

  .cancel {
    opacity: 0.6;
    &.btn-rejected {
      opacity: 1;
    }
    i {
      color: #eb5757;
      font-size: 18px;
    }
  }

  .actions-icons {
    margin-right: 5rem;
    float: right;
    margin-top: 10px;
    button {
      box-shadow: none !important;
    }
  }
`;

const Share = styled("div")`
  display: inline-block;
  width: 100%;
  background-color: white;
  padding: 20px;

  .text-center {
    button {
      background: #da3451 !important;
      border-radius: 6px;
      box-shadow: none !important;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;

      width: 100%;
      display: block;
      text-align: center;

      color: #ffffff;
    }
  }
`;

const CopyContainer = styled("button")`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  background-color: white;
  margin: 8px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
`;
