import React from "react";
import DOMPurify from "dompurify";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const Community = () => {
  useTrackPage(pages.COMMUNITY_GUIDE);

  const iframe = () => ({
    // Sanitize external HTML to prevent XSS attacks
    __html: DOMPurify.sanitize(
      `<iframe class="clickup-embed" src="https://share-docs.clickup.com/4575769/d/h/4bmgt-21060/7edc6c91b4e441d" onwheel="" width="100%" height="700px" style="background: transparent; border: 1px solid #ccc;"></iframe>`,
      {
        ALLOWED_TAGS: ["iframe"],
        ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
      },
    ),
  });
  return (
    <DashboardLayout>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={iframe()} />
    </DashboardLayout>
  );
};

export default Community;
