/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PaymentContainer = ({ children }) => {
  return <PaymentDiv>{children}</PaymentDiv>;
};

const PaymentDiv = styled.div`
  display: table;
  height: 100%;
  padding-bottom: 0.5em;
  position: relative;

  h4 {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 22px;
    color: #151a30;
  }

  a {
    box-shadow: none !important;
  }

  p {
    font-weight: 300;
    font-size: 15px !important;
    line-height: 150%;
    /* or 24px */

    color: #3e4857;
  }
  .w {
    &-20 {
      width: 20%;
    }
    &-80 {
      width: 80%;
    }
  }

  .pdb-40 {
    padding-bottom: 4.4em;
  }

  .bs-border {
    &-right {
      border-right: 1px solid #ddd;
    }

    &-bottom {
      border-bottom: 1px solid #ddd;
    }
  }
`;

PaymentContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PaymentContainer;
