/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import { FormGroup } from "react-bootstrap";

/* -------------------------- Internal dependencies ------------------------- */
import useTempProject from "../useTempProject";
import { TempProjectContext } from "../TempProjectContext";
import { Wrapper } from "../style";
import { DOC_TYPE_CONTRACT, DOC_TYPE_OTHER, DOCUMENT_TYPES_MAP } from "../../../../../utils/api";
import DocumentPicker from "../../../../../components/DocumentPicker";
import Icon from "../../../../../components/Icon";
import FieldError from "../../../../../components/FieldError";
import IconButton from "../../../../../components/IconButton";

const Documentation = () => {
  const [documents, setDocuments] = useState({
    contract: [],
    other: [],
  });

  const { project, createDocument, deleteDocument } = useTempProject();
  const [error, setError] = useState("");
  const filterDocumentsByType = (docs, type) => {
    const filteredDocs = [];
    if (!docs) {
      return filteredDocs;
    }
    /* istanbul ignore next */
    docs.forEach((doc) => {
      if (doc.type === type) {
        filteredDocs.push(doc);
      }
    });
    return filteredDocs;
  };
  const [paymentStructure, setPaymentStructure] = useContext(TempProjectContext);

  useEffect(() => {
    setPaymentStructure({ ...paymentStructure });
  }, []);

  const onChangeValue = (key, value) => {
    const newState = {};
    newState[key] = value;
    setPaymentStructure({ ...paymentStructure, ...newState });
  };

  const onChangeField = (key, e) => {
    onChangeValue(key, e.target.value);
  };

  /* istanbul ignore next */
  const onChangeDocs = (key, docs) => {
    if (docs.length > documents[key].length) {
      const newDoc = docs[docs.length - 1];
      createDocument({
        ...newDoc,
        project: { id: project.id },
      });
    }

    const newState = {};
    newState[key] = docs;
    setDocuments({ ...documents, ...newState, ...paymentStructure });
  };

  const onRemoveDoc = (idx) => {
    /* istanbul ignore next */
    deleteDocument(idx);
  };

  /* istanbul ignore next */
  const validateEmail = () => {
    const exp =
      /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    !exp.test(paymentStructure.invoicing_email) ? setError("Invalid email address.") : setError("");
  };

  return (
    <StyledWrapper>
      <div className="header">
        <h4>Payment</h4>
        <p>
          Enter payment details for this client{" "}
          <span style={{ color: "#DA3451", paddingLeft: "2px" }}>*</span>
        </p>
        <div className="mt-4">
          <label className="mb-2">Invoicing Currency</label>
          <FormGroup className="custom-radio-group mb-3">
            <div className="radio-button">
              <input
                type="radio"
                name="invoicing_currency"
                aria-label="invoicing_currency_euros"
                id="euros"
                data-testid="invoicing_currency_euros"
                checked={paymentStructure?.currency === "EUR"}
                onChange={() => onChangeValue("currency", "EUR")}
                required
              />
              <label htmlFor="euros">Euros(€)</label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                name="invoicing_currency"
                id="usd"
                aria-label="invoicing_currency_usd"
                data-testid="invoicing_currency_usd"
                checked={paymentStructure?.currency === "USD"}
                onChange={() => onChangeValue("currency", "USD")}
                required
              />
              <label htmlFor="usd">US Dollars($)</label>
            </div>
          </FormGroup>
        </div>
        <div className="mt-3">
          <label className="mb-2">Client invoicing email address</label>
          {error && <FieldError message={error} />}
          <input
            required
            type="email"
            placeholder="Enter email address to send client invoices to"
            value={paymentStructure.invoicing_email || ""}
            onChange={(e) => onChangeField("invoicing_email", e)}
            onKeyUp={validateEmail}
          />
        </div>
      </div>

      <div className="header" style={{ paddingTop: "25px" }}>
        <h4>Documentation</h4>
        <p>Upload or link relevant project documents</p>
      </div>

      {[DOC_TYPE_CONTRACT, DOC_TYPE_OTHER].map((docType) => {
        return (
          <div key={`doc-type-${docType}`} className="section">
            <div className="section-title">
              <span>
                {(DOCUMENT_TYPES_MAP[docType] || _.upperFirst(docType)).replace(/\s?document/, "")}{" "}
                {!(docType === DOC_TYPE_CONTRACT) ? "Documents(Optional)" : null}
              </span>
              <DocumentPicker
                variant="new-button"
                showSelected={false}
                documentType={docType}
                size="main"
                onChange={(docs) => {
                  onChangeDocs(docType, docs);
                }}
              />
            </div>

            <div className="file-list">
              {filterDocumentsByType(project.documents, docType).map((doc) => {
                return (
                  <div className="section-item" key={`doc-${doc.id}`}>
                    <a href={doc.download_url} target="_blank" rel="noreferrer">
                      <Icon name={doc.file ? "file-document-outline" : "link-variant"} />{" "}
                      {doc.title ? `${doc.title}` : "Document Name"}
                    </a>
                    <IconButton
                      name="delete-outline"
                      size="main"
                      className="btn-edit"
                      onClick={() => onRemoveDoc(doc.id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Wrapper)`
  .section .file-list .section-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    a {
      text-decoration: none;
    }
  }
  .flex-tab {
    display: flex;
  }
  .active-tab {
    background: #062e64;
    color: #fff;
    height: 40px;
    width: 160px;
    cursor: pointer;
  }
  .tab {
    height: 40px;
    width: 160px;
    font-size: 16px;
    cursor: pointer;
  }
  .per-tab {
    border-radius: 4px 0px 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .fix-tab {
    border-radius: 0px 4px 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .span-append {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
    border-radius: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .file-list {
    margin-bottom: 0px;

    &:not(:empty) {
      margin-top: 26px;
    }

    .file-item {
      display: inline-block;
    }
  }

  .btn-edit {
    font-size: 1.25rem;
  }

  .section {
    border-bottom: 1px solid #edf1f7;
    padding-top: 43px;
    padding-bottom: 43px;

    &:last-of-type {
      border-bottom: none;
    }

    .section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #151a30;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #062e64;
        i {
          vertical-align: baseline;
        }
      }
    }

    .section-item {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0px 15px;
      place-items: center;

      a,
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #3e4857;

        i {
          vertical-align: baseline;
          margin-right: 20px;
          color: #8f9bb3;
        }
      }

      button {
        color: #8f9bb3;
        height: auto;
      }
    }
  }
`;

export default Documentation;
