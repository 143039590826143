/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Filters from "./Filters";
import Progress from "../../../../../components/Progress";
import SummaryPlaceholder from "../../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import {
  listProgressEvents,
  resetFilters,
  showUsersFilter,
} from "../../../../../redux/actions/ProjectActions";
import { PROGRESS_EVENT_TYPE_CM, PROGRESS_EVENT_TYPE_DEVELOPER } from "../../../../../utils/api";
import { addPropsToChildren } from "../../../../../utils/children";
import {
  CLEAR_DURATION_FILTER,
  REMOVE_STATUS_FILTER,
  REMOVE_USER_FILTER,
} from "../../../../../configs/constants/ActionTypes";

const ReportsListContainer = ({ children, type, params, page }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    project,
    filters,
    progressEvents,
    progressEventsCount: count,
    isMakingRequest,
  } = useSelector(({ Projects }) => Projects);

  const [activities, setActivities] = useState([]);
  const [initialFilters, setInitialFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0); // react table pagination page
  const [pageSize, setPageSize] = useState(20);

  const handleUserFilter = (show) => dispatch(showUsersFilter(show));

  const getList = (filtersParam = {}) => {
    dispatch(
      listProgressEvents({
        ...(filtersParam || {}),
        project: project.id,
      }),
    );
  };

  useEffect(() => {
    if (project.id) {
      const pageFilters = { page: currentPage + 1, page_size: pageSize };
      let param = {};
      if (type === `${PROGRESS_EVENT_TYPE_DEVELOPER},${PROGRESS_EVENT_TYPE_CM}`) {
        param = { types: type };
      } else {
        param = { type };
      }

      if (params) param = params;

      getList({ ...param, ...pageFilters });

      setInitialFilters({ ...param, ...pageFilters });

      if (page === "yours" || page === "client") {
        handleUserFilter(false);
      } else {
        handleUserFilter(true);
      }
    }
    dispatch(resetFilters());
  }, [location, project, currentPage, pageSize]);

  useEffect(() => {
    dispatch(resetFilters());
  }, [location, project]);

  useEffect(() => {
    if (Object.keys(initialFilters).length > 0) {
      let newParam = { ...initialFilters };
      const { duration, checkedUsers, checkedStatus } = filters;
      if (duration.start && duration.end) {
        newParam = {
          ...newParam,
          daterange_0: moment(duration.start).format("yyyy-MM-DD"),
          daterange_1: moment(duration.end).format("yyyy-MM-DD"),
        };
      }

      if (Object.keys(checkedUsers).length > 0) {
        newParam = {
          ...newParam,
          users: Object.keys(checkedUsers).join(","),
        };
      }

      if (Object.keys(checkedStatus).length > 0) {
        newParam = {
          ...newParam,
          status: Object.keys(checkedStatus).join(","),
        };
      }
      getList(newParam);
    }
  }, [filters]);

  useEffect(() => {
    setActivities(Object.values(progressEvents));
  }, [progressEvents]);

  const renderChildren = () => {
    return addPropsToChildren(children, {
      activities,
      onLoadMore: (currPage, page_size) => {
        setPageSize(page_size);
        setCurrentPage(currPage);
      },
      currentPage,
      pageSize,
      count,
    });
  };

  const clearDuration = () => dispatch({ type: CLEAR_DURATION_FILTER });

  const removeStatus = (key) =>
    dispatch({
      type: REMOVE_STATUS_FILTER,
      key,
    });

  const removeUser = (key) =>
    dispatch({
      type: REMOVE_USER_FILTER,
      key,
    });

  return (
    <>
      <Filters
        filters={filters}
        activities={activities}
        clearDuration={clearDuration}
        removeStatus={removeStatus}
        removeUser={removeUser}
      />

      {isMakingRequest.progressEvent ? (
        <Progress />
      ) : activities.length !== 0 ? (
        renderChildren()
      ) : (
        <SummaryPlaceholder
          className="page-with-filters"
          description="There are no reports yet for this project"
        />
      )}
    </>
  );
};

export default ReportsListContainer;
