/* eslint-disable no-unused-vars */
import React, { lazy } from "react";
import Payments from "../../pages/Dashboard/payments";
import Projects from "../../pages/Dashboard/projects";
import CreateProject from "../../pages/Dashboard/projects/createProjects";
import Tests from "../../pages/Dashboard/tests";
import Community from "../../pages/Dashboard/community";
import Settings from "../../pages/Dashboard/settings";
import Routing from "./Routing";
import Referral from "../../pages/Dashboard/referral";

const Login = lazy(() => import("../../pages/AuthPages/Login"));
const SignUp = lazy(() => import("../../pages/AuthPages/Signup"));
const ForgotPassword = lazy(() => import("../../pages/AuthPages/ForgotPassword"));
const PasswordConfirm = lazy(() => import("../../pages/AuthPages/PasswordResetConfirm"));
const Dashboard = lazy(() => import("../../pages/Dashboard/dashboard/Dashboard"));

const authRoutes = [
  {
    path: Routing.home.path,
    name: Routing.home.name,
    exact: true,
    component: Login,
  },
  {
    path: Routing.login.path,
    name: Routing.login.name,
    exact: false,
    component: Login,
  },
  {
    path: Routing.signup.path,
    name: Routing.signup.name,
    exact: true,
    component: SignUp,
  },
  {
    path: Routing.forgotPassword.path,
    name: Routing.forgotPassword.name,
    exact: false,
    component: ForgotPassword,
  },
  {
    path: Routing.resetPassword.path,
    name: Routing.resetPassword.name,
    exact: false,
    component: PasswordConfirm,
  },
  {
    path: Routing.resetClientPassword.path,
    name: Routing.resetClientPassword.name,
    exact: false,
    component: () => <PasswordConfirm newUser={true} />,
  },
];

const childRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    exact: false,
    name: "dashboard",
  },
  {
    path: "/projects",
    component: Projects,
    exact: false,
    name: "projects",
  },
  // {
  //   path: "/tests",
  //   component: Tests,
  //   exact: false,
  //   name: "Tests",
  // },
  {
    path: "/referral",
    component: Referral,
    exact: false,
    name: "Refer and Earn",
  },
  {
    path: "/community",
    component: Community,
    exact: false,
    name: "Community Guide",
  },
  {
    path: "/settings",
    component: Settings,
    exact: false,
    name: "settings",
  },
  {
    path: "/payments/:type",
    component: Payments,
    exact: false,
    name: "payments",
  },
];

export { authRoutes, childRoutes };
