import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertDialogue from "../components/AlertDialogue";
import IbanForm from "../components/Payment/IbanForm";
import { addIban, fetchIban, fetchPaymentProviders } from "../redux/actions/PaymentActions";
import { openModal, openAlert } from "../utils/modals";

const useConnectIban = () => {
  const [payload, setPayload] = useState({ email: "", name: "", iban: "" });
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { ibanProvider, errors } = useSelector(({ Payments }) => Payments);

  const dispatch = useDispatch();

  const connectIban = () => {
    setSubmitError(null);
    openModal({
      body: <IbanForm id="iban-connect-form" payload={payload} submitError={submitError} />,
      title: "Connect your IBAN",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Connect",
        form: {
          type: "submit",
          form: "iban-connect-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      header: null,
      hideActions: false,
    }).then(async (data) => {
      const { name, iban } = data;
      setPayload(data);

      await dispatch(
        addIban({
          account_holder: name,
          iban_number: iban,
        }),
      );
    });
  };

  useEffect(() => {
    if (ibanProvider?.success) {
      openAlert({
        body: <AlertDialogue msg="Successfully connected IBAN" />,
        canClose: false,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispatch(fetchIban({ silent: true }));
      dispatch(fetchPaymentProviders());
      setShouldTriggerPrompt(true);
    }
  }, [ibanProvider?.success]);

  useEffect(() => {
    if (submitError) {
      connectIban();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.addIban) {
      setSubmitError(errors.addIban);
    }
  }, [errors.addIban]);

  return { connectIban, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectIban;
