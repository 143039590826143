/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import * as actionTypes from "../../configs/constants/ActionTypes";
// import { reducerUpdate } from "../../utils/reducers";

const ids = (state = {}, action) => {
  const selection_key = action?.data?.selection || "default";
  const new_state = {};

  switch (action.type) {
    case actionTypes.LIST_USERS_SUCCESS:
      new_state[selection_key] = action.data.results;
      return { ...state, ...new_state };

    case actionTypes.LIST_MORE_USERS_SUCCESS:
      new_state[selection_key] = [...state[selection_key], ...action.data.results];
      return { ...state, ...new_state };

    case actionTypes.LIST_USERS_START:
      if (action.prev_selection && state[action.prev_selection]) {
        new_state[selection_key] = state[action.prev_selection];
        return { ...state, ...new_state };
      }
      return state;
    case actionTypes.LIST_USERS_FAILED:
      return state;
    default:
      return state;
  }
};

function users(state = {}, action) {
  switch (action.type) {
    case actionTypes.LIST_USERS_SUCCESS:
    case actionTypes.LIST_MORE_USERS_SUCCESS: {
      const all_users = {};
      action.data.results.forEach((user) => {
        all_users[user.id] = user;
      });
      return { ...all_users };
    }
    default:
      return state;
  }
}
function allUsersData(state = {}, action) {
  switch (action.type) {
    case actionTypes.LIST_USERS_SUCCESS:
    case actionTypes.LIST_MORE_USERS_SUCCESS: {
      const all_users = action.data;
      return { ...all_users };
    }
    default:
      return state;
  }
}

function talentPool(state = { count: 0, results: [] }, action) {
  switch (action.type) {
    case actionTypes.LIST_TALENTS_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
}

function isFetching(state = {}, action) {
  // TODO: this is just the same as isRetrieving
  switch (action.type) {
    case actionTypes.LIST_USERS_START:
      return { loading: true };
    case actionTypes.LIST_USERS_SUCCESS:
    case actionTypes.LIST_USERS_FAILED:
      return { loading: false };
    case actionTypes.LIST_TALENTS_START:
      return { talentPool: true };
    case actionTypes.LIST_TALENTS_SUCCESS:
    case actionTypes.LIST_TALENTS_FAILED:
      return { talentPool: false };
    default:
      return state;
  }
}

function count(state = {}, action) {
  const selectionKey = action.selection || "default";
  const newState = {};
  switch (action.type) {
    case actionTypes.LIST_USERS_SUCCESS:
      newState[selectionKey] = action.count;
      return { ...state, ...newState };
    case actionTypes.LIST_USERS_START:
    case actionTypes.LIST_USERS_FAILED:
      newState[selectionKey] = 0;
      return { ...state, ...newState };
    default:
      return state;
  }
}

function isSaved(state = {}, action) {
  switch (action.type) {
    case actionTypes.DEACTIVATION_ACTIVATION_USER_SUCCESS:
      return { user_deactivation: true };
    default:
      return state;
  }
}

const User = combineReducers({
  ids,
  users,
  isFetching,
  isSaved,
  count,
  allUsersData,
  talentPool,
});

export default User;
