import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";

import RemoveFromShortlistForm from "./RemoveFromShorlistForm";
import Avatar from "../../../../components/Avatar";
import Icon from "../../../../components/Icon";
import IconButton from "../../../../components/IconButton";
import InputGroup from "../../../../components/InputGroup";
import ReactTable from "../../../../components/ReactTable";
import Progress from "../../../../components/Progress";
import UserProfile from "../components/UserProfile";
import Success from "../../../../components/Success";
import Error from "../../../../components/Error";
import useRightNav from "../../../../layouts/RightSideNav/useRightNav";
import { shortlist } from "../../../../redux/actions/InterestPollActions";
import { fetchProject, fetchShortlist } from "../../../../redux/actions/ProjectActions";
import { listTalents } from "../../../../redux/actions/UserActions";
import { openConfirm, openModal } from "../../../../utils/modals";
import { generateUserInitials } from "../../../../utils/stringUtils";
import { AddCircle, CaretDown, Remove } from "../../dashboard/Icons";
import useClearInterestUpdateFeedback from "../../../../hooks/interestPoll/useClearInterestUpdateFeedback";

const { CancelToken } = axios;
let cancel;

const TalentPool = ({ project }) => {
  useClearInterestUpdateFeedback();

  const currentPageRef = JSON.parse(localStorage.getItem("talentCurrentPage") || 0);
  const history = useHistory();

  const dispatch = useDispatch();
  const { open: openSideBar } = useRightNav();

  const { isFetching, talentPool } = useSelector(({ User }) => User);
  const { shortlist: shortlists } = useSelector(({ Projects }) => Projects);
  const { interestUpdate } = useSelector(({ InterestPoll }) => InterestPoll);

  const { count, results } = talentPool;

  const [currentPage, setCurrentPage] = useState(currentPageRef);
  const [page_size, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");

  const [checked, setChecked] = useState([]);

  const checkAll = () => {
    const allIDs = results.map(({ id }) => id);
    return setChecked((v) => {
      if (v.length === +page_size) {
        return [];
      }
      return allIDs;
    });
  };

  const checkItem = (item) => {
    let newArr = [];

    if (!checked.includes(item)) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a !== item);
    }
    setChecked(newArr);
  };

  const tableData = (data) => [
    ...data.map((dev) => {
      const row = {
        batch_action: dev,
        developer: dev,
        actions: dev,
      };
      return row;
    }),
  ];

  const handleUserProfile = (id) => {
    openSideBar(
      <UserProfile userId={id} />,
      { width: "75%", scrollBehavior: "smooth" },
      true,
      true,
    );
  };

  const onLoadMore = (page, pageSize) => {
    localStorage.setItem("talentCurrentPage", page);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchShortlistFn = () => {
    dispatch(fetchShortlist(project.id, { show_all: true }));
  };

  const handleShortlistDev = (user) => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to add {user ? "this developer" : "these developers"} to the
          shortlist?
        </div>
      ),
      title: "Add to shortlist",
      canClose: true,
      options: { ok: "Add to shortlist" },
    })
      .then(() => {
        dispatch(
          shortlist(project, { users: user ? [user] : checked, action: "add" }, () => {
            dispatch(fetchProject(project.id));
            fetchShortlistFn();
          }),
        );
      })
      .catch(() => {});
  };

  const removeFromShortlist = (user) => {
    openModal({
      body: <RemoveFromShortlistForm id="remove-dev-form" />,
      title: "Remove from shortlist",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Remove from Shortlist",
        size: "md",
        form: {
          type: "submit",
          form: "remove-dev-form",
        },
      },
    })
      .then(({ reason }) => {
        dispatch(
          shortlist(project, { users: user ? [user] : checked, action: "remove", reason }, () =>
            dispatch(fetchProject(project.id)),
          ),
        );
      })
      .catch(() => {});
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const sendQuery = (q) => {
    dispatch(
      listTalents(
        { search: q },
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      ),
    );
  };

  const delayedQuery = useMemo(() => debounce((q) => sendQuery(q), 500), []);

  const handleChange = (event) => {
    if (cancel !== undefined) {
      cancel();
    }

    setSearchTerm(event.target.value);
    delayedQuery(event.target.value);
  };

  const getTableDisplayValue = (cell) => {
    const data = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "batch_action": {
        return (
          <td key={`batch_action${key}`} style={{ width: "75px" }}>
            <input
              type="checkbox"
              name="check-it"
              className="custom-checkbox"
              data-testid={`batchCheck${data.id}`}
              id={data.id}
              onChange={() => checkItem(data.id)}
              checked={checked.includes(data.id)}
            />
          </td>
        );
      }

      case "developer": {
        return (
          <td className="nowrap" key={`developer${key}`}>
            <div className="d-flex align-items-center">
              <Avatar image={data.avatar_url} initials={generateUserInitials(data)} size="dash" />
              <button
                style={{
                  marginLeft: "-8px",
                  background: "none",
                  border: "none",
                }}
                type="button"
                onClick={() => handleUserProfile(data.id)}
              >
                <span
                  style={{ color: "black", padding: 6, fontWeight: "bold" }}
                  data-testid={`user_display_${data.id}`}
                >
                  {data?.display_name}
                </span>
                <span style={{ padding: 0 }}>
                  <CaretDown />
                </span>
              </button>
            </div>
          </td>
        );
      }

      case "actions": {
        const isShortlisted = !!shortlists.results.find(({ user }) => user.id === data.id);

        return (
          <td ky={`actions${key}`} style={{ display: "table", width: "100%" }}>
            <div className="actions-icons">
              {!isShortlisted ? (
                <button
                  data-tip="Add to shortlist"
                  className="btn"
                  style={{ background: "none" }}
                  data-testid={`acceptBtn${data.id}`}
                  onClick={() => handleShortlistDev(data.id)}
                  type="button"
                >
                  <AddCircle />
                </button>
              ) : (
                <button
                  className="btn"
                  style={{ background: "none" }}
                  onClick={() => removeFromShortlist(data.id)}
                  data-testid={`rejectBtn${data.id}`}
                  type="button"
                  data-tip="Reject User"
                >
                  <Remove />
                </button>
              )}
            </div>
          </td>
        );
      }

      default:
        return null;
    }
  };

  const talentPoolTableColumns = useMemo(() => {
    return [
      {
        Header: (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              name="check-it"
              className="custom-checkbox"
              checked={checked.length === +page_size}
              onChange={() => checkAll()}
            />
          </div>
        ),
        accessor: "batch_action",
      },
      {
        Header: "Developer",
        accessor: "developer",
      },

      {
        Header: <div className="actions-header-label">Actions</div>,
        accessor: "actions",
      },
    ];
  }, [JSON.stringify(results), checked.length]);

  useEffect(() => {
    dispatch(listTalents({ page: currentPage + 1, page_size }));
    fetchShortlistFn();
  }, [currentPage, page_size]);

  useEffect(() => {
    document.querySelector("#main-content").scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const unListen = history.listen(() => {
      localStorage.removeItem("talentCurrentPage");
    });

    return unListen;
  }, []);

  return (
    <div className="table-responsive" style={{ maxWidth: "820px" }}>
      {interestUpdate.success && (
        <Success
          data-testid="success"
          message={`Successfully ${
            interestUpdate?.data?.message.includes("added") ? "added to" : "removed from"
          } shortlist`}
        />
      )}

      {interestUpdate.error && (
        <Error data-testid="error" message="Something went wrong! Please try again." />
      )}

      <div className="row m-0 mb-3" style={{ width: "100%" }}>
        <div className="col-5 d-flex align-items-center">
          <Text className="m-0">Talent Pool</Text>
        </div>

        <div className="col-7 px-0">
          <div className="d-flex">
            <SelectionWrap
              className={`d-flex align-items-center  ${checked.length > 0 ? "" : "invisible"}`}
            >
              <div className="d-inline-block no-of-selected">{checked.length} selected </div>
              <div className="actions-icons d-flex align-items-center">
                <button
                  className="btn px-0 batch-accept-btn"
                  data-testid="batchAcceptBtn"
                  onClick={() => handleShortlistDev()}
                  type="button"
                  title="Add to shortlist"
                >
                  <AddCircle />
                </button>

                <button
                  className="btn px-0 batch-reject-btn"
                  onClick={() => removeFromShortlist()}
                  data-testid="batchRejectBtn"
                  type="button"
                  title="Remove User"
                >
                  <Remove />
                </button>
              </div>
            </SelectionWrap>

            <StyledSearchInput
              data-testid="talent-search"
              type="search"
              value={searchTerm}
              onChange={handleChange}
              autoComplete="off"
              placeholder="Search Talent Pool"
              prepend={<Icon name="search1" />}
              isAppendText={false}
              append={
                <IconButton
                  data-testid="clear-btn"
                  className="search-close-btn bsd-search-ic"
                  name="x-circle"
                />
              }
              appendFunc={clearSearch}
            />
          </div>
        </div>
      </div>

      {isFetching.talentPool ? (
        <Progress />
      ) : (
        <Table>
          <ReactTable
            colDeps={[checked]}
            tableData={tableData(results)}
            tableColumns={talentPoolTableColumns}
            currentPage={currentPage}
            count={count || 0}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={page_size}
          />
        </Table>
      )}
    </div>
  );
};

const Table = styled("div")`
  width: 100%;
  .actions-header-label {
    text-align: right;
  }

  .btn {
    background: #fff;
    border: none;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    height: 2.5rem;
  }

  .edit {
    opacity: 0.6;
    margin-right: 0px;

    &.btn-accepted {
      opacity: 1;
      background: none;
    }
    i {
      color: #219653;
      font-size: 18px;
    }
  }

  .cancel {
    opacity: 0.6;
    &.btn-rejected {
      opacity: 1;
      background: none;
    }
    i {
      color: #eb5757;
      font-size: 18px;
    }
  }

  .actions-icons {
    float: right;
    margin-top: 10px;
    button {
      box-shadow: none !important;
    }
  }
`;

const Text = styled.p`
  font-weight: bold;
  font-size: 1.2em;
`;

const SelectionWrap = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #686c72;

  .no-of-selected {
    white-space: nowrap;
    vertical-align: middle;
  }

  .no-of-selected,
  .batch-accept-btn {
    margin-right: 28px;
  }

  .batch-reject-btn {
    margin-right: 40px;
  }

  .batch-reject-btn,
  .batch-accept-btn {
    background: none;
    height: unset;
    line-height: unset;
    border: none;
  }
`;

const StyledSearchInput = styled(InputGroup)`
  box-shadow: none;
  border: 1px solid rgb(237, 241, 247);
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 18px;
  padding-left: 4px;

  .input-group-prepend .input-group-text {
    background-color: rgb(255, 255, 255);
    color: rgb(62, 72, 87);
    padding: 0 10px;
    font-size: 16px;
    height: initial;
    border: none;
  }

  > div {
    margin: 0;

    input {
      padding: 0 10px;
      border-radius: 4px;
      border: none;

      ::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      ::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      /* clears the ‘X’ from Chrome */
      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button,
      ::-webkit-search-results-button,
      ::-webkit-search-results-decoration {
        display: none;
      }

      &:focus {
        border: none !important;
      }
    }
  }

  .search-close-btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(143, 155, 179);
    font-size: 16px;
  }
`;

TalentPool.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    expected_duration: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({ avatar_url: PropTypes.string, display_name: PropTypes.string }),
    created_at: PropTypes.string,
  }),
};

export default TalentPool;
