/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from "react";
import { debounce, isEqual } from "lodash";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import { generateUserInitials } from "../../utils/stringUtils";
import randomString from "../../utils/generateRandomString";
import Avatar from "../Avatar";
import IconButton from "../IconButton";
import Input from "../Input";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import { listUsers } from "../../redux/actions/UserActions";

const VARIANT_BOTTOM = "bottom";

const UserSelector = (props) => {
  const {
    className,
    label,
    max,
    disabled,
    size,
    placeholder,
    selected,
    type,
    variant,
    userRemoved,
    filter,
    userType,
    users,
    selected_users,
    onChange,
    account_type,
    value,
    selectionKey: key,
  } = props;
  const dispatch = useDispatch();

  const User = useSelector(({ User: UserState }) => UserState);

  const [prevKey] = useState(null);
  const [selectionKey] = useState(key || randomString.generate());
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(selected || value || []);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const prevSearch = usePrevious(search);
  const prevSelectedSkill = usePrevious(selectedUser);

  const searchKey = useCallback(() => {
    return `${selectionKey}-${search}`;
  }, [selectionKey, search]);

  const getUsers = useCallback(
    debounce((userFilter) => {
      dispatch(listUsers(userFilter, searchKey(), prevKey));
    }, 300),
    [searchKey],
  );

  const handleChange = (e) => {
    const username = e.target.value;
    setSearch(username);
    setShowSuggestions(!!username);
  };
  /* istanbul ignore next */
  const onSelectUser = (e, user) => {
    const newSelected = [...selectedUser, user];
    e.preventDefault();
    setSearch("");
    setShowSuggestions(false);
    setSelectedUser(
      Array.from(new Set(newSelected.map((a) => a.id))).map((id) => {
        return newSelected.find((a) => a.id === id);
      }),
    );
  };
  /* istanbul ignore next */
  const onRemoveUser = (e, user) => {
    e.preventDefault();
    const idx = selectedUser
      .map((userToRemove) => {
        return userToRemove.id;
      })
      .indexOf(user.id);

    if (userRemoved) userRemoved(user.id);

    if (idx > -1) {
      setSelectedUser(
        Array.from(new Set([...selectedUser.slice(0, idx), ...selectedUser.slice(idx + 1)])),
      );
    }
  };

  const renderSelection = () => {
    if (selectedUser && selectedUser.length) {
      return (
        <div className="selected">
          {selectedUser.map((user) => {
            return (
              <div className={`item ${!label && "nolabel"}`} key={`user-${user.id}`}>
                <Avatar image={user.avatar_url} initials={generateUserInitials(user)} size="dash" />
                <span>{user.display_name}</span>

                {!disabled && (
                  <div className="item-actions">
                    <IconButton
                      data-testid={`btn-del-user-${user.id}`}
                      name="delete-outline"
                      onClick={(e) => onRemoveUser(e, user)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (Array.isArray(selected)) setSelectedUser(selected);
  }, [selected]);

  useDidUpdate(() => {
    /* istanbul ignore next */
    if (!isEqual(selectedUser, prevSelectedSkill) && onChange) {
      onChange(selectedUser);
    }
  }, [selectedUser]);

  useDidUpdate(() => {
    /* istanbul ignore next */
    if (!isEqual(search, prevSearch)) {
      getUsers({
        search,
        account_type,
      });
    }
  }, [search, getUsers]);

  return (
    <Wrapper className="tag-input" label={label}>
      {variant !== VARIANT_BOTTOM && renderSelection()}
      <div className="form">
        {label && <label htmlFor="user">User</label>}

        {((selectedUser.length === 0 && type === "singular") || type === "normal") && (
          <Input
            id="user"
            disabled={!(!max || max > selectedUser.length) || disabled}
            className={className}
            variant="personalnew"
            size={size}
            placeholder={placeholder}
            {...filterInputProps(props)}
            {...filterEventProps(props)}
            selected={selectedUser}
            value={search}
            onFocus={() => setShowSuggestions(!!search)}
            autoComplete="off"
            dataTestId="user-selector"
            onChange={handleChange}
          />
        )}

        {showSuggestions && (
          <div className="list-group suggestions">
            {(User.ids[searchKey()] || []).map(({ id }) => {
              const user = User.users[id] || {};

              if (Object.keys(user).length === 0) return null;

              if (selectedUser.indexOf(id) > -1 || (filter && !users.includes(user.id))) {
                return null;
              }

              if (
                selected_users.includes(user.id) ||
                selectedUser.map((it) => it.id).includes(user.id)
              ) {
                return null;
              }

              if (userType !== "") {
                if (userType === "developer" && !user.is_developer) {
                  return null;
                }
              }
              return (
                <button
                  type="button"
                  className="item-selected"
                  key={`user-${user.id}`}
                  data-testid={`user-${user.id}`}
                  onClick={(e) => onSelectUser(e, user)}
                >
                  <Avatar
                    image={user.avatar_url}
                    initials={generateUserInitials(user)}
                    size="dash"
                  />
                  <span>{user.display_name}</span>
                </button>
              );
            })}
          </div>
        )}
      </div>

      {variant === VARIANT_BOTTOM && renderSelection()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #151a30;
  }

  .form {
    ${(props) => (props.label ? "padding-bottom: 40px; border-bottom: 1px solid #edf1f7;" : "")}
  }

  .selected {
    ${(props) => (props.label ? "padding-top: 40px;" : "padding-bottom: 20px;")}
  }

  .suggestions {
    background: #fff;
    padding: 11px 16px;
    border: 1px solid #edf1f7;

    .item-selected {
      cursor: pointer;
      margin-bottom: 0px;
      border: none;
      border-bottom: 1px solid #edf1f7;
      padding: 11px 0px;

      &::last-of-type {
        border-bottom: none;
      }
    }
  }

  .item.nolabel {
    padding: 5px 8px;
    .avatar.avatar-dash {
      width: 29px;
      height: 29px;

      &.avatar-initials {
        font-size: 10px;
      }
    }

    button {
      height: auto;
    }
  }

  .item,
  .item-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border: 1px solid #edf1f7;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 11px 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .avatar.avatar-dash {
      width: 35px;
      height: 35px;

      &.avatar-initials {
        font-size: 14px;
      }
    }

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    .item-actions {
      margin: 0 0 0 auto;

      button i {
        margin-left: 30px;
        color: #8f9bb3;
        font-size: 20px;
      }
    }
  }
`;

UserSelector.defaultProps = {
  placeholder: "Type a name or username here",
  selectionKey: null,
  max: null,
  label: true,
  type: "normal",
  filter: false,
  users: [],
  userType: "",
  disabled: false,
  selected_users: [],
};

UserSelector.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  account_type: PropTypes.string,
  max: PropTypes.number,
  label: PropTypes.bool,
  type: PropTypes.string,
  filter: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  selected_users: PropTypes.arrayOf(PropTypes.shape({})),
  userRemoved: PropTypes.func,
  userType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UserSelector;
