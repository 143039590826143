import React from "react";

export const FilterLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-1 w-1 mr-1"
      fill="none"
      width="14"
      height="14"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
      />
    </svg>
  );
};
