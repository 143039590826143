import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import AlertDialogue from "../../../../components/AlertDialogue";
import SummaryPlaceholder from "../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import TimeSheetList from "./TimeSheetList";
import TimeSheetSummary from "./TimeSheetSummary";
import RejectTimeSheetForm from "../../projects/opportunity/RejectTimeSheetForm";
import {
  approveRejectEntries,
  listTimeSheetsMonthlyEntries,
  listTimeSheetsYearlyEntries,
} from "../../../../redux/actions/TimeSheetActions";
import { openAlert, openConfirm, openModal } from "../../../../utils/modals";
import { isAdminOrCMOrClientOrCSO } from "../../../../utils/auth";

const TimeSheet = ({ currentMonth, currentYear }) => {
  const dispatch = useDispatch();

  const { user: userObj } = useSelector(({ Auth }) => Auth);
  const { monthView, isSaved } = useSelector(({ TimeSheetEntries }) => TimeSheetEntries);
  const { project } = useSelector(({ Projects }) => Projects);

  const onApproveTimeSheet = (userId) => {
    openConfirm({
      message: <div>Are you sure you want to approve this timesheet?</div>,
      title: "Approve Timesheet",
      canClose: true,
      options: {
        ok: "Approve",
        cancel: "Cancel",
        dismissAsCancel: true,
        size: "sm",
      },
    }).then(() => {
      dispatch(approveRejectEntries(project.id, userId, currentMonth, "approve"));
    });
  };

  const onRejectTimeSheet = (userId) => {
    openModal({
      body: <RejectTimeSheetForm id="reject-timesheet-form" />,
      title: "Reject Timesheet",
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Reject",
        cancel: "Cancel",
        size: "md",
        form: {
          type: "submit",
          form: "reject-timesheet-form",
        },
      },
    }).then(({ reason }) => {
      dispatch(approveRejectEntries(project.id, userId, currentMonth, "reject", reason));
    });
  };

  const renderTimeSheet = () => {
    if (isAdminOrCMOrClientOrCSO()) {
      return (
        <>
          <TimeSheetSummary />

          {monthView?.results.length === 0 ? (
            <SummaryPlaceholder className="page-filters-pay-summary" description="No data found." />
          ) : (
            <>
              {monthView?.results?.map(({ expected_hours, user, total, status }) => {
                return (
                  <TimeSheetList
                    key={user.id}
                    expectedHours={+expected_hours ?? 0}
                    user={user}
                    total={total}
                    status={status}
                    currentMonth={currentMonth}
                    projectId={project.id}
                    onApproveTimeSheet={onApproveTimeSheet}
                    onRejectTimeSheet={onRejectTimeSheet}
                  />
                );
              })}
            </>
          )}
        </>
      );
    }

    const { expected_hours = 0, total = 0, status = "" } = monthView.results[0] || {};

    return (
      <TimeSheetList
        key={userObj.id}
        expectedHours={expected_hours}
        user={userObj}
        total={total}
        status={status}
        currentMonth={currentMonth}
        projectId={project.id}
        onApproveTimeSheet={onApproveTimeSheet}
        onRejectTimeSheet={onRejectTimeSheet}
      />
    );
  };

  useEffect(() => {
    if (isSaved?.approveReject) {
      openAlert({
        body: <AlertDialogue msg={isSaved.message} />,
        canClose: true,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
      dispatch(listTimeSheetsMonthlyEntries(project.id, currentMonth, ""));
    }

    if (isSaved?.updateTimeSheetEntry) {
      dispatch(listTimeSheetsMonthlyEntries(project.id, currentMonth, "", true));
      dispatch(listTimeSheetsYearlyEntries(project.id, currentYear, ""));
    }
  }, [isSaved?.approveReject, isSaved?.updateTimeSheetEntry]);

  return <>{renderTimeSheet()}</>;
};

TimeSheet.propTypes = {
  currentMonth: PropTypes.string.isRequired,
  currentYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TimeSheet;
