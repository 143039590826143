/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import * as actionTypes from "../../configs/constants/ActionTypes";

const skills = (state = {}, action) => {
  const selection_key = action?.data?.selection || "default";
  const new_state = {};

  switch (action.type) {
    case actionTypes.GET_SKILLS_SUCCESS:
      new_state[selection_key] = action.data.items;
      return { ...state, ...new_state };

    case actionTypes.INVALIDATE_SKILLS:
      new_state[selection_key] = [];
      return { ...state, ...new_state };

    default:
      return state;
  }
};

function isFetching(state = {}, action) {
  const selection_key = action?.data?.selection || "default";
  const new_state = {};

  switch (action.type) {
    case actionTypes.GET_SKILLS_START:
      new_state[selection_key] = true;
      return { ...state, ...new_state };

    case actionTypes.GET_SKILLS_SUCCESS:
    case actionTypes.GET_SKILLS_FAILED:
      new_state[selection_key] = false;
      return { ...state, ...new_state };

    default:
      return state;
  }
}

const Skill = combineReducers({
  skills,
  isFetching,
});

export default Skill;
