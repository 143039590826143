/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DropdownToggle, DropdownMenu } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

/* -------------------------- Internal dependencies ------------------------- */
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import Icon from "../../../components/Icon";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import InvoiceHistory from "../../../components/InvoiceHistory";
import ReactTable from "../../../components/ReactTable";
import ActionItem from "./components/ActionItem";
import DropdownActionItem from "./components/DropdownActionItem";
import {
  APPROVE_BATCH_ACTION,
  APPROVE_CSO_PAYOUT_ACTION,
  DELETE_ACTION_BATCH,
  EDIT_ACTION_BATCH,
  MARK_AS_PAID_BATCH_ACTION,
  REJECT_PAYOUT_ACTION,
} from "../../../configs/constants/invoiceConstants";
import { ENDPOINT_INVOICES, INVOICE_TYPE_PURCHASE } from "../../../utils/api";
import {
  batchInvoices,
  onCreateInvoice,
  filterInvoices,
  payoutTableData,
  getPayoutTableColumns,
} from "../../../utils/invoiceUtils";
import {
  isAdmin,
  isInternalUser,
  isAdminOrPMOrSpaceLead,
  isCMOrCSOAndHasProjectAcess,
  isCSO,
  isDev,
  isPayAdmin,
  isPM,
  isSpaceLead,
  isCM,
} from "../../../utils/auth";

/* --------------------------- Styles dependencies -------------------------- */
import { StyledButtonDropdown } from "./styles";
import Avatar from "../../../components/Avatar";
import { generateUserInitials } from "../../../utils/stringUtils";
import useDropdownPopper from "../../../hooks/useDropdownPopper";
import PayoutFilter from "../../../components/PayoutFilter";
import BulkActions from "../../../components/BulkActions";
import OverlayTooltip from "../../../components/OverlayTooltip";
import CSVDownload from "./components/CSVDownload";

const actions = [
  { id: "approve", name: "Approve payout", value: "approve" },
  { id: "mark_as_paid", name: "Mark as paid", value: "mark_as_paid" },
  { id: "archive", name: "Archive invoice", value: "archive" },
  { id: "delete", name: "Delete invoice", value: "delete" },
];

const Payouts = (props) => {
  const {
    data: invoices,
    filter,
    project,
    getList,
    setcreateAction,
    onLoadMore,
    count,
    currentPage,
    pageSize,
  } = props;
  const paymentFiltersFromStorage = JSON.parse(localStorage.getItem("paymentFilters"));

  const [paymentFilters, setPaymentFilters] = useState(paymentFiltersFromStorage || []);

  const [checked, setChecked] = useState([]);
  const [options, setOptions] = useState(actions);

  const { open: openSideBar } = useRightNav();
  const { styles, attributes, setPopperElement, setReferenceElement, dropDownPortal } =
    useDropdownPopper();

  const {
    styles: sideBarPaymentStyles,
    attributes: sideBarPaymentAttributes,
    setPopperElement: sideBarPaymentSetPopperElement,
    setReferenceElement: sideBarPaymentSetReferenceElement,
    dropDownPortal: sideBarPaymentDropDownPortal,
  } = useDropdownPopper();

  const payouts = filterInvoices(invoices, INVOICE_TYPE_PURCHASE);
  const batchPayouts = batchInvoices(payouts);
  const filteredPayouts = (items) =>
    items.filter(
      (item) =>
        (isPayAdmin() && !["draft", "rejected"].includes(item.status)) ||
        isAdmin() ||
        isCSO() ||
        isDev() ||
        isPM() ||
        isSpaceLead(),
    );
  const [open, setopen] = useState(null);

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";

  const handlePaymentFiltersCheck = (x) => {
    const newPaymentFilters = [...paymentFilters];
    const ind = newPaymentFilters.indexOf(x);

    if (newPaymentFilters.includes(x)) {
      newPaymentFilters.splice(ind, 1);
    } else {
      newPaymentFilters.push(x);
    }

    setPaymentFilters(newPaymentFilters);
    localStorage.setItem("paymentFilters", JSON.stringify(newPaymentFilters));
  };

  const handleApplyFilters = () => {
    onLoadMore(0, pageSize);
    getList({ payment_method: paymentFilters.toString().toLowerCase() });
  };

  const handleClearFilters = () => {
    const checkboxes = document.querySelectorAll("[name=clear-it]");
    Array.from(checkboxes).map((checkbox) => {
      return (checkbox.checked = false);
    });
    setPaymentFilters([]);
    localStorage.removeItem("paymentFilters");
  };

  const checkForIbanWiseNil = (items) => {
    const ibanWiseNil = items.some(
      ({ payment_platform }) =>
        payment_platform === "iban" || payment_platform === "wise" || payment_platform === null,
    );

    const checkForInactiveAccount = items.some(
      ({ user, payment_platform }) =>
        payment_platform === "payoneer" &&
        !(
          user.payment_provider?.status === "approved" || user.payment_provider?.status === "active"
        ),
    );

    if (ibanWiseNil) {
      const a = [...actions];
      setOptions(a.slice(1));
    } else if (checkForInactiveAccount) {
      const clone = [...actions];
      clone[0].disabled = true;
      setOptions(clone);
    } else {
      const clone = [...actions];
      clone[0].disabled = false;

      setOptions(clone);
    }
  };

  const checkItem = (item) => {
    let newArr = [];
    const hasItem = checked.filter((a) => a.id === item.id).length > 0;

    if (!hasItem) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a.id !== item.id);
    }
    checkForIbanWiseNil(newArr);
    setChecked(newArr);
  };

  const toggleAll = (state) => {
    const checkboxes = document.querySelectorAll("[name=check-it]");
    const checkedBoxMapped = Array.from(checkboxes).map((checkbox) => {
      checkbox.checked = state;

      const found = filteredPayouts(payouts).find((fp) => fp.id === +checkbox.id);
      return found;
    });

    if (state) {
      checkForIbanWiseNil(checkedBoxMapped);
      setChecked(checkedBoxMapped);
    } else {
      checkForIbanWiseNil([]);
      setChecked([]);
    }
  };

  const getPayoutTableData = useCallback(() => {
    if (scope === "sideBarPayment") {
      return payoutTableData(filteredPayouts(payouts), scope);
    }

    return payoutTableData(filteredPayouts(batchPayouts), scope);
  }, [scope, payouts]);

  useEffect(() => {
    if (setcreateAction)
      setcreateAction({
        ...(!project ||
        ((isPayAdmin() || isAdmin() || isCMOrCSOAndHasProjectAcess(project)) && !project?.archived)
          ? {
              visibility: true,
              add: [
                {
                  title: "Payout",
                  action: () => {
                    onCreateInvoice(INVOICE_TYPE_PURCHASE, props);
                  },
                },
              ],
            }
          : { visibility: false }),
      });
  }, []);

  const handleInvoiceHistory = (invoice) => {
    openSideBar(<InvoiceHistory selected_invoice={invoice} type="payout" />, { width: "450px" });
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  const getTableDisplayValue = (cell) => {
    const invoice = cell?.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    /* Checking if the invoice payment platform is payoneer. */
    let paymentToPayoneer = invoice?.payment_platform === "payoneer";

    /* Checking if the invoice payment platform is not payoneer or if the invoice payment platform is null. */
    let isNotPaymentToPayoneer =
      invoice?.payment_platform !== "payoneer" || invoice?.payment_platform === null;

    /* Checking if the invoice user payment provider status is approved or active. */
    let paymentProviderActive =
      invoice?.user?.payment_provider?.status === "approved" ||
      invoice?.user?.payment_provider?.status === "active";

    /* Checking if the payment provider is active or not. */
    if (invoice?.invoices?.length > 0) {
      paymentProviderActive = !invoice?.invoices?.some((invoiceItem) => {
        /* Checking if the user has a payment provider and if the status is not approved or active. */
        const check =
          invoiceItem?.user?.payment_provider?.status !== "approved" &&
          invoiceItem?.user?.payment_provider?.status !== "active";

        return check;
      });

      /* Checking if the invoice has a payment platform of payoneer. */
      paymentToPayoneer = invoice?.invoices?.some((invoiceItem) => {
        const check = invoiceItem?.payment_platform === "payoneer";
        return check;
      });

      /* The below code is checking if the invoice is not a payment to payoneer. */
      isNotPaymentToPayoneer = invoice?.invoices?.some((invoiceItem) => {
        const check =
          invoiceItem?.payment_platform !== "payoneer" || invoiceItem?.payment_platform === null;

        return check;
      });
    }

    /* Checking if the invoice is not approved, not paid, payment to payoneer, not payment to payoneer, and payment provider is not active. */
    const inactivePayoneerAccountCheck =
      invoice?.status !== "approved" &&
      !invoice?.paid &&
      paymentToPayoneer &&
      !isNotPaymentToPayoneer &&
      !paymentProviderActive;

    switch (cell.column.id) {
      case "batch_action": {
        return (
          <td key={`batch_action${key}`}>
            {!(invoice.paid || invoice.status === "approved") && !inactivePayoneerAccountCheck && (
              <input
                type="checkbox"
                name="check-it"
                className="custom-checkbox"
                id={invoice.id}
                onClick={() => checkItem(invoice)}
              />
            )}
          </td>
        );
      }

      case "client": {
        return (
          <td className="nowrap" key={`client${key}`}>
            {invoice.project && invoice.project.owner ? invoice.project.owner.display_name : "N/A"}
          </td>
        );
      }
      case "project": {
        return (
          <td className="nowrap" key={`project${key}`}>
            <Link className="line-bottom" to={`/projects/${invoice.project.id}/pay/payouts/all`}>
              {invoice?.project?.title || "N/A"}
            </Link>
          </td>
        );
      }
      case "freelancer": {
        return (
          <td className="nowrap" key={`freelancer${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={invoice?.user?.avatar_url}
                initials={generateUserInitials(invoice?.user)}
                size="dash"
              />
              {`${invoice?.user?.display_name}`}
            </div>
          </td>
        );
      }
      case "platform": {
        return (
          <td className="nowrap" key={`platform${key}`}>
            {invoice?.payment_platform || "Nil"}
          </td>
        );
      }
      case "due_at": {
        return (
          <td key={`due_at${key}`} className="nowrap">
            {moment.utc(invoice?.due_at).format("DD MMM YYYY")}

            {new Date(`${invoice?.due_at}`) < new Date() &&
              !invoice?.paid &&
              invoice?.status !== "approved" && (
                <Icon className="danger" name="exclamation-triangle" />
              )}
          </td>
        );
      }
      case "created_at": {
        return (
          <td key={`created_at${key}`} className="nowrap">
            {moment.utc(invoice?.issued_at).format("DD MMM YYYY")}
          </td>
        );
      }
      case "title": {
        return (
          <td className="nowrap" key={`title${key}`}>
            {isAdminOrPMOrSpaceLead() || isCSO() ? (
              <button
                type="button"
                className="btn-trans text-capitalize line-bottom"
                onClick={() => handleInvoiceHistory(invoice)}
              >
                {invoice?.title}
              </button>
            ) : (
              <span className="pe-1">{invoice.title}</span>
            )}

            {inactivePayoneerAccountCheck && (
              <OverlayTooltip
                tooltipId="payoneer-tooltip"
                placement="top"
                overlay={
                  isDev()
                    ? "Your Payoneer account is inactive, and payment can't be processed. Kindly update your PSP"
                    : "Developer has inactive Payoneer account, Payout cannot be approved"
                }
              >
                <Icon name="warning" size="sm" className="text-warning" />
              </OverlayTooltip>
            )}
          </td>
        );
      }
      case "user": {
        return (
          <td key={`user${key}`} className="nowrap">
            {invoice?.invoices.map((item) => {
              return <div key={item.id}>{item.user.display_name}</div>;
            })}
          </td>
        );
      }
      case "invoice": {
        return (
          <td key={`invoice${key}`} className="nowrap">
            {invoice?.invoices ? (
              invoice?.invoices?.map((item) => {
                return (
                  <div key={item.id}>
                    <a
                      className="line-bottom"
                      href={`${ENDPOINT_INVOICES}${item.id}/download/?format=pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.number}
                    </a>
                  </div>
                );
              })
            ) : (
              <div key={invoice.id}>
                <a
                  className="line-bottom"
                  href={`${ENDPOINT_INVOICES}${invoice.id}/download/?format=pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {invoice.number}
                </a>
              </div>
            )}
          </td>
        );
      }
      case "amount": {
        return (
          <>
            {scope === "sideBarPayment" ? (
              <td key={`amount${key}`} className="nowrap cta">
                €{invoice?.amount}
              </td>
            ) : (
              <td key={`amount${key}`} className="nowrap">
                {invoice?.invoices?.map((item) => {
                  return (
                    <div key={item.id}>
                      €{item.amount}{" "}
                      {invoice.paid && !item.paid && item.status !== "approved" && (
                        <Icon className="danger" name="exclamation-triangle" />
                      )}
                    </div>
                  );
                })}
                {!isDev() && <div className="subtotal">€{invoice.amount}</div>}
              </td>
            )}
          </>
        );
      }

      case "status": {
        return (
          <td key={`status${key}`} className="nowrap">
            {(new Date(`${invoice.due_at}`) < new Date() &&
              !invoice.paid &&
              invoice.status === "approved") ||
            (!invoice.paid && invoice.status === "approved") ? (
              <span className="pending">Processing</span>
            ) : invoice.paid ? (
              <span className="completed">Paid</span>
            ) : new Date(`${invoice.due_at}`) < new Date() && !invoice.paid ? (
              <span className="overdue">Overdue</span>
            ) : invoice.status === "draft" ? (
              <span className="pending">Draft</span>
            ) : invoice.status === "rejected" ? (
              <span className="overdue">Rejected</span>
            ) : (
              <span className="pending">Pending</span>
            )}
          </td>
        );
      }

      case "actions_sideBarPayment": {
        return (
          <td key={`actions_sideBarPayment${key}`} className="cta">
            {isInternalUser() &&
              invoice?.project &&
              !invoice?.project.archived &&
              !invoice?.paid &&
              invoice?.status !== "approved" && (
                <div className="cta-wrapper" data-testid={`cta-wrapper-${invoice.id}`}>
                  <StyledButtonDropdown
                    isOpen={open === invoice.id}
                    toggle={() => toggleAction(invoice.id)}
                  >
                    <DropdownToggle
                      className="btn-more d-flex align-items-center"
                      ref={sideBarPaymentSetReferenceElement}
                    >
                      <Icon name="dots-horizontal-small" />
                    </DropdownToggle>

                    {sideBarPaymentDropDownPortal(
                      <DropdownMenu
                        className="dropdown-menu"
                        ref={sideBarPaymentSetPopperElement}
                        style={sideBarPaymentStyles.popper}
                        {...sideBarPaymentAttributes.popper}
                      >
                        {paymentToPayoneer && !isNotPaymentToPayoneer && (
                          <DropdownActionItem
                            action={APPROVE_BATCH_ACTION}
                            invoice={invoice}
                            disabled={!paymentProviderActive}
                          >
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Approve payout
                          </DropdownActionItem>
                        )}

                        <DropdownActionItem action={MARK_AS_PAID_BATCH_ACTION} invoice={invoice}>
                          <Icon name="circle-check" />
                          &nbsp;&nbsp;&nbsp; Mark as Paid
                        </DropdownActionItem>

                        <DropdownActionItem
                          action={EDIT_ACTION_BATCH}
                          invoice={invoice}
                          project={project}
                        >
                          <Icon name="circle-edit-outline" />
                          &nbsp;&nbsp;&nbsp; Edit invoice
                        </DropdownActionItem>

                        <DropdownActionItem action={DELETE_ACTION_BATCH} invoice={invoice}>
                          <Icon name="delete-outline" />
                          &nbsp;&nbsp;&nbsp; Delete invoice
                        </DropdownActionItem>

                        {!invoice?.project?.archived && !invoice?.paid && (
                          <DropdownActionItem action={REJECT_PAYOUT_ACTION} invoice={invoice}>
                            <Icon name="cancel" />
                            &nbsp;&nbsp;&nbsp; Reject payout
                          </DropdownActionItem>
                        )}

                        {invoice.status === "draft" && (
                          <DropdownActionItem action={APPROVE_CSO_PAYOUT_ACTION} invoice={invoice}>
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Approve payout
                          </DropdownActionItem>
                        )}
                      </DropdownMenu>,
                    )}
                  </StyledButtonDropdown>
                </div>
              )}
          </td>
        );
      }

      case "actions": {
        return (
          <td key={`actions${key}`} className="cta">
            {(isAdmin() || isSpaceLead() || isCMOrCSOAndHasProjectAcess(project)) &&
              !invoice.paid &&
              invoice.status !== "approved" && (
                <div className="cta-wrapper">
                  {isPayAdmin() && paymentToPayoneer && !invoice.paid && (
                    <ActionItem
                      disabled={!paymentProviderActive}
                      className="line-bottom"
                      invoice={invoice}
                    >
                      Approve
                      <Icon name="round-arrow-back-ios" className="mx-2" />
                    </ActionItem>
                  )}

                  <StyledButtonDropdown
                    isOpen={open === invoice.ref}
                    toggle={() => toggleAction(invoice.ref)}
                  >
                    <DropdownToggle
                      className="btn-more d-flex align-items-center"
                      ref={setReferenceElement}
                    >
                      <Icon name="dots-horizontal-small" />
                    </DropdownToggle>
                    {dropDownPortal(
                      <DropdownMenu
                        className="dropdown-menu"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <DropdownActionItem action={MARK_AS_PAID_BATCH_ACTION} invoice={invoice}>
                          <Icon name="circle-check" />
                          &nbsp;&nbsp;&nbsp; Mark as Paid
                        </DropdownActionItem>
                        <DropdownActionItem action={REJECT_PAYOUT_ACTION} invoice={invoice}>
                          <Icon name="cancel" />
                          &nbsp;&nbsp;&nbsp; Reject payout
                        </DropdownActionItem>

                        {(!isAdmin() || (isAdmin() && paymentToPayoneer)) && (
                          <DropdownActionItem
                            disabled={!paymentProviderActive}
                            action={
                              isInternalUser() && invoice.status === "draft"
                                ? APPROVE_CSO_PAYOUT_ACTION
                                : isPayAdmin() || isSpaceLead() || isCM()
                                ? APPROVE_BATCH_ACTION
                                : ""
                            }
                            invoice={invoice}
                          >
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Approve payout
                          </DropdownActionItem>
                        )}

                        <DropdownActionItem
                          action={EDIT_ACTION_BATCH}
                          invoice={invoice}
                          project={project}
                        >
                          <Icon name="circle-edit-outline" />
                          &nbsp;&nbsp;&nbsp; Edit
                        </DropdownActionItem>

                        <DropdownActionItem action={DELETE_ACTION_BATCH} invoice={invoice}>
                          <Icon name="delete-outline" />
                          &nbsp;&nbsp;&nbsp; Delete
                        </DropdownActionItem>
                      </DropdownMenu>,
                    )}
                  </StyledButtonDropdown>
                </div>
              )}
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="section">
      <FilterWrap className="d-flex align-items-center justify-content-end">
        <p className="space-placeholder">Space placeholder</p>

        <>
          {(isAdmin() || isPayAdmin()) && (
            <PayoutFilter
              paymentFilters={paymentFilters}
              handleApplyFilters={handleApplyFilters}
              handleClearFilters={handleClearFilters}
              handlePaymentFiltersCheck={handlePaymentFiltersCheck}
            />
          )}

          {isInternalUser() && checked.length === 0 && (
            <div className="ms-2">
              <CSVDownload type="out" filter={filter} project={project?.id || null} />
            </div>
          )}
        </>

        {checked.length !== 0 && (
          <BulkActions checked={checked} options={options} toggleAll={toggleAll} />
        )}
      </FilterWrap>

      {invoices.length === 0 ? (
        <SummaryPlaceholder
          className="page-filters-pay-summary"
          description="No payouts have been created yet"
        />
      ) : (
        <ReactTable
          scope="payout"
          tableData={getPayoutTableData()}
          tableColumns={getPayoutTableColumns(filter, project, scope)}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

const FilterWrap = styled.div`
  margin-top: -72px;
  margin-bottom: 38px;

  .space-placeholder {
    visibility: hidden;
  }
`;

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  archived: PropTypes.bool,
  filter: PropTypes.string,
  getList: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.number,
    archived: PropTypes.bool,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setcreateAction: PropTypes.func,
  onLoadMore: PropTypes.func,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

Payouts.propTypes = proptypes;

export default Payouts;
