import React, { useState } from "react";
import { FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";

const ShareShortlistForm = (props) => {
  const { id, proceed, message: propMessage } = props;
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("" || propMessage);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ email, message });
  };

  return (
    <form id={id} onSubmit={onSubmit}>
      <FormGroup>
        <label
          className="control-label"
          htmlFor="reason"
          style={{ fontSize: "14px", width: "100%" }}
        >
          Client&apos;s email address
          <span
            style={{
              color: "red",
              fontSize: "15px",
            }}
          >
            *
          </span>
        </label>
        <Input
          className="mb-3"
          id="email"
          value={email}
          type="email"
          placeholder="Enter email address"
          onChange={(e) => setEmail(e.target.value)}
          required
          data-testid="clientEmail"
        />
      </FormGroup>

      <FormGroup>
        <label
          className="control-label"
          htmlFor="reason"
          style={{ fontSize: "14px", width: "100%" }}
        >
          Message preview
          <span
            style={{
              color: "red",
              fontSize: "15px",
            }}
          >
            *
          </span>
        </label>
        <TextArea
          id="message"
          className="form-control"
          value={message}
          placeholder="Enter message"
          onChange={(e) => setMessage(e.target.value)}
          rows="5"
          required
        />
      </FormGroup>
    </form>
  );
};

ShareShortlistForm.propTypes = {
  id: PropTypes.string.isRequired,
  proceed: PropTypes.func,
  message: PropTypes.string.isRequired,
};

export default ShareShortlistForm;
