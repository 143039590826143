import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { months } from "../../../configs/constants/projectConstants";
import ArrowRight from "../../../components/ArrowRight";

import TimeSheet from "./components/TimeSheet";
import { TIME_SHEET_MONTH_VIEW } from "../../../configs/constants/global";
import {
  listTimeSheetsMonthlyEntries,
  listTimeSheetsYearlyEntries,
} from "../../../redux/actions/TimeSheetActions";
import Progress from "../../../components/Progress";
import { isDev } from "../../../utils/auth";

const Timesheets = ({ currentView, currentMonth, currentYear, gotoSelectedMonth }) => {
  const dispatch = useDispatch();

  const { project } = useSelector(({ Projects }) => Projects);
  const { user } = useSelector(({ Auth }) => Auth);
  const { isMakingRequest, yearView } = useSelector(({ TimeSheetEntries }) => TimeSheetEntries);

  useEffect(() => {
    const userId = isDev() ? user?.id : "";
    dispatch(listTimeSheetsMonthlyEntries(project.id, currentMonth, userId));
  }, [currentMonth]);

  useEffect(() => {
    dispatch(listTimeSheetsYearlyEntries(project.id, currentYear));
  }, [currentYear]);

  const monthTimeSheetList = months.map((month, i) => {
    return {
      month,
      data: yearView?.results?.find((d) => parseInt(d.month.split("-")[1], 10) === i + 1),
    };
  });

  return (
    <TimeSheetLayout>
      {currentView === TIME_SHEET_MONTH_VIEW ? (
        <>
          {isMakingRequest?.monthView ? (
            <div className="py-4">
              <Progress />
            </div>
          ) : (
            <TimeSheet currentMonth={currentMonth} currentYear={currentYear} />
          )}
        </>
      ) : (
        <>
          {isMakingRequest?.yearView ? (
            <div className="py-4">
              <Progress />
            </div>
          ) : (
            <div className="year-list-wrap d-lg-flex flex-lg-wrap justify-content-between">
              {monthTimeSheetList.map(({ month, data }, index) => {
                const isDisabled =
                  new Date().getMonth() < index && new Date().getFullYear() === +currentYear;

                return (
                  <div className={`sheet-flex ${isDisabled ? "disabled" : ""}`} key={index}>
                    <div className="month">{month}</div>
                    <div className="sheet-item-flex" key={month}>
                      {!isDisabled && (
                        <div className="rate">
                          <strong>{data?.total ?? 0}</strong>/{data?.expected_hours ?? 0} hrs
                        </div>
                      )}

                      <div className="mx-4">
                        <div className="round">
                          <input
                            type="checkbox"
                            id={month}
                            defaultChecked={data?.status === "approved"}
                            checked={data?.status === "approved"}
                            onChange={() => null}
                            readOnly
                          />
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label htmlFor={month} />
                        </div>
                      </div>

                      <button
                        type="button"
                        data-testid={`arrow-right-${month}`}
                        className="arrow btn border-0 bg-transparent p-0 d-flex justify-content-center align-items-center"
                        onClick={() => gotoSelectedMonth(month, currentYear)}
                      >
                        <ArrowRight />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </TimeSheetLayout>
  );
};

const TimeSheetLayout = styled.div`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .year-list-wrap {
    column-gap: 20px;
  }

  .total-input {
    background: #f5f7fa;
    height: 40px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
  }
  .btn-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  input,
  input:focus {
    border: none !important;
    border-color: transparent !important;
    outline: none;
    width: 100%;
  }
  .span-append {
    position: absolute;
    right: 8px;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
    margin-top: 10px;
  }
  .span-append-total {
    position: absolute;
    right: 15%;
    transform: translateY(-50%);
    padding: 4px 40px;
    color: #777;
    border-radius: 8px;
    margin-top: 20px;
  }
  .inp-div {
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    position: relative;
    padding: 11px 16px;
    width: 30rem;
  }
  .disabled {
    pointer-events: none;
    background: rgba(255, 255, 255, 0.45) !important;
  }
  .right-nav {
    background: #ffffff;
    display: absolute;
  }
  .rate {
    background: rgba(21, 26, 48, 0.05);
    border: 1px solid rgba(21, 26, 48, 0.04);
    padding: 5px 20px;
    border-radius: 20px;
    color: #3e4857;

    strong {
      color: #151a30;
      font-weight: 600;
    }
  }
  .arrow {
    cursor: pointer;
    margin-top: 3px;
    margin-left: 20px;
    height: 24px;
  }
  .sheet-flex {
    background: #ffffff;
    display: flex;
    border: 1px solid #edf1f7;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-radius: 6px;
    padding: 0px 20px;
    margin: 10px 0px;

    @media only screen and (min-width: 992px) {
      width: 48.5%;
    }

    .month {
      font-weight: 600;
      color: #151a30;
    }

    &.disabled .month {
      opacity: 0.45;
    }
  }

  .sheet-item-flex {
    display: flex;
  }
  button.timesheet {
    background: #da3451;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 26px;
    left: 0;
    position: absolute;
    top: 0;
    width: 26px;
    margin-top: 2px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #062e64;
    border-color: #062e64;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

Timesheets.propTypes = {
  currentView: PropTypes.string,
  currentYear: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
  currentMonth: PropTypes.string,
  gotoSelectedMonth: PropTypes.func,
};

export default Timesheets;
