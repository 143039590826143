const CATEGORIES = {
  dashboard: "DASHBOARD",
  projects: "PROJECTS",
  payments: "PAYMENTS",
};

const EVENTS = {
  click: "click",
  setup_payoneer: "setup_payoneer",
  go_to_profile_settings: "go_to_profile_settings",
  click_upcoming_payment_for: "click_upcoming_payment_for",
  click_scheduled_update_for: "click_scheduled_update_for",
  close_notification: "close_notification",
  clear_all_recent_notification: "clear_all_recent_notification",
  click_active_project: "click_active_project",
  click_active_project_settings: "click_active_project_settings",
  click_create_new_opportunity_button: "click_create_new_opportunity_button",
  create_new_opportunity: "create_new_opportunity",
  view_project: "view_project",
  view_opportunity: "view_opportunity",
  accept_invite: "accept_invite",
  activate_project: "activate_project",
  log_in: "Log In",
  view_invoice_history: "View Invoice History",
  create_new_payment: "Create New Payment",
  click_send_for_approval_action: "Click send for approval action",
  click_approve_invoice_action: "Click approve invoice action",
  click_reject_invoice_action: "Click reject invoice action",
  click_edit_invoice_action: "Click edit invoice action",
  click_generate_invoice_action: "Click generate invoice action",
  click_delete_invoice_action: "Click delete invoice action",
  click_send_invoice_reminder_action: "Click send invoice reminder action",
  click_mark_invoice_as_paid_action: "Click mark invoice as paid action",
  click_archive_action: "Click archive action",
  click_overdue_filter: "Click filter by overdue ",
  click_pending_filter: "Click filter by pending",
  click_paid_filter: "Click filter by paid ",
  click_credit_note_filter: "Click filter by credit note ",
  click_commitment_filter: "Click filter by commitment",
  click_archived_filter: "Click filter by archived",
  click_all_filter: "Click filter by all",
};

export { CATEGORIES, EVENTS };
