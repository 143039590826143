const DASHBOARD = "Dashboard";
const PAYMENTS = "Payments";
const PROJECTS = "Projects";
const SETTINGS = "Settings";
const REFER_AND_EARN = "Refer And Earn";
const COMMUNITY_GUIDE = "Community Guide";
const ONBOARDING = "Onboarding";

const pages = {
  DASHBOARD,
  PAYMENTS,
  PROJECTS,
  SETTINGS,
  REFER_AND_EARN,
  COMMUNITY_GUIDE,
  ONBOARDING,
};
export { pages };
