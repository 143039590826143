/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Iban from "../../../components/Payment/Iban";
import Payoneer from "../../../components/Payment/Payoneer";
import Wise from "../../../components/Payment/Wise";
import Progress from "../../../components/Progress";
import {
  fetchIban,
  fetchPaymentProviders,
  fetchPayoneer,
  fetchWise,
} from "../../../redux/actions/PaymentActions";

const col = "col-lg-4 col-md-4 col-sm-12";

const Payment = ({ fromSetup = false }) => {
  const dispatch = useDispatch();

  const { isMakingRequest } = useSelector(({ Payments }) => Payments);

  useEffect(() => {
    dispatch(fetchPaymentProviders());
    dispatch(fetchIban());
    dispatch(fetchWise());
    dispatch(fetchPayoneer());
  }, []);

  if (!fromSetup && (isMakingRequest.fetchWise || isMakingRequest.getPayoneer)) {
    return <Progress />;
  }

  return (
    <PaymentCard>
      <div className="d-flex align-items-start">
        <div className="payment-header">
          <h4>Payment methods</h4>
          <p className="m-0">
            {fromSetup
              ? "How do you like to get paid?"
              : "All payouts will be sent to your default method"}
          </p>
        </div>
      </div>

      <div className="row payment-row">
        <div className={col}>
          <Payoneer />
        </div>

        <div className={col}>
          <Wise />
        </div>

        <div className={col}>
          <Iban />
        </div>
      </div>
    </PaymentCard>
  );
};

const PaymentCard = styled.div`
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #151a30;
  }

  a {
    box-shadow: none !important;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: #3e4857;
  }

  .payment {
    &-header {
      flex: 1;
    }

    &-row {
      padding-top: 2.635rem;
    }
  }
`;

export default React.memo(Payment);
