import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertDialogue from "../components/AlertDialogue";
import PayoneerForm from "../components/Payment/PayoneerForm";
import PayoneerHandler from "../components/Payment/PayoneerHandler";
import { addPayoneer, fetchPaymentProviders, fetchPayoneer } from "../redux/actions/PaymentActions";
import { openAlert, openModal } from "../utils/modals";

const useConnectPayoneer = () => {
  const dispatch = useDispatch();

  const { payoneerProvider, errors } = useSelector(({ Payments }) => Payments);

  const [submitError, setSubmitError] = useState(null);
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);

  const [payload, setPayload] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const connectPayoneer = () => {
    setSubmitError(null);
    openModal({
      body: <PayoneerForm id="payoneer-connect-form" payload={payload} submitError={submitError} />,
      title: `Connect Payoneer`,
      canClose: true,
      options: {
        className: "modal-payments",
        ok: "Connect",
        form: {
          type: "submit",
          form: "payoneer-connect-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      header: null,
      hideActions: false,
    }).then((data) => {
      setSubmitError(null);
      setPayload(data);
      dispatch(addPayoneer(data));
    });
  };

  const openConnectionHandler = () => {
    openModal({
      body: (
        <PayoneerHandler
          message={payoneerProvider.message}
          redirect_url={payoneerProvider.redirect_url}
        />
      ),
      title: `Connect Payoneer`,
      canClose: true,
      options: {
        className: "modal-payments",
        style: {
          paddingBottom: 0,
        },
      },
      header: null,
      hideActions: true,
    });
  };

  // Handle success
  useEffect(() => {
    if (payoneerProvider.success) {
      if (
        payoneerProvider?.message?.toLowerCase() ===
        "payoneer account is already connected and is active"
      ) {
        openAlert({
          body: <AlertDialogue msg={payoneerProvider.message} />,
          canClose: false,
          options: {
            className: "alert-dailogue",
            hideActions: true,
            hideBackdrop: true,
          },
        });
        dispatch(fetchPayoneer({ silent: true }));
        dispatch(fetchPaymentProviders());
        setShouldTriggerPrompt(true);
      } else {
        openConnectionHandler();
      }
    }
  }, [payoneerProvider?.success]);

  useEffect(() => {
    if (submitError) {
      connectPayoneer();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.addPayoneer) {
      setSubmitError(errors.addPayoneer);
    }
  }, [errors.addPayoneer]);

  return { connectPayoneer, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectPayoneer;
