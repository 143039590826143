/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

/* -------------------- Internel Dependencies (Utilites) -------------------- */

import {
  isAdmin,
  isAdminOrClient,
  isPMAndHasProjectAcess,
  isDevOrClient,
} from "../../../../utils/auth";
import SummaryPlaceholder from "../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import Icon from "../../../../components/Icon";
import IconButton from "../../../../components/IconButton";
import { onManageSchedule } from "../../../../utils/projectUtils";
import { fetchProject } from "../../../../redux/actions/ProjectActions";
import usePrevious from "../../../../hooks/usePrevious";

/* ------------------------- Component dependencies ------------------------- */

const Planning = () => {
  const dispatch = useDispatch();
  const { project, isMakingRequest } = useSelector(({ Projects }) => Projects);
  const prevIsMakingRequest = usePrevious(isMakingRequest);
  const hasAccess = isAdminOrClient(project) || isPMAndHasProjectAcess(project);

  useEffect(() => {
    if (
      prevIsMakingRequest?.updateDocument ||
      prevIsMakingRequest?.createEvent ||
      prevIsMakingRequest?.updateEvent
    ) {
      dispatch(fetchProject(project.id));
    }
  }, [isMakingRequest]);

  return (
    <div className="project-planning">
      {!project.start_date && !project.deadline && isDevOrClient() && !isAdmin() ? (
        <SummaryPlaceholder className="page" description="No planning available yet." />
      ) : (
        <div className="section">
          <div className="section-title">
            <span>Timeline</span>

            {hasAccess && ((!project.archived && !project.start_date) || !project.deadline) && (
              <button type="button" className="add-btn" onClick={() => onManageSchedule(project)}>
                <Icon name="round-add" size="sm" />
                &nbsp;&nbsp;Add New
              </button>
            )}
          </div>

          {project.start_date && project.deadline && (
            <Timeline>
              <div>{moment(project.start_date).format("Do MMM YYYY")}</div>
              <div>-</div>
              <div>{moment(project.deadline).format("Do MMM YYYY")}</div>

              {hasAccess && !project.archived && (
                <IconButton
                  name="circle-edit-outline"
                  size="main"
                  className="mgd-schedule"
                  onClick={() =>
                    onManageSchedule(project, {
                      start_date: project.start_date,
                      deadline: project.deadline,
                    })
                  }
                />
              )}
            </Timeline>
          )}
        </div>
      )}
    </div>
  );
};

const Timeline = styled.div`
  display: flex;

  div {
    width: 195px;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 24px;
    color: #3e4857;
    background: #ffffff;
    border: 1px solid rgba(194, 204, 217, 0.25);
    border-radius: 4px;

    &:nth-child(2) {
      width: fit-content;
      background: transparent;
      border: none;
    }
  }

  button {
    margin-left: 26px;
    i {
      color: #8f9bb3;
    }
  }
`;

export default Planning;
