/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Route, Switch, withRouter, useLocation, useRouteMatch } from "react-router-dom";
import _ from "lodash";
/* -------------------------- Internal Dependencies ------------------------- */
import DetailsForm from "./dedicatedProjects/DetailsForm";
import Documentation from "./dedicatedProjects/Documentation";
import TeamForm from "./dedicatedProjects/TeamForm";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";

import { TempProjectProvider, TempProjectContext } from "./TempProjectContext";
import { createProject } from "../../../../redux/actions/ProjectActions";
import { eventTrack } from "../../../../utils/analytics";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";

const ProjectWizard = ({ setWizard, history }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();

  let current = 1;
  let title = "";
  let steps = 3;

  useEffect(() => {
    /* istanbul ignore next */
    setWizard({
      title,
      steps,
      current,
    });
  }, [location.pathname]);

  const isDedicatedProject = () => {
    return location.pathname.includes("dedicated");
  };

  const isOpportunity = () => {
    return location.pathname.includes("opportunity");
  };

  const next = (path) => {
    history.push(path);
  };

  const back = () => {
    history.goBack();
  };

  const exp =
    /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; // email validation

  const createOpportunity = (data) => {
    eventTrack(CATEGORIES.projects, `${EVENTS.create_new_opportunity}: ${data.title}`);
    dispatch(createProject(data));
  };

  // dedicated project handler
  /* istanbul ignore next */
  const saveDedicatedProject = (data) => {
    // let projectData;
    const res = _.omit(data, [
      "payment_type",
      "hours_per_week",
      "payout_rate",
      "client_rate",
      "amount",
    ]);
    const projectData = {
      ...res,
      participation: data.participation?.map((userParticipation) => ({
        user: {
          id: userParticipation.id,
        },
        payment_structure: {
          hours_per_week: userParticipation.hours_per_week,
          payout_rate: userParticipation.payout_rate,
          client_rate: userParticipation.client_rate,
        },
      })),
    };
    if (projectData.cm) {
      projectData.cm = { id: projectData.cm.id };
    }
    if (projectData.cso) {
      projectData.cso = { id: projectData.cso.id };
    }
    if (projectData.owner) {
      projectData.owner = { id: projectData.owner.id };
    }
    dispatch(createProject(projectData));
  };

  return (
    <StyledContainerCard className="content-wrapper container">
      <TempProjectProvider>
        <div className="create-content">
          <Switch>
            {[
              ...(isDedicatedProject()
                ? [
                    ["dedicated/details", <DetailsForm category="dedicated" />],
                    ["dedicated/documentation", <Documentation category="dedicated" />],
                    ["dedicated/team", <TeamForm category="dedicated" />],
                  ]
                : []),
              ...(isOpportunity() ? [["opportunity", <DetailsForm stage="opportunity" />]] : []),
            ].map((path, i) => {
              return (
                <Route
                  key={`create-container-path--${i}`}
                  path={`${match.path}/${path[0]}`}
                  render={() => path[1]}
                />
              );
            })}
          </Switch>
        </div>
        <div className="content-nav">
          <Switch>
            <Route
              path="/projects/new/opportunity"
              component={
                /* istanbul ignore next */
                () => {
                  title = "Create New Opportunity";
                  steps = 1;
                  const [projectData] = useContext(TempProjectContext);

                  const disableNext = !(
                    projectData.title &&
                    projectData.description &&
                    projectData.category &&
                    !projectData?.error?.contact_email &&
                    projectData.expected_start_date &&
                    (projectData.expected_end_date == null ||
                      projectData.expected_end_date !== +new Date(2099, 11, 31))
                  );

                  return (
                    <>
                      <div />
                      <Button
                        className="next"
                        onClick={() => createOpportunity(projectData)}
                        disabled={disableNext}
                      >
                        Create Opportunity
                      </Button>
                    </>
                  );
                }
              }
            />
            <Route
              path="/projects/new/dedicated/:step"
              component={
                /* istanbul ignore next */
                ({ match }) => {
                  const [projectData] = useContext(TempProjectContext);
                  let showBack = true;
                  let lastStep = false;
                  let nextStepPath = "";
                  title = "Create New Dedicated Developer Project";
                  steps = 3;

                  // TODO: solve back issue where someone goes directly to like team route.
                  switch (match.params.step) {
                    case "details":
                      showBack = false;
                      nextStepPath = "/projects/new/dedicated/documentation";
                      current = 1;
                      break;
                    case "documentation":
                      nextStepPath = "/projects/new/dedicated/team";
                      current = 2;
                      break;
                    case "team":
                      lastStep = true;
                      current = 3;
                      break;
                    default:
                      break;
                  }
                  let disableNext;
                  current === 1
                    ? (disableNext = !(
                        projectData.title &&
                        projectData.description &&
                        projectData.expected_start_date &&
                        projectData.type &&
                        (projectData.expected_end_date == null ||
                          projectData.expected_end_date !== +new Date(2099, 11, 31))
                      ))
                    : current === 2
                    ? (disableNext = !(
                        projectData.title &&
                        projectData.currency &&
                        projectData.invoicing_email &&
                        exp.test(projectData.invoicing_email)
                      ))
                    : current === 3
                    ? (disableNext = !projectData.title)
                    : null;
                  return (
                    <>
                      {showBack ? (
                        <Button className="previous" onClick={() => back()}>
                          <Icon name="arrow-up-left" />
                          Previous Step
                        </Button>
                      ) : (
                        <div />
                      )}
                      {lastStep ? (
                        <Button
                          className="next"
                          onClick={() => saveDedicatedProject(projectData)}
                          disabled={disableNext}
                        >
                          Create Project
                        </Button>
                      ) : (
                        <div>
                          <Button
                            className="next"
                            onClick={() => next(nextStepPath)}
                            disabled={disableNext}
                          >
                            Next
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }
              }
            />
          </Switch>
        </div>
      </TempProjectProvider>
    </StyledContainerCard>
  );
};

const StyledContainerCard = styled.div`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content-nav {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    background: rgba(237, 241, 247, 0.25);

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #151a30;
      margin-right: 16px;
    }

    button.previous {
      background: transparent;
      border: none;
      box-shadow: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #062e64;
      padding-left: 0;

      i {
        vertical-align: baseline;
        font-size: 14px;
        margin-right: 12px;
      }
    }

    button.next {
      background: #da3451;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
    .next:disabled {
      cursor: not-allowed;
    }
  }
`;

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  setWizard: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

ProjectWizard.propTypes = proptypes;

export default withRouter(ProjectWizard);
