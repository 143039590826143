/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { DropdownToggle, DropdownMenu } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

/* -------------------------- Internal dependencies ------------------------- */
import Icon from "../../../components/Icon";
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import InvoiceHistory from "../../../components/InvoiceHistory";

import {
  ENDPOINT_INVOICES,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_SALE,
  INVOICE_TYPE_COMMITMENT,
} from "../../../utils/api";
import {
  getTableColumns,
  showAction,
  tableData,
  Reminder,
  onCreateInvoice,
} from "../../../utils/invoiceUtils";
import PaymentStatus from "./components/PaymentStatus";
import ActionItem from "./components/ActionItem";

import {
  GENERATE_INVOICE_ACTION,
  MARK_AS_PAID_ACTION,
  ARCHIVE_ACTION,
  SEND_REMINDER,
  EDIT_ACTION,
  DELETE_ACTION,
  APPROVE_ACTION,
  REJECT_ACTION,
  SEND_FOR_APPROVAL_ACTION,
} from "../../../configs/constants/invoiceConstants";

/* --------------------------- Styles dependencies -------------------------- */
import { StyledButtonDropdown } from "./styles";
import useDropdownPopper from "../../../hooks/useDropdownPopper";
import DropdownActionItem from "./components/DropdownActionItem";
import ReactTable from "../../../components/ReactTable";
import BulkActions from "../../../components/BulkActions";

import {
  isAdmin,
  isCMOrCSOAndHasProjectAcess,
  isPayAdmin,
  isAdminOrPMOrSpaceLead,
  isCSO,
  isInternalUser,
} from "../../../utils/auth";
import CSVDownload from "./components/CSVDownload";
import { trackEvent } from "../../../analytics/segment";
import { EVENTS } from "../../../analytics/events";

const Payments = (props) => {
  const { status } = useParams();
  const {
    filter,
    data: invoices,
    isSaving,
    count,
    onLoadMore,
    currentPage,
    project,
    setcreateAction,
    pageSize,
  } = props;

  const [open, setopen] = useState(null);
  const [checked, setChecked] = useState([]);
  const { open: openSideBar } = useRightNav();
  const { styles, attributes, setPopperElement, setReferenceElement, dropDownPortal } =
    useDropdownPopper();
  const { errors } = useSelector(({ Invoice }) => Invoice);

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";

  const checkItem = (item) => {
    let newArr = [];

    if (!checked.includes(item)) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a !== item);
    }
    setChecked(newArr);
  };

  /* istanbul ignore next */
  const toggleAll = (state) => {
    const checkboxes = document.querySelectorAll("[name=check-it]");
    const checkedBoxMapped = Array.from(checkboxes).map((checkbox) => {
      checkbox.checked = state;
      const found = invoices.find((fp) => fp.id === +checkbox.id);
      return found;
    });

    if (state) {
      setChecked(checkedBoxMapped);
    } else {
      setChecked([]);
    }
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  useEffect(() => {
    if (Object.keys(errors).length && errors?.create?.data) {
      onCreateInvoice(errors.create.data.type, {
        ...props,
        data: errors.create.data,
        errors: errors.create,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (setcreateAction)
      setcreateAction({
        // If on dashboard any CSO can initiate creation of payment
        ...(!project ||
        ((isPayAdmin() || isAdmin() || isCMOrCSOAndHasProjectAcess(project)) && !project?.archived)
          ? {
              visibility: true,
              add: [
                {
                  title: "Add New Payment",
                  action: () => onCreateInvoice(INVOICE_TYPE_SALE, props),
                },
                {
                  title: "Add Credit Note",
                  action: () => onCreateInvoice(INVOICE_TYPE_CREDIT_NOTE, props),
                },
                {
                  title: "Add Commitment",
                  action: () => onCreateInvoice(INVOICE_TYPE_COMMITMENT, props),
                },
                {
                  title: "Add Final Invoice",
                  action: () => onCreateInvoice(INVOICE_TYPE_FINAL, props),
                },
              ],
            }
          : { visibility: false }),
      });
  }, [project]);

  const checkIfFinalised = () => {
    if (checked.filter((e) => e.finalized).length > 0) {
      return true;
    }
    return false;
  };

  const canGenerateInvoice = checked.filter(
    (invoice) => !invoice.finalized && !invoice.last_sent_at && !invoice.paid,
  ).length;

  const canMarkAsPaid = () => {
    if (checked.filter((e) => !e.finalized).length > 0) {
      return false;
    }
    return true;
  };

  const options = [
    !checkIfFinalised() &&
      canGenerateInvoice > 0 && {
        id: "generate",
        name: "Generate Invoice",
        value: "generate",
      },
    canMarkAsPaid() && {
      id: "mark_as_paid",
      name: "Mark as paid",
      value: "mark_as_paid",
    },
    canMarkAsPaid() && {
      id: "send_reminder",
      name: "Send Reminder",
      value: "send_reminder",
    },
    status !== "archived" && {
      id: "archive",
      name: "Archive Invoice",
      value: "archive",
    },

    !checkIfFinalised() && {
      id: "delete",
      name: "Delete Invoice",
      value: "delete",
    },
  ];

  const handleInvoiceHistory = (invoice) => {
    trackEvent(EVENTS.view_invoice_history, {
      invoice_id: invoice.id,
      invoice_title: invoice.full_title,
    });
    openSideBar(<InvoiceHistory selected_invoice={invoice} type="payment" />, { width: "450px" });
  };

  const getTableDisplayValue = (cell) => {
    const invoice = cell?.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    // eslint-disable-next-line default-case
    switch (cell.column.id) {
      case "batch_action": {
        return (
          <td key={`batch_action${key}`}>
            {!invoice.paid && (
              <input
                type="checkbox"
                name="check-it"
                className="custom-checkbox"
                data-testid={`checkbox-${key}`}
                id={invoice.id}
                onClick={() => checkItem(invoice)}
              />
            )}
          </td>
        );
      }
      case "created_at": {
        return (
          <td key={`created_at${key}`} className="nowrap">
            {moment.utc(invoice).format("DD MMM YYYY")}
          </td>
        );
      }
      case "title": {
        return (
          <td className="nowrap" key={`title${key}`}>
            {isAdminOrPMOrSpaceLead() || isCSO() ? (
              <button
                type="button"
                className="btn-trans text-capitalize line-bottom"
                onClick={() => handleInvoiceHistory(invoice)}
              >
                {invoice?.title}
              </button>
            ) : (
              invoice.title
            )}
          </td>
        );
      }
      case "client": {
        return (
          <td key={`client${key}`} className="nowrap">
            {cell.value.project?.owner ? cell.value.project?.owner?.display_name : "N/A "}
          </td>
        );
      }
      case "project": {
        return (
          <td className="nowrap" key={`project${key}`}>
            <Link className="line-bottom" to={`/projects/${invoice.project.id}/pay/payments/all`}>
              {invoice?.project?.title || "N/A"}
            </Link>
          </td>
        );
      }
      case "invoice": {
        return (
          <td key={`invoice${key}`} className="nowrap">
            {invoice.finalized ? (
              <a
                className="line-bottom"
                href={`${ENDPOINT_INVOICES}${invoice.id}/download/?format=pdf`}
                target="_blank"
                rel="noreferrer"
              >
                {invoice.number}
              </a>
            ) : (
              <b>N/A</b>
            )}
          </td>
        );
      }
      case "amount": {
        const currency = invoice.currency === "USD" ? "$" : "€";

        return (
          <td key={`amount${key}`} className="nowrap">
            {`${invoice.type === INVOICE_TYPE_CREDIT_NOTE ? "-" : ""}${currency}${
              invoice.subtotal
            }`}
          </td>
        );
      }

      case "reminder_count": {
        return (
          <>
            {isAdmin() ? (
              <Tip className="reminder-last-sent-text" key={`reminder_count${key}`}>
                <div
                  data-tooltip={
                    invoice.reminder_count > 0
                      ? moment.utc(invoice.reminder_sent_at).format("DD MMM YYYY")
                      : "No Reminder Sent"
                  }
                >
                  {invoice.reminder_count}
                </div>
              </Tip>
            ) : null}
          </>
        );
      }
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(invoice.due_at).format("DD MMM YYYY")}
            {new Date(`${invoice.due_at}`) < new Date() && !invoice.paid && (
              <Icon className="danger" name="exclamation-triangle" />
            )}
          </td>
        );
      }
      case "status": {
        return (
          <td key={`status${key}`} className="nowrap">
            <PaymentStatus invoice={invoice} isSaving={isSaving} />
          </td>
        );
      }
      case "email": {
        const val = cell.value;
        return (
          <td key={`email${key}`} className="nowrap">
            {val === "failed" ? (
              <span className="overdue" style={{ padding: 6 }}>
                Failed
              </span>
            ) : val === "sent" ? (
              <span className="completed" style={{ padding: 6 }}>
                Sent
              </span>
            ) : val === "pending" ? (
              <span className="pending" style={{ padding: 6 }}>
                Pending
              </span>
            ) : null}
          </td>
        );
      }
      case "actions": {
        const cellValue = cell.value;
        return (
          <td key={`actions${key}`} className="cta nowrap">
            <div className="cta-wrapper">
              <ActionItem
                className="line-bottom"
                {...props}
                action={GENERATE_INVOICE_ACTION}
                invoice={cellValue}
              >
                Generate Invoice
                <Icon name="round-arrow-back-ios" className="mx-2" />
              </ActionItem>

              {(showAction(MARK_AS_PAID_ACTION, cellValue) ||
                showAction(ARCHIVE_ACTION, cellValue) ||
                showAction(EDIT_ACTION, cellValue) ||
                showAction(DELETE_ACTION, cellValue) ||
                showAction(APPROVE_ACTION, cellValue) ||
                showAction(REJECT_ACTION, cellValue) ||
                showAction(SEND_FOR_APPROVAL_ACTION, cellValue)) && (
                <StyledButtonDropdown
                  isOpen={open === cellValue.id}
                  toggle={() => toggleAction(cellValue.id)}
                >
                  <DropdownToggle ref={setReferenceElement}>
                    <Icon name="dots-horizontal-small" />
                  </DropdownToggle>
                  {dropDownPortal(
                    <DropdownMenu
                      className="dropdown-menu"
                      ref={setPopperElement}
                      style={styles.popper}
                      {...attributes.popper}
                    >
                      <DropdownActionItem
                        {...props}
                        action={MARK_AS_PAID_ACTION}
                        invoice={cellValue}
                      >
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Mark as Paid
                      </DropdownActionItem>
                      <DropdownActionItem {...props} action={SEND_REMINDER} invoice={cellValue}>
                        <Reminder />
                        &nbsp;&nbsp;&nbsp; Send Reminder
                      </DropdownActionItem>

                      <DropdownActionItem {...props} action={ARCHIVE_ACTION} invoice={cellValue}>
                        <Icon name="archive-outline" />
                        &nbsp;&nbsp;&nbsp; Archive
                      </DropdownActionItem>

                      <DropdownActionItem
                        {...props}
                        action={SEND_FOR_APPROVAL_ACTION}
                        invoice={cellValue}
                      >
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Send for approval
                      </DropdownActionItem>

                      <DropdownActionItem {...props} action={APPROVE_ACTION} invoice={cellValue}>
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Approve
                      </DropdownActionItem>

                      <DropdownActionItem {...props} action={REJECT_ACTION} invoice={cellValue}>
                        <Icon name="cancel" />
                        &nbsp;&nbsp;&nbsp; Reject
                      </DropdownActionItem>

                      <DropdownActionItem {...props} action={EDIT_ACTION} invoice={cellValue}>
                        <Icon name="circle-edit-outline" />
                        &nbsp;&nbsp;&nbsp; Edit
                      </DropdownActionItem>

                      <DropdownActionItem
                        {...props}
                        action={DELETE_ACTION}
                        invoice={{ ...cellValue, scope, project }}
                      >
                        <Icon name="delete-outline" />
                        &nbsp;&nbsp;&nbsp; Delete
                      </DropdownActionItem>
                    </DropdownMenu>,
                  )}
                </StyledButtonDropdown>
              )}
            </div>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="section">
      <FilterWrap className="d-flex justify-content-end">
        <p className="space-placeholder">Space placeholder</p>

        <>
          {checked.length !== 0 && (
            <BulkActions checked={checked} options={options} toggleAll={toggleAll} />
          )}
          {isInternalUser() && checked.length === 0 && (
            <div className="ms-2">
              <CSVDownload type="in" filter={filter} project={project?.id || null} />
            </div>
          )}
        </>
      </FilterWrap>

      {invoices.length === 0 ? (
        <SummaryPlaceholder
          className="page-filters-pay-summary"
          description="No payments have been created yet"
        />
      ) : (
        <ReactTable
          scope="payment"
          tableData={tableData(invoices)}
          tableColumns={getTableColumns(filter, project, scope)}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  filter: PropTypes.string,
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape({}),
  isSaving: PropTypes.shape({}),
  count: PropTypes.number,
  exportCsv: PropTypes.func,
  onLoadMore: PropTypes.func,
  trackPagination: PropTypes.func,
  currentPage: PropTypes.number,
  setcreateAction: PropTypes.func,
};

Payments.propTypes = proptypes;
Payments.defaultProps = {
  project: null,
};

const Tip = styled.td`
  border-bottom: 1px solid #edf1f7;
  margin-left: -17px;

  *[data-tooltip] {
    position: relative;
  }

  *[data-tooltip]::after {
    content: attr(data-tooltip);

    position: absolute;
    top: -10px;
    right: -10px;
    width: 90px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;

    font-size: 12px;
    line-height: 16px;
    background: #edf1f7;
    padding: 2px 2px;
    border-radius: 3px;
    border: 1px solid #c0c0c0;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
  }

  *[data-tooltip]:hover::after {
    opacity: 1;
  }

  &.reminder-last-sent-text {
    padding-left: -24px !important;
  }
`;

const FilterWrap = styled.div`
  margin-top: -72px;
  margin-bottom: 38px;

  .space-placeholder {
    visibility: hidden;
  }
`;
export default Payments;
