import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";

import SmileysModal from "../../../../../../components/SmileysModal";
import { submitDeveloperRating } from "../../../../../../redux/actions/ProjectActions";
import { getUser } from "../../../../../../utils/auth";
import { pxToRem } from "../../../../../../assets/theme/functions";

const DeveloperSurvey = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();

  const [rating, setRating] = useState(null);
  const [ratingId, setRatingId] = useState(null);

  const { progress_event, isMakingRequest } = useSelector(({ Projects }) => Projects);
  const { project } = progress_event;

  const onChange = (value) => {
    setRating(value);
  };

  const onSave = (e) => {
    e.preventDefault();

    const payload = {
      event: {
        id: params.report,
      },
      rating,
      user: getUser().id,
      id: ratingId,
    };

    dispatch(submitDeveloperRating(payload));
  };

  useEffect(() => {
    if (progress_event.developer_ratings && progress_event.developer_ratings.length) {
      let ratingValue;
      let ratingIdValue;

      progress_event.developer_ratings.forEach((developer_rating) => {
        if (developer_rating.created_by.id === getUser().id) {
          ratingValue = `${developer_rating.rating}`;
          ratingIdValue = `${developer_rating.id}`;
        }
      });

      setRating(ratingValue);
      setRatingId(ratingIdValue);
    }
  }, [progress_event?.developer_ratings]);

  return (
    <Wrapper data-testid="developerSurvey-form" className="container" onSubmit={onSave}>
      <div className="content">
        <div className="card">
          <div className="card-body">
            <div className="card__section">
              <h4>
                How would you rate your collaboration with{" "}
                <b>{project?.category === "dedicated" ? progress_event?.project.title : "Tunga"}</b>{" "}
                on this project?
              </h4>
              <SmileysModal name="rating" selected={rating} onChange={onChange} />
            </div>
          </div>

          <div className="card-bottom">
            <div className="card-body">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  isMakingRequest.developerRating || rating === "null" || Number(rating) < 1
                }
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content {
    padding: 0px;
  }

  .card {
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .card-bottom {
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;
    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .card__section {
    padding: 2.4rem 1rem;
    border-bottom: 1px solid #edf1f7;
    &:last-child {
      border: 0px solid #edf1f7;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 150%;
      margin-bottom: 0.9rem;
      color: #151a30;
    }
  }
`;

export default DeveloperSurvey;
