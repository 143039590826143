/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "../../../../components/Icon";
import TermsNdConditions from "../../../../components/TermsNdConditions";
import { openModal } from "../../../../utils/modals";
import { ModalHeader } from "../../../../components/ModalHeader";

const AcceptInvite = ({ action }) => {
  const category = window.localStorage.getItem("tempProjectCategory");

  const [consentValues, setConsentValues] = useState([]);

  const onChangeConsentValue = (e, value) => {
    const idx = consentValues.indexOf(value);

    if (e.target.checked) {
      if (idx === -1) {
        setConsentValues([...consentValues, value]);
      }
    } else if (idx > -1) {
      const newValues = consentValues;
      newValues.splice(idx, 1);
      setConsentValues([...newValues]);
    }
  };

  const openTCs = (e) => {
    e.preventDefault();
    openModal({
      body: <TermsNdConditions />,
      title: "",
      canClose: true,
      options: {
        className: "modal-tcs",
        hideActions: true,
      },
      header: (
        <ModalHeader style={{ paddingBottom: "8px" }} options={{ title: "Terms & Conditions" }} />
      ),
      hideActions: false,
    });
  };

  useEffect(() => {
    action({
      saveDisabled:
        category === "dedicated" ? consentValues.length !== 5 : consentValues.length !== 4,
    });
  }, [consentValues]);

  return (
    <Wrapper>
      <div className="header">
        To join this <b>{category === "dedicated" ? "Dedicated" : "Managed"} Project</b>, please
        read and accept the following:
      </div>

      <form>
        <FormGroup>
          <FormCheck>
            <span>
              I formally accept the <b>Terms & Conditions</b> provided by Tunga.
              <a onClick={openTCs} className="d-flex align-items-center" href="/#" rel="noreferrer">
                View Terms & Conditions <Icon name="arrow-right" size="sm" />
              </a>
            </span>
            <input
              type="checkbox"
              id="terms-and-conditions"
              aria-label="terms-and-conditions"
              onChange={(e) => onChangeConsentValue(e, "terms-and-conditions")}
            />
            <label htmlFor="terms-and-conditions" />
          </FormCheck>
          <FormCheck>
            <span>
              I have read and understood the <b>Tunga Developer Guide.</b>
              <a
                target="_blank"
                className="d-flex align-items-center"
                href="https://tunga.io/developer-agreement/"
                rel="noreferrer"
              >
                View Developer Guide <Icon name="arrow-right" size="sm" />
              </a>
            </span>
            <input
              type="checkbox"
              id="developer-guidelines"
              aria-label="developer-guidelines"
              onChange={(e) => onChangeConsentValue(e, "developer-guidelines")}
            />
            <label htmlFor="developer-guidelines" />
          </FormCheck>
          {category === "dedicated" && (
            <FormCheck>
              <span>I accept the weekly number of hours assigned to me.</span>
              <input
                type="checkbox"
                id="hourly-rate"
                aria-label="hourly-rate"
                onChange={(e) => onChangeConsentValue(e, "hourly-rate")}
              />
              <label htmlFor="hourly-rate" />
            </FormCheck>
          )}
          <FormCheck>
            <span>I understand that missing progress reports will have consequences.</span>
            <input
              type="checkbox"
              id="progress-reports"
              aria-label="progress-reports"
              onChange={(e) => onChangeConsentValue(e, "progress-reports")}
            />
            <label htmlFor="progress-reports" />
          </FormCheck>
          <FormCheck>
            <span>
              I understand that I must always notify my contact at Tunga regarding sick days, leave
              days, or any disputes with other developers or the client.{" "}
            </span>
            <input
              type="checkbox"
              id="tunga-communications"
              aria-label="tunga-communications"
              onChange={(e) => onChangeConsentValue(e, "tunga-communications")}
            />
            <label htmlFor="tunga-communications" />
          </FormCheck>
        </FormGroup>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .header {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 29px;
    color: #3e4857;
    b {
      font-weight: 600;
    }
  }
`;

const FormCheck = styled.div`
  position: relative;
  padding-left: 30px;
  margin-bottom: 24px;

  &.disabled {
    span {
      font-weight: 100;
    }

    label {
      opacity: 0.4;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: #3e4857;

    b {
      font-weight: 600;
    }

    a {
      float: right;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #da3451;
      text-decoration: none;

      i {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  label {
    background: transparent;
    border: 2px solid #8f9bb3;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    color: #8f9bb3;
    margin-top: 2px;
  }

  label:after {
    border-width: 2px;
    border-style: none none solid solid;
    content: "";
    height: 5px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 5px;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari and Chrome */
    transform: rotate(-45deg);
    width: 12px;
  }
`;

AcceptInvite.propTypes = {
  action: PropTypes.func,
};

export default AcceptInvite;
