import { parseInt } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "../../../../../components/Avatar";
import Icon from "../../../../../components/Icon";
import IconButton from "../../../../../components/IconButton";
import { ModalHeader } from "../../../../../components/ModalHeader";
import ProjectMemberForm from "../../../../../components/ProjectMemberForm";
import { deleteParticipation, updateProject } from "../../../../../redux/actions/ProjectActions";
import { isAdmin, isCMOrCSOAndHasProjectAcess } from "../../../../../utils/auth";
import { openConfirm, openModal } from "../../../../../utils/modals";
import { generateUserInitials } from "../../../../../utils/stringUtils";

const Team = () => {
  const dispatch = useDispatch();
  const { project } = useSelector(({ Projects }) => Projects);

  const [addPayment, setAddPayment] = useState(
    project.participation.reduce(
      (acc, curr) => ({ ...acc, [curr.user.id]: curr.payment_structure }),
      {},
    ),
  );

  const [openOwner, setOpenOwner] = useState(false);
  const [openCso, setOpenCso] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [openTeam, setOpenTeam] = useState({ id: null, status: false });

  const onAddUsers = (type, title, max) => {
    const typeMap = {
      cm: "client_manager",
      cso: "client_support_officer",
      owner: "project_owner",
      dev: "developer",
    };

    openModal({
      body: (
        <ProjectMemberForm
          id="users-form"
          type={typeMap[type]}
          max={max}
          selected_users={(project.participation || []).map((item) => item.user)}
        />
      ),
      title,
      canClose: true,
      options: {
        size: "sm",
        ok: "Save",
        hideCancel: true,
        form: {
          type: "submit",
          form: "users-form",
        },
      },
      header: null,
      hideActions: false,
    }).then((users) => {
      if (users && users.length) {
        const reqData = {};

        if (type === "dev") {
          reqData.participation = users.map((user) => ({
            user: {
              id: user.id,
            },
            payment_structure: {
              hours_per_week: 0,
              payout_rate: 0,
              client_rate: 0,
            },
          }));
        } else {
          reqData[type] = { id: users[0].id };
        }

        dispatch(updateProject(project.id, reqData));
      }
    });
  };

  const onDeleteUser = (user, type) => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to delete
          <br />
          {user.display_name} from the project?
        </div>
      ),
      title: "",
      canClose: true,
      options: { ok: "Delete user" },
      header: <ModalHeader style={{ paddingBottom: "24px" }} options={{ title: "Remove User" }} />,
    }).then(() => {
      if (["cm", "cso", "owner"].includes(type)) {
        const reqData = {};
        reqData[type] = null;
        dispatch(updateProject(project.id, reqData));
      } else if (type === "dev") {
        dispatch(deleteParticipation(user.id));
      }
    });
  };

  const onSavePaymentStructure = () => {
    dispatch(
      updateProject(project.id, {
        participation: Object.entries(addPayment).map((userParticipation) => ({
          user: {
            id: userParticipation[0],
          },
          payment_structure: {
            hours_per_week: userParticipation[1]?.hours_per_week,
            payout_rate: userParticipation[1]?.payout_rate,
            client_rate: userParticipation[1]?.client_rate,
          },
        })),
      }),
    );
  };

  const handleChange = (key, id, value) => {
    const newAddPayment = addPayment;
    newAddPayment[id] = { ...newAddPayment[id], [key]: value };
    setAddPayment({ ...newAddPayment });
  };

  return (
    <StypledWrappeer>
      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center mb-3">
          <span>Project Owner</span>
          {(isCMOrCSOAndHasProjectAcess(project) || isAdmin()) &&
            !project.archived &&
            !project.owner && (
              <button
                type="button"
                className="btn px-0"
                data-testid="poAddBtn"
                onClick={() => onAddUsers("owner", "Add project owner", 1)}
              >
                <Icon name="round-add" size="sm" /> Add
              </button>
            )}
        </div>

        <div className="section-items">
          {project.owner && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.owner.avatar_url}
                  initials={generateUserInitials(project.owner)}
                  size="dash"
                />
                <span>{project.owner.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {(isCMOrCSOAndHasProjectAcess(project) || isAdmin()) &&
                    !project.archived &&
                    (!openOwner ? (
                      <IconButton
                        className="px-0"
                        name="arrow-down"
                        data-testid="poToggle-down"
                        onClick={() => setOpenOwner(true)}
                      />
                    ) : (
                      <IconButton
                        className="px-0"
                        name="arrow-up"
                        data-testid="poToggle-up"
                        onClick={() => setOpenOwner(false)}
                      />
                    ))}
                </div>
              </div>

              {openOwner && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-0"
                    data-testid="poToggle-rm-user"
                    onClick={() => onDeleteUser(project.owner, "owner")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Client Support Officer</span>
          {isAdmin() && !project.archived && !project.cso && (
            <button
              type="button"
              className="btn px-0"
              data-testid="csoAddBtn"
              onClick={() => onAddUsers("cso", "Add Client support officer", 1)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>
        <div className="section-items">
          {project.cso && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.cso.avatar_url}
                  initials={generateUserInitials(project.cso)}
                  size="dash"
                />
                <span>{project.cso.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {isAdmin() &&
                    !project.archived &&
                    (!openCso ? (
                      <IconButton
                        data-testid="csoToggle-down"
                        name="arrow-down"
                        className="px-0"
                        onClick={() => setOpenCso(true)}
                      />
                    ) : (
                      <IconButton
                        data-testid="csoToggle-up"
                        name="arrow-up"
                        className="px-0"
                        onClick={() => setOpenCso(false)}
                      />
                    ))}
                </div>
              </div>
              {openCso && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-0"
                    data-testid="csoToggle-rm-user"
                    onClick={() => onDeleteUser(project.cso, "cso")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Client Manager</span>
          {isAdmin() && !project.archived && !project.cm && (
            <button
              type="button"
              className="btn px-0"
              data-testid="cmAddBtn"
              onClick={() => onAddUsers("cm", "Add client manager", 1)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>

        <div className="section-items">
          {project.cm && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.cm.avatar_url}
                  initials={generateUserInitials(project.cm)}
                  size="dash"
                />
                <span>{project.cm.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {isAdmin() &&
                    !project.archived &&
                    (!openManager ? (
                      <IconButton
                        name="arrow-down"
                        className="px-0"
                        data-testid="cmToggle-down"
                        onClick={() => setOpenManager(true)}
                      />
                    ) : (
                      <IconButton
                        name="arrow-up"
                        className="px-0"
                        data-testid="cmToggle-up"
                        onClick={() => setOpenManager(false)}
                      />
                    ))}
                </div>
              </div>
              {openManager && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-0"
                    data-testid="cmToggle-rm-user"
                    onClick={() => onDeleteUser(project.cm, "cm")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Team Members</span>
          {(isCMOrCSOAndHasProjectAcess(project) || isAdmin()) && !project.archived && (
            <button
              className="btn px-0"
              type="button"
              data-testid="tmAddBtn"
              onClick={() => onAddUsers("dev", "Add team members", 100000)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>

        <div className="section-items">
          {project.participation.map((participation) => (
            <div className="rmv" key={participation.user.id}>
              <div className="item">
                <Avatar
                  image={participation.user.avatar_url}
                  initials={generateUserInitials(participation.user)}
                  size="dash"
                />
                <span>{participation.user.display_name}</span>&nbsp;
                <span style={{ color: "#8F9BB3" }}>- Developer</span>
                <div className="item-actions">
                  <span
                    className={`${participation.status === "accepted" ? "completed" : "pending"}`}
                  >
                    {participation.status === "accepted" ? "Active" : "Pending"}
                  </span>
                  {(isCMOrCSOAndHasProjectAcess(project) || isAdmin()) &&
                    !project.archived &&
                    (openTeam.id === participation.id && openTeam.status === true ? (
                      <IconButton
                        name="arrow-up"
                        className="px-0"
                        data-testid={`team-member-toggle-up-${participation.id}`}
                        onClick={() => setOpenTeam({ id: participation.id, status: false })}
                      />
                    ) : (
                      <IconButton
                        name="arrow-down"
                        className="px-0"
                        data-testid={`team-member-toggle-down-${participation.id}`}
                        onClick={() => setOpenTeam({ id: participation.id, status: true })}
                      />
                    ))}
                </div>
              </div>
              {openTeam.id === participation.id && openTeam.status === true && (
                <div className="rmv-border">
                  {project.category === "dedicated" && (
                    <div>
                      <div
                        className="inp-div"
                        style={{
                          marginLeft: ".05rem",
                          marginRight: "0rem",
                          marginBottom: "-11px",
                        }}
                      >
                        <input
                          style={{ borderLeft: "none", borderRight: "none" }}
                          name="hours_per_week"
                          required
                          aria-label="hours_per_week"
                          type="number"
                          placeholder="Enter expected hours"
                          value={addPayment[participation.user.id]?.hours_per_week}
                          onChange={(e) =>
                            e.target.value >= 0 &&
                            handleChange("hours_per_week", participation.user.id, e.target.value)
                          }
                        />
                        <span className="span-append">hours/week</span>
                      </div>
                      <div className="row" style={{ marginBottom: "-22px" }}>
                        <div className="inp-div col">
                          <input
                            style={{ borderLeft: "none" }}
                            name="payout_rate"
                            aria-label="payout_rate"
                            required
                            value={addPayment[participation.user.id]?.payout_rate}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              handleChange("payout_rate", participation.user.id, e.target.value)
                            }
                            type="number"
                            placeholder="€ Developer rate per hour"
                          />
                          <span className="span-append">/hour</span>
                        </div>
                        <div className="inp-div col">
                          <input
                            disabled
                            style={{ borderRight: "none" }}
                            value={(
                              (parseInt(addPayment[participation.user.id]?.payout_rate, 10) / 5) *
                              parseInt(addPayment[participation.user.id]?.hours_per_week, 10) *
                              22
                            ).toFixed(2)}
                            type="number"
                          />
                          <span className="span-append">expected total payout/month</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="inp-div col">
                          <input
                            style={{ borderLeft: "none" }}
                            name="client_rate"
                            required
                            aria-label="client_rate"
                            value={addPayment[participation.user.id]?.client_rate}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              handleChange("client_rate", participation.user.id, e.target.value)
                            }
                            type="number"
                            placeholder="€ Client rate per hour"
                          />
                          <span className="span-append">/hour</span>
                        </div>
                        <div className="inp-div col">
                          <input
                            disabled
                            style={{ borderRight: "none" }}
                            value={(
                              (parseInt(addPayment[participation.user.id]?.client_rate, 10) / 5) *
                              parseInt(addPayment[participation.user.id]?.hours_per_week, 10) *
                              22
                            ).toFixed(2)}
                            type="number"
                          />
                          <span className="span-append">expected total payment/month</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn red mt-0 px-0"
                    data-testid={`tm-rm-user${participation.id}`}
                    onClick={() => onDeleteUser(participation, "dev")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {project.participation.length > 0 &&
          project.category === "dedicated" &&
          (isCMOrCSOAndHasProjectAcess(project) || isAdmin()) && (
            <button
              className="form-control save-btn"
              type="button"
              onClick={onSavePaymentStructure}
            >
              Save changes
            </button>
          )}
      </div>
    </StypledWrappeer>
  );
};

const StypledWrappeer = styled.div`
  .btn {
    line-height: unset;
    height: unset;
    font-size: 1rem;
  }

  .save-btn {
    font-size: 1rem;
    background-color: #f5f7fa;
    width: 10rem;
    color: #000;
  }

  input:focus {
    outline: none;
  }

  background: #ffffff;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 40px;

  .span-append {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
    padding: 0px 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
  .row {
    padding: 11px 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .red {
    width: fit-content;
    color: #eb5757;
    margin-top: 20px;
    cursor: pointer;
  }

  .rmv-border {
    /* border-top: 1px solid #edf1f7; */
    margin-top: 20px;
    border-right: none;
    border-left: none;
  }
  .section {
    padding: 40px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }

    .section-title {
      font-size: 16px;
      span {
        font-weight: 600;
        line-height: 19px;
        color: #151a30;
      }

      button {
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: #062e64;
        text-decoration: none;

        i {
          margin-right: 12px;
          font-size: 16px;
          vertical-align: baseline;
        }
      }
    }

    .section-items {
      .rmv {
        background: #ffffff;
        border: 1px solid #edf1f7;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 11px 16px;
      }
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:last-of-type {
          margin-bottom: 0;
        }

        .avatar.avatar-dash {
          width: 35px;
          height: 35px;

          &.avatar-initials {
            font-size: 14px;
          }
        }

        > span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #3e4857;
        }

        .item-actions {
          margin: 0 0 0 auto;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            border-radius: 50px;
            padding: 6px 24px;

            &.pending {
              color: #8f9bb3;
              background: rgba(143, 155, 179, 0.05);
              border: 1px solid rgba(143, 155, 179, 0.04);
            }

            &.completed {
              color: #219653;
              background: rgba(33, 150, 83, 0.04);
              border: 1px solid rgba(33, 150, 83, 0.04);
            }
          }

          button {
            margin-left: 30px;

            i {
              color: #8f9bb3;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
`;

export default Team;
