/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Avatar from "../../../../../components/Avatar";
import IconButton from "../../../../../components/IconButton";
import ModalHeader from "../components/ModalHeader";
import ClientsModalContent from "../components/ClientsModalContent";
import { isAdminOrPMOrSpaceLead, isClient, isCSO, isDev } from "../../../../../utils/auth";
import { openModal } from "../../../../../utils/modals";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import { getReportsTableColumns, reportsTableData } from "../../../../../utils/projectUtils";
import ReactTable from "../../../../../components/ReactTable";

const ClientReports = ({ activities, count, onLoadMore, currentPage, pageSize }) => {
  const match = useRouteMatch();

  const { project } = useSelector(({ Projects }) => Projects);

  const isMissed = (activity) => {
    return isClient()
      ? false // TODO: ask PO if this is really the case
      : moment.utc(activity.due_at).add(isDev() ? 1 : 3, "day") < moment.utc();
  };

  const clientCheck = (activity) => {
    let clientReportStatus = null;

    if (project.category === "dedicated") {
      clientReportStatus = activity.developer_ratings.length > 0;
    } else {
      clientReportStatus = activity.progress_reports.length > 0;
    }

    return clientReportStatus;
  };

  const openClientReport = (activity) => {
    openModal({
      body: <ClientsModalContent activity={activity} />,
      title: "Report Title",
      canClose: true,
      options: { className: "modal-report" },
      header: <ModalHeader title={activity.title} created_at={new Date(activity.updated_at)} />,
    });
  };

  const getTableDisplayValue = (cell) => {
    const activity = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(activity.due_at).format("DD MMM YYYY")}
          </td>
        );
      }

      case "report_name": {
        return (
          <td key={`report_name${key}`} className="nowrap">
            {activity.title}
          </td>
        );
      }

      case "user": {
        return (
          <td className="nowrap" key={`user${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={activity?.user?.avatar_url}
                initials={generateUserInitials(activity?.user)}
                size="dash"
              />
              {activity?.user?.display_name}
            </div>
          </td>
        );
      }

      case "status": {
        return (
          <td className="nowrap" key={`status${key}`}>
            <span
              className={`${
                clientCheck(activity) ? "completed" : isMissed(activity) ? "overdue" : "pending"
              }`}
            >
              {clientCheck(activity) ? "Completed" : isMissed(activity) ? "Overdue" : "Pending"}
            </span>
          </td>
        );
      }

      case "action": {
        return (
          <td className="nowrap" key={`action${key}`}>
            <span style={{ height: 35, paddingLeft: 0, display: "block" }}>
              {isClient() && (
                <Link to={`${match.url}/client-survey/${project.id}/${activity.id}`}>
                  <IconButton className="btn-cta" name="arrow-right" size="sm" />
                </Link>
              )}
              {(isAdminOrPMOrSpaceLead() || isCSO()) && clientCheck(activity) && (
                <IconButton
                  onClick={() => openClientReport(activity)}
                  data-testid="arrow-right-btn"
                  className="btn-cta"
                  name="arrow-right"
                  size="sm"
                />
              )}
            </span>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="project-reports table-responsive">
      <Table>
        <ReactTable
          tableData={reportsTableData(activities)}
          tableColumns={getReportsTableColumns(clientCheck)}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
        />
      </Table>
    </div>
  );
};

ClientReports.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      due_at: PropTypes.string,
      title: PropTypes.string,
      progress_reports: PropTypes.arrayOf(
        PropTypes.shape({
          created_at: PropTypes.string,
        }),
      ),
      user: PropTypes.shape({
        avatar_url: PropTypes.string,
      }),
    }),
  ),
  count: PropTypes.number,
  onLoadMore: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

export const Table = styled("div")`
  width: 100%;
  .btn {
    background: #fff;
    border: none;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    height: 2.5rem;
  }
  thead tr {
    th:last-child {
      padding-right: 5rem;
      float: right;
    }
  }

  .edit {
    opacity: 0.6;
    margin-right: 0px;

    &.btn-accepted {
      opacity: 1;
      background: none;
    }
    i {
      color: #219653;
      font-size: 18px;
    }
  }

  .cancel {
    opacity: 0.6;
    &.btn-rejected {
      opacity: 1;
      background: none;
    }
    i {
      color: #eb5757;
      font-size: 18px;
    }
  }

  .actions-icons {
    float: right;
    margin-top: 10px;
    button {
      box-shadow: none !important;
    }
  }
`;
export default ClientReports;
