import { createConfirmation } from "react-confirm";
import GenericModal from "../components/GenericModal";

export const confirm = createConfirmation(GenericModal);

export const openGenericModal = (modalContent, modalHeader, options = {}) => {
  return confirm({
    modalHeader,
    modalContent,
    options: { ...options },
  });
};

const safeClassNameOptions = (className, options) => {
  const safeOptions = options || {};
  if (safeOptions.className) {
    safeOptions.className = `${className} ${safeOptions.className}`;
  } else {
    safeOptions.className = className;
  }
  return safeOptions;
};

export const openConfirm = ({
  message = null,
  title = "",
  canClose = true,
  options = { ok: "Yes", cancel: "Cancel" },
  header = null,
} = {}) => {
  return openGenericModal(message, header, {
    mustRespond: !canClose,
    title,
    ...safeClassNameOptions("modal-confirm", options),
  });
};

export const openModal = ({
  body = null,
  title = "",
  canClose = true,
  options = null,
  header = null,
  hideActions = false,
} = {}) => {
  return openGenericModal(body, header, {
    hideActions,
    mustRespond: !canClose,
    title,
    ...(options || {}),
  });
};

export const openAlert = ({ body = null, canClose = false, options = null }) => {
  return openGenericModal(body, null, {
    hideCancel: true,
    mustRespond: !canClose,
    ...safeClassNameOptions("modal-alert", options),
  });
};
