/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem } from "reactstrap";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { useSelector } from "react-redux";
import SearchBox from "./SearchBox";
import NavLinks from "./NavLinks";
import Button from "./Button";
import Icon from "./Icon";
import Progress from "./Progress";
import useRightNav from "../layouts/RightSideNav/useRightNav";
import Navigation from "../layouts/RightSideNav/Navigation";
import Overview from "../pages/Dashboard/projects/projectDetail/Overview";
import Activity from "../pages/Dashboard/projects/projectDetail/Activity";
import { isAdminOrPMOrSpaceLead, isCSO } from "../utils/auth";
import { eventTrack } from "../utils/analytics";
import { CATEGORIES, EVENTS } from "../analytics/events";

function getMainPath(str) {
  const regex = /^\/([^?\\/]+)/;
  return str.match(regex) ? str.match(regex)[1] : "";
}

const NavBar = forwardRef((props, ref) => {
  const [openBtn, setOpenBtn] = useState(null);
  const [navHeight, setNavHeight] = useState(null);

  const { className } = props;
  const history = useHistory();
  const { isMakingRequest, project } = useSelector(({ Projects }) => Projects);

  const { open } = useRightNav();

  useEffect(() => {
    if (ref.current) setNavHeight(ref.current.offsetHeight);
  }, [ref.current]);

  const getNavTitle = () => {
    let title = "Dashboard";
    switch (getMainPath(history.location.pathname)) {
      case "projects":
        title = isMakingRequest.fetch ? <Progress /> : project?.title || "Projects";
        break;
      case "payments":
        title = "Payments";
        break;
      case "settings":
        title = "Settings";
        break;
      case "tests":
        title = "Tests";
        break;
      case "referral":
        title = "Refer and Earn";
        break;
      case "community":
        title = "Community Guide";
        break;
      default:
        break;
    }
    return title;
  };

  const viewTitle = getNavTitle();

  // TODO: move this to another file
  const navSections = [
    {
      component: <Activity project={project} />,
      title: "Activity",
    },
    {
      component: <Overview project={project} />,
      title: "Overview",
    },
  ];

  return (
    <Wrapper ref={ref} className={`navbar ${className || ""}`}>
      <div className="title-bar">
        <Link to="/dashboard" className="navbar-brand">
          {viewTitle}
        </Link>
        <ul className="navbar-nav ml-auto">
          {viewTitle === "Tests" && (
            <li>
              <StyledButton id="createResult" variant="primary">
                <Icon name="round-add" />
                &nbsp;&nbsp;&nbsp;Add New Result
              </StyledButton>
            </li>
          )}
          <li>
            <SearchBox navHeight={navHeight} />
          </li>
          {!(project?.title || viewTitle === "Tests") && (
            <li className="add-item">
              <ButtonDropdown
                className="add-btn"
                isOpen={openBtn}
                toggle={() => setOpenBtn(!openBtn)}
              >
                {(isAdminOrPMOrSpaceLead() || isCSO()) && (
                  <DropdownToggle>
                    <Icon name="round-add" />
                    &nbsp;&nbsp;&nbsp;New
                  </DropdownToggle>
                )}
                <DropdownMenu className="dropdown-menu">
                  <DropdownItem className="dropdown-item">
                    <Link to="/projects/new/dedicated/details">
                      <Icon name="account-outline" />
                      Dedicated Developer
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() =>
                      eventTrack(CATEGORIES.projects, EVENTS.click_create_new_opportunity_button)
                    }
                  >
                    <Link to="/projects/new/opportunity">
                      <Icon name="bullhorn-outline" />
                      Opportunity
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </li>
          )}

          {project?.title && (
            <li>
              <Button
                className="right-nav-button ms-4"
                onClick={() => open(<Navigation navSections={navSections} />)}
              >
                <Icon name="dots-horizontal" />
                &nbsp;&nbsp;&nbsp;More
              </Button>
            </li>
          )}
        </ul>
      </div>

      <NavLinks />
    </Wrapper>
  );
});

const Wrapper = styled.nav`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 80% / 50%);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
  left: 220px;
  padding: 0 40px;

  .title-bar {
    padding: 16px 0;
    display: flex;
    align-items: center;
    width: 100%;

    .navbar-brand {
      color: #151a30;
      font-weight: bold;
      line-height: 24px;
      padding: 0;

      &.hover {
        color: #000000;
      }
    }
  }

  .right-nav-button {
    color: #8f9bb3;
    background: rgba(143, 155, 179, 0.1);
    border: none;
    border-radius: 6px;
    box-shadow: none;
    line-height: 22px;
    font-size: 14px;
    text-align: right;

    i {
      font-size: 3px;
      vertical-align: middle;
    }

    &:hover,
    &:visited,
    &:link,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:focus {
      outline: 0;
      box-shadow: none;
      border: none;
      border-radius: 6px;
      color: #8f9bb3;
      background: rgba(143, 155, 179, 0.2);
    }
  }

  .navbar-nav {
    flex-direction: row;
    margin: 0 0 0 auto;
    > li {
      > a {
        color: #000000;
      }
    }
  }

  .add-item {
    margin-left: 24px;

    .add-btn {
      > button {
        color: #fff;
        font-weight: 600;
        font-size: px-to-rem(16);
        line-height: px-to-rem(22);
        text-align: center;
        background: #da3451;
        border-color: transparent;
        box-shadow: none;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
      }
    }
  }

  /*override reactstrap inline styles*/
  .dropdown-menu {
    transform: translate3d(0px, 54px, 0px) !important;
    left: initial !important;
    right: 0px;
    border: 1px solid #edf1f7 !important;
    box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04) !important;
    border-radius: 6px !important;
    padding: 10px !important;

    .dropdown-item {
      padding: 0;
      color: #3e4857;

      &:active {
        color: initial;
        background-color: initial;
      }

      a {
        padding: 15px;
        color: #3e4857;
        text-decoration: none;
        display: block;
        i {
          margin-right: 12px;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  box-shadow: none;
`;

NavBar.propTypes = {
  // title: PropTypes.string,
  className: PropTypes.string,
};

export default NavBar;
