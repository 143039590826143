import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import CustomSelect from "../CustomSelect";
import { isPayAdmin } from "../../utils/auth";
import {
  bulkApproveInvoice,
  bulkArchiveInvoice,
  bulkDeleteInvoice,
  bulkMarkAsPaidInvoice,
  bulkSendReminderInvoice,
  bulkGenerateInvoice,
} from "../../utils/invoiceUtils";

const BulkActions = ({ checked, options, toggleAll }) => {
  const [defaultText, setDefaultText] = useState("Actions");

  const checkedItems = () => {
    const ids = [];

    checked.forEach((batch) => {
      if (batch.invoices) {
        batch.invoices.forEach((invoice) => {
          ids.push(invoice.id);
        });
      } else {
        ids.push(batch.id);
      }
    });

    return ids;
  };

  const bulkHandler = (e) => {
    const invoices = checkedItems();
    switch (e) {
      case "approve":
        bulkApproveInvoice(invoices);
        break;

      case "mark_as_paid":
        bulkMarkAsPaidInvoice(invoices);
        break;
      case "send_reminder":
        bulkSendReminderInvoice(invoices);
        break;
      case "archive":
        bulkArchiveInvoice(invoices);
        break;

      case "delete":
        bulkDeleteInvoice(invoices);
        break;

      case "generate":
        bulkGenerateInvoice(invoices);
        break;

      default:
        break;
    }
  };

  const onHandleBulkChange = (value) => {
    bulkHandler(value);
    setDefaultText("Actions");
  };

  return (
    <>
      {checked.length !== 0 && isPayAdmin() && (
        <Wrapper>
          <ActionSlate data-testid="bulk-actions" className="d-flex bulk-actions">
            <div>
              <span className="mx-1 cursor-pointer selected">{checked.length} selected |</span>
              <span
                role="presentation"
                className="mx-1 cursor-pointer un-selected"
                data-testid="unselect-all"
                onClick={() => toggleAll(false)}
              >
                Unselect all
              </span>
              <span
                className="mx-2 cursor-pointer select-all"
                role="presentation"
                data-testid="select-all"
                onClick={() => toggleAll(true)}
              >
                Select all
              </span>
              <CustomSelect
                defaultText={defaultText}
                options={options}
                handler={onHandleBulkChange}
              />
            </div>
          </ActionSlate>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  order: 2;

  .cursor-pointer {
    cursor: pointer;
  }

  .selected {
    color: #7f8c9f;
  }
  .un-selected {
    color: #062e64;
  }
  .select-all {
    color: #062e64;
  }
  .bulk-actions {
    margin-top: 2px;
    padding-left: 10px;
  }
`;

const ActionSlate = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;

  button {
    box-shadow: none;
    font-weight: 800;
    border: 1px solid #8590a0;
    border-radius: 1px;
    color: #8590a0;
    padding: 0 10px;
  }
`;

BulkActions.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  toggleAll: PropTypes.func,
};

export default BulkActions;
