/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useMemo } from "react";
import { useTable, usePagination } from "react-table";
import { Table } from "react-bootstrap";
import styled, { css } from "styled-components";
import ReactPaginate from "react-paginate";

/* -------------------------------------------------------------------------- */
/*                            Internal dependencies                           */
/* -------------------------------------------------------------------------- */
import { CustomSelector } from "../utils/styles";
import Select from "./Select";
import Icon from "./Icon";

export const PAGINNATION_COUNT = 20; // TODO: move this to environment variables

const ReactTable = (props) => {
  const {
    tableData,
    tableColumns,
    currentPage,
    count,
    getTableDisplayValue,
    loadPage,
    pageSize,
    scope,
    colDeps = [],
  } = props;

  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [...colDeps]);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(count / PAGINNATION_COUNT),
    },
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  const handleLimitChange = (event) => {
    loadPage(0, event.target.value);
  };

  const handlePageClick = (event) => {
    loadPage(event.selected, pageSize);
  };

  return (
    <>
      <div className="table-responsive">
        <StyledTable scope={scope} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`header-${i}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, n) => (
                  <th
                    key={`column-${n}`}
                    {...column.getHeaderProps({ className: column.className || "" })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={`row-${i}-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return getTableDisplayValue(cell);
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
      {data.length > 0 ? (
        <TableFooter colSpan={8}>
          <div className="flex-items">
            <div>
              Showing{" "}
              <b>
                {currentPage * pageSize + 1} -{" "}
                {currentPage * pageSize + +pageSize > count
                  ? count
                  : currentPage * pageSize + +pageSize}
              </b>{" "}
              out of <b>{count}</b> items
            </div>

            <CustomSelector>
              <Select
                className="form-control"
                style={{ backgroundColor: "white" }}
                onChange={(value) => {
                  handleLimitChange(value);
                }}
                value={pageSize}
                data-testid="select"
              >
                {[20, 50, 100, 150].map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </Select>
            </CustomSelector>

            <ReactPaginate
              breakLabel="..."
              nextLabel={<Icon name="arrow-right" size="sm" />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(count / pageSize)}
              marginPagesDisplayed={3}
              previousLabel={<Icon name="arrow-left" size="sm" />}
              renderOnZeroPageCount={null}
              containerClassName="pagination justify-content-end"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              forcePage={currentPage}
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </TableFooter>
      ) : (
        <p className="mx-auto text-center mt-4">No User Available</p>
      )}
    </>
  );
};

export const StyledTable = styled(Table)`
  .active {
    background: rgba(33, 150, 83, 0.04);
    border: 1px solid rgba(33, 150, 83, 0.04);
    box-sizing: border-box;
    border-radius: 50px;
    color: #219653;
    height: 30px;
    width: 65px;
  }
  .deactivated {
    background: rgba(235, 87, 87, 0.04);
    border: 1px solid rgba(235, 87, 87, 0.04);
    box-sizing: border-box;
    border-radius: 50px;
    color: #eb5757;
    height: 30px;
    width: 65px;
  }
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 6px;
  /* table-layout: fixed; */
  border-collapse: initial;
  border-spacing: 0;
  overflow: hidden;

  button {
    line-height: unset;
    height: unset;
    color: #3e4857;

    &.btn-more {
      font-size: unset;
    }
  }

  .btn-trans {
    background: transparent;
    border: none;
    cursor: pointer;
    text-align: left;
  }

  .btn-approve {
    font-weight: 600;
    color: rgb(62, 72, 87);
  }

  .subtotal {
    border-top: 1px solid #444;
  }

  thead tr {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #8f9bb3;
    background: rgba(62, 72, 87, 0.05);
    border-bottom: 1px solid #edf1f7;
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;

    th {
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #edf1f7;
      white-space: nowrap;
    }
    th:nth-last-child(2):not(.reminder-count) {
      width: 150px;
    }
    th:last-child {
      padding-right: 24px;
    }
    th:first-child {
      padding-left: 24px;
    }
  }

  tbody tr {
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #3e4857;
    border-bottom: 1px solid #edf1f7;

    td {
      vertical-align: middle;
      padding: 16px 10px;
      width: auto;

      &.nowrap {
        white-space: nowrap;
      }

      a,
      b {
        font-weight: 600;
        color: #3e4857;
        text-decoration: none;
      }

      > i.danger {
        color: #da3451;
        margin-left: 8px;
        vertical-align: baseline;
      }

      .payout-title {
        margin-bottom: 0;
        min-width: 300px;
        display: inline-block;
        white-space: break-spaces;
      }

      .avatar.avatar-dash {
        width: 35px;
        height: 35px;

        &.avatar-initials {
          font-size: 14px;
        }
      }
    }

    td:first-child {
      padding-left: 24px;
    }

    td:last-child {
      display: flex;
      padding-right: 24px;

      &.cta {
        display: table-cell;
        color: #3e4857;
        text-decoration: none;

        &:empty {
          width: fit-content;
        }
        div.cta-wrapper {
          display: flex;
          justify-content: flex-end;
          a {
            white-space: nowrap;
            align-self: center;
            padding-right: 16px;
            i {
              font-size: 15px;
              color: #3e4857;
              margin-left: 8px;
              vertical-align: middle;
            }
          }
          > button {
            align-self: center;
            padding-left: 5px;
            i {
              color: #3e4857;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
        }
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      border-radius: 50px;
      padding: 6px 24px;

      &.pending {
        color: #8f9bb3;
        background: rgba(143, 155, 179, 0.05);
        border: 1px solid rgba(143, 155, 179, 0.04);
      }

      &.overdue {
        color: #eb5757;
        background: rgba(235, 87, 87, 0.04);
        border: 1px solid rgba(235, 87, 87, 0.04);
      }

      &.missed {
        color: #3e4857;
        background: rgba(62, 72, 87, 0.04);
        border: 1px solid rgba(62, 72, 87, 0.04);
      }

      &.completed {
        color: #219653;
        background: rgba(33, 150, 83, 0.04);
        border: 1px solid rgba(33, 150, 83, 0.04);
      }
    }
  }

  ${({ scope }) =>
    scope === "payout" &&
    css`
      thead tr {
        th:nth-last-child(2) {
          width: 170px;
        }
        th:last-child {
          width: fit-content;
        }
    }
      }

      tbody tr {
        td {
          padding: 16px 10px;
          i.danger {
            color: #da3451;
          }
          .payout-title {
            margin-bottom: 0;
            min-width: 300px;
            display: inline-block;
            white-space: break-spaces;
          }
        }
        td:last-child {
          padding-right: 24px;
          display: ${(props) => (props.filter === "paid" ? "revert;" : "flex;")};
          ${(props) => (props.filter === "paid" ? "flex-direction: column" : null)};
          ${(props) => (props.filter === "paid" ? "padding-top: 35px;" : null)};
        }
        td:nth-last-child(3) {
          padding-top: 16px !important;
        }
        td:first-child {
          padding-left: 24px;
        }
        td:nth-last-child(3) {
          padding-top: 40px;
        }
      }
    `}

  .line-bottom {
    :hover {
      border-bottom: 1px solid #3e4857 !important;
    }
  }
`;

const TableFooter = styled.div`
  td {
    display: table-cell !important;
    div {
      display: flex;
      font-size: 14px;
      line-height: 21px;
      justify-content: space-between;

      span {
        color: #3e4857;
        align-self: center;
        padding-left: 0px;
      }

      > nav {
        margin: 0 0 0 auto;
      }
    }
  }

  .page-item.active .page-link {
    background: rgb(6, 46, 100);
    border-color: rgb(6, 46, 100);
  }
  .page-link {
    color: rgb(62, 72, 87);
  }
  .page-link:hover {
    color: #a41e35;
  }
  .page-item.active .page-link {
    color: #fff;
  }
  .pagination {
    margin-top: 20px;
  }
  .flex-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    padding: 15px;
  }

  select,
  select:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #dee2e6;
  }
`;

export default ReactTable;
