/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import IBAN from "iban";

import FieldError from "../FieldError";
import HelpIcon from "../svg/HelpIcon";
import { Label, Title } from "./styles";
import Input from "../Input";

const IbanForm = ({ id, proceed, payload: data, submitError }) => {
  const [name, setName] = useState(data.name);
  const [iban, setIban] = useState(data.iban);
  const [ibanError, setIbanError] = useState(null);
  const [nameError, setNameError] = useState(null);

  const handleName = (event) => {
    const { value } = event.target;
    if (typeof value === "string" && value.trim()) {
      setNameError(null);
      setName(value);
    } else {
      setNameError("Enter a valid name");
      setName(value.trim());
    }
  };

  const handleIban = (event) => {
    const { value } = event.target;
    if (IBAN.isValid(value)) {
      setIbanError(null);
      setIban(value);
    } else {
      setIbanError("Enter a valid IBAN");
      setIban(value);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (nameError || ibanError) {
      return;
    }

    proceed({ name, iban, ibanError });
  };

  return (
    <form className="mt-3" id={id} data-testid="iban-form" onSubmit={onSubmit}>
      <Title>Enter the details below to connect IBAN</Title>

      {submitError && (
        <div style={{ marginBottom: "1em" }}>
          <FieldError message={submitError} />
        </div>
      )}

      <FormGroup className="mb-4">
        <Label htmlFor="accountHolderName" className="mb-2">
          Full name of account holder
        </Label>
        <Input
          id="accountHolderName"
          dataTestId="accountHolderName"
          type="text"
          className="py-0"
          placeholder="Enter name as registered in your e-wallet"
          value={name}
          onChange={handleName}
          required
        />
        {nameError && <FieldError message={nameError} />}
      </FormGroup>

      <FormGroup>
        <Label className="d-flex justify-content-between mb-2" htmlFor="iban">
          <span className="d-inline-block">IBAN</span>
          <span className="d-inline-block text-right align-self-end">
            <HelpIcon />
          </span>
        </Label>
        <Input
          id="iban"
          dataTestId="iban"
          type="text"
          className="py-0"
          placeholder="000000000"
          value={iban}
          onChange={handleIban}
          required
        />
        {ibanError && <FieldError message={ibanError} />}
      </FormGroup>
    </form>
  );
};

export default IbanForm;
