/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useRef, useContext } from "react";
import Switch from "react-switch";
import moment from "moment";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin, isCMOrCSOAndHasProjectAcess } from "../../../../../utils/auth";
/* -------------------------- Internel Dependencies ------------------------- */

import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import Icon from "../../../../../components/Icon";
import FieldError from "../../../../../components/FieldError";
import { StyledDateTimePicker } from "../../createProjects/style";
import { TempProjectContext } from "../../createProjects/TempProjectContext";
import { openConfirm } from "../../../../../utils/modals";
import { updateProject } from "../../../../../redux/actions/ProjectActions";
import { StyledWrapper } from "./style";
import { emailRegex } from "../../../../../configs/constants/global";

/* --------------------------- Component proptypes -------------------------- */

const TeamDetails = () => {
  const dispatch = useDispatch();
  const [project, setProject] = useContext(TempProjectContext);
  const [error, setError] = useState("");

  useEffect(() => {
    setProject({ ...project });
  }, []);
  /* istanbul ignore next */
  const validateEmail = () => {
    // eslint-disable-next-line no-unused-expressions
    !emailRegex.test(projectDetails.invoicing_email)
      ? setError("Invalid email address.")
      : setError("");
  };

  const { project: projectFromState, isMakingRequest } = useSelector(({ Projects }) => Projects);
  const {
    id,
    title,
    description,
    archived,
    category,
    expected_start_date,
    expected_end_date,
    payment_structure,
    invoicing_email,
    cc_primary_email,
  } = projectFromState;
  const [projectDetails, setprojectDetails] = useState({
    id,
    title,
    description,
    expected_start_date,
    expected_end_date,
    payment_structure,
    amount: payment_structure?.amount,
    hours_per_week: payment_structure?.hours_per_week,
    invoicing_email,
    cc_primary_email,
  });

  const [canSave, setcanSave] = useState(false);
  const [canSaveDuration, setCanSaveDuration] = useState(false);
  const [canSavePayment, setCanSavePayment] = useState(false);

  const prevProjectDetailsPropsRef = useRef();
  const durPrevProjectDetailsPropsRef = useRef();
  const payPrevProjectDetailsPropsRef = useRef();

  /* istanbul ignore next */
  useEffect(() => {
    prevProjectDetailsPropsRef.current = {
      title,
      description,
    };
    durPrevProjectDetailsPropsRef.current = {
      expected_start_date,
      expected_end_date,
    };
    payPrevProjectDetailsPropsRef.current = {
      hours_per_week: projectDetails.hours_per_week,
      amount: projectDetails.amount,
      payment_type: projectDetails.payment_type,
      invoicing_email,
    };
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (!isMakingRequest.update) {
      const prevProps = prevProjectDetailsPropsRef.current;
      if (prevProps.title !== title || prevProps.description !== description) {
        prevProjectDetailsPropsRef.current = {
          title,
          description,
        };
        setcanSave(false);
      }
    }
    if (!isMakingRequest.update) {
      const durPrevProps = durPrevProjectDetailsPropsRef.current;
      if (
        durPrevProps.expected_start_date !== expected_start_date ||
        durPrevProps.expected_end_date !== expected_end_date
      ) {
        durPrevProjectDetailsPropsRef.current = {
          expected_start_date,
          expected_end_date,
        };
        setCanSaveDuration(false);
      }
    }
    if (!isMakingRequest.update) {
      const payPrevProps = payPrevProjectDetailsPropsRef.current;
      if (
        payPrevProps.invoicing_email !== projectDetails.invoicing_email ||
        payPrevProps.cc_primary_email !== projectDetails.cc_primary_email
      ) {
        payPrevProjectDetailsPropsRef.current = {
          invoicing_email: projectDetails.invoicing_email,
          cc_primary_email: projectDetails.cc_primary_email,
        };
        setCanSavePayment(false);
      }
    }
  }, [isMakingRequest.update]);

  /* istanbul ignore next */
  useEffect(() => {
    const prevProps = prevProjectDetailsPropsRef.current;
    if (
      prevProps.title !== projectDetails.title ||
      prevProps.description !== projectDetails.description
    ) {
      setcanSave(true);
    } else {
      setcanSave(false);
    }
    const durPrevProps = durPrevProjectDetailsPropsRef.current;
    if (
      moment(durPrevProps.expected_start_date).format("MM/DD/YYYY") !==
        moment(projectDetails.expected_start_date).format("MM/DD/YYYY") ||
      moment(durPrevProps.expected_end_date).format("MM/DD/YYYY") !==
        moment(projectDetails.expected_end_date).format("MM/DD/YYYY")
    ) {
      setCanSaveDuration(true);
    } else {
      setCanSaveDuration(false);
    }
    const payPrevProps = payPrevProjectDetailsPropsRef.current;
    if (
      payPrevProps.invoicing_email !== projectDetails.invoicing_email ||
      payPrevProps.cc_primary_email !== projectDetails.cc_primary_email
    ) {
      setCanSavePayment(true);
    } else {
      setCanSavePayment(false);
    }
  }, [projectDetails]);

  const onToggleArchiveProject = () => {
    openConfirm({
      message: (
        <div>Are you sure you want to {archived ? "un-archive" : "archive"} this project?</div>
      ),
      title: null,
      canClose: false,
      options: {
        ok: "Yes",
        cancel: "No",
      },
    }).then(
      () => {
        updateProject(id, {
          archived: !archived,
        })(dispatch);
      },
      () => {
        // do nothing
      },
    );
  };

  const onToggleConvertProject = () => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to convert this project to a{" "}
          {category !== "project" ? "managed project" : "dedicated project"}
        </div>
      ),
      title: null,
      canClose: false,
      options: {
        ok: "Yes",
        cancel: "No",
      },
    }).then(
      () => {
        updateProject(id, {
          category: category === "project" ? "dedicated" : "project",
        })(dispatch);
      },
      () => {
        // do nothing
      },
    );
  };

  const onSaveProject = () => {
    updateProject(id, {
      title: projectDetails.title,
      description: projectDetails.description,
    })(dispatch);
  };

  const onSaveProjectDuration = () => {
    updateProject(id, {
      expected_start_date: projectDetails.expected_start_date,
      expected_end_date: projectDetails.expected_end_date,
    })(dispatch);
  };

  const onSavePaymentStructure = () => {
    updateProject(id, {
      invoicing_email: projectDetails.invoicing_email,
      cc_primary_email: projectDetails.cc_primary_email,
    })(dispatch);
  };
  return (
    <StyledWrapper>
      <div className="section">
        <h3 className="section-title">
          Project Title and Description
          <span style={{ color: "#da3451", paddingLeft: "2px" }}>*</span>
        </h3>

        <Input
          placeholder="Title"
          className="form-control"
          value={projectDetails.title || ""}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin())}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              title: e.target.value,
            })
          }
        />
        <TextArea
          placeholder="Description"
          className="form-control"
          value={projectDetails.description || ""}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin())}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              description: e.target.value,
            })
          }
        />

        {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
          <Button
            type="button"
            data-testid="save-project-btn"
            disabled={
              !canSave ||
              isMakingRequest.update ||
              !projectDetails.description ||
              !projectDetails.title
            }
            onClick={onSaveProject}
          >
            Save changes
          </Button>
        )}
      </div>
      <div className="section">
        <div className="title">
          What is the expected duration of the project?
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup className="row">
          <div className="col">
            <label htmlFor="startDate">Start date</label>
            <StyledDateTimePicker
              $calendar
              id="startDate"
              className="tg-date-field"
              placeholder="Select start date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $time={false}
              value={
                projectDetails.expected_start_date
                  ? new Date(projectDetails.expected_start_date)
                  : null
              }
              max={
                projectDetails.expected_end_date
                  ? new Date(projectDetails.expected_end_date)
                  : new Date(2099, 11, 31)
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_start_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
            />
          </div>
          <div className="col">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="endDate">End date</label>
              <div>
                <span className="mx-1">No end date</span>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={13.5}
                  width={22.5}
                  onChange={() => {
                    setprojectDetails({
                      ...projectDetails,
                      expected_end_date: projectDetails.expected_end_date
                        ? null
                        : +new Date(2099, 11, 31),
                    });
                  }}
                  checked={!projectDetails.expected_end_date}
                  onColor="#062E64"
                />
              </div>
            </div>
            <StyledDateTimePicker
              id="endDate"
              className="tg-date-field"
              placeholder="Select end date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $calendar
              $time={false}
              value={
                projectDetails.expected_end_date &&
                typeof projectDetails.expected_end_date === "string"
                  ? new Date(projectDetails.expected_end_date)
                  : null
              }
              min={
                new Date(projectDetails.expected_start_date) >= new Date(Date.now())
                  ? new Date(projectDetails.expected_start_date)
                  : new Date(Date.now())
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_end_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
              required
            />
          </div>
        </FormGroup>
        {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
          <Button
            type="button"
            data-testid="save-project-duration-btn"
            disabled={
              !canSaveDuration ||
              isMakingRequest.update ||
              !projectDetails.expected_start_date ||
              !(
                projectDetails.expected_end_date == null ||
                projectDetails.expected_end_date !== +new Date(2099, 11, 31)
              )
            }
            onClick={() => onSaveProjectDuration()}
          >
            Save changes
          </Button>
        )}
      </div>
      <div className="section">
        <div className="title">
          <DocWrapper>
            <div className="header">
              <h4>Payment</h4>
              <p>
                Enter payment terms for this client{" "}
                <span style={{ color: "#DA3451", paddingLeft: "2px" }}>*</span>
              </p>
              <div className="mt-4">
                <label htmlFor="email">Client invoicing email address</label>
                {error ? <FieldError message={error} /> : null}
                <input
                  required
                  id="email"
                  data-testid="invoicing_email"
                  type="email"
                  className="mb-0"
                  placeholder="Enter email address to send client invoices to"
                  value={projectDetails.invoicing_email || ""}
                  onChange={(e) =>
                    setprojectDetails({
                      ...projectDetails,
                      invoicing_email: e.target.value,
                    })
                  }
                  onKeyUp={() => validateEmail()}
                />
              </div>
            </div>

            {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
              <div className="mt-2">
                <div className="mb-3">
                  <span className="mx-1">Send to primary email</span>
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={13.5}
                    width={22.5}
                    onChange={() => {
                      setprojectDetails({
                        ...projectDetails,
                        cc_primary_email: !projectDetails.cc_primary_email,
                      });
                    }}
                    checked={projectDetails.cc_primary_email}
                    onColor="#062E64"
                  />
                </div>
                <Button
                  type="button"
                  data-testid="save-payment-btn"
                  disabled={
                    !canSavePayment ||
                    isMakingRequest.update ||
                    !projectDetails.invoicing_email ||
                    error
                  }
                  onClick={() => onSavePaymentStructure()}
                >
                  Save changes
                </Button>
              </div>
            )}
          </DocWrapper>
        </div>
      </div>
      {(isCMOrCSOAndHasProjectAcess(projectFromState) || isAdmin()) && (
        <>
          <div className="section">
            <h3 className="section-title">
              Convert to {category !== "project" ? "Managed" : "Dedicated"} Project
            </h3>
            <Button type="button" onClick={() => onToggleConvertProject()}>
              <Icon name="baseline-folder-open" size="sm" />
              &nbsp; Convert
            </Button>
          </div>
          <div className="section">
            <h3 className="section-title">Archive Project</h3>
            <p>
              Mark this project as archive and read-only. This will also remove this project from
              your project list and send it to the archived list.
            </p>
            <Button type="button" onClick={() => onToggleArchiveProject()}>
              <Icon name="archive-outline" size="sm" />
              &nbsp;
              {archived ? "Un" : null}Archive Project
            </Button>
          </div>
        </>
      )}
    </StyledWrapper>
  );
};

const DocWrapper = styled.div`
  .section .file-list .section-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    a {
      text-decoration: none;
    }
  }
  .flex-tab {
    display: flex;
  }
  .active-tab {
    background: #062e64;
    color: #fff;
    height: 40px;
    width: 160px;
    cursor: pointer;
  }
  .tab {
    height: 40px;
    width: 160px;
    font-size: 16px;
    cursor: pointer;
  }
  .per-tab {
    border-radius: 4px 0px 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .fix-tab {
    border-radius: 0px 4px 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .span-append {
    position: absolute;
    right: 8px;
    top: 40%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
    border-radius: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .file-list {
    margin-bottom: 0px;

    &:not(:empty) {
      margin-top: 26px;
    }

    .file-item {
      display: inline-block;
    }
  }

  .section {
    border-bottom: 1px solid #edf1f7;
    padding-top: 43px;
    padding-bottom: 43px;

    &:last-of-type {
      border-bottom: none;
    }

    .section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #151a30;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #062e64;
        i {
          vertical-align: baseline;
        }
      }
    }

    .section-item {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0px 15px;
      place-items: center;

      a,
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #3e4857;

        i {
          vertical-align: baseline;
          margin-right: 20px;
          color: #8f9bb3;
        }
      }

      button {
        color: #8f9bb3;
        height: auto;
      }
    }
  }
`;

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default TeamDetails;
