const REPORT_STATUS_AHEAD_OF_SCHEDULE = "ahead";
const REPORT_STATUS_ON_SCHEDULE = "on_schedule";
// const REPORT_STATUS_BEHIND = "behind";
// const REPORT_STATUS_STUCK = "stuck";
const REPORT_STATUS_BEHIND_BUT_PROGRESSING = "behind_progressing";
const REPORT_STATUS_BEHIND_AND_STUCK = "behind_stuck";

const REPORT_STUCK_RESOLVING_ERROR = "resolving_error";
const REPORT_STUCK_POOR_DOC = "poor_doc";
const REPORT_STUCK_HARDWARE_PROBLEM = "hardware_problem";
const REPORT_STUCK_UNCLEAR_SPEC = "unclear_spec";
const REPORT_STUCK_PERSONAL_ISSUE = "personal_issue";
const REPORT_STUCK_OTHER = "other";

const TIME_SHEET_MONTH_VIEW = "timesheet_month_view";
const TIME_SHEET_YEAR_VIEW = "timesheet_year_view";
const SEARCH_INITIAL_LIST = "searchInitialList";

const AGREEMENT_VERSION = 1.3; // UPDATE EVERY TIME AGREEMENT CHANGES

const onboardingRouteLinks = {
  ONBOARDING_STAGE_ONE: "/onboard/step-one",
  ONBOARDING_STAGE_TWO: "/onboard/step-two",
  ONBOARDING_STAGE_THREE: "/onboard/step-three",
  ONBOARDING_FINISH: "/dashboard",
};

const REPORT_STATUSES = [
  [REPORT_STATUS_AHEAD_OF_SCHEDULE, "Ahead of schedule"],
  [REPORT_STATUS_ON_SCHEDULE, "On schedule"],
  [REPORT_STATUS_BEHIND_BUT_PROGRESSING, "Behind but Progressing"],
  [REPORT_STATUS_BEHIND_AND_STUCK, "Behind and Stuck"],
];

export const REPORT_STUCK_REASONS = [
  [REPORT_STUCK_RESOLVING_ERROR, "Resolving an error"],
  [REPORT_STUCK_POOR_DOC, "Poor documentation"],
  [REPORT_STUCK_HARDWARE_PROBLEM, "Hardware problem"],
  [REPORT_STUCK_UNCLEAR_SPEC, "Unclear specification"],
  [REPORT_STUCK_PERSONAL_ISSUE, "Personal circumstances"],
  [REPORT_STUCK_OTHER, "Other"],
];

const countryListEU = [
  { code: "AT", name: "Austria" },
  { code: "BE", name: "Belgium" },
  { code: "BG", name: "Bulgaria" },
  { code: "HR", name: "Croatia" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "EE", name: "Estonia" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "DE", name: "Germany" },
  { code: "GR", name: "Greece" },
  { code: "HU", name: "Hungary" },
  { code: "IE", name: "Ireland" },
  { code: "IT", name: "Italy" },
  { code: "LV", name: "Latvia" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MT", name: "Malta" },
  { code: "NL", name: "Netherlands" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "RO", name: "Romania" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "ES", name: "Spain" },
  { code: "SE", name: "Sweden" },
  { code: "GB", name: "United Kingdom" },
];

const invoiceStatus = {
  DRAFT: "draft",
  REJECTED: "rejected",
  APPROVED: "approved",
  PENDING: "pending",
};

const skillsTitleDictionary = {
  "language": "Languages",
  "framework": "Frameworks",
  "library": "Libraries",
  "platform": "Platforms",
  "storage": "Storage",
  "api": "Api",
  "other": "Miscellaneous",
};

const emailRegex =
  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export {
  onboardingRouteLinks,
  countryListEU,
  REPORT_STATUSES,
  invoiceStatus,
  TIME_SHEET_MONTH_VIEW,
  TIME_SHEET_YEAR_VIEW,
  skillsTitleDictionary,
  emailRegex,
  AGREEMENT_VERSION,
  SEARCH_INITIAL_LIST,
};
