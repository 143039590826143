import axios from "axios";

import { ENDPOINT_SKILLS } from "../../utils/api";
import * as actionTypes from "../../configs/constants/ActionTypes";
import { formatErrorResponse, success } from "../../utils/actions";

const getSkillsStart = (filter, selection, prev_selection) => {
  return {
    type: actionTypes.GET_SKILLS_START,
    data: {
      filter,
      selection,
      prev_selection,
    },
  };
};

export function getSkills(filter, selection, prev_selection) {
  return (dispatch) => {
    dispatch(getSkillsStart(filter, selection, prev_selection));
    axios
      .get(ENDPOINT_SKILLS, { params: filter })
      .then(({ data }) => {
        dispatch(
          success(actionTypes.GET_SKILLS_SUCCESS, {
            items: data.results,
            previous: data.previous,
            next: data.next,
            count: data.count,
            selection,
            prev_selection,
          }),
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SKILLS_FAILED,
          data: {
            error: formatErrorResponse(error),
            selection,
            prev_selection,
          },
        });
      });
  };
}
