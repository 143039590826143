/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useParams, useLocation, Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import fileDownload from "js-file-download";
import moment from "moment";
import { DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../components/Icon";
import SectionNav from "../../../components/SectionNav";
import { isInternalUser, isDev } from "../../../utils/auth";
import DashboardLayout from "../../../layouts/DashboardLayout";
import InvoiceListContainer from "./InvoiceListContainer";
import Payments from "./Payments";

import Payouts from "./Payouts";
import { filterPaymentSummaries } from "../../../utils/invoiceUtils";
import PaymentTotals from "./components/PaymentTotals";
import { NavActions } from "./styles";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const PaymentsPage = () => {
  useTrackPage(pages.PAYMENTS);

  const [openBtn, setOpenBtn] = useState(false);
  const [createAction, setcreateAction] = useState(null);
  const { type } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { csv } = useSelector(({ Invoice }) => Invoice);
  const filter = pathname.split("/")[3];

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [summariesRange, setSummariesRange] = useState({
    start: `${moment(firstDay).format(moment.HTML5_FMT.DATE)}T00:00:00`,
    end: `${moment(new Date()).format(moment.HTML5_FMT.DATE)}T23:59:59`,
  });

  useEffect(() => {
    if (csv) {
      fileDownload(csv, `${filter?.toUpperCase()} ${type === "in" ? "Payments" : "Payouts"}.csv`);
      dispatch({ type: "CLEAR_CSV" });
    }
  }, [csv]);

  return (
    <DashboardLayout style={{ paddingTop: "1px" }}>
      <Wrapper>
        <SectionNav
          urlExact={false}
          links={[]}
          rightActions={
            <WrapperNavActions>
              <button
                type="button"
                className="text-capitalize btn filter-btn ml-0"
                data-testid="filterSummaries"
                onClick={() => filterPaymentSummaries(summariesRange, type, setSummariesRange)}
              >
                <Icon name="filter-variant" size="xs" /> Filter Total{" "}
                {type === "in" ? "Payments" : "Payouts"}
              </button>
              {isInternalUser() && (
                <ButtonDropdown
                  isOpen={openBtn}
                  toggle={() => setOpenBtn(!openBtn)}
                  data-testid="filterWrapper"
                >
                  <DropdownToggle color="primary" className="create-btn dropdown-toggle">
                    <Icon name="round-add" className="bsd-icon" />
                    Create
                    <Icon
                      style={{ marginTop: "10px" }}
                      name="arrow-down"
                      size="sm"
                      className="bsd-icon"
                    />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu">
                    {(createAction && createAction.add ? createAction.add : []).map((item, idx) => {
                      return (
                        <DropdownItem
                          key={idx}
                          className="dropdown-item add-btn"
                          onClick={item.action}
                          data-testid={item.title}
                        >
                          {item.title}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              )}
            </WrapperNavActions>
          }
        />
        <PaymentTotals type={type} summariesRange={summariesRange} />
        <SectionNav
          links={[
            ...(isDev()
              ? [
                  {
                    route: `payments/${type}/all`,
                    name: "All",
                  },
                  {
                    route: `payments/${type}/pending`,
                    name: "Pending",
                  },
                  {
                    route: `payments/${type}/paid`,
                    name: "Paid",
                  },
                ]
              : [
                  {
                    route: `payments/${type}/all`,
                    name: "All",
                  },
                  {
                    route: `payments/${type}/overdue`,
                    name: "Overdue",
                  },
                  {
                    route: `payments/${type}/pending`,
                    name: "Pending",
                  },
                  {
                    route: `payments/${type}/paid`,
                    name: "Paid",
                  },
                  ...(type === "in"
                    ? [
                        {
                          route: `payments/${type}/credit-notes`,
                          name: "Credit Notes",
                        },
                        {
                          route: `payments/${type}/commitment-payments`,
                          name: "Commitment Payments",
                        },
                      ]
                    : []),
                  {
                    route: `payments/${type}/archived`,
                    name: "Archived",
                  },
                ]),
          ]}
          style={{ marginTop: "40px" }}
        />
        <Switch>
          <Route
            path={`/payments/${type}/:filter`}
            render={(props) => {
              return (
                <InvoiceListContainer {...props} type={type}>
                  {type === "in" ? (
                    <Payments {...props} setcreateAction={setcreateAction} />
                  ) : (
                    <Payouts {...props} setcreateAction={setcreateAction} />
                  )}
                </InvoiceListContainer>
              );
            }}
          />
          <Redirect from="*" to={`/payments/${isDev() ? "out" : type}/all`} />
        </Switch>
      </Wrapper>
    </DashboardLayout>
  );
};

const Wrapper = styled.div`
  padding-top: 0px; /* TODO: moving this couldnt work cause navbar doesnt record right hieght on page reload */
  .filter-links a {
    margin-right: 20px;
  }
`;

const WrapperNavActions = styled(NavActions)`
  .filter-btn,
  .download-csv-btn {
    background: #ffffff;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    color: #8f9bb3;
    box-shadow: none !important;
  }

  .filter-btn,
  .create-btn {
    margin: 0 !important ;
  }

  .download-csv-btn,
  .btn-group {
    margin-left: 10px !important ;
  }

  .create-btn {
    background-color: #da3451;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    padding: 0 2em;
  }

  .dropdown-toggle::after {
    display: none;
  }

  /*override reactstrap inline styles*/
  .dropdown-menu {
    transform: translate3d(0px, 54px, 0px) !important;
    left: initial !important;
    right: 0px;
    border: 1px solid #edf1f7 !important;
    box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04) !important;
    border-radius: 6px !important;
    padding: 10px !important;
    line-height: 2rem;
    margin-top: -10px;

    .dropdown-item,
    .dropdown-item:hover {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      background-color: #fff !important;

      &:active {
        color: initial;
        background-color: initial;
      }
    }
  }
`;

export default PaymentsPage;
