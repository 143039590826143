import React from "react";
import axios from "axios";
import {
  ENDPOINT_ACTIVITIES,
  ENDPOINT_INVOICES,
  ENDPOINT_INVOICES_SUMMARY,
  ENDPOINT_GET_PLATFORM_SUMMARY,
} from "../../utils/api";
import * as types from "../../configs/constants/ActionTypes";
import { success, start, failed, formatErrorResponse } from "../../utils/actions";
import { openAlert } from "../../utils/modals";
import AlertDialogue from "../../components/AlertDialogue";
import { fetchProject } from "./ProjectActions";
import { trackEvent } from "../../analytics/segment";
import { EVENTS } from "../../analytics/events";

export function createInvoice(invoice) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_START));
    axios
      .post(ENDPOINT_INVOICES, invoice)
      .then((response) => {
        if (invoice.scope && invoice.scope === "project") {
          dispatch(fetchProject(invoice.project.id, true));
        }
        dispatch(success(types.CREATE_INVOICE_SUCCESS, response.data));

        trackEvent(EVENTS.create_new_payment, {
          project_id: invoice.project.id,
          project_name: invoice.projectTitle,
          payment_type: invoice.type,
        });
      })
      .catch((error) => {
        const err = error?.response?.data || {};

        dispatch({ type: types.CREATE_INVOICE_FAILED, error: { ...err, data: invoice } });
      });
  };
}

export function createInvoiceBatch(invoices, { callback }) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_BATCH_START));
    axios
      .post(`${ENDPOINT_INVOICES}bulk/`, invoices)
      .then((response) => {
        if (callback) callback();
        dispatch(success(types.CREATE_INVOICE_BATCH_SUCCESS, response.data));
      })
      .catch((error) => {
        const err = error?.response?.data || null;

        dispatch(failed(types.CREATE_INVOICE_BATCH_FAILED, { message: err }));
      });
  };
}

export function downloadInoicesCsv(invoiceSet) {
  return (dispatch) => {
    dispatch(start(types.DOWNLOAD_INVOICE_CSV_STARTED));
    axios
      .get(`${ENDPOINT_INVOICES}export/`, {
        params: invoiceSet,
      })
      .then(({ data }) => {
        dispatch(success(types.DOWNLOAD_INVOICE_CSV_SUCCESS, data));
      })
      .catch((error) => {
        dispatch(failed(types.DOWNLOAD_INVOICE_CSV_FAILED, error));
      });
  };
}

export function listInvoices(filter, search = false) {
  return (dispatch) => {
    dispatch(start(search ? types.SEARCH_INVOICES_START : types.LIST_INVOICES_START));
    // TODO: refactor even further
    axios
      .get(filter.archived === "True" ? `${ENDPOINT_INVOICES}archived/` : ENDPOINT_INVOICES, {
        params: filter,
      })
      .then((response) => {
        dispatch(
          success(
            search ? types.SEARCH_INVOICES_SUCCESS : types.LIST_INVOICES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        dispatch(failed(search ? types.SEARCH_INVOICES_FAILED : types.LIST_INVOICES_FAILED, error));
      });
  };
}

export function retrieveInvoice(id) {
  return (dispatch) => {
    dispatch(start(types.RETRIEVE_INVOICE_START));
    axios
      .get(`${ENDPOINT_INVOICES + id}/`)
      .then((response) => {
        dispatch(success(types.RETRIEVE_INVOICE_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.RETRIEVE_INVOICE_FAILED, error));
      });
  };
}

export function updateInvoice(id, invoice) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_INVOICE_START));
    axios
      .patch(`${ENDPOINT_INVOICES + id}/`, invoice)
      .then((response) => {
        if (invoice.successMsg) {
          openAlert({
            body: <AlertDialogue msg={invoice.successMsg} />,
            canClose: true,
            options: {
              className: "alert-dailogue",
              hideActions: true,
              hideBackdrop: true,
            },
          });
        }
        dispatch(success(types.UPDATE_INVOICE_SUCCESS, response.data));

        const eventObjMap = {
          "send for approval": EVENTS.click_send_for_approval_action,
          "approved": EVENTS.click_approve_invoice_action,
          "rejected": EVENTS.click_reject_invoice_action,
          "updated": EVENTS.click_edit_invoice_action,
          "mark as paid": EVENTS.click_mark_invoice_as_paid_action,
        };

        const event = eventObjMap[invoice.action.toLowerCase()];

        if (event) {
          trackEvent(event, {
            project_name: response.data.project.title,
            invoice_id: response.data.id,
            invoice_name: response.data.title,
            action: invoice.action,
          });
        }
      })
      .catch((error) => {
        const errorMsg = formatErrorResponse(error);
        openAlert({
          body: <AlertDialogue msg={errorMsg.message} iconClass="bs-x-danger" />,
          canClose: true,
          options: {
            className: "error-dailogue",
            hideActions: true,
            hideBackdrop: true,
          },
        });
        dispatch(failed(types.UPDATE_INVOICE_FAILED, errorMsg));
      });
  };
}

export function paymentReminder(invoice) {
  return (dispatch) => {
    dispatch(start(types.PAYMENT_REMINDER_START));
    axios
      .post(`${ENDPOINT_INVOICES}${invoice.id}/payment-reminder/`)
      .then((response) => {
        if (response.status === 200) {
          openAlert({
            body: <AlertDialogue msg={response.data.message} />,
            canClose: true,
            options: {
              className: "alert-dailogue",
              hideActions: true,
              hideBackdrop: true,
            },
          });
        }
        dispatch(success(types.PAYMENT_REMINDER_SUCCESS, response.data));

        trackEvent(EVENTS.click_send_invoice_reminder_action, {
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.project.title,
          action: "send reminder",
        });
      })
      .catch((error) => {
        dispatch(failed(types.PAYMENT_REMINDER_FAILED, error));
      });
  };
}

export function listMoreInvoices(url, search = false) {
  return (dispatch) => {
    dispatch(start(search ? types.SEARCH_MORE_INVOICES_START : types.LIST_MORE_INVOICES_START));
    axios
      .get(url)
      .then((response) => {
        dispatch(
          success(
            search ? types.SEARCH_MORE_INVOICES_SUCCESS : types.LIST_MORE_INVOICES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          failed(
            search ? types.SEARCH_MORE_INVOICES_FAILED : types.LIST_MORE_INVOICES_FAILED,
            error,
          ),
        );
      });
  };
}

export function deleteInvoice(invoice) {
  return (dispatch) => {
    dispatch(start(types.DELETE_INVOICE_START));
    axios
      .delete(`${ENDPOINT_INVOICES + invoice.id}/`)
      .then(() => {
        trackEvent(EVENTS.click_delete_invoice_action, {
          action: "delete",
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.full_title.split(":")[0],
        });

        dispatch(
          success(types.DELETE_INVOICE_SUCCESS, {
            id: invoice.id,
          }),
        );

        if (invoice?.scope && invoice?.scope === "project") {
          dispatch(fetchProject(invoice.project.id, true));
        }
      })
      .catch((error) => {
        dispatch(failed(types.DELETE_INVOICE_FAILED, error));
      });
  };
}

export function archiveInvoice(invoice) {
  return (dispatch) => {
    dispatch(start(types.ARCHIVE_INVOICE_START));
    axios
      .post(`${ENDPOINT_INVOICES}${invoice.id}/archive/`)
      .then(() => {
        openAlert({
          body: <AlertDialogue msg="Payment archived successfully" />,
          canClose: true,
          options: {
            className: "alert-dailogue",
            hideActions: true,
            hideBackdrop: true,
          },
        });

        dispatch(
          success(types.ARCHIVE_INVOICE_SUCCESS, {
            id: invoice.id,
          }),
        );

        trackEvent(EVENTS.click_archive_action, {
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.project.title,
          action: "archived",
        });
      })
      .catch((error) => {
        dispatch(failed(types.ARCHIVE_INVOICE_FAILED, error));
      });
  };
}

export function generateInvoice(id) {
  return (dispatch) => {
    dispatch(start(types.GENERATE_INVOICE_START));
    axios
      .post(`${ENDPOINT_INVOICES}${id}/generate/`)
      .then((response) => {
        openAlert({
          body: <AlertDialogue msg="Invoice generated successfully" />,
          canClose: true,
          options: {
            className: "alert-dailogue",
            hideActions: true,
            hideBackdrop: true,
          },
        });

        dispatch(success(types.GENERATE_INVOICE_SUCCESS, response.data));
        trackEvent(EVENTS.click_generate_invoice_action, {
          project_name: response.data.project.title,
          invoice_id: response.data.id,
          invoice_name: response.data.title,
          action: "generate",
        });
      })
      .catch((error) => {
        dispatch(failed(types.GENERATE_INVOICE_FAILED, error));
      });
  };
}

export function bulkAction(invoices, action) {
  return (dispatch) => {
    dispatch(start(types.BULK_ACTION_START));
    axios
      .patch(`${ENDPOINT_INVOICES}bulk/edit/`, {
        invoices,
        action,
      })
      .then((response) => {
        const message = {
          approve: "Invoices approved",
          archive: "Invoices archived",
          mark_as_paid: "Invoices  marked as paid",
          generate: "Invoices generated",
          delete: "Invoices deleted",
        };

        openAlert({
          body: (
            <AlertDialogue
              msg={action === "send_reminder" ? response.data.message : message[action]}
            />
          ),
          canClose: true,
          options: {
            className: "alert-dailogue",
            hideActions: true,
            hideBackdrop: true,
          },
        });
        dispatch(success(types.BULK_ACTION_SUCCESS, {}));
      })
      .catch((error) => {
        dispatch(failed(types.BULK_ACTION_FAILED, error));
      });
  };
}

export function getInvoiceSummary(params) {
  return (dispatch) => {
    dispatch(start(types.INVOICE_SUMMARY_START));
    axios
      .get(`${ENDPOINT_INVOICES_SUMMARY}`, {
        params,
      })
      .then((response) => {
        dispatch(success(types.INVOICE_SUMMARY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.INVOICE_SUMMARY_FAILED, error));
      });
  };
}

export function getPlatformSummary(params) {
  return (dispatch) => {
    dispatch(start(types.GET_PLATFORM_SUMMARY_START));
    axios
      .get(`${ENDPOINT_GET_PLATFORM_SUMMARY}`, {
        params,
      })
      .then((response) => {
        dispatch(success(types.GET_PLATFORM_SUMMARY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.GET_PLATFORM_SUMMARY_FAILED, error));
      });
  };
}

export function getInvoiceActivity(id) {
  return (dispatch) => {
    dispatch(start(types.INVOICE_ACTIVITY_START));

    axios
      .get(`${ENDPOINT_ACTIVITIES}?invoice=${id}`)
      .then((response) => {
        dispatch(success(types.INVOICE_ACTIVITY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.INVOICE_ACTIVITY_FAILED, formatErrorResponse(error)));
      });
  };
}

export function addInvoiceComments(invoiceId, comment, callback) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_COMMENT_START));

    axios
      .post(`${ENDPOINT_INVOICES}${invoiceId}/comments/`, { body: comment })
      .then((response) => {
        if (callback) callback();
        dispatch(success(types.CREATE_INVOICE_COMMENT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.CREATE_INVOICE_COMMENT_FAILED, formatErrorResponse(error)));
      });
  };
}

export function getInvoiceComments(invoiceId) {
  return (dispatch) => {
    dispatch(start(types.GET_INVOICE_COMMENTS_START));

    axios
      .get(`${ENDPOINT_ACTIVITIES}?invoice=${invoiceId}&verb=invoice_comment`)
      .then((response) => {
        dispatch(success(types.GET_INVOICE_COMMENTS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.GET_INVOICE_COMMENTS_FAILED, formatErrorResponse(error)));
      });
  };
}
