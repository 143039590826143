import React from "react";
import axios from "axios";
import { ENDPOINT_USERS } from "../../utils/api";
import { openAlert } from "../../utils/modals";
import AlertDialogue from "../../components/AlertDialogue";
import {
  LIST_USERS_START,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILED,
  DEACTIVATION_ACTIVATION_USER_START,
  DEACTIVATION_ACTIVATION_USER_SUCCESS,
  DEACTIVATION_ACTIVATION_USER_FAILED,
  LIST_TALENTS_START,
  LIST_TALENTS_SUCCESS,
  LIST_TALENTS_FAILED,
} from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, success } from "../../utils/actions";

export const listUsers = (filter, selection, prev_selection) => {
  return (dispatch) => {
    dispatch({
      type: LIST_USERS_START,
      filter,
      selection,
      prev_selection,
    });

    axios
      .get(ENDPOINT_USERS, {
        params: filter,
      })
      .then((response) => {
        dispatch(success(LIST_USERS_SUCCESS, { ...response.data, filter, selection }));
      })
      .catch((error) => {
        dispatch(
          failed(
            LIST_USERS_FAILED,
            error.response
              ? error.response.data
              : {
                  message: "Could not fetch results",
                },
          ),
        );
      });
  };
};

export const listTalents = (filter, { silent = false, cancelToken } = {}) => {
  return (dispatch) => {
    if (!silent) {
      dispatch({
        type: LIST_TALENTS_START,
      });
    }

    axios
      .get(ENDPOINT_USERS, {
        cancelToken,
        params: {
          type: 1,
          ...filter,
        },
      })
      .then((response) => {
        dispatch(success(LIST_TALENTS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(LIST_TALENTS_FAILED, formatErrorResponse(error)));
      });
  };
};

export const deactivate_reactivate_User = (id, action, selectionKey) => {
  return (dispatch) => {
    dispatch({
      type: DEACTIVATION_ACTIVATION_USER_START,
      action,
      selectionKey,
    });
    axios
      .post(`${ENDPOINT_USERS}${id}/status/change/`, action)
      .then((response) => {
        dispatch(
          success(DEACTIVATION_ACTIVATION_USER_SUCCESS, { ...response.data, action, selectionKey }),
          openAlert({
            body: <AlertDialogue msg={`${response.data.message}`} />,
            canClose: false,
            options: {
              className: "alert-dailogue",
              hideActions: true,
              hideBackdrop: true,
            },
          }),
        );
      })
      .catch((error) => {
        dispatch(
          failed(
            DEACTIVATION_ACTIVATION_USER_FAILED,
            error.response ? error.response.data : null,
            selectionKey,
          ),
          openAlert({
            body: <AlertDialogue msg={`${error.response.data.message}`} />,
            canClose: false,
            options: {
              className: "error-dailogue",
              hideActions: true,
              hideBackdrop: true,
            },
          }),
        );
      });
  };
};
