import axios from "axios";
import * as actionTypes from "../../configs/constants/ActionTypes";
import { ENDPOINT_INVITE, ENDPOINT_USERS } from "../../utils/api";
import { success } from "../../utils/actions";
import Alert from "../../utils/alert";

// TODO:
// - Work error handling
// - Loading via button

export function createUser(data) {
  return (dispatch, sideEffect = null) =>
    axios
      .post(ENDPOINT_USERS, data)
      .then(() => {
        dispatch(success(actionTypes.SET_BUTTON, false));
        Alert("Client Successfully Created.");
        if (sideEffect) sideEffect();
      })
      .catch((error) => {
        dispatch(success(actionTypes.SET_BUTTON, false));
        if (error.response.data && error.response.data.email) {
          Alert(error.response.data.email[0], false);
        }
      });
}

export function getPendingInvites() {
  return (dispatch) => {
    axios
      .get(ENDPOINT_INVITE, {
        params: { used: "False" },
      })
      .then((response) => {
        dispatch(success(actionTypes.SET_PENDING_INVITES, response.data));
      })
      .catch(() => {});
  };
}

export function getMorePendingInvites(url) {
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(success(actionTypes.SET_MORE_PENDING_INVITES, response.data));
      })
      .catch(() => {});
  };
}

export function deleteInvite(id) {
  return (dispatch) => {
    dispatch(success(actionTypes.DELETE_PENDING_INVITE, id));
    axios
      .delete(`${ENDPOINT_INVITE}${id}/`, {})
      .then(() => {
        dispatch(getPendingInvites());
      })
      .catch(() => {});
  };
}

export function invite(data, isPatch = null) {
  const request_method = isPatch ? "patch" : "post";

  return (dispatch, sideEffect = null) =>
    axios
      .request({
        url: `${ENDPOINT_INVITE}${request_method === "patch" ? `${data.id}/` : ""}`,
        method: request_method,
        data,
      })
      .then(() => {
        Alert("Invitation Successfully Sent.");
        if (sideEffect) sideEffect();

        return dispatch(success(actionTypes.SET_BUTTON, false));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.email) {
          // Request made and server responded
          Alert(error.response.data.email[0], false);
        }
        dispatch(success(actionTypes.SET_BUTTON, false));
      });
}

export const retrieveInviteStart = (key) => {
  return {
    type: actionTypes.RETRIEVE_INVITE_START,
    key,
  };
};

export const retrieveInviteSuccess = (data) => {
  return {
    type: actionTypes.RETRIEVE_INVITE_SUCCESS,
    invite: data,
  };
};

export const retrieveInviteFailed = (error) => {
  return {
    type: actionTypes.RETRIEVE_INVITE_FAILED,
    error,
  };
};

export const retrieveInvite = (key) => {
  return (dispatch) => {
    dispatch(retrieveInviteStart(key));
    axios
      .get(`${ENDPOINT_INVITE}key/${key}/`)
      .then((response) => {
        dispatch(retrieveInviteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(retrieveInviteFailed(error.response ? error.response.data : null));
      });
  };
};
