export function filterValidProps(allowedPropKeys, props) {
  const filteredProps = {};
  (allowedPropKeys || []).forEach((item) => {
    if (props[item] || props[item] === "" || typeof props[item] === "boolean") {
      filteredProps[item] = props[item] || "";
    }
  });
  return filteredProps;
}

export function filterInputProps(props) {
  return filterValidProps(
    [
      "id",
      "name",
      "value",
      "defaultValue",
      "required",
      "disabled",
      "autoComplete",
      "step",
      "pattern",
      "aria-labelledby",
      "data-testid",
    ],
    props,
  );
}

export function filterButtonProps(props) {
  return filterValidProps(["disabled"], props);
}

export const getFormData = (formdata) => {
  const data = {};
  // eslint-disable-next-line no-return-assign
  formdata.forEach((val, key) => (data[key] = val));
  return data;
};
