/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Icon from "../../../../../../components/Icon";
import TextArea from "../../../../../../components/TextArea";
import Input from "../../../../../../components/Input";
import IconButton from "../../../../../../components/IconButton";
import Upload from "../../../../../../components/Upload";
import SmileysModal from "../../../../../../components/SmileysModal";
import { pxToRem } from "../../../../../../assets/theme/functions";
import { isCM, isCSO, isDev } from "../../../../../../utils/auth";
import { StyledDateTimePicker } from "../../../../../../utils/styles";
import { REPORT_STATUSES, REPORT_STUCK_REASONS } from "../../../../../../configs/constants/global";
import {
  submitDeveloperRating,
  updateProgressReport,
} from "../../../../../../redux/actions/ProjectActions";

const PersonalSurvey = () => {
  const { progress_event, project, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const dispatch = useDispatch();

  const [disclaimerNotice, setDisclaimerNotice] = useState(true);
  const [developers, setDevelopers] = useState([]);
  const [report, setReport] = useState({
    percentage: "",
    status: "",
    accomplished: "",
    uploads: "",
    rate_deliverables: "",
    todo: "",
    next_deadline: "",
    next_deadline_meet: "",
    last_deadline_met: "",
    next_deadline_fail_reason: "",
    remarks: "",
    stuck_reason: "",
    deadline_report: "",
    deadline_miss_communicated: "",
  });

  useEffect(() => {
    if (progress_event.progress_reports && progress_event.progress_reports.length > 0) {
      setReport({ ...report, ...progress_event.progress_reports[0] });
    } else {
      setReport({
        percentage: "",
        status: "",
        accomplished: "",
        uploads: "",
        rate_deliverables: "",
        todo: "",
        next_deadline: "",
        next_deadline_meet: "",
        last_deadline_met: "",
        next_deadline_fail_reason: "",
        remarks: "",
        stuck_reason: "",
        deadline_report: "",
        deadline_miss_communicated: "",
      });
    }
  }, [progress_event]);

  useEffect(() => {
    if (progress_event.project && Object.keys(project || {}).length !== 0) {
      const latestRatings = progress_event.developer_ratings || [];
      const projectDevs = project.participation.map((participant) => {
        return {
          id: "",
          name: participant.user.display_name,
          rating: "",
          user: participant.user.id,
        };
      });

      const userIDs = {};
      for (let i = 0; i < projectDevs.length; i += 1) {
        const dev = projectDevs[i];
        userIDs[dev.user] = i;
      }

      for (let i = 0; i < latestRatings.length; i += 1) {
        const rating = latestRatings[i];
        const availableIDs = Object.keys(userIDs);
        if (availableIDs.indexOf(rating.user.id.toString()) > -1) {
          const matchIndex = userIDs[rating.user.id];
          projectDevs[matchIndex].rating = `${latestRatings[i].rating}`;
          projectDevs[matchIndex].id = latestRatings[i].id;
        }
      }
      setDevelopers(projectDevs);
    }
  }, [project]);

  const onChangeValue = (key, value) => {
    const newState = {};
    newState[key] = value;
    setReport((prevReport) => ({ ...prevReport, ...newState }));
  };

  const removeUploads = () => {
    setReport({ ...report, uploads: [] });
  };

  const onChange = (value) => {
    onChangeValue("rate_deliverables", value);
  };

  const onRateDevelopers = (value, idx) => {
    const devs = developers;
    devs[idx] = { ...devs[idx], rating: value };
    setDevelopers([...devs]);
  };

  const closeDisclaimer = (e) => {
    e.preventDefault();
    setDisclaimerNotice(false);
  };

  const confirmAtLeastRatedOne = () => {
    let ratedOne = false;
    if (developers.length === 0) {
      ratedOne = true;
    }
    developers.forEach((dev) => {
      if (dev.rating !== "") {
        ratedOne = true;
      }
    });
    return ratedOne;
  };

  const checkValidFields = () =>
    !!(
      !report.status ||
      !report.percentage ||
      report.percentage === "0" ||
      !report.accomplished ||
      (isDev() && (!report.rate_deliverables || report.next_deadline_meet === "")) ||
      (report.status === "behind_stuck" && !report.stuck_reason) ||
      !report.todo ||
      ((isCM() || isCSO()) && report.last_deadline_met === "") ||
      ((isCM() || isCSO()) &&
        !report.last_deadline_met &&
        (!report.deadline_report || report.deadline_miss_communicated === "")) ||
      ((isCM() || isCSO()) && !confirmAtLeastRatedOne()) ||
      !report.next_deadline
    );

  const handleDeveloperRating = (event) => {
    if (!confirmAtLeastRatedOne()) {
      return;
    }
    developers.forEach((dev) => {
      const proxyDev = { ...dev };
      proxyDev.event = { ...event };
      if (proxyDev.rating !== "") {
        dispatch(
          submitDeveloperRating({
            ...proxyDev,
          }),
        );
      }
    });
  };

  const onSave = (e) => {
    e.preventDefault();

    if (checkValidFields()) {
      // eslint-disable-next-line no-alert
      alert("Please fill in required details");
      return false;
    }

    const reqData = {
      event: { id: progress_event.id },
      ...report,
    };

    if (isCM() || isCSO()) {
      delete reqData.rate_deliverables;
    }

    const progress_report = progress_event.progress_reports[0];
    dispatch(updateProgressReport(progress_report?.id || null, reqData));

    if (isCM() || isCSO()) {
      handleDeveloperRating(reqData.event);
    }

    return true;
  };

  return (
    <Container data-testid="personalSurvey-form" className="container" onSubmit={onSave}>
      <div className="content">
        {disclaimerNotice && (
          <div className="section">
            <div className="warning">
              <Icon name="information-outline" />

              <p>
                Please take into account that{" "}
                <b>these answers will be shared directly with clients.</b> Therefore, please{" "}
                <b>always consider if your answers are business/client appropriate.</b> Thank you
                for filling in the report!
              </p>

              <button className="btn" type="button" onClick={closeDisclaimer}>
                I understand, dismiss
              </button>
            </div>
          </div>
        )}

        <div className="section">
          <h4 className="title">
            Task Status<span className="label-style">*</span>
          </h4>

          <FormGroup className="custom-radio-group">
            {REPORT_STATUSES.map((status) => (
              <div className="radio-button" key={status[0]}>
                <input
                  type="radio"
                  name="status"
                  id={status[0]}
                  checked={report.status === status[0]}
                  onChange={() => onChangeValue("status", status[0])}
                />
                <label htmlFor={status[0]}>{status[1]}</label>
              </div>
            ))}
          </FormGroup>
        </div>

        {report.status === "behind_stuck" && (
          <div className="section">
            <h4>
              What is the reason for being stuck?
              <span className="label-style">*</span>
            </h4>

            <FormGroup className="custom-radio-group">
              {REPORT_STUCK_REASONS.map((status) => (
                <div className="radio-button" key={status[0]}>
                  <input
                    type="radio"
                    name="stuck_reason"
                    id={status[0]}
                    checked={report.stuck_reason === status[0]}
                    onChange={() => onChangeValue("stuck_reason", status[0])}
                  />
                  <label htmlFor={status[0]}>{status[1]}</label>
                </div>
              ))}
            </FormGroup>
          </div>
        )}

        {(isCM() || isCSO()) && (
          <>
            <div className="section">
              <h4 className="title">
                Was the last deadline met?
                <span className="label-style">*</span>
              </h4>
              <FormGroup className="custom-radio-group">
                <div className="radio-button">
                  <input
                    type="radio"
                    name="last_deadline_met"
                    id="deadline-yes"
                    checked={report.last_deadline_met === true}
                    onChange={() => onChangeValue("last_deadline_met", true)}
                  />
                  <label htmlFor="deadline-yes">Yes</label>
                </div>
                <div className="radio-button">
                  <input
                    type="radio"
                    name="last_deadline_met"
                    id="deadline-no"
                    checked={report.last_deadline_met === false}
                    onChange={() => onChangeValue("last_deadline_met", false)}
                  />
                  <label htmlFor="deadline-no">No</label>
                </div>
              </FormGroup>
            </div>

            {typeof report.last_deadline_met === "boolean" && !report.last_deadline_met && (
              <>
                <div className="section">
                  <label htmlFor="deadline_report">
                    Why wasn&#39;t the last deadline met? Please provide a detailed explanation.
                    <span className="label-style">*</span>
                  </label>
                  <FormGroup>
                    <TextArea
                      id="deadline_report"
                      onChange={(e) => onChangeValue("deadline_report", e.target.value)}
                      value={report.deadline_report}
                      placeholder="Enter answer here"
                    />
                  </FormGroup>
                </div>
                <div className="section">
                  <h4 className="title">
                    Did you inform the client promptly about not making the deadline?
                    <span className="label-style">*</span>
                  </h4>
                  <FormGroup className="custom-radio-group">
                    <div className="radio-button">
                      <input
                        type="radio"
                        name="deadline_miss_communicated"
                        id="deadline-communicated-yes"
                        checked={report.deadline_miss_communicated === true}
                        onChange={() => onChangeValue("deadline_miss_communicated", true)}
                      />
                      <label htmlFor="deadline-communicated-yes">Yes</label>
                    </div>
                    <div className="radio-button">
                      <input
                        type="radio"
                        name="deadline_miss_communicated"
                        id="deadline-communicated-no"
                        checked={report.deadline_miss_communicated === false}
                        onChange={() => onChangeValue("deadline_miss_communicated", false)}
                      />
                      <label htmlFor="deadline-communicated-no">No</label>
                    </div>
                  </FormGroup>
                </div>
              </>
            )}
          </>
        )}

        <div className="section">
          <h4>
            Percentage completed until next deadline
            <span className="label-style">*</span>
          </h4>
          <div className="progress-wrapper">
            <div>
              <input
                className="inp-wid"
                type="range"
                onChange={(e) => onChangeValue("percentage", e.target.value)}
                value={parseInt(report.percentage, 10) || 0}
              />
            </div>
            <div className="input-group suffix">
              <Input
                onChange={(e) => onChangeValue("percentage", e.target.value)}
                placeholder="0"
                value={report.percentage}
                min="0"
                type="number"
                max="100"
              />
              <span className="input-group-addon">%</span>
            </div>
          </div>
        </div>

        <div className="section">
          <label htmlFor="accomplished">
            What has been accomplished since the last update?
            <span className="label-style">*</span>
          </label>
          <FormGroup>
            <TextArea
              id="accomplished"
              onChange={(e) => onChangeValue("accomplished", e.target.value)}
              value={report.accomplished}
              placeholder="Enter accomplishments here"
            />
          </FormGroup>
        </div>

        <div className="section">
          <h4>Files (if any)</h4>

          {report.uploads.length > 0 && (
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0">
                <Icon name="paperclip" /> {report.uploads[0]?.name}
              </p>
              <IconButton onClick={removeUploads} className="p-0" name="delete-outline" size="sm" />
            </div>
          )}

          <FormGroup>
            <Upload
              type="image/*"
              files={report.uploads}
              placeholder={
                report.attachments &&
                report.attachments.length > 0 && (
                  <img
                    className="text-dark"
                    src={report.attachments[0].url}
                    height="130px"
                    title="File"
                    alt="File"
                  />
                )
              }
              onChange={(uploads) => {
                onChangeValue("uploads", uploads);
              }}
            />
          </FormGroup>
        </div>

        {isDev() && (
          <div className="section">
            <label htmlFor="rating">
              How would you rate your latest deliverable?
              <span className="label-style">*</span>
            </label>

            <SmileysModal
              index={1}
              name="rating"
              selected={`${report.rate_deliverables}`}
              onChange={onChange}
            />
          </div>
        )}

        <div className="section">
          <label htmlFor="todo">
            {isCM() || isCSO()
              ? "What are the next steps?"
              : "What do you intend to achieve/complete today?"}
            <span className="label-style">*</span>
          </label>
          <FormGroup>
            <TextArea
              id="todo"
              onChange={(e) => onChangeValue("todo", e.target.value)}
              value={report.todo}
              placeholder="Enter answer here"
              required
            />
          </FormGroup>
        </div>

        <div className="section">
          <h4>
            When is the next deadline?
            <span className="label-style">*</span>
          </h4>
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              className="tg-date-field"
              placeholder="Select date"
              format="DD MMM YYYY"
              $time={false}
              value={report.next_deadline ? new Date(report.next_deadline) : null}
              onChange={
                /* istanbul ignore next */
                (date) => {
                  onChangeValue("next_deadline", moment(date).format());
                }
              }
              required
            />
          </FormGroup>
        </div>

        {isDev() && (
          <>
            <div className="section">
              <h4 className="title">
                Do you anticipate to meet this deadline?
                <span className="label-style">*</span>
              </h4>
              <FormGroup className="custom-radio-group">
                <div className="radio-button">
                  <input
                    type="radio"
                    name="next_deadline_meet"
                    id="next-deadline-yes"
                    checked={report.next_deadline_meet === true}
                    onChange={() => onChangeValue("next_deadline_meet", true)}
                    required
                  />
                  <label htmlFor="next-deadline-yes">Yes</label>
                </div>
                <div className="radio-button">
                  <input
                    type="radio"
                    name="next_deadline_meet"
                    id="next-deadline-no"
                    checked={report.next_deadline_meet === false}
                    onChange={() => onChangeValue("next_deadline_meet", false)}
                  />
                  <label htmlFor="next-deadline-no">No</label>
                </div>
              </FormGroup>
            </div>
            {typeof report.next_deadline_meet === "boolean" && !report.next_deadline_meet && (
              <>
                <div className="section">
                  <label htmlFor="next_deadline_fail_reason">
                    What is preventing you from meeting this deadline?
                  </label>
                  <FormGroup>
                    <TextArea
                      id="next_deadline_fail_reason"
                      onChange={(e) => onChangeValue("next_deadline_fail_reason", e.target.value)}
                      value={report.next_deadline_fail_reason}
                      placeholder="Enter answer here"
                    />
                  </FormGroup>
                </div>
                <div className="section">
                  <h4>What can be done to make sure the next deadline is met?</h4>
                  <FormGroup>
                    <TextArea
                      onChange={(e) => onChangeValue("remarks", e.target.value)}
                      value={report.remarks}
                      placeholder="Enter answer here"
                    />
                  </FormGroup>
                </div>
              </>
            )}
          </>
        )}

        {(isCM() || isCSO()) && developers.length > 0 && (
          <div className="section">
            <h4>
              Are you satisfied with the performance of the developers on this project, please give
              details?
              <span className="label-style">*</span>
            </h4>
            {developers.map((dev, index) => (
              <div key={index} style={{ marginBottom: "16px" }}>
                How do you rate {dev.name}
                <SmileysModal
                  index={index}
                  name={`rating-${dev.user}`}
                  selected={dev.rating}
                  onChange={onRateDevelopers}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="footer">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={isMakingRequest?.progressReport || checkValidFields()}
        >
          Submit Report
        </button>
      </div>
    </Container>
  );
};

const Container = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
  .content {
    padding: 40px;
  }

  .footer {
    padding: 20px 40px;
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;

    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .section {
    padding: 40px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0px solid #edf1f7;
    }

    h4,
    label {
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 19px;
      margin-bottom: 0.9rem;
      color: #151a30;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3e4857;

      i {
        color: #8f9bb3;
        font-size: 16px;
      }
    }

    button.btn-icon {
      i.tg-ic-delete-outline {
        color: #8f9bb3;
        font-size: 18px;
      }
    }

    textarea {
      min-height: 100px;
    }

    input,
    textarea,
    select {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }

      &.rw-input {
        padding-left: 1.25rem;
      }
    }

    .warning {
      padding: 24px;
      background: rgba(235, 87, 87, 0.05);
      border-radius: 6px;

      i {
        font-size: 20px;
        position: absolute;
        color: #eb5757;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #eb5757;
        padding-left: 40px;

        b {
          font-weight: 600;
        }
      }

      button.btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #062e64;
        text-decoration: none;
        margin-left: 40px;
        padding: 0;
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .custom-radio-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      .radio-button {
        margin-right: 16px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    .custom-radio-group input[type="radio"] {
      position: absolute;
      left: -9999px;
    }

    .custom-radio-group input[type="radio"] + label {
      position: relative;
      padding: 0 0 0 30px;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      color: #151a30;
      font-weight: 300;
      min-width: 128px;
    }

    .custom-radio-group input[type="radio"] + label:before {
      content: "";
      background: #fff;
      border: 2px solid #151a30;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0;
    }

    .custom-radio-group input[type="radio"] + label:after {
      content: "";
      background: #151a30;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 5px;
      opacity: 0;
      transform: scale(2);
      transition: transform 0.3s linear, opacity 0.3s linear;
    }

    .custom-radio-group input[type="radio"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

    .progress-wrapper {
      display: flex;
      flex-direction: row;
      height: 35px;
      align-items: center;

      > div {
        flex: 1;
        position: relative;
      }

      /* chrome, safari, edge, opera -- hide arrow on type number */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox -- hide arrow on type number */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .inp-wid {
        width: 100%;
      }
      .input-group.suffix {
        position: relative;
        display: table;
        border-collapse: separate;
        width: 100px;
        flex: none;
        margin-left: 40px;

        .input-group-addon {
          border-left: 0;
          border-radius: 0px 4px 4px 0px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          text-align: center;
          border: 1px solid rgba(194, 204, 217, 0.25);
          width: 1%;
          white-space: nowrap;
          vertical-align: middle;
          display: table-cell;
          border-left: none;
          padding-right: 24px;
          color: #8f9bb3;
          font-size: 16px;
        }

        input {
          border-radius: 4px 0px 0px 4px;
          position: relative;
          z-index: 2;
          float: left;
          width: 100%;
          margin-bottom: 0;
          display: table-cell;
          padding: 8px 0 8px 24px;
          border: 1px solid rgba(194, 204, 217, 0.25);
          border-right: none;
          margin: 0;
          text-align: end;
        }
      }
    }
  }
`;

export default PersonalSurvey;
