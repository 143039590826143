/* eslint-disable react/no-unstable-nested-components */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { IBAN_UNPAID_EXPORT } from "../../../../utils/api";
import { getInvoiceSummary, getPlatformSummary } from "../../../../redux/actions/InvoiceActions";
import { uploadCsv } from "../../../../redux/actions/PaymentActions";
import { numberWithCommas } from "../../../../utils/stringUtils";
import { isAdmin, isAdminOrPMOrSpaceLead, isPayAdmin } from "../../../../utils/auth";
import usePrevious from "../../../../hooks/usePrevious";
import useDidUpdate from "../../../../hooks/useDidUpdate";
import PayoutSummary from "../PayoutSummary";
import upArrow from "../../../../assets/images/icons/chevron-up.svg";
import downArrow from "../../../../assets/images/icons/chevron-down.svg";
import cloud_upload from "../../../../assets/images/icons/upload-cloud.svg";
import cloud_download from "../../../../assets/images/icons/download-cloud.svg";
import AlertDialogue from "../../../../components/AlertDialogue";
import { openAlert } from "../../../../utils/modals";
import { markAllInvoicesPrompt } from "../../../../utils/invoiceUtils";

const PaymentTotals = ({ type, project, summariesRange }) => {
  const [download, setDownload] = useState(true);
  const [unpaidIban, setUnpaidIban] = useState(0);
  const dispatch = useDispatch();
  const { summary, list, platformSummary } = useSelector(({ Invoice }) => Invoice);
  const { project: activeProject } = useSelector(({ Projects }) => Projects);

  const prevList = usePrevious(list);

  const isPayment = type === "in";
  const title = isPayment ? "Payments" : "Payouts";

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";

  const showUSDSummary =
    (isPayment && scope === "sideBarPayment") || // Show USD summary if on main payment page
    (isPayment && scope === "project" && activeProject?.currency === "USD") || // Show USD summary if on project payment page and project currency is USD
    (isPayment &&
      scope === "project" &&
      activeProject?.currency !== "USD" &&
      summary?.USD?.total !== 0); // Show USD summary if on project payment page and project currency is EUR but summary total in USD as a value

  const showEURSummary =
    scope === "sideBarPayment" || // Show EUR summary if on main payment page
    (scope === "project" && !isPayment) || // Show EUR summary if on project payout page
    (scope === "project" && activeProject?.currency !== "USD") || // Show EUR summary if on project payout page and project currency is EUR
    (scope === "project" && activeProject?.currency === "USD" && summary?.EUR?.total !== 0); // Show EUR summary if on project payment page and project currency is USD but summary total in EUR as a value

  const listClassName = showUSDSummary && showEURSummary ? "align-list" : "";

  const invoiceSummary = useCallback(() => {
    const queryParams = {
      min_date: summariesRange?.start,
      max_date: summariesRange?.end,
      type: type === "in" ? "sale" : "purchase",
    };

    if (project) queryParams.project = project;

    getInvoiceSummary(queryParams)(dispatch);
    if (isAdmin() || isPayAdmin()) getPlatformSummary()(dispatch);
  }, [type]);

  const fileInput = useRef();

  const onUpload = (e) => {
    markAllInvoicesPrompt(e.target.files[0], uploadCsv);
  };

  const onTrigger = () => {
    /* Collecting node-element and performing click */
    fileInput.current.value = "";
    fileInput.current.click();
  };

  const showExportInfo = () => {
    // Notify user
    if (!(unpaidIban > 0))
      openAlert({
        body: <AlertDialogue msg="No unpaid payouts found" />,
        canClose: true,
        options: {
          className: "alert-dailogue",
          hideActions: true,
          hideBackdrop: true,
        },
      });
  };

  const up_down = {
    height: "16px",
    marginTop: "1.9px",
    marginLeft: "10px",
    cursor: "pointer",
  };
  const arrow = {
    marginTop: "-6px",
    height: "14px",
    cursor: "pointer",
  };
  const DownloadIcon = () => (
    <span
      aria-hidden="true"
      style={{ marginTop: "-5px" }}
      tabIndex={0}
      role="button"
      onClick={showExportInfo}
    >
      <img alt="dload" src={cloud_download} style={up_down} />
    </span>
  );

  useDidUpdate(() => {
    if (!_.isEqual(prevList, list)) invoiceSummary();
  }, [list]);

  useEffect(() => {
    invoiceSummary();
  }, [type]);

  /** handle unpaid iban payouts */
  useEffect(() => {
    if (platformSummary?.summary?.length) {
      let unpaid = 0;
      platformSummary?.summary?.forEach((platform) => {
        if (platform.payment_platform === "iban" || platform.payment_platform === "wise") {
          unpaid += platform.total_amount;
        }
      });
      setUnpaidIban(unpaid);
    }
  }, [platformSummary?.summary]);

  return (
    <Wrapper>
      <div className="project-payments">
        <div className="pay-summary">
          <ul className="p-0">
            {showUSDSummary && showEURSummary && (
              <li className={listClassName}>
                <span className="invisible">Currency</span>
                {showEURSummary && <p className="currency">EUR</p>}
                <p className="currency">USD</p>
              </li>
            )}

            {isAdminOrPMOrSpaceLead() && (
              <li className={listClassName}>
                <span>Expected {title}</span>
                {showEURSummary && <p>€{numberWithCommas(summary?.EUR?.total_expected)}</p>}
                {showUSDSummary && <p>${numberWithCommas(summary?.USD?.total_expected)}</p>}
              </li>
            )}
            <li className={listClassName}>
              <span>Total {title}</span>
              {showEURSummary && <p>€{numberWithCommas(summary?.EUR?.total)}</p>}
              {showUSDSummary && <p>${numberWithCommas(summary?.USD?.total)}</p>}
            </li>
            <li className={listClassName}>
              <span>Paid</span>
              {showEURSummary && <p className="green">€{numberWithCommas(summary?.EUR?.paid)}</p>}
              {showUSDSummary && <p className="green">${numberWithCommas(summary?.USD?.paid)}</p>}
            </li>
            <li className={listClassName}>
              <span>Unpaid</span>
              {showEURSummary && <p className="red">€{numberWithCommas(summary?.EUR?.unpaid)}</p>}
              {showUSDSummary && <p className="red">${numberWithCommas(summary?.USD?.unpaid)}</p>}
            </li>

            {type === "in" && (
              <li className={listClassName}>
                <span>Credit Notes</span>
                {showEURSummary && (
                  <p>
                    -€
                    {summary?.EUR?.credit_notes
                      ? numberWithCommas(summary?.EUR?.credit_notes.total)
                      : "0.00"}
                  </p>
                )}
                {showUSDSummary && (
                  <p>
                    -$
                    {summary?.USD?.credit_notes
                      ? numberWithCommas(summary?.USD?.credit_notes.total)
                      : "0.00"}
                  </p>
                )}
              </li>
            )}
          </ul>
          {(isAdmin() || isPayAdmin()) && scope === "sideBarPayment" && type === "out" && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <PayoutSummary>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  {platformSummary?.summary?.map((platform, idx, array) => {
                    const nextElem = array[idx + 1];
                    return (
                      <span key={idx} className="mr-1" style={{ fontSize: "14px" }}>
                        <i>
                          {platform?.payment_platform === "iban"
                            ? platform?.payment_platform.toUpperCase()
                            : platform.payment_platform.charAt(0).toUpperCase() +
                              platform.payment_platform.slice(1)}
                        </i>{" "}
                        &mdash; €{numberWithCommas(platform.total_amount) || 0}{" "}
                        <span className="mx-1" style={{ color: "#fff", opacity: "0.15" }}>
                          {nextElem ? "|" : null}
                        </span>
                      </span>
                    );
                  })}
                  {download ? (
                    <>
                      {unpaidIban > 0 ? (
                        <a
                          href={IBAN_UNPAID_EXPORT}
                          style={{ all: "unset", marginTop: -5 }}
                          download
                        >
                          <DownloadIcon />
                        </a>
                      ) : (
                        <DownloadIcon />
                      )}
                      <span aria-hidden="true" className="ml-1" onClick={() => setDownload(false)}>
                        <img alt="hey" src={downArrow} style={arrow} />
                      </span>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={onUpload}
                        ref={fileInput}
                        accept=".xlsx"
                        id="file"
                        style={{ display: "none" }}
                      />
                      <span
                        aria-hidden="true"
                        style={{ marginTop: "-5px" }}
                        tabIndex={0}
                        role="button"
                        onClick={onTrigger}
                      >
                        <img alt="trigger" src={cloud_upload} style={up_down} />
                      </span>
                      <span aria-hidden="true" className="ml-1" onClick={() => setDownload(true)}>
                        <img alt="" src={upArrow} style={arrow} />
                      </span>
                    </>
                  )}
                </div>
              </PayoutSummary>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

PaymentTotals.propTypes = {
  project: PropTypes.number,
  type: PropTypes.string.isRequired,
  summariesRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};

PaymentTotals.defaultProps = {
  project: null,
};

const Wrapper = styled.div`
  border-bottom: 1px solid #edf1f7;
  ul {
    margin-top: 60px;
    list-style: none;
    display: inline-flex;
    justify-content: space-evenly;
    width: 55%;
    margin-left: -1.5%;

    li {
      text-align: center;
      text-align: center;
      text-transform: capitalize;
      line-height: 150%;

      &.align-list {
        margin: 0 auto 0 0;
      }

      span,
      .currency {
        font-weight: 500;
        font-size: 14px;
        color: #8f9bb3;
      }

      p {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 8px;
      }
    }

    .green {
      color: #219653;
    }

    .red {
      color: #eb5757;
    }

    li p {
      color: #3e4857;
    }
  }
`;

export default PaymentTotals;
