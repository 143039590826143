/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import IBAN from "iban";

import FieldError from "../FieldError";
import HelpIcon from "../svg/HelpIcon";
import { Label, Title } from "./styles";
import Input from "../Input";

const WiseForm = ({ id, proceed, payload: data, submitError }) => {
  const [email, setEmail] = useState(data.email);
  const [name, setName] = useState(data.name);
  const [iban, setIban] = useState(data.iban);
  const [emailError, setEmailError] = useState(null);
  const [ibanError, setIbanError] = useState(null);
  const [nameError, setNameError] = useState(null);

  const validateEmail = (emailString) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailString).toLowerCase());
  };

  const handleEmail = (event) => {
    const { value } = event.target;
    if (validateEmail(value)) {
      setEmailError(null);
      setEmail(value);
    } else {
      setEmailError("Enter a valid email address");
      setEmail(value);
    }
  };

  const handleName = (event) => {
    const { value } = event.target;
    if (typeof value === "string" && value.trim()) {
      setNameError(null);
      setName(value);
    } else {
      setNameError("Enter a valid name");
      setName(value.trim());
    }
  };

  const handleIban = (event) => {
    const { value } = event.target;
    if (IBAN.isValid(value)) {
      setIbanError(null);
      setIban(value);
    } else {
      setIbanError("Enter a valid IBAN");
      setIban(value);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (nameError || emailError || ibanError) {
      return;
    }

    proceed({ email, name, iban, emailError, ibanError });
  };

  return (
    <form className="mt-3" id={id} data-testid="wise-form" onSubmit={onSubmit}>
      <Title>Enter the details below to connect Wise</Title>

      {submitError && (
        <div style={{ marginBottom: "1em" }}>
          <FieldError message={submitError} />
        </div>
      )}

      <FormGroup className="mb-4">
        <Label htmlFor="email" className="mb-2">
          Email address
        </Label>
        <Input
          dataTestId="email"
          id="email"
          type="email"
          className="py-0"
          placeholder="Type your Wise email address"
          value={email}
          onChange={handleEmail}
          required
        />
        {emailError && <FieldError message={emailError} />}
      </FormGroup>

      <FormGroup className="mb-4">
        <Label htmlFor="accountName" className="mb-2">
          Full name of account holder
        </Label>
        <Input
          id="accountName"
          dataTestId="accountName"
          type="text"
          className="py-0"
          placeholder="Enter name"
          value={name}
          onChange={handleName}
          required
        />
        {nameError && <FieldError message={nameError} />}
      </FormGroup>

      <FormGroup>
        <Label className="d-flex justify-content-between mb-2" htmlFor="iban">
          <span className="d-inline-block">IBAN</span>
          <span className="d-inline-block text-right align-self-end">
            <HelpIcon />
          </span>
        </Label>
        <Input
          id="iban"
          dataTestId="iban"
          type="text"
          className="py-0"
          placeholder="000000000"
          value={iban}
          onChange={handleIban}
          required
        />
        {ibanError && <FieldError message={ibanError} />}
      </FormGroup>
    </form>
  );
};

export default WiseForm;
