import React from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import SectionNav from "../../../../../components/SectionNav";
import Details from "./Details";
import { isAdminOrPMOrSpaceLead, isCSO } from "../../../../../utils/auth";
import Team from "./Team";
import Reports from "./Reports";

const Settings = () => {
  const match = useRouteMatch();

  return (
    <>
      <SectionNav
        links={[
          ...(isAdminOrPMOrSpaceLead() || isCSO()
            ? [
                {
                  route: `${match.url.replace("/", "")}/details`,
                  name: "Details",
                },
                {
                  route: `${match.url.replace("/", "")}/team`,
                  name: "Team",
                },
                {
                  route: `${match.url.replace("/", "")}/reports`,
                  name: "Reports",
                },
              ]
            : []),
        ]}
      />

      <Switch>
        {[
          ["details", <Details />],
          ["team", <Team />],
          ["reports", <Reports />],
        ].map((path) => (
          <Route
            key={`report-${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => {
              /* istanbul ignore next */
              return <>{path[1]}</>;
            }}
          />
        ))}
        <Redirect
          exact
          from={`${match.url}`}
          to={`${match.url}/${isAdminOrPMOrSpaceLead() || isCSO() ? "details" : "integrations"}`}
        />
      </Switch>
    </>
  );
};

export default Settings;
