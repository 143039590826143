/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Select from "../Select";
import { getCountries } from "../../redux/actions/CommonActions";

const CountrySelector = ({
  className,
  value,
  selected,
  onChange,
  size,
  placeholder,
  onBlur,
  required,
}) => {
  const [state, setState] = useState({ selected });
  const dispatch = useDispatch();

  const { countries } = useSelector(({ Common }) => Common);

  const getOptions = () => {
    const options = [];

    options.push({ value: "", name: placeholder || "-- Select a country --" });

    const countriesWithCodes = countries?.reduce((accumulator, currentValue) => {
      if (currentValue.code) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    if (countriesWithCodes && countriesWithCodes.length) {
      countriesWithCodes.forEach((country, idx) => {
        if (country.code || idx) {
          options.push({
            value: country.code,
            name: country.name === "The Netherlands" ? "Netherlands" : country.name,
          });
        }
      });
    }
    return options;
  };

  const handleChange = (e) => {
    const { value: choice } = e.target;
    setState({ selected: choice });

    if (onChange) onChange(choice);
  };

  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(getCountries());
    }
  }, [countries]);

  return (
    <Wrapper>
      <Select
        className={className}
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        required={required}
        selected={state.selected}
        value={value}
        options={getOptions()}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

CountrySelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default CountrySelector;
